const isBrowser = typeof window !== 'undefined';

export const setItemInSessionStorage = (key: string, value: any): void => {
    if (isBrowser) {
        window.sessionStorage.setItem(key, JSON.stringify(value));
    }
};

export const getItemFromSessionStorage = (key: string): any => {
    if (isBrowser) {
        const stringifiedItem: string | null =
            window.sessionStorage.getItem(key);
        return stringifiedItem ? JSON.parse(stringifiedItem) : null;
    }
    return null;
};

export const removeFromSessionStorage = (key: string): void => {
    if (isBrowser) {
        window.sessionStorage.removeItem(key);
    }
};
