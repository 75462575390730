/* eslint-disable import/no-unresolved */
/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { useEffect, useState } from "react";

import { getEnvironmentVariableServerSide } from "@/utils/server-functions/getEnvServerSide";

export const useGetEnvironmentVariable = (variableNames: string[]) => {
	const [variables, setVariables] = useState<
		Record<string, string | undefined>
	>({});

	useEffect(() => {
		const runAsync = async () => {
			for (const variableName of variableNames) {
				const { [variableName]: value } =
					await getEnvironmentVariableServerSide([variableName]);
				setVariables(
					(previousVariables: Record<string, string | undefined>) => ({
						...previousVariables,
						[variableName]: value,
					})
				);
			}
		};
		runAsync();
	}, []);

	return variables;
};
