import fetchShopify from '../helpers/fetchShopify';

import type { SerializedError } from '@reduxjs/toolkit/dist';

// https://shopify.dev/api/storefront/reference/customers/customer
export interface ShopifyStorefrontCustomer {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    acceptsMarketing: boolean;
}

export interface ShopifyStorefrontCustomerResponseData {
    customer: ShopifyStorefrontCustomer & { metafield: { value: string } };
}

export type CustomerOrderErrorCode = 'FORBIDDEN';

export class CustomerOrderError extends Error implements SerializedError {
    readonly code: CustomerOrderErrorCode;

    constructor(code: CustomerOrderErrorCode, message: string) {
        super(message);
        this.code = code;
        this.name = 'CustomerOrderError';
    }
}

export const customerIdQuery = `query customerId($customerAccessToken: String!) {
    customer(customerAccessToken: $customerAccessToken) {
        id
        firstName
        lastName
        email
    }
}`;

export const customerInfoQuery = `query customerInfo($customerAccessToken: String!, $namespace: String!, $key: String!) {
    customer(customerAccessToken: $customerAccessToken) {
        id
        firstName
        lastName
        email
        metafield(key: $key, namespace: $namespace) {
            value
        }
    }
}`;

// Note that in Shopify, cancelledAt is misspelled as "canceledAt"
export const customerOrdersQuery = `query customerOrders($customerAccessToken: String!) {
    customer(customerAccessToken: $customerAccessToken) {
        orders(first: 250) {
            edges {
                node {
                    canceledAt, 
                    financialStatus,
                    processedAt
                    lineItems(first: 250) {
                        edges {
                            node {
                                variant {
                                    sku
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}`;

export interface CustomerOrdersQueryVariables {
    customerAccessToken: string;
}

export interface LineItemNode {
    variant: {
        sku: string;
    } | null;
}

export interface CustomerOrdersQueryResponse {
    data: {
        customer: {
            orders: {
                edges: {
                    node: {
                        canceledAt: string | null;
                        financialStatus: string;
                        processedAt: string;
                        lineItems: {
                            edges: {
                                node: LineItemNode | null;
                            }[];
                        };
                    };
                }[];
            };
        };
    };
}

export function fetchShopifyCustomerOrders(
    queryVariables: CustomerOrdersQueryVariables,
): Promise<CustomerOrdersQueryResponse> {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return fetchShopify({
        query: customerOrdersQuery,
        queryVariables,
    });
}

type FetchCustomerOrderSKUsResponse = { sku: string; processedAt: string }[];

export async function fetchCustomerOrders(
    queryVariables: CustomerOrdersQueryVariables,
): Promise<FetchCustomerOrderSKUsResponse> {
    const response = await fetchShopifyCustomerOrders(queryVariables);
    if (!response.data.customer)
        throw new CustomerOrderError(
            'FORBIDDEN',
            'Account not found, it may have been blocked or deleted. If this is unexpected please contact the helpdesk.',
        );
    // eslint-disable-next-line unicorn/no-array-reduce
    return response.data.customer.orders.edges.reduce<FetchCustomerOrderSKUsResponse>(
        (skus, orderEdge) => {
            if (
                orderEdge.node.canceledAt === null &&
                orderEdge.node.financialStatus === 'PAID'
            ) {
                return [
                    ...skus,
                    // eslint-disable-next-line unicorn/no-array-reduce
                    ...orderEdge.node.lineItems.edges.reduce<
                        { sku: string; processedAt: string }[]
                    >((lineItemSKUs, lineItemEdge) => {
                        const itemSKUs = [...lineItemSKUs];

                        if (lineItemEdge.node?.variant?.sku) {
                            itemSKUs.push({
                                sku: lineItemEdge.node.variant.sku,
                                processedAt: orderEdge.node.processedAt,
                            });
                        }
                        return [...itemSKUs].filter((sku) => sku.sku);
                    }, []),
                ];
            }
            return skus;
        },
        [],
    );
}
