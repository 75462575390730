/* eslint-disable @typescript-eslint/require-await */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable unicorn/prevent-abbreviations */
"use server";

import { unstable_noStore } from "next/cache";

export const getEnvironmentVariableServerSide = async (names: string[]) => {
	unstable_noStore(); // This is a hack to prevent the cache from being used in this function

	const env: Record<string, string | undefined> = {};

	for (const name of names) {
		env[name] = process.env[name] ?? "";
	}

	return env;
};
