import { BLOCKS } from '@contentful/rich-text-types';

import { MOCK_EVENT } from './eventData';
import { eventInfoLogoUrl } from './contentfulData';

import type { ContentfulEventHero, DiscoverCarousel } from '../types';

export const DISCOVER_RESPONSE_MOCK: {
    discoverCarousels: DiscoverCarousel[];
} = {
    discoverCarousels: [
        {
            size: 'small',
            weight: 0,
            title: 'Past Shows',
            events: [
                MOCK_EVENT,
                MOCK_EVENT,
                {
                    __type: 'event',
                    title: 'Anne Marie Therapy Past',
                    slug: 'annemarie-therapypast',
                    shopifyProductId: '6720059736256',
                    genres: [],
                    variants: [
                        {
                            title: 'Friday / 20:00 AEST / HD + Stereo',
                            sku: 'annemarie-therapy-fri-aest-hd',
                            doorsOpen: '2021-12-14T21:00+01:00',
                            saleEnd: '2021-12-11T21:00+01:00',
                            eventStart: '2021-12-14T14:00+01:00',
                            eventEnd: '2021-12-14T18:00+01:00',
                            stream: {
                                title: 'annmarie-therapy-fri-aest-hd',
                                dashUrl:
                                    'https://test.onair.events/vod/20211227-144705_dolby-demo-nodrm-conf-v2_1_3/dash/index_web.mpd',
                                hlsUrl: 'https://test.onair.events/vod/20211227-144705_dolby-demo-nodrm-conf-v2_1_3/cmaf/index.m3u8',
                            },
                            options: [
                                {
                                    position: 1,
                                    value: 'Friday',
                                },
                                {
                                    position: 2,
                                    value: '20:00 AEST',
                                },
                                {
                                    position: 3,
                                    value: 'HD + Stereo',
                                },
                            ],
                        },
                        {
                            title: 'Saturday / 20:00 EST / HD + Stereo',
                            sku: 'annemarie-therapy-sat-aest-hd',
                            doorsOpen: '2021-12-11T21:00+01:00',
                            saleEnd: '2021-12-11T21:00+01:00',
                            eventStart: '2021-12-04T20:00+01:00',
                            eventEnd: '2021-12-05T00:00+01:00',
                            stream: {
                                title: 'annmarie-therapy-fri-aest-hd',
                                dashUrl:
                                    'https://test.onair.events/vod/20211227-144705_dolby-demo-nodrm-conf-v2_1_3/dash/index_web.mpd',
                                hlsUrl: 'https://test.onair.events/vod/20211227-144705_dolby-demo-nodrm-conf-v2_1_3/cmaf/index.m3u8',
                            },
                            options: [
                                {
                                    position: 1,
                                    value: 'Saturday',
                                },
                                {
                                    position: 2,
                                    value: '20:00 EST',
                                },
                                {
                                    position: 3,
                                    value: 'HD + Stereo',
                                },
                            ],
                        },
                        {
                            title: 'Friday / 21:00 CEST / HD + Stereo',
                            sku: 'annemarie-therapy-fri-cest-hd',
                            doorsOpen: '2021-12-11T21:00+01:00',
                            saleEnd: '2021-12-11T21:00+01:00',
                            eventStart: '2021-12-10T21:00+01:00',
                            eventEnd: '2021-12-10T23:00+01:00',
                            stream: {
                                title: 'annmarie-therapy-fri-aest-hd',
                                dashUrl:
                                    'https://test.onair.events/vod/20211227-144705_dolby-demo-nodrm-conf-v2_1_3/dash/index_web.mpd',
                                hlsUrl: 'https://test.onair.events/vod/20211227-144705_dolby-demo-nodrm-conf-v2_1_3/cmaf/index.m3u8',
                            },
                            options: [
                                {
                                    position: 1,
                                    value: 'Friday',
                                },
                                {
                                    position: 2,
                                    value: '21:00 CEST',
                                },
                                {
                                    position: 3,
                                    value: 'HD + Stereo',
                                },
                            ],
                        },
                        {
                            title: 'Saturday / 21:00 CEST / HD + Stereo',
                            sku: 'annemarie-therapy-sat-cest-hd',
                            doorsOpen: '2021-12-11T21:00+01:00',
                            saleEnd: '2021-12-11T21:00+01:00',
                            eventStart: '2021-12-11T21:00+01:00',
                            eventEnd: '2021-12-12T00:00+01:00',
                            stream: {
                                title: 'annmarie-therapy-fri-aest-hd',
                                dashUrl:
                                    'https://test.onair.events/vod/20211227-144705_dolby-demo-nodrm-conf-v2_1_3/dash/index_web.mpd',
                                hlsUrl: 'https://test.onair.events/vod/20211227-144705_dolby-demo-nodrm-conf-v2_1_3/cmaf/index.m3u8',
                            },
                            options: [
                                {
                                    position: 1,
                                    value: 'Saturday',
                                },
                                {
                                    position: 2,
                                    value: '21:00 CEST',
                                },
                                {
                                    position: 3,
                                    value: 'HD + Stereo',
                                },
                            ],
                        },
                        {
                            title: 'Friday / 22:00 GMT / HD + Stereo',
                            sku: 'annemarie-therapy-fri-gmt-hd',
                            doorsOpen: '2021-12-11T21:00+01:00',
                            saleEnd: '2021-12-11T21:00+01:00',
                            eventStart: '2021-12-10T22:00+01:00',
                            eventEnd: '2021-12-11T00:00+01:00',
                            stream: {
                                title: 'annmarie-therapy-fri-aest-hd',
                                dashUrl:
                                    'https://test.onair.events/vod/20211227-144705_dolby-demo-nodrm-conf-v2_1_3/dash/index_web.mpd',
                                hlsUrl: 'https://test.onair.events/vod/20211227-144705_dolby-demo-nodrm-conf-v2_1_3/cmaf/index.m3u8',
                            },
                            options: [
                                {
                                    position: 1,
                                    value: 'Friday',
                                },
                                {
                                    position: 2,
                                    value: '22:00 GMT',
                                },
                                {
                                    position: 3,
                                    value: 'HD + Stereo',
                                },
                            ],
                        },
                        {
                            title: 'Saturday / 22:00 GMT / HD + Stereo',
                            sku: 'annemarie-therapy-sat-gmt-hd',
                            doorsOpen: '2021-12-11T21:00+01:00',
                            saleEnd: '2021-12-11T21:00+01:00',
                            eventStart: '2021-12-10T22:00+01:00',
                            eventEnd: '2021-12-11T00:00+01:00',
                            stream: {
                                title: 'annmarie-therapy-fri-aest-hd',
                                dashUrl:
                                    'https://test.onair.events/vod/20211227-144705_dolby-demo-nodrm-conf-v2_1_3/dash/index_web.mpd',
                                hlsUrl: 'https://test.onair.events/vod/20211227-144705_dolby-demo-nodrm-conf-v2_1_3/cmaf/index.m3u8',
                            },
                            options: [
                                {
                                    position: 1,
                                    value: 'Saturday',
                                },
                                {
                                    position: 2,
                                    value: '22:00 GMT',
                                },
                                {
                                    position: 3,
                                    value: 'HD + Stereo',
                                },
                            ],
                        },
                        {
                            title: 'Friday / 20:00 AEST / 4K UHD + Dolby Atmos',
                            sku: 'annemarie-therapy-fri-aest-uhd',
                            doorsOpen: '2021-12-11T21:00+01:00',
                            saleEnd: '2021-12-11T21:00+01:00',
                            eventStart: '2021-12-10T20:00+01:00',
                            eventEnd: '2021-12-11T00:00+01:00',
                            stream: {
                                title: 'annmarie-therapy-fri-aest-hd',
                                dashUrl:
                                    'https://test.onair.events/vod/20211227-144705_dolby-demo-nodrm-conf-v2_1_3/dash/index_web.mpd',
                                hlsUrl: 'https://test.onair.events/vod/20211227-144705_dolby-demo-nodrm-conf-v2_1_3/cmaf/index.m3u8',
                            },
                            options: [
                                {
                                    position: 1,
                                    value: 'Friday',
                                },
                                {
                                    position: 2,
                                    value: '20:00 AEST',
                                },
                                {
                                    position: 3,
                                    value: '4K UHD + Dolby Atmos',
                                },
                            ],
                        },
                        {
                            title: 'Saturday / 20:00 AEST / 4K UHD + Dolby Atmos',
                            sku: 'annemarie-therapy-sat-aest-uhd',
                            doorsOpen: '2021-12-11T21:00+01:00',
                            saleEnd: '2021-12-11T21:00+01:00',
                            eventStart: '2021-12-11T20:00+01:00',
                            eventEnd: '2021-12-12T00:00+01:00',
                            stream: {
                                title: 'annmarie-therapy-fri-aest-hd',
                                dashUrl:
                                    'https://test.onair.events/vod/20211227-144705_dolby-demo-nodrm-conf-v2_1_3/dash/index_web.mpd',
                                hlsUrl: 'https://test.onair.events/vod/20211227-144705_dolby-demo-nodrm-conf-v2_1_3/cmaf/index.m3u8',
                            },
                            options: [
                                {
                                    position: 1,
                                    value: 'Saturday',
                                },
                                {
                                    position: 2,
                                    value: '20:00 AEST',
                                },
                                {
                                    position: 3,
                                    value: '4K UHD + Dolby Atmos',
                                },
                            ],
                        },
                        {
                            title: 'Friday / 21:00 CEST / 4K UHD + Dolby Atmos',
                            sku: 'annemarie-therapy-fri-cest-uhd',
                            doorsOpen: '2021-12-11T21:00+01:00',
                            saleEnd: '2021-12-11T21:00+01:00',
                            eventStart: '2021-12-10T21:00+01:00',
                            eventEnd: '2021-12-11T00:00+01:00',
                            stream: {
                                title: 'annmarie-therapy-fri-aest-hd',
                                dashUrl:
                                    'https://test.onair.events/vod/20211227-144705_dolby-demo-nodrm-conf-v2_1_3/dash/index_web.mpd',
                                hlsUrl: 'https://test.onair.events/vod/20211227-144705_dolby-demo-nodrm-conf-v2_1_3/cmaf/index.m3u8',
                            },
                            options: [
                                {
                                    position: 1,
                                    value: 'Friday',
                                },
                                {
                                    position: 2,
                                    value: '21:00 CEST',
                                },
                                {
                                    position: 3,
                                    value: '4K UHD + Dolby Atmos',
                                },
                            ],
                        },
                        {
                            title: 'Saturday / 21:00 CEST / 4K UHD + Dolby Atmos',
                            sku: 'annemarie-therapy-sat-cest-uhd',
                            doorsOpen: '2021-12-11T21:00+01:00',
                            saleEnd: '2021-12-11T21:00+01:00',
                            eventStart: '2021-12-11T21:00+01:00',
                            eventEnd: '2021-12-12T00:00+01:00',
                            stream: {
                                title: 'annmarie-therapy-fri-aest-hd',
                                dashUrl:
                                    'https://test.onair.events/vod/20211227-144705_dolby-demo-nodrm-conf-v2_1_3/dash/index_web.mpd',
                                hlsUrl: 'https://test.onair.events/vod/20211227-144705_dolby-demo-nodrm-conf-v2_1_3/cmaf/index.m3u8',
                            },
                            options: [
                                {
                                    position: 1,
                                    value: 'Saturday',
                                },
                                {
                                    position: 2,
                                    value: '21:00 CEST',
                                },
                                {
                                    position: 3,
                                    value: '4K UHD + Dolby Atmos',
                                },
                            ],
                        },
                        {
                            title: 'Friday / 22:00 GMT / 4K UHD + Dolby Atmos',
                            sku: 'annemarie-therapy-fri-gmt-uhd',
                            doorsOpen: '2021-12-11T21:00+01:00',
                            saleEnd: '2021-12-11T21:00+01:00',
                            eventStart: '2021-12-10T22:00+01:00',
                            eventEnd: '2021-12-11T00:00+01:00',
                            stream: {
                                title: 'annmarie-therapy-fri-aest-hd',
                                dashUrl:
                                    'https://test.onair.events/vod/20211227-144705_dolby-demo-nodrm-conf-v2_1_3/dash/index_web.mpd',
                                hlsUrl: 'https://test.onair.events/vod/20211227-144705_dolby-demo-nodrm-conf-v2_1_3/cmaf/index.m3u8',
                            },
                            options: [
                                {
                                    position: 1,
                                    value: 'Saturday',
                                },
                                {
                                    position: 2,
                                    value: '22:00 GMT',
                                },
                                {
                                    position: 3,
                                    value: '4K UHD + Dolby Atmos',
                                },
                            ],
                        },
                        {
                            title: 'Saturday / 22:00 GMT / 4K UHD + Dolby Atmos',
                            sku: 'annemarie-therapy-sat-gmt-uhd',
                            doorsOpen: '2021-12-11T21:00+01:00',
                            saleEnd: '2021-12-11T21:00+01:00',
                            eventStart: '2021-12-11T22:00+01:00',
                            eventEnd: '2021-12-12T00:00+01:00',
                            stream: {
                                title: 'annmarie-therapy-fri-aest-hd',
                                dashUrl:
                                    'https://test.onair.events/vod/20211227-144705_dolby-demo-nodrm-conf-v2_1_3/dash/index_web.mpd',
                                hlsUrl: 'https://test.onair.events/vod/20211227-144705_dolby-demo-nodrm-conf-v2_1_3/cmaf/index.m3u8',
                            },
                            options: [
                                {
                                    position: 1,
                                    value: 'Saturday',
                                },
                                {
                                    position: 2,
                                    value: '22:00 GMT',
                                },
                                {
                                    position: 3,
                                    value: '4K UHD + Dolby Atmos',
                                },
                            ],
                        },
                    ],
                    poster: {
                        asset: {
                            title: 'Anne-Marie-Therapy',
                            description: 'on air home page banner',
                            url: '//images.ctfassets.net/s26j9zm7rn0w/3AvhknIQvgH6RVOUMTxQoq/51bd9204765c8a4b2798197a5c6395fa/Anne_Marie_1.jpeg',
                            width: 750,
                            height: 387,
                            file: {
                                url: '//images.ctfassets.net/s26j9zm7rn0w/3AvhknIQvgH6RVOUMTxQoq/51bd9204765c8a4b2798197a5c6395fa/Anne_Marie_1.jpeg',
                                details: {
                                    size: 92908,
                                    image: {
                                        width: 750,
                                        height: 387,
                                    },
                                },
                                fileName: 'Anne Marie 1.jpeg',
                                contentType: 'image/jpeg',
                            },
                        },
                        alt: 'Anne Marie listening to her fans with a mic in her hand',
                    },
                    eventInfoLogo: {
                        asset: {
                            title: 'Logo Title',
                            description: '',
                            url: eventInfoLogoUrl,
                            width: 366,
                            height: 180,
                            file: {
                                url: eventInfoLogoUrl,
                                details: {
                                    size: 25531,
                                    image: {
                                        width: 366,
                                        height: 180,
                                    },
                                },
                                fileName: 'Logo Title.png',
                                contentType: 'image/png',
                            },
                        },
                        alt: 'Anne Marie Therapy - The Live Experience with a pink and pretty font',
                    },
                    tags: ['past-events'],
                    type: 'spo',
                    blocks: [
                        {
                            __type: 'contentBlock',
                            type: 'EventDescription',
                            title: 'Therapy',
                            description: {
                                data: {},
                                content: [
                                    {
                                        data: {},
                                        content: [
                                            {
                                                data: {},
                                                marks: [],
                                                value: '‘Therapy’ is a collection of songs that embody Anne-Marie’s characterful artistry, self-effacing attitude, and beautiful honesty, which has seen the Essex-born star reign supreme as Gen Z role model across the globe. The highly-anticipated new album will include Anne-Marie’s UK Top 3 ‘Don’t Play’ with KSI and Digital Farm Animals, feel-good Nathan Dawe and MoStack collaboration ‘Way Too Long’; and her recent Niall Horan duet ‘Our Song’.',
                                                nodeType: 'text',
                                            },
                                        ],
                                        nodeType: BLOCKS.PARAGRAPH,
                                    },
                                ],
                                nodeType: BLOCKS.DOCUMENT,
                            },
                            imagePosition: 'right',
                            shouldShowSocialButtons: true,
                            showAppStoreBanners: false,
                            image: {
                                asset: {
                                    title: 'Logo Title',
                                    description: '',
                                    url: eventInfoLogoUrl,
                                    width: 366,
                                    height: 180,
                                    file: {
                                        url: eventInfoLogoUrl,
                                        details: {
                                            size: 25531,
                                            image: {
                                                width: 366,
                                                height: 180,
                                            },
                                        },
                                        fileName: 'Logo Title.png',
                                        contentType: 'image/png',
                                    },
                                },
                                alt: 'Anne Marie Therapy - The Live Experience with a pink and pretty font',
                            },
                        },
                        {
                            __type: 'videoBlock',
                            youtubeLink:
                                'https://www.youtube.com/watch?v=r3RXHOTMmLw&ab_channel=Anne-Marie',
                        },
                        {
                            __type: 'contentBlock',
                            type: 'Artist',
                            title: 'Anne-Marie',
                            description: {
                                data: {},
                                content: [
                                    {
                                        data: {},
                                        content: [
                                            {
                                                data: {},
                                                marks: [],
                                                value: 'Anne-Marie is an English singer and songwriter. She has attained several charting singles on the UK Singles Chart, including Clean Bandit\'s "Rockabye", featuring Sean Paul, which peaked at number one, as well as "Alarm", "Ciao Adios", "Friends", "2002" and "Don\'t Play". Her debut studio album Speak Your Mind was released on 27 April 2018, and peaked at number three on the UK Albums Chart. She was nominated for four awards at the 2019 Brit Awards, including Best British Female Solo Artist. In 2015, she signed a record deal with Asylum (a sub-label of Atlantic Records) and, till 2018, she began releasing her work through the label.',
                                                nodeType: 'text',
                                            },
                                        ],
                                        nodeType: BLOCKS.PARAGRAPH,
                                    },
                                ],
                                nodeType: BLOCKS.DOCUMENT,
                            },
                            imagePosition: 'left',
                            shouldShowSocialButtons: false,
                            showAppStoreBanners: false,
                            image: {
                                asset: {
                                    title: 'Logo Title',
                                    description: '',
                                    url: eventInfoLogoUrl,
                                    width: 366,
                                    height: 180,
                                    file: {
                                        url: eventInfoLogoUrl,
                                        details: {
                                            size: 25531,
                                            image: {
                                                width: 366,
                                                height: 180,
                                            },
                                        },
                                        fileName: 'Logo Title.png',
                                        contentType: 'image/png',
                                    },
                                },
                                alt: 'Anne Marie Therapy - The Live Experience with a pink and pretty font',
                            },
                        },
                        {
                            __type: 'galleryBlock',
                            preserveAspectRatio: false,
                            images: [
                                {
                                    asset: {
                                        title: 'Annemarie during brit awards',
                                        description: '',
                                        url: '//images.ctfassets.net/s26j9zm7rn0w/40gW57BZYEWP0TOGaYkL18/bb1273f3e517b9c359f538e2a01b1c17/awards_2.jpeg',
                                        width: 1200,
                                        height: 800,
                                        file: {
                                            url: '//images.ctfassets.net/s26j9zm7rn0w/40gW57BZYEWP0TOGaYkL18/bb1273f3e517b9c359f538e2a01b1c17/awards_2.jpeg',
                                            details: {
                                                size: 117153,
                                                image: {
                                                    width: 1200,
                                                    height: 800,
                                                },
                                            },
                                            fileName: 'awards 2.jpeg',
                                            contentType: 'image/jpeg',
                                        },
                                    },
                                    alt: 'Annemarie during the brit awards',
                                },
                                {
                                    asset: {
                                        title: 'Annemarie in her car',
                                        description: '',
                                        url: '//images.ctfassets.net/s26j9zm7rn0w/7DhugovEReeLsVdDf9kYYT/7fa6befd99a4a597a14722d39bb6d837/annemarie_car.jpeg',
                                        width: 1638,
                                        height: 2048,
                                        file: {
                                            url: '//images.ctfassets.net/s26j9zm7rn0w/7DhugovEReeLsVdDf9kYYT/7fa6befd99a4a597a14722d39bb6d837/annemarie_car.jpeg',
                                            details: {
                                                size: 295556,
                                                image: {
                                                    width: 1638,
                                                    height: 2048,
                                                },
                                            },
                                            fileName: 'annemarie car.jpeg',
                                            contentType: 'image/jpeg',
                                        },
                                    },
                                    alt: 'Annemarie in a fancy auto',
                                },
                                {
                                    asset: {
                                        title: 'Annemarie close up',
                                        description: '',
                                        url: '//images.ctfassets.net/s26j9zm7rn0w/6O9wB7UnbXotxpEiXDd6zM/49eeb881a13aa7c2f2bc92b7990ea4a0/anne-marie-2-1610125552-view-1.jpeg',
                                        width: 460,
                                        height: 374,
                                        file: {
                                            url: '//images.ctfassets.net/s26j9zm7rn0w/6O9wB7UnbXotxpEiXDd6zM/49eeb881a13aa7c2f2bc92b7990ea4a0/anne-marie-2-1610125552-view-1.jpeg',
                                            details: {
                                                size: 28123,
                                                image: {
                                                    width: 460,
                                                    height: 374,
                                                },
                                            },
                                            fileName:
                                                'anne-marie-2-1610125552-view-1.jpeg',
                                            contentType: 'image/jpeg',
                                        },
                                    },
                                    alt: 'Annemarie close up',
                                },
                            ],
                        },
                    ],
                    socialLinks: {
                        instagramLink: 'https://www.instagram.com/annemarie/',
                        twitterLink: 'https://twitter.com/annemarie',
                        facebookLink: 'https://facebook.com/iamannemarie',
                    },
                },
            ],
        },
        {
            size: 'medium',
            weight: 1,
            title: 'Upcoming Shows',
            events: [
                MOCK_EVENT,
                MOCK_EVENT,
                {
                    __type: 'event',
                    type: 'vod',
                    title: 'Anne Marie Therapy Future',
                    slug: 'annemarie-therapyfuture',
                    shopifyProductId: 'fake-id-2',
                    variants: [
                        {
                            title: 'Friday / 20:00 AEST / HD + Stereo',
                            sku: 'annemarie-therapy-fri-aest-hd',
                            doorsOpen: '2021-12-14T21:00+01:00',
                            saleEnd: '2021-12-11T21:00+01:00',
                            eventStart: '2021-12-14T14:00+01:00',
                            eventEnd: '2021-12-14T18:00+01:00',
                            stream: {
                                title: 'annmarie-therapy-fri-aest-hd',
                                dashUrl:
                                    'https://test.onair.events/vod/20211227-144705_dolby-demo-nodrm-conf-v2_1_3/dash/index_web.mpd',
                                hlsUrl: 'https://test.onair.events/vod/20211227-144705_dolby-demo-nodrm-conf-v2_1_3/cmaf/index.m3u8',
                            },
                            options: [
                                {
                                    position: 1,
                                    value: 'Friday',
                                },
                                {
                                    position: 2,
                                    value: '20:00 AEST',
                                },
                                {
                                    position: 3,
                                    value: 'HD + Stereo',
                                },
                            ],
                        },
                        {
                            title: 'Saturday / 20:00 EST / HD + Stereo',
                            sku: 'annemarie-therapy-sat-aest-hd',
                            doorsOpen: '2021-12-11T21:00+01:00',
                            saleEnd: '2021-12-11T21:00+01:00',
                            eventStart: '2021-12-04T20:00+01:00',
                            eventEnd: '2021-12-05T00:00+01:00',
                            stream: {
                                title: 'annmarie-therapy-fri-aest-hd',
                                dashUrl:
                                    'https://test.onair.events/vod/20211227-144705_dolby-demo-nodrm-conf-v2_1_3/dash/index_web.mpd',
                                hlsUrl: 'https://test.onair.events/vod/20211227-144705_dolby-demo-nodrm-conf-v2_1_3/cmaf/index.m3u8',
                            },
                            options: [
                                {
                                    position: 1,
                                    value: 'Saturday',
                                },
                                {
                                    position: 2,
                                    value: '20:00 EST',
                                },
                                {
                                    position: 3,
                                    value: 'HD + Stereo',
                                },
                            ],
                        },
                        {
                            title: 'Friday / 21:00 CEST / HD + Stereo',
                            sku: 'annemarie-therapy-fri-cest-hd',
                            doorsOpen: '2021-12-11T21:00+01:00',
                            saleEnd: '2021-12-11T21:00+01:00',
                            eventStart: '2021-12-10T21:00+01:00',
                            eventEnd: '2021-12-10T23:00+01:00',
                            stream: {
                                title: 'annmarie-therapy-fri-aest-hd',
                                dashUrl:
                                    'https://test.onair.events/vod/20211227-144705_dolby-demo-nodrm-conf-v2_1_3/dash/index_web.mpd',
                                hlsUrl: 'https://test.onair.events/vod/20211227-144705_dolby-demo-nodrm-conf-v2_1_3/cmaf/index.m3u8',
                            },
                            options: [
                                {
                                    position: 1,
                                    value: 'Friday',
                                },
                                {
                                    position: 2,
                                    value: '21:00 CEST',
                                },
                                {
                                    position: 3,
                                    value: 'HD + Stereo',
                                },
                            ],
                        },
                        {
                            title: 'Saturday / 21:00 CEST / HD + Stereo',
                            sku: 'annemarie-therapy-sat-cest-hd',
                            doorsOpen: '2021-12-11T21:00+01:00',
                            saleEnd: '2021-12-11T21:00+01:00',
                            eventStart: '2021-12-11T21:00+01:00',
                            eventEnd: '2021-12-12T00:00+01:00',
                            stream: {
                                title: 'annmarie-therapy-fri-aest-hd',
                                dashUrl:
                                    'https://test.onair.events/vod/20211227-144705_dolby-demo-nodrm-conf-v2_1_3/dash/index_web.mpd',
                                hlsUrl: 'https://test.onair.events/vod/20211227-144705_dolby-demo-nodrm-conf-v2_1_3/cmaf/index.m3u8',
                            },
                            options: [
                                {
                                    position: 1,
                                    value: 'Saturday',
                                },
                                {
                                    position: 2,
                                    value: '21:00 CEST',
                                },
                                {
                                    position: 3,
                                    value: 'HD + Stereo',
                                },
                            ],
                        },
                        {
                            title: 'Friday / 22:00 GMT / HD + Stereo',
                            sku: 'annemarie-therapy-fri-gmt-hd',
                            doorsOpen: '2021-12-11T21:00+01:00',
                            saleEnd: '2021-12-11T21:00+01:00',
                            eventStart: '2021-12-10T22:00+01:00',
                            eventEnd: '2021-12-11T00:00+01:00',
                            stream: {
                                title: 'annmarie-therapy-fri-aest-hd',
                                dashUrl:
                                    'https://test.onair.events/vod/20211227-144705_dolby-demo-nodrm-conf-v2_1_3/dash/index_web.mpd',
                                hlsUrl: 'https://test.onair.events/vod/20211227-144705_dolby-demo-nodrm-conf-v2_1_3/cmaf/index.m3u8',
                            },
                            options: [
                                {
                                    position: 1,
                                    value: 'Friday',
                                },
                                {
                                    position: 2,
                                    value: '22:00 GMT',
                                },
                                {
                                    position: 3,
                                    value: 'HD + Stereo',
                                },
                            ],
                        },
                        {
                            title: 'Saturday / 22:00 GMT / HD + Stereo',
                            sku: 'annemarie-therapy-sat-gmt-hd',
                            doorsOpen: '2021-12-11T21:00+01:00',
                            saleEnd: '2021-12-11T21:00+01:00',
                            eventStart: '2021-12-10T22:00+01:00',
                            eventEnd: '2021-12-11T00:00+01:00',
                            stream: {
                                title: 'annmarie-therapy-fri-aest-hd',
                                dashUrl:
                                    'https://test.onair.events/vod/20211227-144705_dolby-demo-nodrm-conf-v2_1_3/dash/index_web.mpd',
                                hlsUrl: 'https://test.onair.events/vod/20211227-144705_dolby-demo-nodrm-conf-v2_1_3/cmaf/index.m3u8',
                            },
                            options: [
                                {
                                    position: 1,
                                    value: 'Saturday',
                                },
                                {
                                    position: 2,
                                    value: '22:00 GMT',
                                },
                                {
                                    position: 3,
                                    value: 'HD + Stereo',
                                },
                            ],
                        },
                        {
                            title: 'Friday / 20:00 AEST / 4K UHD + Dolby Atmos',
                            sku: 'annemarie-therapy-fri-aest-uhd',
                            doorsOpen: '2021-12-11T21:00+01:00',
                            saleEnd: '2021-12-11T21:00+01:00',
                            eventStart: '2021-12-10T20:00+01:00',
                            eventEnd: '2021-12-11T00:00+01:00',
                            stream: {
                                title: 'annmarie-therapy-fri-aest-hd',
                                dashUrl:
                                    'https://test.onair.events/vod/20211227-144705_dolby-demo-nodrm-conf-v2_1_3/dash/index_web.mpd',
                                hlsUrl: 'https://test.onair.events/vod/20211227-144705_dolby-demo-nodrm-conf-v2_1_3/cmaf/index.m3u8',
                            },
                            options: [
                                {
                                    position: 1,
                                    value: 'Friday',
                                },
                                {
                                    position: 2,
                                    value: '20:00 AEST',
                                },
                                {
                                    position: 3,
                                    value: '4K UHD + Dolby Atmos',
                                },
                            ],
                        },
                        {
                            title: 'Saturday / 20:00 AEST / 4K UHD + Dolby Atmos',
                            sku: 'annemarie-therapy-sat-aest-uhd',
                            doorsOpen: '2021-12-11T21:00+01:00',
                            saleEnd: '2021-12-11T21:00+01:00',
                            eventStart: '2021-12-11T20:00+01:00',
                            eventEnd: '2021-12-12T00:00+01:00',
                            stream: {
                                title: 'annmarie-therapy-fri-aest-hd',
                                dashUrl:
                                    'https://test.onair.events/vod/20211227-144705_dolby-demo-nodrm-conf-v2_1_3/dash/index_web.mpd',
                                hlsUrl: 'https://test.onair.events/vod/20211227-144705_dolby-demo-nodrm-conf-v2_1_3/cmaf/index.m3u8',
                            },
                            options: [
                                {
                                    position: 1,
                                    value: 'Saturday',
                                },
                                {
                                    position: 2,
                                    value: '20:00 AEST',
                                },
                                {
                                    position: 3,
                                    value: '4K UHD + Dolby Atmos',
                                },
                            ],
                        },
                        {
                            title: 'Friday / 21:00 CEST / 4K UHD + Dolby Atmos',
                            sku: 'annemarie-therapy-fri-cest-uhd',
                            doorsOpen: '2021-12-11T21:00+01:00',
                            saleEnd: '2021-12-11T21:00+01:00',
                            eventStart: '2021-12-10T21:00+01:00',
                            eventEnd: '2021-12-11T00:00+01:00',
                            stream: {
                                title: 'annmarie-therapy-fri-aest-hd',
                                dashUrl:
                                    'https://test.onair.events/vod/20211227-144705_dolby-demo-nodrm-conf-v2_1_3/dash/index_web.mpd',
                                hlsUrl: 'https://test.onair.events/vod/20211227-144705_dolby-demo-nodrm-conf-v2_1_3/cmaf/index.m3u8',
                            },
                            options: [
                                {
                                    position: 1,
                                    value: 'Friday',
                                },
                                {
                                    position: 2,
                                    value: '21:00 CEST',
                                },
                                {
                                    position: 3,
                                    value: '4K UHD + Dolby Atmos',
                                },
                            ],
                        },
                        {
                            title: 'Saturday / 21:00 CEST / 4K UHD + Dolby Atmos',
                            sku: 'annemarie-therapy-sat-cest-uhd',
                            doorsOpen: '2021-12-11T21:00+01:00',
                            saleEnd: '2021-12-11T21:00+01:00',
                            eventStart: '2021-12-11T21:00+01:00',
                            eventEnd: '2021-12-12T00:00+01:00',
                            stream: {
                                title: 'annmarie-therapy-fri-aest-hd',
                                dashUrl:
                                    'https://test.onair.events/vod/20211227-144705_dolby-demo-nodrm-conf-v2_1_3/dash/index_web.mpd',
                                hlsUrl: 'https://test.onair.events/vod/20211227-144705_dolby-demo-nodrm-conf-v2_1_3/cmaf/index.m3u8',
                            },
                            options: [
                                {
                                    position: 1,
                                    value: 'Saturday',
                                },
                                {
                                    position: 2,
                                    value: '21:00 CEST',
                                },
                                {
                                    position: 3,
                                    value: '4K UHD + Dolby Atmos',
                                },
                            ],
                        },
                        {
                            title: 'Friday / 22:00 GMT / 4K UHD + Dolby Atmos',
                            sku: 'annemarie-therapy-fri-gmt-uhd',
                            doorsOpen: '2021-12-11T21:00+01:00',
                            saleEnd: '2021-12-11T21:00+01:00',
                            eventStart: '2021-12-10T22:00+01:00',
                            eventEnd: '2021-12-11T00:00+01:00',
                            stream: {
                                title: 'annmarie-therapy-fri-aest-hd',
                                dashUrl:
                                    'https://test.onair.events/vod/20211227-144705_dolby-demo-nodrm-conf-v2_1_3/dash/index_web.mpd',
                                hlsUrl: 'https://test.onair.events/vod/20211227-144705_dolby-demo-nodrm-conf-v2_1_3/cmaf/index.m3u8',
                            },
                            options: [
                                {
                                    position: 1,
                                    value: 'Friday',
                                },
                                {
                                    position: 2,
                                    value: '22:00 GMT',
                                },
                                {
                                    position: 3,
                                    value: '4K UHD + Dolby Atmos',
                                },
                            ],
                        },
                        {
                            title: 'Saturday / 22:00 GMT / 4K UHD + Dolby Atmos',
                            sku: 'annemarie-therapy-sat-gmt-uhd',
                            doorsOpen: '2021-12-11T21:00+01:00',
                            saleEnd: '2021-12-11T21:00+01:00',
                            eventStart: '2021-12-11T22:00+01:00',
                            eventEnd: '2021-12-12T00:00+01:00',
                            stream: {
                                title: 'annmarie-therapy-fri-aest-hd',
                                dashUrl:
                                    'https://test.onair.events/vod/20211227-144705_dolby-demo-nodrm-conf-v2_1_3/dash/index_web.mpd',
                                hlsUrl: 'https://test.onair.events/vod/20211227-144705_dolby-demo-nodrm-conf-v2_1_3/cmaf/index.m3u8',
                            },
                            options: [
                                {
                                    position: 1,
                                    value: 'Saturday',
                                },
                                {
                                    position: 2,
                                    value: '22:00 GMT',
                                },
                                {
                                    position: 3,
                                    value: '4K UHD + Dolby Atmos',
                                },
                            ],
                        },
                    ],
                    poster: {
                        asset: {
                            title: 'Anne-Marie-Therapy',
                            description: 'on air home page banner',
                            url: '//images.ctfassets.net/s26j9zm7rn0w/3AvhknIQvgH6RVOUMTxQoq/51bd9204765c8a4b2798197a5c6395fa/Anne_Marie_1.jpeg',
                            width: 750,
                            height: 387,
                            file: {
                                url: '//images.ctfassets.net/s26j9zm7rn0w/3AvhknIQvgH6RVOUMTxQoq/51bd9204765c8a4b2798197a5c6395fa/Anne_Marie_1.jpeg',
                                details: {
                                    size: 92908,
                                    image: {
                                        width: 750,
                                        height: 387,
                                    },
                                },
                                fileName: 'Anne Marie 1.jpeg',
                                contentType: 'image/jpeg',
                            },
                        },
                        alt: 'Anne Marie listening to her fans with a mic in her hand',
                    },
                    eventInfoLogo: {
                        asset: {
                            title: 'Logo Title',
                            description: '',
                            url: eventInfoLogoUrl,
                            width: 366,
                            height: 180,
                            file: {
                                url: eventInfoLogoUrl,
                                details: {
                                    size: 25531,
                                    image: {
                                        width: 366,
                                        height: 180,
                                    },
                                },
                                fileName: 'Logo Title.png',
                                contentType: 'image/png',
                            },
                        },
                        alt: 'Anne Marie Therapy - The Live Experience with a pink and pretty font',
                    },
                    tags: ['future-events'],
                    genres: [],
                },
            ],
        },
        {
            size: 'medium',
            weight: 2,
            title: 'Watch Now',
            events: [],
        },
    ],
};

export const DISCOVER_HERO_RESPONSE_MOCK: {
    eventHero: ContentfulEventHero;
} = {
    eventHero: {
        __type: 'hero',
        nickname: 'Becky Hill You / Me / Us',
        description: {
            // @ts-expect-error TS is spacing
            nodeType: BLOCKS.DOCUMENT,
            data: {},
            content: [
                {
                    nodeType: BLOCKS.PARAGRAPH,
                    data: {},
                    content: [
                        {
                            nodeType: 'text',
                            value: 'Enjoy a poolside view of this ',
                            marks: [],
                            data: {},
                        },
                        {
                            nodeType: 'text',
                            value: 'closing-party performance from BRIT Award winning ',
                            marks: [],
                            data: {},
                        },
                        {
                            nodeType: 'text',
                            value: 'dance music powerhouse Becky Hill, recorded at the Ibiza Rocks Hotel in summer 2022.',
                            marks: [],
                            data: {},
                        },
                    ],
                },
            ],
        },
        background: {
            __type: 'image',
            asset: {
                title: 'Becky Hill Website Banner 3',
                description: 'Becky Hill Website Banner 3',
                url: '//images.ctfassets.net/s26j9zm7rn0w/3j9e8wWiRfoa3nyIefhqdz/16da4331271ee2df737f8945d3c398dc/Becky_Hill_Website_Banner_3.png',
                width: 1920,
                height: 1080,
                file: {
                    url: '//images.ctfassets.net/s26j9zm7rn0w/3j9e8wWiRfoa3nyIefhqdz/16da4331271ee2df737f8945d3c398dc/Becky_Hill_Website_Banner_3.png',
                    details: {
                        size: 3358430,
                        image: { width: 1920, height: 1080 },
                    },
                    fileName: 'Becky Hill Website Banner 3.png',
                    contentType: 'image/png',
                },
            },
            alt: 'Becky Hill Website Banner 3',
        },
        eventInfoLogo: {
            __type: 'image',
            asset: {
                title: 'BH logo white - no text',
                description: 'BH logo white - no text',
                url: '//images.ctfassets.net/s26j9zm7rn0w/2inpwto63euVktOEDw7T9y/c9a262e44fe33ccfc5b6172b7cf8a376/BH_logo_white.png',
                width: 909,
                height: 350,
                file: {
                    url: '//images.ctfassets.net/s26j9zm7rn0w/2inpwto63euVktOEDw7T9y/c9a262e44fe33ccfc5b6172b7cf8a376/BH_logo_white.png',
                    details: {
                        size: 24983,
                        image: { width: 909, height: 350 },
                    },
                    fileName: 'BH logo white.png',
                    contentType: 'image/png',
                },
            },
            alt: 'BH logo white - no text',
        },
        event: {
            __type: 'event',
            title: 'Becky Hill - YOU / ME / US',
            slug: 'becky-hill-you-me-us',
            shortDescription:
                "Watch dance music powerhouse Becky Hill's closing pool-party performance from the notorious Ibiza Rocks Hotel. ",
            poster: {
                __type: 'image',
                asset: {
                    title: 'Becky Hill Website Banner 3',
                    description: 'Becky Hill Website Banner 3',
                    url: '//images.ctfassets.net/s26j9zm7rn0w/3j9e8wWiRfoa3nyIefhqdz/16da4331271ee2df737f8945d3c398dc/Becky_Hill_Website_Banner_3.png',
                    width: 1920,
                    height: 1080,
                    file: {
                        url: '//images.ctfassets.net/s26j9zm7rn0w/3j9e8wWiRfoa3nyIefhqdz/16da4331271ee2df737f8945d3c398dc/Becky_Hill_Website_Banner_3.png',
                        details: {
                            size: 3358430,
                            image: { width: 1920, height: 1080 },
                        },
                        fileName: 'Becky Hill Website Banner 3.png',
                        contentType: 'image/png',
                    },
                },
                alt: 'Becky Hill Website Banner 3',
            },
            eventInfoLogo: {
                __type: 'image',
                asset: {
                    title: 'Becky Hill You / Me / Us Logo',
                    description: 'Becky Hill You / Me / Us Logo',
                    url: '//images.ctfassets.net/s26j9zm7rn0w/2ECU2QewNEjasjTrZHXAdn/9096944740cffb85d1263bb5ea04e71d/BECKYHILL_DATED_LOGO.png',
                    width: 1000,
                    height: 633,
                    file: {
                        url: '//images.ctfassets.net/s26j9zm7rn0w/2ECU2QewNEjasjTrZHXAdn/9096944740cffb85d1263bb5ea04e71d/BECKYHILL_DATED_LOGO.png',
                        details: {
                            size: 29066,
                            image: { width: 1000, height: 633 },
                        },
                        fileName: 'BECKYHILL DATED LOGO.png',
                        contentType: 'image/png',
                    },
                },
                alt: 'Becky Hill You / Me / Us Logo',
            },
            trailer: {
                __type: 'stream',
                title: 'Dolby Atmos trailer',
                hlsUrl: 'https://test.onair.events/vod/20211227-144705_dolby-demo-nodrm-conf-v2_1_3/cmaf/index.m3u8',
                dashUrl:
                    'https://test.onair.events/vod/20211227-144705_dolby-demo-nodrm-conf-v2_1_3/dash/index_web.mpd',
                dashCastUrl:
                    'https://test.onair.events/vod/20211227-144705_dolby-demo-nodrm-conf-v2_1_3/dash/index_web.mpd',
                dashAppUrl:
                    'https://test.onair.events/vod/20211227-144705_dolby-demo-nodrm-conf-v2_1_3/dash/index_web.mpd',
                duration: 17,
            },
            tags: ['currently-vod'],
            genres: [{ title: 'country', displayLabel: 'Country' }],
            variants: [
                {
                    __type: 'eventVariant',
                    sku: 'becky-hill-hd',
                    options: [
                        {
                            __type: 'eventOptions',
                            position: 1,
                            value: 'HD + Stereo',
                        },
                    ],
                    icons: [
                        { __type: 'icon', type: 'oa-quality-hd' },
                        { __type: 'icon', type: 'oa-sound-stereo' },
                    ],
                    title: 'Becky Hill - YOU/ME/US - HD + Stereo',
                    doorsOpen: '2023-05-01T16:00+01:00',
                    eventStart: '2023-05-01T17:00+01:00',
                    saleEnd: '2023-05-01T18:00+01:00',
                    eventEnd: '2023-05-01T19:00+01:00',
                    price: {
                        __type: 'price',
                        title: 'becky-hill-hd - 6.99',
                        EUR: 8.99,
                        USD: 8.99,
                        GBP: 7.99,
                        AUD: 12.99,
                    },
                    shopifyProductVariantId: '43311118450880',
                    stream: {
                        __type: 'stream',
                        title: 'Becky Hill - YOU/ME/US - HD + Stereo',
                        duration: 60,
                    },
                },
                {
                    __type: 'eventVariant',
                    sku: 'becky-hill-uhd',
                    options: [
                        {
                            __type: 'eventOptions',
                            position: 1,
                            value: '4K UHD Dolby Vision with Dolby Atmos sound',
                        },
                    ],
                    icons: [
                        { __type: 'icon', type: 'oa-quality-uhd' },
                        {
                            __type: 'icon',
                            type: 'oa-dolby-vision-atmos-horizontal',
                        },
                    ],
                    title: 'Becky Hill - YOU/ME/US - 4K UHD + Dolby Atmos & Vision /',
                    doorsOpen: '2023-05-01T16:00+01:00',
                    eventStart: '2023-05-01T17:00+01:00',
                    saleEnd: '2023-05-01T18:00+01:00',
                    eventEnd: '2023-05-01T19:00+01:00',
                    price: {
                        __type: 'price',
                        title: 'becky-hill-uhd - 10.99',
                        EUR: 10.99,
                        USD: 10.99,
                        GBP: 10.99,
                        AUD: 15.99,
                    },
                    shopifyProductVariantId: '43311118418112',
                    stream: {
                        __type: 'stream',
                        title: 'Becky Hill - YOU/ME/US - 4K UHD + Dolby Atmos & Vision',
                        duration: 60,
                    },
                },
            ],
            preferredVariant: {
                __type: 'eventVariant',
                sku: 'becky-hill-uhd',
                options: [
                    {
                        __type: 'eventOptions',
                        position: 1,
                        value: '4K UHD Dolby Vision with Dolby Atmos sound',
                    },
                ],
                icons: [
                    { __type: 'icon', type: 'oa-quality-uhd' },
                    {
                        __type: 'icon',
                        type: 'oa-dolby-vision-atmos-horizontal',
                    },
                ],
                title: 'Becky Hill - YOU/ME/US - 4K UHD + Dolby Atmos & Vision /',
                doorsOpen: '2023-05-01T16:00+01:00',
                eventStart: '2023-05-01T17:00+01:00',
                saleEnd: '2023-05-01T18:00+01:00',
                eventEnd: '2023-05-01T19:00+01:00',
                price: {
                    __type: 'price',
                    title: 'becky-hill-uhd - 10.99',
                    EUR: 10.99,
                    USD: 10.99,
                    GBP: 10.99,
                    AUD: 15.99,
                },
                shopifyProductVariantId: '43311118418112',
                stream: {
                    __type: 'stream',
                    title: 'Becky Hill - YOU/ME/US - 4K UHD + Dolby Atmos & Vision',
                    duration: 60,
                },
            },
            blocks: [
                {
                    __type: 'sellingPointsSection',
                    title: 'Selling Points - no on demand ',
                    sellingPoints: [
                        {
                            __type: 'eventFeature',
                            title: 'Global stream',
                            description: {
                                nodeType: BLOCKS.DOCUMENT,
                                data: {},
                                content: [
                                    {
                                        nodeType: BLOCKS.PARAGRAPH,
                                        data: {},
                                        content: [
                                            {
                                                nodeType: 'text',
                                                value: 'You can watch the show anywhere around the globe.',
                                                marks: [],
                                                data: {},
                                            },
                                        ],
                                    },
                                ],
                            },
                            icon: { __type: 'icon', type: 'oa-globe' },
                        },
                        {
                            __type: 'eventFeature',
                            title: 'Watch On Demand',
                            description: {
                                data: {},
                                content: [
                                    {
                                        data: {},
                                        content: [
                                            {
                                                data: {},
                                                marks: [],
                                                value: 'Available On Demand for 48 hours from date of show release.',
                                                nodeType: 'text',
                                            },
                                        ],
                                        nodeType: BLOCKS.PARAGRAPH,
                                    },
                                    {
                                        data: {},
                                        content: [
                                            {
                                                data: {},
                                                marks: [],
                                                value: '\n\n',
                                                nodeType: 'text',
                                            },
                                        ],
                                        nodeType: BLOCKS.PARAGRAPH,
                                    },
                                ],
                                nodeType: BLOCKS.DOCUMENT,
                            },
                            icon: { __type: 'icon', type: 'oa-restart' },
                        },
                        {
                            __type: 'eventFeature',
                            title: 'Dolby',
                            description: {
                                nodeType: BLOCKS.DOCUMENT,
                                data: {},
                                content: [
                                    {
                                        nodeType: BLOCKS.PARAGRAPH,
                                        data: {},
                                        content: [
                                            {
                                                nodeType: 'text',
                                                value: 'Watch in 4K UHD with Dolby Vision with Dolby Atmos technologies.',
                                                marks: [],
                                                data: {},
                                            },
                                        ],
                                    },
                                ],
                            },
                            icon: { __type: 'icon', type: 'oa-dolby-icon' },
                        },
                        {
                            __type: 'eventFeature',
                            title: 'One Tree Planted',
                            description: {
                                data: {},
                                content: [
                                    {
                                        data: {},
                                        content: [
                                            {
                                                data: {},
                                                marks: [],
                                                value: 'One tree planted for every ticket purchased from our website.',
                                                nodeType: 'text',
                                            },
                                        ],
                                        nodeType: BLOCKS.PARAGRAPH,
                                    },
                                ],
                                nodeType: BLOCKS.DOCUMENT,
                            },
                            icon: { __type: 'icon', type: 'oa-onetree3' },
                        },
                    ],
                    showTitle: false,
                },
                {
                    __type: 'contentBlock',
                    title: 'Becky Hill - YOU / ME / US',
                    subtitle: 'Becky Hill',
                    description: {
                        data: {},
                        content: [
                            {
                                data: {},
                                content: [
                                    {
                                        data: {},
                                        marks: [],
                                        value: 'Throughout the summer of 2022 ',
                                        nodeType: 'text',
                                    },
                                    {
                                        data: {},
                                        marks: [{ type: 'bold' }],
                                        value: 'Becky Hill',
                                        nodeType: 'text',
                                    },
                                    {
                                        data: {},
                                        marks: [],
                                        value: ' headlined and curated a weekly pool party at the world-famous',
                                        nodeType: 'text',
                                    },
                                    {
                                        data: {},
                                        marks: [{ type: 'bold' }],
                                        value: ' ',
                                        nodeType: 'text',
                                    },
                                    {
                                        data: {
                                            uri: 'https://www.ibizarocks.com/hotel/',
                                        },
                                        content: [
                                            {
                                                data: {},
                                                marks: [{ type: 'bold' }],
                                                value: 'Ibiza Rocks Hotel',
                                                nodeType: 'text',
                                            },
                                        ],
                                        nodeType: BLOCKS.PARAGRAPH,
                                    },
                                    {
                                        data: {},
                                        marks: [],
                                        value: ". Titled ‘YOU / ME / US', ",
                                        nodeType: 'text',
                                    },
                                    {
                                        data: {},
                                        marks: [{ type: 'bold' }],
                                        value: 'On Air',
                                        nodeType: 'text',
                                    },
                                    {
                                        data: {},
                                        marks: [],
                                        value: ' were there to capture the closing-party performance, offering fans around the world a poolside view of this not to be missed performance from the dance music powerhouse.  ',
                                        nodeType: 'text',
                                    },
                                ],
                                nodeType: BLOCKS.PARAGRAPH,
                            },
                            {
                                data: {},
                                content: [
                                    {
                                        data: {},
                                        marks: [],
                                        value: 'Recorded in 4K UHD ',
                                        nodeType: 'text',
                                    },
                                    {
                                        data: {
                                            uri: 'https://onair.events/about-dolby',
                                        },
                                        content: [
                                            {
                                                data: {},
                                                marks: [],
                                                value: 'Dolby Vision',
                                                nodeType: 'text',
                                            },
                                        ],
                                        nodeType: BLOCKS.PARAGRAPH,
                                    },
                                    {
                                        data: {},
                                        marks: [],
                                        value: ' with ',
                                        nodeType: 'text',
                                    },
                                    {
                                        data: {
                                            uri: 'https://onair.events/about-dolby',
                                        },
                                        content: [
                                            {
                                                data: {},
                                                marks: [],
                                                value: 'Dolby Atmos',
                                                nodeType: 'text',
                                            },
                                        ],
                                        nodeType: BLOCKS.PARAGRAPH,
                                    },
                                    {
                                        data: {},
                                        marks: [],
                                        value: ' technologies, the show will be available to stream from 7pm on November 24th.   It will then be available to watch On Demand for 48 hours.',
                                        nodeType: 'text',
                                    },
                                ],
                                nodeType: BLOCKS.PARAGRAPH,
                            },
                            {
                                data: {},
                                content: [
                                    {
                                        data: {},
                                        marks: [],
                                        value: 'Talking about the show, Becky explains ',
                                        nodeType: 'text',
                                    },
                                    {
                                        data: {},
                                        marks: [{ type: 'italic' }],
                                        value: '“I’ve always wanted the opportunity to create & curate my own party, and Ibiza was always going to be the place to do it!  With my Ibiza Rocks residency, it was super important to be as inclusive, diverse & fun as possible!”',
                                        nodeType: 'text',
                                    },
                                ],
                                nodeType: BLOCKS.PARAGRAPH,
                            },
                            {
                                data: {},
                                content: [
                                    {
                                        data: {},
                                        marks: [{ type: 'italic' }],
                                        value: ' “YOU ME US (lyrics taken from my song ‘I Could Get Used To This’) is my vision of what a rave should look like, embracing what dance music and club culture represents, & as winner of the best dance act brit award 2022, this really is my time to stand up & show the world exactly what YOU / ME / US is all about”.',
                                        nodeType: 'text',
                                    },
                                ],
                                nodeType: BLOCKS.PARAGRAPH,
                            },
                            {
                                data: {},
                                content: [
                                    {
                                        data: {},
                                        marks: [],
                                        value: "Get your tickets for 'Becky Hill YOU / ME / US' now.",
                                        nodeType: 'text',
                                    },
                                ],
                                nodeType: BLOCKS.PARAGRAPH,
                            },
                        ],
                        nodeType: BLOCKS.DOCUMENT,
                    },
                    button: {
                        __type: 'button',
                        text: 'Buy Show',
                        type: 'solid-secondary',
                        ariaLabel:
                            'watch the classical show of Igor Stravinsky - The Firebird performed by Royal Philharmonic Orchestra, conducted by Peter Breiner',
                        href: 'https://onair.events/igor-stravinsky-the-firebird',
                        shouldOpenLinkInNewTab: false,
                    },
                    image: {
                        __type: 'image',
                        asset: {
                            title: 'Becky Hill You / Me / Us square artwork',
                            description:
                                'Becky Hill You / Me / Us square show poster',
                            url: '//images.ctfassets.net/s26j9zm7rn0w/2379NV6fnH0YIdEqJgTSBU/a9d3fef4a4bf54cf27aa525e94e88eeb/1080x1080_beckyhill.png',
                            width: 1080,
                            height: 1080,
                            file: {
                                url: '//images.ctfassets.net/s26j9zm7rn0w/2379NV6fnH0YIdEqJgTSBU/a9d3fef4a4bf54cf27aa525e94e88eeb/1080x1080_beckyhill.png',
                                details: {
                                    size: 1209754,
                                    image: { width: 1080, height: 1080 },
                                },
                                fileName: '1080x1080_beckyhill.png',
                                contentType: 'image/png',
                            },
                        },
                        alt: 'On Air livestream poster showing Becky Hill laid in the sea like a mermaid',
                    },
                    links: [
                        {
                            __type: 'link',
                            text: 'Follow Becky Hill on Tik Tok',
                            href: 'https://www.tiktok.com/@beckyhillofficial',
                            icon: { __type: 'icon', type: 'oa-tiktok2' },
                        },
                    ],
                    shouldShowSocialButtons: true,
                    type: 'Generic',
                    showAppStoreBanners: true,
                    imagePosition: 'right',
                },
                {
                    __type: 'contentBlock',
                    title: 'About Becky Hill',
                    description: {
                        data: {},
                        content: [
                            {
                                data: {},
                                content: [
                                    {
                                        data: {},
                                        marks: [],
                                        value: 'With an irrefutable aptitude for writing chart-smashing pop songs and a reputation as a pioneer in electronic music, BRIT Award-winning Becky Hill is one of Britain’s most in-demand musical exports of the moment. ',
                                        nodeType: 'text',
                                    },
                                ],
                                nodeType: BLOCKS.PARAGRAPH,
                            },
                            {
                                data: {},
                                content: [
                                    {
                                        data: {},
                                        marks: [],
                                        value: 'Described by ',
                                        nodeType: 'text',
                                    },
                                    {
                                        data: {
                                            uri: 'https://davidguetta.com',
                                        },
                                        content: [
                                            {
                                                data: {},
                                                marks: [],
                                                value: 'David Guetta',
                                                nodeType: 'text',
                                            },
                                        ],
                                        nodeType: BLOCKS.PARAGRAPH,
                                    },
                                    {
                                        data: {},
                                        marks: [],
                                        value: ' as',
                                        nodeType: 'text',
                                    },
                                    {
                                        data: {},
                                        marks: [{ type: 'italic' }],
                                        value: ' “one of the very rare queens of dance of music”, ',
                                        nodeType: 'text',
                                    },
                                    {
                                        data: {},
                                        marks: [],
                                        value: 'Becky has written and performed on 16x UK Top 40 singles, including six Top 10 singles and one UK #1, and amassed over 22.6 million followers and 4 billion streams on Spotify alone. Her BPI certifications total 13x Platinum, 12x Gold and 14x Silver records, and she was the third most streamed British female solo artist on Spotify in 2021, behind only ',
                                        nodeType: 'text',
                                    },
                                    {
                                        data: {
                                            uri: 'https://www.dualipa.com',
                                        },
                                        content: [
                                            {
                                                data: {},
                                                marks: [],
                                                value: 'Dua Lipa',
                                                nodeType: 'text',
                                            },
                                        ],
                                        nodeType: BLOCKS.PARAGRAPH,
                                    },
                                    {
                                        data: {},
                                        marks: [],
                                        value: ' and ',
                                        nodeType: 'text',
                                    },
                                    {
                                        data: { uri: 'https://www.adele.com' },
                                        content: [
                                            {
                                                data: {},
                                                marks: [],
                                                value: 'Adele',
                                                nodeType: 'text',
                                            },
                                        ],
                                        nodeType: BLOCKS.PARAGRAPH,
                                    },
                                    {
                                        data: {},
                                        marks: [],
                                        value: '. ',
                                        nodeType: 'text',
                                    },
                                ],
                                nodeType: BLOCKS.PARAGRAPH,
                            },
                            {
                                data: {},
                                content: [
                                    {
                                        data: {},
                                        marks: [],
                                        value: 'Her gold-selling debut studio album, ‘Only Honest On The Weekend’, released in August 2021 and peaked at #7 on the UK Albums Chart, spawning Platinum-selling UK #3 single ‘Remember’, which also ended the year as one of the most streamed and best-selling songs of 2021 with over 1 million UK sales. ',
                                        nodeType: 'text',
                                    },
                                ],
                                nodeType: BLOCKS.PARAGRAPH,
                            },
                            {
                                data: {},
                                content: [
                                    {
                                        data: {},
                                        marks: [],
                                        value: 'Alongside her solo career, Becky has also collaborated with everyone from ',
                                        nodeType: 'text',
                                    },
                                    {
                                        data: {
                                            uri: 'https://www.rudimental.co.uk',
                                        },
                                        content: [
                                            {
                                                data: {},
                                                marks: [],
                                                value: 'Rudimental',
                                                nodeType: 'text',
                                            },
                                        ],
                                        nodeType: BLOCKS.PARAGRAPH,
                                    },
                                    {
                                        data: {},
                                        marks: [],
                                        value: ' to ',
                                        nodeType: 'text',
                                    },
                                    {
                                        data: {
                                            uri: 'https://www.littlesimz.com',
                                        },
                                        content: [
                                            {
                                                data: {},
                                                marks: [],
                                                value: 'Lil Simz ',
                                                nodeType: 'text',
                                            },
                                        ],
                                        nodeType: BLOCKS.PARAGRAPH,
                                    },
                                    {
                                        data: {},
                                        marks: [],
                                        value: 'over the last decade, as well as writing songs for a whole range of artists; from dance titans like MK, ',
                                        nodeType: 'text',
                                    },
                                    {
                                        data: { uri: 'https://www.tiesto.com' },
                                        content: [
                                            {
                                                data: {},
                                                marks: [],
                                                value: 'Tiesto',
                                                nodeType: 'text',
                                            },
                                        ],
                                        nodeType: BLOCKS.PARAGRAPH,
                                    },
                                    {
                                        data: {},
                                        marks: [],
                                        value: ', ',
                                        nodeType: 'text',
                                    },
                                    {
                                        data: {
                                            uri: 'https://www.jaxjones.co.uk',
                                        },
                                        content: [
                                            {
                                                data: {},
                                                marks: [],
                                                value: 'Jax Jones ',
                                                nodeType: 'text',
                                            },
                                        ],
                                        nodeType: BLOCKS.PARAGRAPH,
                                    },
                                    {
                                        data: {},
                                        marks: [],
                                        value: 'right the way through to pop royalty in ',
                                        nodeType: 'text',
                                    },
                                    {
                                        data: {
                                            uri: 'https://www.mnekofficial.com',
                                        },
                                        content: [
                                            {
                                                data: {},
                                                marks: [],
                                                value: 'MNEK',
                                                nodeType: 'text',
                                            },
                                        ],
                                        nodeType: BLOCKS.PARAGRAPH,
                                    },
                                    {
                                        data: {},
                                        marks: [],
                                        value: ' and ',
                                        nodeType: 'text',
                                    },
                                    {
                                        data: {
                                            uri: 'https://www.little-mix.com',
                                        },
                                        content: [
                                            {
                                                data: {},
                                                marks: [],
                                                value: 'Little Mix',
                                                nodeType: 'text',
                                            },
                                        ],
                                        nodeType: BLOCKS.PARAGRAPH,
                                    },
                                    {
                                        data: {},
                                        marks: [],
                                        value: '.',
                                        nodeType: 'text',
                                    },
                                ],
                                nodeType: BLOCKS.PARAGRAPH,
                            },
                        ],
                        nodeType: BLOCKS.DOCUMENT,
                    },
                    image: {
                        __type: 'image',
                        asset: {
                            title: 'Becky Hill Neon Sign',
                            description: 'Becky Hill Neon Sign',
                            url: '//images.ctfassets.net/s26j9zm7rn0w/vtUj2ZYVqmfRJyyhfGujQ/42761f9f252a052da155b075361feab9/Becky_Hill_Neon_Sign.png',
                            width: 2000,
                            height: 2000,
                            file: {
                                url: '//images.ctfassets.net/s26j9zm7rn0w/vtUj2ZYVqmfRJyyhfGujQ/42761f9f252a052da155b075361feab9/Becky_Hill_Neon_Sign.png',
                                details: {
                                    size: 6888287,
                                    image: { width: 2000, height: 2000 },
                                },
                                fileName: 'Becky Hill Neon Sign.png',
                                contentType: 'image/png',
                            },
                        },
                        alt: 'Becky Hill holding an On Air neon sign',
                    },
                    links: [
                        {
                            __type: 'link',
                            text: 'Follow Becky Hill on Facebook',
                            href: 'https://www.facebook.com/Beckyhillofficial/',
                            icon: { __type: 'icon', type: 'oa-facebook' },
                        },
                        {
                            __type: 'link',
                            text: 'Follow Becky Hill on Twitter',
                            href: 'https://twitter.com/BeckyHill',
                            icon: { __type: 'icon', type: 'oa-twitter' },
                        },
                        {
                            __type: 'link',
                            text: 'Follow Becky Hill on Instagram',
                            href: 'https://www.instagram.com/beckyhill/',
                            icon: { __type: 'icon', type: 'oa-instagram1' },
                        },
                        {
                            __type: 'link',
                            text: 'Follow Becky Hill on Tik Tok',
                            href: 'https://www.tiktok.com/@beckyhillofficial',
                            icon: { __type: 'icon', type: 'oa-tiktok2' },
                        },
                        {
                            __type: 'link',
                            text: 'Follow Becky Hill on YouTube',
                            href: 'https://www.youtube.com/channel/UCr6o7T_bNQPzHlRAtQz7EDw',
                            icon: { __type: 'icon', type: 'oa-youtube1' },
                        },
                    ],
                    shouldShowSocialButtons: false,
                    type: 'Generic',
                    showAppStoreBanners: false,
                    imagePosition: 'left',
                },
                {
                    __type: 'videoBlock',
                    youtubeLink: 'https://www.youtube.com/watch?v=JoFHliN5wRU',
                },
                {
                    __type: 'galleryBlock',
                    preserveAspectRatio: false,
                    images: [
                        {
                            __type: 'image',
                            asset: {
                                title: 'Bexky Hill live photo 3',
                                description: '',
                                url: '//images.ctfassets.net/s26j9zm7rn0w/4F3xZczRDme9DU9Hdjyp33/c55f2b50cb45f63a6b9e0bf955bcc157/Bexky_Hill_live_photo_3.jpg',
                                width: 2000,
                                height: 1333,
                                file: {
                                    url: '//images.ctfassets.net/s26j9zm7rn0w/4F3xZczRDme9DU9Hdjyp33/c55f2b50cb45f63a6b9e0bf955bcc157/Bexky_Hill_live_photo_3.jpg',
                                    details: {
                                        size: 1960611,
                                        image: { width: 2000, height: 1333 },
                                    },
                                    fileName: 'Bexky Hill live photo 3.jpg',
                                    contentType: 'image/jpeg',
                                },
                            },
                            alt: 'Becky Hill Live Photo 3',
                        },
                        {
                            __type: 'image',
                            asset: {
                                title: 'Becky Hill Live Photo 2',
                                description: '',
                                url: '//images.ctfassets.net/s26j9zm7rn0w/3QItbjJnUkvgtTFZxRtb3s/434e16fdc16268330ba00e5c13ec74e0/Becky_Hill_Live_Photo_2.jpg',
                                width: 2000,
                                height: 1333,
                                file: {
                                    url: '//images.ctfassets.net/s26j9zm7rn0w/3QItbjJnUkvgtTFZxRtb3s/434e16fdc16268330ba00e5c13ec74e0/Becky_Hill_Live_Photo_2.jpg',
                                    details: {
                                        size: 1835393,
                                        image: { width: 2000, height: 1333 },
                                    },
                                    fileName: 'Becky Hill Live Photo 2.jpg',
                                    contentType: 'image/jpeg',
                                },
                            },
                            alt: 'Becky Hill Live Photo 2',
                        },
                        {
                            __type: 'image',
                            asset: {
                                title: 'Becky Hill Live Photo 1',
                                description: '',
                                url: '//images.ctfassets.net/s26j9zm7rn0w/12kwjZSsjIIXMYW1iXJ0SH/e709ee732f8bee22a17e236323babceb/Becky_Hill_Live_Photo_1.jpg',
                                width: 2000,
                                height: 1333,
                                file: {
                                    url: '//images.ctfassets.net/s26j9zm7rn0w/12kwjZSsjIIXMYW1iXJ0SH/e709ee732f8bee22a17e236323babceb/Becky_Hill_Live_Photo_1.jpg',
                                    details: {
                                        size: 2503503,
                                        image: { width: 2000, height: 1333 },
                                    },
                                    fileName: 'Becky Hill Live Photo 1.jpg',
                                    contentType: 'image/jpeg',
                                },
                            },
                            alt: 'Becky Hill Live Photo 1',
                        },
                    ],
                },
                {
                    __type: 'contentBlock',
                    title: 'One Tree Planted',
                    description: {
                        data: {},
                        content: [
                            {
                                data: {},
                                content: [
                                    {
                                        data: {},
                                        marks: [],
                                        value: 'On Air has partnered with ',
                                        nodeType: 'text',
                                    },
                                    {
                                        data: {
                                            uri: 'https://onair.events/one-tree-planted',
                                        },
                                        content: [
                                            {
                                                data: {},
                                                marks: [],
                                                value: 'One Tree Planted',
                                                nodeType: 'text',
                                            },
                                        ],
                                        nodeType: BLOCKS.PARAGRAPH,
                                    },
                                    {
                                        data: {},
                                        marks: [],
                                        value: '. One Tree Planted are a non-profit charity on a mission to make it simple for anyone to help the environment by planting trees. Their projects span the globe and are done in partnership with local communities and knowledgeable experts to create an impact for nature, people, and wildlife. ',
                                        nodeType: 'text',
                                    },
                                ],
                                nodeType: BLOCKS.PARAGRAPH,
                            },
                            {
                                data: {},
                                content: [
                                    {
                                        data: {},
                                        marks: [],
                                        value: "On Air will plant one tree for every ticket purchased from their website for the 'Becky Hill - YOU / ME / US' show.",
                                        nodeType: 'text',
                                    },
                                ],
                                nodeType: BLOCKS.PARAGRAPH,
                            },
                        ],
                        nodeType: BLOCKS.DOCUMENT,
                    },
                    image: {
                        __type: 'image',
                        asset: {
                            title: 'One Tree Planted About Us Image',
                            description: 'One Tree Planted About Us Image',
                            url: '//images.ctfassets.net/s26j9zm7rn0w/1UJ2C8cYRnQzzbgvUmRndw/567ac4a9e544a95588a69184247bb17a/OTP_About_Us_Image.png',
                            width: 1080,
                            height: 1080,
                            file: {
                                url: '//images.ctfassets.net/s26j9zm7rn0w/1UJ2C8cYRnQzzbgvUmRndw/567ac4a9e544a95588a69184247bb17a/OTP_About_Us_Image.png',
                                details: {
                                    size: 3011164,
                                    image: { width: 1080, height: 1080 },
                                },
                                fileName: 'OTP About Us Image.png',
                                contentType: 'image/png',
                            },
                        },
                        alt: 'One Tree Planted About Us Image',
                    },
                    shouldShowSocialButtons: false,
                    type: 'Generic',
                    showAppStoreBanners: false,
                    imagePosition: 'right',
                },
                {
                    __type: 'highlightsBlock',
                    highlights: [
                        {
                            __type: 'highlight',
                            title: 'Therapy Album',
                            text: {
                                data: {},
                                content: [
                                    {
                                        data: {},
                                        content: [
                                            {
                                                data: {},
                                                marks: [],
                                                value: "The performance, titled 'Therapy - The Live Experience', saw Anne-Marie performing all 12 songs from the album live for the first time ever.",
                                                nodeType: 'text',
                                            },
                                        ],
                                        nodeType: BLOCKS.PARAGRAPH,
                                    },
                                ],
                                nodeType: BLOCKS.DOCUMENT,
                            },
                            icon: { __type: 'icon', type: 'oa-loudspeaker' },
                        },
                        {
                            __type: 'highlight',
                            title: 'What the artist says',
                            text: {
                                data: {},
                                content: [
                                    {
                                        data: {},
                                        content: [
                                            {
                                                data: {},
                                                marks: [],
                                                value: 'Ahead of releasing the stream Anne-Marie explained “I cannot wait to a very special performance of my new album ‘Therapy’ for you all. I’ve missed performing so much and this is the closest thing we are going to get at the moment! So, I have to imagine you all in my mind and that you’re there with me, singing every lyric. We’re going to go on a rollercoaster ride of emotions, so don’t hold back! LET IT OUT!! Scream, shout, dance, cry and smile. I can’t wait.”',
                                                nodeType: 'text',
                                            },
                                        ],
                                        nodeType: BLOCKS.PARAGRAPH,
                                    },
                                ],
                                nodeType: BLOCKS.DOCUMENT,
                            },
                            icon: { __type: 'icon', type: 'oa-live' },
                        },
                        {
                            __type: 'highlight',
                            title: 'Award winning',
                            text: {
                                data: {},
                                content: [
                                    {
                                        data: {},
                                        content: [
                                            {
                                                data: {},
                                                marks: [],
                                                value: 'Over her career to date, Anne-Marie has become a BRITs Critics Choice finalist; Nordoff Robbins ‘Best Newcomer’ winner; 9 x BRIT Award nominee; an official ambassador of The Princes Trust and more.',
                                                nodeType: 'text',
                                            },
                                        ],
                                        nodeType: BLOCKS.PARAGRAPH,
                                    },
                                ],
                                nodeType: BLOCKS.DOCUMENT,
                            },
                            icon: { __type: 'icon', type: 'oa-hearth' },
                        },
                    ],
                },
            ],
            type: 'vod',
            shopifyProductId: '7408347840704',
        },
        theme: 'default',
    },
};
