import classNames from 'classnames';
import React, { FC, memo } from 'react';
import Icon from './Icon';

type LoadingSpinnerProps = {
    style?: React.CSSProperties;
    className?: string;
};

const LoadingSpinner: FC<LoadingSpinnerProps> = memo(({ style, className }) => (
    <div className={classNames('loading-spinner', className)} style={style}>
        <Icon icon="oa-loader" />
    </div>
));

export default LoadingSpinner;
