import React, { FC } from 'react';
import classNames from 'classnames';

export interface IconProps {
    icon: string;
    onClick?: (e: React.MouseEvent<HTMLElement>) => void;
    onKeyDown?: (e: React.KeyboardEvent<HTMLElement>) => void;
    className?: string;
    label?: string;
    testId?: string;
    altText?: string;
}

const Icon: FC<IconProps> = ({
    icon,
    onClick,
    className,
    label,
    onKeyDown,
    testId,
    altText,
}) => {
    if (onClick)
        return (
            <i
                className={classNames('oa', icon, className)}
                onClick={onClick}
                onKeyDown={onKeyDown}
                role="button"
                tabIndex={0}
                aria-label={label}
                data-testid={testId}
                title={altText}
            />
        );

    return <i className={classNames('oa', icon, className)} title={altText} />;
};

export default Icon;
