'use client';
import { useState, useEffect, useRef } from 'react';

import { fetchAuthXML } from '../helpers/fetchAuthXML';

import type { AuthState } from '../reducers/authSlice';
import type { EventStatus } from '../types';

export interface ContentProtection {
    isLoading: boolean;
    error?: string;
    hasAccess?: boolean;
    authXML?: string;
    foundInCache?: boolean;
}

/**
 * Fetches the content-protection secrets (DRM) for the event with the given SKU.
 */
export function useContentProtection({
    sku,
    auth,
    eventStatus = 'LIVE', // by default LIVE to avoid breaking the native app
    disabled = false,
}: {
    auth?: AuthState;
    eventStatus?: EventStatus;
    sku?: string;
    disabled?: boolean;
}): ContentProtection {
    const jwt = auth?.token?.jwt;
    const [contentProtection, setContentProtection] =
        useState<ContentProtection>({
            isLoading: !!jwt && !!sku && eventStatus === 'LIVE',
            hasAccess: false,
        });
    const isMountedRef = useRef(true);

    useEffect(() => {
        isMountedRef.current = true;

        return () => {
            isMountedRef.current = false;
        };
    }, []);

    useEffect(() => {
        if (disabled) {
            setContentProtection({ isLoading: false, hasAccess: true });
            return;
        }

        if (jwt && sku && eventStatus === 'LIVE') {
            const fetchContentProtection = async () => {
                setContentProtection(() => ({ isLoading: true }));
                try {
                    const { authXML, hasAccess, foundInCache } =
                        await fetchAuthXML({
                            sku,
                            jwt,
                        });
                    if (isMountedRef.current)
                        setContentProtection(() => ({
                            isLoading: false,
                            authXML,
                            hasAccess,
                            foundInCache,
                        }));
                } catch (error: any) {
                    if (isMountedRef.current)
                        setContentProtection(() => ({
                            isLoading: false,
                            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
                            error: error.message,
                        }));
                }
            };
            void fetchContentProtection();
        }
    }, [jwt, sku, eventStatus, disabled]);

    return contentProtection;
}
