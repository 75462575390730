'use client'; //TODO: review this
import { useState, useCallback } from 'react';
import { isAfter, parseISO } from 'date-fns';

import type { EventVariantOption, EventOption, EventVariant } from '../types';

/**
 * Checks if variant is valid based on the defined business logic
 * valid variants: saleEnd is after today, saleEnd is not defined
 */
export function isVariantValid(
    eventVariant: EventVariant,
    currentDate: Date = new Date(),
): boolean {
    const { saleEnd } = eventVariant;
    // Do not include options from variants that are expired
    return !saleEnd || isAfter(parseISO(saleEnd), currentDate.getTime());
}

/**
 * Tries to find the lowest option position within all the
 * variants. If the variants have no options, it returns 1.
 * */
export function getLowestOptionsPosition(variants: EventVariant[]): number {
    return (
        variants
            .flatMap(({ options }) => options?.map(({ position }) => position))
            // sort ascending to get the lowest position
            .sort((a, b) => (a && b ? a - b : -1))[0] || 1
    );
}

/**
 * Returns available options based on already selectedOptions, filtered by the
 * corresponding position.
 */
export function getAvailableOptions(config: {
    variants: EventVariant[];
    selectedOptions: EventOption[];
    position?: number;
    currentDate?: Date;
}): {
    position: number;
    options: EventOption[];
}[] {
    const { variants, selectedOptions, currentDate = new Date() } = config;
    const position = config.position ?? getLowestOptionsPosition(variants);

    const selectedOption = selectedOptions.find(
        (option) => option.position === position,
    );

    // eslint-disable-next-line unicorn/no-array-reduce
    const positionOptions = variants.reduce<EventOption[]>(
        (validOptions, variant) => {
            if (!isVariantValid(variant, currentDate)) {
                return validOptions;
            }
            const positionOption: EventVariantOption | undefined =
                variant.options?.find((option) => option.position === position);

            if (!positionOption) {
                return validOptions;
            }

            positionOption.price = variant.price;
            positionOption.compareAtPrice = variant.compareAtPrice;
            positionOption.icons = variant.verticalIcons?.length
                ? variant.verticalIcons
                : variant.icons;

            // Do not include options that already exist in the current position
            if (
                validOptions.some(
                    (validOption) => validOption.value === positionOption.value,
                )
            ) {
                return validOptions;
            }

            return [...validOptions, positionOption];
        },
        [],
    );

    if (!selectedOption) {
        return positionOptions.length > 0
            ? [{ position, options: positionOptions }]
            : [];
    }

    const filteredVariants = variants.filter((variant) =>
        variant.options?.some(({ value }) => value === selectedOption.value),
    );

    return [
        { position, options: positionOptions },
        ...getAvailableOptions({
            variants: filteredVariants,
            selectedOptions,
            position: position + 1,
        }),
    ];
}

/**
 * Returns a valid variant that matches with the selected options
 */
export function getSelectedVariant(config: {
    selectedOptions: EventOption[];
    variants: EventVariant[];
    currentDate?: Date;
}): EventVariant | undefined {
    const { selectedOptions, variants, currentDate = new Date() } = config;
    return variants.find(
        (variant) =>
            isVariantValid(variant, currentDate) &&
            selectedOptions.every((selectedOption) =>
                variant.options?.some(
                    (option) => option.value === selectedOption.value,
                ),
            ),
    );
}

/**
 * Returns the valid variant options based on the single updated option
 * This function relies on the position of the updated option to identify
 * which option is gonna be replaced
 */
export function getUpdatedSelectedOptions(config: {
    selectedOptions: EventOption[];
    updatedOption: EventOption;
    variants: EventVariant[];
}): EventOption[] {
    const { selectedOptions, updatedOption, variants } = config;

    // Substitution is done based on position of the options
    const indexToUpdate = selectedOptions.findIndex(
        ({ position }) => position === updatedOption.position,
    );

    if (indexToUpdate === -1) {
        throw new Error(
            'Something went wrong while updating the selected options. Position of updated option not found.',
        );
    }

    const preSelectedVariantOptions = getSelectedVariant({
        selectedOptions: [
            ...selectedOptions.slice(0, indexToUpdate),
            updatedOption,
        ],
        variants,
    })?.options;

    if (!preSelectedVariantOptions) {
        throw new Error(
            'Something went wrong while pre selecting variant options. Variation with selected option not found.',
        );
    }

    return preSelectedVariantOptions;
}

/**
 * Returns the first valid variant options
 */
export function getDefaultSelectedOptions(
    variants: EventVariant[],
    currentDate: Date = new Date(),
): EventOption[] | undefined {
    const firstValidVariant = variants.find((variant) =>
        isVariantValid(variant, currentDate),
    );
    return firstValidVariant?.options;
}

export function useEventConfigurator(config: {
    variants: EventVariant[];
    currentDate?: Date;
}) {
    const { variants, currentDate = new Date() } = config;
    const [selectedOptions, setSelectedOptions] = useState<EventOption[]>(
        getDefaultSelectedOptions(variants, currentDate) ?? [],
    );
    const [selectedVariant, setSelectedVariant] = useState<
        EventVariant | undefined
    >(getSelectedVariant({ selectedOptions, variants }));

    const availableOptions = getAvailableOptions({
        variants,
        selectedOptions,
    });

    const onSelectOption = useCallback(
        (updatedOption: EventOption) => {
            const updatedSelectedOptions = getUpdatedSelectedOptions({
                selectedOptions,
                updatedOption,
                variants,
            });
            const updatedSelectedVariant = getSelectedVariant({
                selectedOptions: updatedSelectedOptions,
                variants,
            });
            setSelectedOptions(updatedSelectedOptions);
            setSelectedVariant(updatedSelectedVariant);
        },
        [variants, selectedOptions],
    );

    return {
        selectedOptions,
        selectedVariant,
        onSelectOption,
        availableOptions,
    };
}
