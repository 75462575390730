import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { CONTENTFUL_VERSION } from '../types';
import { genericAPIMessages } from '../messages/genericAPI';
import fetch from '../helpers/fetch';

import type {
    DiscoverCarousel,
    ContentfulEventHero,
    FetchStatus,
    RequestPlatform,
} from '../types';
import type { SerializedError } from '@reduxjs/toolkit';
import { getEnvironmentVariableServerSide } from '../../../next/src/utils/server-functions/getEnvServerSide';

interface DiscoverResponse {
    data?: {
        discoverCarousels: DiscoverCarousel[];
    };
    error?: string;
}

interface HeroResponse {
    data?: {
        eventHero: ContentfulEventHero;
    };
    error?: string;
}

export interface DiscoverEventsState {
    status: FetchStatus;
    heroStatus?: FetchStatus;
    error?: SerializedError;
    carousels?: DiscoverCarousel[];
    eventHero?: ContentfulEventHero;
}

export const discoverEventsSliceInitialState: DiscoverEventsState = {
    status: 'NOT_FETCHED',
    heroStatus: 'NOT_FETCHED',
};

export const fetchDiscoverEvents = createAsyncThunk(
    'discoverEvents/fetchDiscoverEvents',
    async ({ isMobile }: { isMobile?: boolean }, { rejectWithValue }) => {
        const { ONAIR_ENDPOINT } = await getEnvironmentVariableServerSide([
            'ONAIR_ENDPOINT',
        ]);
        const response = await fetch(
            `${ONAIR_ENDPOINT}/discover`,
            // TODO put back the GET method when the cache is in place
            // `${ONAIR_ENDPOINT}/discover?version=${CONTENTFUL_VERSION}`,
            {
                method: 'POST',
                headers: { 'content-type': 'application/json' },
                body: JSON.stringify({
                    version: CONTENTFUL_VERSION,
                    platform: isMobile ? 'mobile' : 'tv',
                }),
                //     method: 'GET'
            },
        );
        const { error, data } = (await response.json()) as DiscoverResponse;
        if (!response.ok || error) {
            const errorMessage =
                error ?? genericAPIMessages.error_performing_request;
            return rejectWithValue(errorMessage);
        }
        const { discoverCarousels } = data!;
        return {
            discoverCarousels,
        };
    },
);

export const fetchHero = createAsyncThunk(
    'discoverEvents/fetchHero',
    async (_, { rejectWithValue }) => {
        const { ONAIR_ENDPOINT } = await getEnvironmentVariableServerSide([
            'ONAIR_ENDPOINT',
        ]);
        const response = await fetch(`${ONAIR_ENDPOINT}/discover/hero`, {
            method: 'GET',
        });
        const { error, data } = (await response.json()) as HeroResponse;

        if (!response.ok || error) {
            const errorMessage =
                error ?? genericAPIMessages.error_performing_request;
            return rejectWithValue(errorMessage);
        }
        const { eventHero } = data!;
        return {
            eventHero,
        };
    },
);

export const discoverEventsSlice = createSlice({
    name: 'discoverEvents',
    initialState: discoverEventsSliceInitialState,
    reducers: {
        clear(state) {
            state.status = 'NOT_FETCHED';
            state.heroStatus = 'NOT_FETCHED';
            state.error = undefined;
            state.carousels = undefined;
            state.eventHero = undefined;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchDiscoverEvents.pending, (state) => {
            state.status = 'IN_PROGRESS';
            state.error = undefined;
            state.carousels = undefined;
        });
        builder.addCase(fetchDiscoverEvents.fulfilled, (state, action) => {
            state.status = 'FETCHED';
            const { discoverCarousels } = action.payload as {
                discoverCarousels: DiscoverCarousel[];
            };
            state.carousels = discoverCarousels;
        });
        builder.addCase(fetchDiscoverEvents.rejected, (state, { payload }) => {
            state.status = 'FAILED';
            state.error = payload as SerializedError;
        });
        builder.addCase(fetchHero.pending, (state) => {
            state.heroStatus = 'IN_PROGRESS';
            state.error = undefined;
            state.carousels = undefined;
        });
        builder.addCase(fetchHero.fulfilled, (state, action) => {
            state.heroStatus = 'FETCHED';
            const { eventHero } = action.payload as {
                eventHero: ContentfulEventHero;
            };
            state.eventHero = eventHero;
        });
        builder.addCase(fetchHero.rejected, (state, { payload }) => {
            state.heroStatus = 'FAILED';
            state.error = payload as SerializedError;
        });
    },
});

export const discoverEventsActions = discoverEventsSlice.actions;
