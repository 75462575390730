import { getEnvironmentVariableServerSide } from '../../../next/src/utils/server-functions/getEnvServerSide';

export const generateCustomerRecoverVariables = (email: string) => ({
    email,
});

export const customerRecoverMutation = `
mutation customerRecover($email: String!) {
    customerRecover(email: $email) {
      customerUserErrors {
        code
        field
        message
      }
    }
  }  
`;

interface GenerateCustomerResetByUrlVariablesType {
    resetToken: string;
    userId: string;
    password: string;
}

export const generateCustomerResetByUrlVariables = async ({
    resetToken,
    userId,
    password,
}: GenerateCustomerResetByUrlVariablesType) => {
    const { SHOPIFY_DOMAIN } = await getEnvironmentVariableServerSide([
        'SHOPIFY_DOMAIN',
    ]);
    return {
        resetUrl: `${SHOPIFY_DOMAIN}/account/reset/${userId}/${resetToken}`,
        password,
    };
};

export const customerResetByUrlMutation = `
mutation customerResetByUrl($resetUrl: URL!, $password: String!) {
  customerResetByUrl(resetUrl: $resetUrl, password: $password) {
    customer {
      id
    }
    customerAccessToken {
      accessToken
      expiresAt
    }
    customerUserErrors {
      code
      field
      message
    }
  }
}
`;
