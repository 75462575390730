import { BLOCKS } from '@contentful/rich-text-types';

import {
    contentfulIconsMock,
    contentfulImageMock,
    eventInfoLogoUrl,
    multipleGenresMock,
    streamMock,
} from './contentfulData';

import type {
    Event,
    EventOption,
    EventVariant,
    ContentfulContentBlock,
    ContentfulGallery,
    ContentfulSellingPoints,
    ContentfulVideo,
    GatsbyEvents,
    ContentfulEventMerchandise,
    ContentfulCountryGroup,
    ContentfulSpotifyPlaylist,
} from '../types';

const hlsUrl =
    'https://po.cdn.onair.events/mediatailor/20210731-reggae-recipe-live/20210730T085706-rrl-h265-uhd-unenc/cmaf-uhd-atmos-sur-str/index.m3u8';
const dashUrl = ''; // TODO

export const MOCK_EVENT_OPTION_1_1: EventOption = {
    value: 'Option One Value One',
    position: 1,
};
export const MOCK_EVENT_OPTION_1_2: EventOption = {
    value: 'Option One Value Two',
    position: 1,
};
export const MOCK_EVENT_OPTION_2_1: EventOption = {
    value: 'Option Two Value One',
    position: 2,
};
export const MOCK_EVENT_OPTION_2_2: EventOption = {
    value: 'Option Two Value Two',
    position: 2,
};

export const MOCK_EVENT_OPTION_2_3: EventOption = {
    value: 'Option Two Value Three',
    position: 2,
};

export const MOCK_EVENT_VARIANT: EventVariant = {
    title: 'Default Variant Title',
    sku: 'default-variant-sku',
    doorsOpen: '2040-03-09T15:57:38.923Z',
    eventStart: '2040-03-09T15:57:38.923Z',
    saleEnd: '2040-03-09T17:00:00.000Z',
    eventEnd: '2040-03-09T18:00:00.000Z',
    stream: {
        title: 'Default Stream Title',
        dashUrl,
        hlsUrl,
    },
    price: {
        title: 'Default Variant Price',
        EUR: 10,
        USD: 10,
        GBP: 10,
        AUD: 10,
    },
    compareAtPrice: {
        title: 'Default Variant compareAtPrice',
        EUR: 15,
        USD: 15,
        GBP: 15,
        AUD: 15,
    },
    options: [],
    icons: contentfulIconsMock,
};

export const MOCK_EVENT_VARIANTS: EventVariant[] = [
    {
        ...MOCK_EVENT_VARIANT,
        title: 'Option One Value One / Option Two Value One',
        sku: 'option-one-value-one-option-two-value-one',
        price: {
            title: 'Default Variant Price',
            EUR: 10,
            USD: 10,
            GBP: 10,
            AUD: 10,
        },
        compareAtPrice: {
            title: 'Default Variant compareAtPrice',
            EUR: 15,
            USD: 15,
            GBP: 15,
            AUD: 15,
        },
        options: [MOCK_EVENT_OPTION_1_1, MOCK_EVENT_OPTION_2_1],
        // This is a fake id that represents 1-1/2-1 = option 1 - value 1 / option 2 - value 1
        shopifyProductVariantId: '1-1/2-1',
    },
    {
        ...MOCK_EVENT_VARIANT,
        title: 'Option One Value Two / Option Two Value One',
        sku: 'option-one-value-two-option-two-value-one',
        price: {
            title: 'Default Variant Price',
            EUR: 20,
            USD: 20,
            GBP: 20,
            AUD: 20,
        },
        compareAtPrice: {
            title: 'Default Variant compareAtPrice',
            EUR: 25,
            USD: 25,
            GBP: 25,
            AUD: 25,
        },
        options: [MOCK_EVENT_OPTION_1_2, MOCK_EVENT_OPTION_2_1],
        shopifyProductVariantId: '1-2/2-1',
    },
    {
        ...MOCK_EVENT_VARIANT,
        title: 'Option One Value One / Option Two Value Two',
        sku: 'option-one-value-one-option-two-value-Two',
        price: {
            title: 'Default Variant Price',
            EUR: 30,
            USD: 30,
            GBP: 30,
            AUD: 30,
        },
        compareAtPrice: {
            title: 'Default Variant compareAtPrice',
            EUR: 35,
            USD: 35,
            GBP: 35,
            AUD: 35,
        },
        options: [MOCK_EVENT_OPTION_1_1, MOCK_EVENT_OPTION_2_2],
        shopifyProductVariantId: '1-1/2-2',
    },
    {
        ...MOCK_EVENT_VARIANT,
        title: 'Option One Value Two / Option Two Value Three',
        sku: 'option-one-value-Two-option-two-value-Two',
        price: {
            title: 'Default Variant Price',
            EUR: 40,
            USD: 40,
            GBP: 40,
            AUD: 40,
        },
        compareAtPrice: {
            title: 'Default Variant compareAtPrice',
            EUR: 45,
            USD: 45,
            GBP: 45,
            AUD: 45,
        },
        options: [MOCK_EVENT_OPTION_1_2, MOCK_EVENT_OPTION_2_3],
        shopifyProductVariantId: '1-2/2-3',
    },
];

export const RELEASED_MOCK_EVENT_VARIANTS = [...MOCK_EVENT_VARIANTS].map(
    (variant) => {
        return { ...variant, eventStart: '2020-03-09T15:57:38.923Z' };
    },
);

export const MOCK_SELLING_POINTS_BLOCK: ContentfulSellingPoints = {
    __type: 'sellingPointsSection',
    title: 'SellingPoints',
    showTitle: false,
    sellingPoints: [
        {
            title: 'Ticket instantly available',
            description: {
                raw: '{"data":{},"content":[{"data":{},"content":[{"data":{},"marks":[],"value":"Upon completing your order and payment your ticket will be available to you right away. You can start enjoying the show!","nodeType":"text"}],"nodeType":"paragraph"}],"nodeType":"document"}',
            },
            icon: { type: 'oa-ticket' },
        },
        {
            title: 'Dolby Atmos Sound',
            description: {
                raw: '{"data":{},"content":[{"data":{},"content":[{"data":{},"marks":[],"value":"This concert can be enjoyed with Dolby Atmos sound, the best immersive sound quality available on the market.","nodeType":"text"}],"nodeType":"paragraph"}],"nodeType":"document"}',
            },
            icon: { type: 'oa-dolby-atmos-vertical' },
        },
        {
            title: '4K UHD',
            description: {
                raw: '{"nodeType":"document","data":{},"content":[{"nodeType":"paragraph","content":[{"nodeType":"text","value":"This festival is recorded in 4K UHD for the best quality available today. Get fully immersed in this 3 day long festival. ","marks":[],"data":{}}],"data":{}}]}',
            },
            icon: { type: 'oa-quality-uhd' },
        },
        {
            title: '16+ Event',
            description: {
                raw: '{"nodeType":"document","data":{},"content":[{"nodeType":"paragraph","content":[{"nodeType":"text","value":"The minimum recommended age of the festival visitors is 16 years old.","marks":[],"data":{}}],"data":{}}]}',
            },
            icon: { type: 'oa-age16nl' },
        },
    ],
};

export const MOCK_EVENT_DESCRIPTION_BLOCK: ContentfulContentBlock = {
    __type: 'contentBlock',
    type: 'EventDescription',
    title: 'Therapy',
    subtitle: 'Therapy subtitle',
    description: {
        data: {},
        content: [
            {
                data: {},
                content: [
                    {
                        data: {},
                        marks: [{ type: 'bold' }],
                        value: '‘Therapy’',
                        nodeType: 'text',
                    },
                    {
                        data: {},
                        marks: [],
                        value: ' is a collection of songs that embody Anne-Marie’s characterful artistry, self-effacing attitude, and beautiful honesty, which has seen the Essex-born star reign supreme as Gen Z role model across the globe. The highly-anticipated new album will include Anne-Marie’s UK Top 3 ‘Don’t Play’ with KSI and Digital Farm Animals, feel-good Nathan Dawe and MoStack collaboration ‘Way Too Long’; and her recent Niall Horan duet ',
                        nodeType: 'text',
                    },
                    {
                        data: {},
                        marks: [{ type: 'italic' }, { type: 'underline' }],
                        value: '‘Our Song’.',
                        nodeType: 'text',
                    },
                ],
                nodeType: BLOCKS.PARAGRAPH,
            },
        ],
        nodeType: BLOCKS.DOCUMENT,
    },
    imagePosition: 'right',
    image: {
        asset: {
            title: 'Logo Title',
            description: '',
            url: '//images.ctfassets.net/s26j9zm7rn0w/2cn6mifpjKvltyXYlXYuWX/30f7c0ebf12e2ff4d36942d97a5cf6c5/033_200217_glamour_anne_marie127-v1_sf.jpeg',
            width: 366,
            height: 180,
            file: {
                url: '//images.ctfassets.net/s26j9zm7rn0w/2cn6mifpjKvltyXYlXYuWX/30f7c0ebf12e2ff4d36942d97a5cf6c5/033_200217_glamour_anne_marie127-v1_sf.jpeg',
                details: {
                    size: 25531,
                    image: {
                        width: 366,
                        height: 180,
                    },
                },
                fileName: 'Logo Title.png',
                contentType: 'image/png',
            },
        },
        alt: 'Anne Marie Therapy - The Live Experience with a pink and pretty font',
    },
    shouldShowSocialButtons: true,
    showAppStoreBanners: false,
};

export const MOCK_ARTIST_INFO_BLOCK: ContentfulContentBlock = {
    __type: 'contentBlock',
    type: 'Artist',
    title: 'Anne-Marie',
    subtitle: 'Therapy subtitle',
    description: {
        data: {},
        content: [
            {
                data: {},
                content: [
                    {
                        data: {},
                        marks: [],
                        value: 'Anne-Marie is an English singer and songwriter. She has attained several charting singles on the UK Singles Chart, including Clean Bandit\'s "Rockabye", featuring Sean Paul, which peaked at number one, as well as "Alarm", "Ciao Adios", "Friends", "2002" and "Don\'t Play". Her debut studio album Speak Your Mind was released on 27 April 2018, and peaked at number three on the UK Albums Chart. She was nominated for four awards at the 2019 Brit Awards, including Best British Female Solo Artist. In 2015, she signed a record deal with Asylum (a sub-label of Atlantic Records) and, till 2018, she began releasing her work through the label.',
                        nodeType: 'text',
                    },
                ],
                nodeType: BLOCKS.PARAGRAPH,
            },
        ],
        nodeType: BLOCKS.DOCUMENT,
    },
    imagePosition: 'left',
    image: {
        asset: {
            title: 'Logo Title',
            description: '',
            url: '//images.ctfassets.net/s26j9zm7rn0w/AJjLJSCbl5aAEKbZIXJHq/d98622c343fb4afbdd790ab0e7dfbb65/annemarie_thinking.jpeg',
            width: 366,
            height: 180,
            file: {
                url: '//images.ctfassets.net/s26j9zm7rn0w/AJjLJSCbl5aAEKbZIXJHq/d98622c343fb4afbdd790ab0e7dfbb65/annemarie_thinking.jpeg',
                details: {
                    size: 25531,
                    image: {
                        width: 366,
                        height: 180,
                    },
                },
                fileName: 'Logo Title.png',
                contentType: 'image/png',
            },
        },
        alt: 'Anne Marie Therapy - The Live Experience with a pink and pretty font',
    },
    button: {
        type: 'solid-secondary',
        text: 'Call to action',
        ariaLabel: 'Aria label',
        href: 'https://facebook.com/iamannemarie',
        shouldOpenLinkInNewTab: true,
    },
    shouldShowSocialButtons: false,
    showAppStoreBanners: false,
    links: [
        {
            text: 'Follow Anne-marie on Facebook',
            href: 'https://facebook.com/iamannemarie',
            icon: {
                type: 'oa-facebook',
            },
        },
        {
            text: 'Follow Anne-marie on Instagram',
            href: 'https://www.instagram.com/annemarie/',
            icon: {
                type: 'oa-instagram',
            },
        },
        {
            text: 'Follow Anne-marie on Tik Tok',
            href: 'https://www.tiktok.com/@annemarie',
            icon: {
                type: 'oa-tiktok',
            },
        },
        {
            text: 'Follow Anne-marie on Twitter',
            href: 'https://twitter.com/annemarie',
            icon: {
                type: 'oa-twitter',
            },
        },
        {
            text: 'Follow Anne-marie on YouTube',
            href: 'https://www.youtube.com/channel/UCBL7ZxVX4GvW4CFiES_-0YA',
            icon: {
                type: 'oa-youtube',
            },
        },
    ],
};

export const MOCK_GALLERY_BLOCK: ContentfulGallery = {
    __type: 'galleryBlock',
    preserveAspectRatio: false,
    images: [
        {
            asset: {
                title: 'Annemarie during brit awards',
                description: '',
                url: '//images.ctfassets.net/s26j9zm7rn0w/40gW57BZYEWP0TOGaYkL18/bb1273f3e517b9c359f538e2a01b1c17/awards_2.jpeg',
                width: 1200,
                height: 800,
                file: {
                    url: '//images.ctfassets.net/s26j9zm7rn0w/40gW57BZYEWP0TOGaYkL18/bb1273f3e517b9c359f538e2a01b1c17/awards_2.jpeg',
                    details: {
                        size: 117153,
                        image: {
                            width: 1200,
                            height: 800,
                        },
                    },
                    fileName: 'awards 2.jpeg',
                    contentType: 'image/jpeg',
                },
            },
            alt: 'Annemarie during the brit awards',
        },
        {
            asset: {
                title: 'Annemarie in her car',
                description: '',
                url: '//images.ctfassets.net/s26j9zm7rn0w/7DhugovEReeLsVdDf9kYYT/7fa6befd99a4a597a14722d39bb6d837/annemarie_car.jpeg',
                width: 1638,
                height: 2048,
                file: {
                    url: '//images.ctfassets.net/s26j9zm7rn0w/7DhugovEReeLsVdDf9kYYT/7fa6befd99a4a597a14722d39bb6d837/annemarie_car.jpeg',
                    details: {
                        size: 295556,
                        image: {
                            width: 1638,
                            height: 2048,
                        },
                    },
                    fileName: 'annemarie car.jpeg',
                    contentType: 'image/jpeg',
                },
            },
            alt: 'Annemarie in a fancy auto',
        },
        {
            asset: {
                title: 'Annemarie close up',
                description: '',
                url: '//images.ctfassets.net/s26j9zm7rn0w/6O9wB7UnbXotxpEiXDd6zM/49eeb881a13aa7c2f2bc92b7990ea4a0/anne-marie-2-1610125552-view-1.jpeg',
                width: 460,
                height: 374,
                file: {
                    url: '//images.ctfassets.net/s26j9zm7rn0w/6O9wB7UnbXotxpEiXDd6zM/49eeb881a13aa7c2f2bc92b7990ea4a0/anne-marie-2-1610125552-view-1.jpeg',
                    details: {
                        size: 28123,
                        image: {
                            width: 460,
                            height: 374,
                        },
                    },
                    fileName: 'anne-marie-2-1610125552-view-1.jpeg',
                    contentType: 'image/jpeg',
                },
            },
            alt: 'Annemarie close up',
        },
    ],
};

export const MOCK_VIDEO_BLOCK: ContentfulVideo = {
    __type: 'videoBlock',
    youtubeLink:
        'https://www.youtube.com/watch?v=r3RXHOTMmLw&ab_channel=Anne-Marie',
};

export const MOCK_SPOTIFY_PLAYLIST_BLOCK: ContentfulSpotifyPlaylist = {
    __type: 'spotifyPlaylist',
    id: 'test-spotify-playlist-id',
    title: 'Test playlist',
    callToActionButton: {
        ariaLabel: 'This is a test button',
        href: 'https://open.spotify.com/artist/1zNqDE7qDGCsyzJwohVaoX',
        text: 'Call to action',
        type: 'outline-secondary',
        shouldOpenLinkInNewTab: false,
    },
    embeddedPlaylist: {
        embeddedPlaylist: `<iframe style="border-radius:12px" src="https://open.spotify.com/embed/artist/1zNqDE7qDGCsyzJwohVaoX?utm_source=generator" width="100%" height="352" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>`,
    },
    text: {
        raw: `{"data":{},"content":[{"data":{},"content":[{"data":{},"marks":[],"value":"As she prepares to reach a whole new level with her eagerly anticipated album ","nodeType":"text"},{"data":{},"marks":[{"type":"italic"}],"value":"Therapy","nodeType":"text"},{"data":{},"marks":[],"value":" on July 23rd, Anne-Marie will celebrate its release by playing an exclusive global livestream show.","nodeType":"text"}],"nodeType":"paragraph"}],"nodeType":"document"}`,
    },
};

export const MOCK_SPOTIFY_PLAYLIST_LONG_TEXT = {
    raw: `{"data":{},"content":[{"data":{},"content":[{"data":{},"marks":[],"value":"Throughout the summer of 2022 Becky Hill headlined and curated a weekly pool party at the world-famous Ibiza Rocks Hotel. Titled ‘YOU / ME / US', On Air were there to capture the closing-party performance, offering fans around the world a poolside view of this not to be missed performance from the dance music powerhouse. Recorded in 4K UHD Dolby Vision with Dolby Atmos technologies, the show was broadcast on November 24. Talking about the show, Becky explained “I’ve always wanted the opportunity to create & curate my own party, and Ibiza was always going to be the place to do it!  With my Ibiza Rocks residency, it was super important to be as inclusive, diverse & fun as possible!” “YOU ME US (lyrics taken from my song ‘I Could Get Used To This’) is my vision of what a rave should look like, embracing what dance music and club culture represents, & as winner of the best dance act brit award 2022, this really is my time to stand up & show the world exactly what YOU / ME / US is all about”.As she prepares to reach a whole new level with her eagerly anticipated album ","nodeType":"text"},{"data":{},"marks":[{"type":"italic"}],"value":"Therapy","nodeType":"text"},{"data":{},"marks":[],"value":" on July 23rd, Anne-Marie will celebrate its release by playing an exclusive global livestream show.","nodeType":"text"}],"nodeType":"paragraph"}],"nodeType":"document"}`,
};

export const MOCK_SPOTIFY_PLAYLIST_WRONG_PLAYLIST_ID = `<iframe style="border-radius:12px" src="https://open.spotify.com/embed/ERR/000?utm_source=generator" width="100%" height="352" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>`;

export const MOCK_EVENT: Event = {
    title: 'Anne Marie Therapy Past',
    slug: 'annemarie-therapypast',
    shortDescription:
        'As she prepares to reach a whole new level with the release of her eagerly anticipated second album ‘Therapy’ on July 23rd, Anne-Marie will celebrate its release by playing a global livestream show on August 7th.',
    createdAt: '2021-10-19T13:51:04.350Z',
    shopifyProductId: '6720059736256',
    variants: MOCK_EVENT_VARIANTS,
    type: 'spo',
    poster: {
        asset: {
            title: 'Anne-Marie-Therapy',
            description: 'on air home page banner',
            url: '//images.ctfassets.net/s26j9zm7rn0w/3AvhknIQvgH6RVOUMTxQoq/51bd9204765c8a4b2798197a5c6395fa/Anne_Marie_1.jpeg',
            width: 750,
            height: 387,
            file: {
                url: '//images.ctfassets.net/s26j9zm7rn0w/3AvhknIQvgH6RVOUMTxQoq/51bd9204765c8a4b2798197a5c6395fa/Anne_Marie_1.jpeg',
                details: {
                    size: 92908,
                    image: {
                        width: 750,
                        height: 387,
                    },
                },
                fileName: 'Anne Marie 1.jpeg',
                contentType: 'image/jpeg',
            },
        },
        alt: 'Anne Marie listening to her fans with a mic in her hand',
    },
    eventInfoLogo: {
        asset: {
            title: 'Logo Title',
            description: '',
            url: eventInfoLogoUrl,
            width: 366,
            height: 180,
            file: {
                url: eventInfoLogoUrl,
                details: {
                    size: 25531,
                    image: {
                        width: 366,
                        height: 180,
                    },
                },
                fileName: 'Logo Title.png',
                contentType: 'image/png',
            },
        },
        alt: 'Anne Marie Therapy - The Live Experience with a pink and pretty font',
    },
    tags: ['past-events'],
    socialLinks: {
        instagramLink: 'https://www.instagram.com/annemarie/',
        twitterLink: 'https://twitter.com/annemarie',
        facebookLink: 'https://facebook.com/iamannemarie',
    },
    blocks: [
        MOCK_EVENT_DESCRIPTION_BLOCK,
        MOCK_VIDEO_BLOCK,
        MOCK_SELLING_POINTS_BLOCK,
        MOCK_ARTIST_INFO_BLOCK,
        MOCK_GALLERY_BLOCK,
        MOCK_SPOTIFY_PLAYLIST_BLOCK,
    ],
    trailer: streamMock,
    genres: [],
};

export const GATSBY_MOCK_EVENT_NO_GENRES: GatsbyEvents = {
    edges: [
        {
            node: {
                shopifyProductId: 'mock-1',
                slug: 'mock-1',
                portraitPoster: contentfulImageMock.asset,
                poster: contentfulImageMock.asset,
                eventInfoLogo: contentfulImageMock.asset,
                variants: MOCK_EVENT_VARIANTS,
                genres: [],
            },
        },
    ],
};

export const GATSBY_MOCK_EVENT_WITH_GENRES: GatsbyEvents = {
    edges: [
        {
            node: {
                ...GATSBY_MOCK_EVENT_NO_GENRES.edges[0].node,
                genres: multipleGenresMock,
            },
        },
    ],
};

export const WHITELISTED_COUNTRY_GROUP: ContentfulCountryGroup = {
    title: 'Only NL',
    countries: ['NL'],
};

export const MOCK_EVENT_MERCHANDISE_NO_WHITELIST: ContentfulEventMerchandise = {
    __type: 'merchandise',
    title: 'Test product',
    price: MOCK_EVENT_VARIANT.price!,
    compareAtPrice: MOCK_EVENT_VARIANT.compareAtPrice!,
    shopifyProductId: '0000',
    shopifyVariantId: '00000',
    poster: {
        asset: {
            url: 'test',
        },
        alt: 'test 2',
    },
    whitelistedCountryGroups: [],
};

export const MOCK_EVENT_MERCHANDISE_NL_WHITELIST: ContentfulEventMerchandise = {
    ...MOCK_EVENT_MERCHANDISE_NO_WHITELIST,
    whitelistedCountryGroups: [WHITELISTED_COUNTRY_GROUP],
};
