'use client';
import React from 'react';
import classNames from 'classnames';
import { ButtonType, LinkType } from 'onair_frontend-lib';

import Icon from './Icon';
import LoadingSpinner from './LoadingSpinner';
import LinkInternal from './LinkInternal';

import type { FC, MouseEvent, ReactNode } from 'react';
import { ButtonProps } from '@/types/button';

const ButtonChildren: FC<{
    loading?: boolean;
    icon?: string;
    children: string | ReactNode;
}> = ({ loading, icon, children }) => {
    if (loading) return <LoadingSpinner />;
    return (
        <>
            {icon ? (
                <Icon
                    icon={icon}
                    className={classNames('', {
                        'me-2': !!children,
                    })}
                />
            ) : (
                ''
            )}{' '}
            {children}
        </>
    );
};

const getClassNames = (
    type: ButtonType,
    disabled?: boolean,
    grow?: boolean,
    loading?: boolean,
    className?: string,
) =>
    classNames(
        {
            'body-title btn-onair':
                type !== ButtonType.BUTTON_STYLED_IN_COMPONENT,
        },
        {
            'btn-solid':
                type === ButtonType.SOLID_PRIMARY ||
                type === ButtonType.SOLID_SECONDARY,
            'btn-outline':
                type === ButtonType.OUTLINE_PRIMARY ||
                type === ButtonType.OUTLINE_SECONDARY,
            'btn-primary':
                type === ButtonType.SOLID_PRIMARY ||
                type === ButtonType.OUTLINE_PRIMARY,
            'btn-secondary':
                type === ButtonType.SOLID_SECONDARY ||
                type === ButtonType.OUTLINE_SECONDARY,
            'btn-disabled': disabled,
            'btn-grow': grow,
            'btn-loading': loading,
        },
        className,
    );

const Button: FC<ButtonProps> = ({
    type = ButtonType.SOLID_PRIMARY,
    children,
    onClick,
    disabled,
    icon,
    className,
    grow = false,
    loading,
    ariaLabel,
    testId,
    hrefInternal,
    href,
    id,
}) => {
    const onClickInternal = (event?: MouseEvent) => {
        if (!disabled && onClick) onClick(event);
    };

    // Hack it into a link for accesibility
    if (hrefInternal) {
        return (
            <LinkInternal
                type={LinkType.LINK_STYLED_IN_COMPONENT}
                icon=""
                className={getClassNames(
                    type,
                    disabled,
                    grow,
                    loading,
                    className,
                )}
                aria-label={ariaLabel}
                data-testid={testId}
                to={hrefInternal}
                onClick={onClickInternal} // handy for example tracking
            >
                <ButtonChildren loading={loading} icon={icon}>
                    {/* @ts-ignore */}
                    {children !== '' ? children : '\u00A0'}
                </ButtonChildren>
            </LinkInternal>
        );
    }

    if (href) {
        return (
            <a
                className={getClassNames(
                    type,
                    disabled,
                    grow,
                    loading,
                    className,
                )}
                aria-label={ariaLabel}
                data-testid={testId}
                href={href}
                onClick={onClickInternal}
            >
                <ButtonChildren loading={loading} icon={icon}>
                    {/* @ts-ignore */}
                    {children !== '' ? children : '\u00A0'}
                </ButtonChildren>
            </a>
        );
    }

    // It's just a normal button, so return button tag
    return (
        <button
            type="button"
            className={getClassNames(type, disabled, grow, loading, className)}
            onClick={(event) => onClickInternal(event)}
            disabled={disabled || loading}
            aria-label={ariaLabel}
            data-testid={testId}
            id={id}
        >
            <ButtonChildren loading={loading} icon={icon}>
                {/* @ts-ignore */}
                {children !== '' ? children : '\u00A0'}
            </ButtonChildren>
        </button>
    );
};

export default Button;
