const enum PRIORITY {
    LOW = 1,
    MEDIUM = 2,
    HIGH = 3,
    URGENT = 4,
}

interface CategoryOption {
    title: string;
    priority: PRIORITY;
    type: number;
    requiresAuth?: boolean;
}

export const reportAtProblemCategory: CategoryOption = {
    title: 'Report a Problem',
    priority: PRIORITY.HIGH,
    type: 0,
};

export const questionCategory: CategoryOption = {
    title: 'Question',
    priority: PRIORITY.MEDIUM,
    type: 1,
};

export const requestARefundCategory: CategoryOption = {
    title: 'Request a Refund',
    priority: PRIORITY.LOW,
    requiresAuth: true,
    type: 2,
};

export const artistInquiryCategory: CategoryOption = {
    title: 'Artist Inquiry',
    priority: PRIORITY.MEDIUM,
    type: 3,
};

export const deletePersonalDataCategory: CategoryOption = {
    title: 'Delete Personal Data',
    priority: PRIORITY.LOW,
    requiresAuth: true,
    type: 4,
};

export const otherCategory: CategoryOption = {
    title: 'Other',
    priority: PRIORITY.LOW,
    type: 5,
};

const categories: CategoryOption[] = [
    reportAtProblemCategory,
    questionCategory,
    requestARefundCategory,
    artistInquiryCategory,
    deletePersonalDataCategory,
    otherCategory,
];

const categoryOptions: { [key in CategoryOption['type']]: CategoryOption } = {};
for (const category of categories) {
    categoryOptions[category.type] = category;
}

export { categoryOptions };
