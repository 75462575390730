/** Contentful folds the content into "fields", "metadata", etc.
 * With this function we unfold all the content from "fields" recursively
 * returning a normalized response that matches the response produced
 * using graphql. Additionally it adds a field called __type which
 * holds the content-model type (e.g. "event", "sellingPoint", etc..)
 * IMPORTANT: The function modifies the original input.
 *
 * This normalize function is for the CMA (Management API) only.
 */
export const normalizeContentfulResponse = (t: unknown): unknown => {
    if (Array.isArray(t)) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        return t.map((element) => normalizeContentfulResponse(element));
    }

    if (t && typeof t === 'object') {
        // @ts-expect-error types need fixing!
        if (t.fields) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            const contentTypeId: string | undefined =
                //@ts-expect-error types need fixing
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                t.sys?.contentType?.sys?.id;
            return normalizeContentfulResponse(
                contentTypeId
                    ? {
                          __type: contentTypeId,
                          // @ts-expect-error types need fixing!
                          ...t.fields,
                      }
                    : // @ts-expect-error types need fixing!
                      t.fields,
            );
        }

        for (const [key, value] of Object.entries(t)) {
            // Iterate through all the keys from the object, if one of them it's an array
            // and any of its elements has the field "fields", then normalize it
            if (
                Array.isArray(value) &&
                // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-member-access
                value.some((arrayItem) => arrayItem?.fields)
            ) {
                // @ts-expect-error types need fixing!
                t[key] = normalizeContentfulResponse(value); // eslint-disable-line no-param-reassign
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                // @ts-expect-error types need fixing!
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            } else if (typeof value === 'object' && t[key]?.fields) {
                // @ts-expect-error types need fixing!
                t[key] = normalizeContentfulResponse(t[key]); // eslint-disable-line no-param-reassign
            }
        }
    }
    return t;
};

/**
 * Contentful request queries using graphQL have a special syntax in case you want to fetch a collection
 * The "Collection" prefix must me included in the name of the collection
 * e.g. fetching users must be usersCollection.
 * Each fetched collection is an object with a prop named items which holds the collection's data
 *
 * The function will loop through the object keys and the values of them
 * If a key with the "Collection" in its name is detected it will normalize it removing the "Collection" prefix
 * also the data of this key will be normalized by checking for any nested collections, removing the items prop
 * and store the data under the new normalized key.
 *
 * @param rawCollection The raw response object of a contentful request
 * @returns A normalized object with normalized key names and values
 *
 * This normalize function is for the CDA (Contentful Delivery API) only.
 */
export const normalizeContentfulDeliveryResponse = (
    rawCollection: any,
): any => {
    const COLLECTION_PREFIX = 'Collection';
    const normalizedResponse: Record<string, any> = {};
    // Check if the rawCollection is of type object
    if (rawCollection && typeof rawCollection === 'object') {
        // Loop through all keys
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        for (const rawKey of Object.keys(rawCollection)) {
            // Check each key to see if it includes COLLECTION_PREFIX and has the items prop
            if (rawKey.includes(COLLECTION_PREFIX)) {
                // Normalize also any nested collections
                let normalizedCollection;
                if (rawCollection[rawKey].items) {
                    normalizedCollection = rawCollection[rawKey].items.map(
                        (entry: unknown) =>
                            normalizeContentfulDeliveryResponse(entry),
                    );
                } else {
                    normalizedCollection = rawCollection[rawKey].map(
                        (entry: unknown) =>
                            normalizeContentfulDeliveryResponse(entry),
                    );
                }
                // Remove collection prefix from key
                const normalizedKey = rawKey.replace(COLLECTION_PREFIX, '');
                normalizedResponse[normalizedKey] = normalizedCollection;
            } else {
                // If the key does not contain the COLLECTION_PREFIX add it to response as it is
                normalizedResponse[rawKey] = rawCollection[rawKey];
            }
        }
    } else {
        // if rawCollection is not an object return it as it is
        return rawCollection;
    }

    return normalizedResponse;
};
