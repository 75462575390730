const isBrowser = typeof window !== 'undefined';

export const setItemInLocalStorage = (key: string, value: any): void => {
    if (isBrowser) {
        window.localStorage.setItem(key, JSON.stringify(value));
    }
};

export const getItemFromLocalStorage = (key: string): any => {
    if (isBrowser) {
        const stringifiedItem: string | null = window.localStorage.getItem(key);
        return stringifiedItem && stringifiedItem !== 'undefined' ? JSON.parse(stringifiedItem) : null;
    }
    return null;
};

export const removeFromLocalStorage = (key: string): void => {
    if (isBrowser) {
        window.localStorage.removeItem(key);
    }
};
