import nullthrows from 'nullthrows';

// eslint-disable-next-line unicorn/prevent-abbreviations
export const mapKeyToEnvVar = {
    CONTENTFUL_SPACE_ID:
        process.env.CONTENTFUL_SPACE_ID ||
        process.env.NEXT_PUBLIC_CONTENTFUL_SPACE_ID,
    CONTENTFUL_ENVIRONMENT:
        process.env.CONTENTFUL_ENVIRONMENT ||
        process.env.NEXT_PUBLIC_CONTENTFUL_ENVIRONMENT,
    CONTENTFUL_ACCESS_TOKEN:
        process.env.CONTENTFUL_ACCESS_TOKEN ||
        process.env.NEXT_PUBLIC_CONTENTFUL_ACCESS_TOKEN,
    DEFAULT_LOCALE:
        process.env.DEFAULT_LOCALE || process.env.NEXT_PUBLIC_DEFAULT_LOCALE,
    CONTENTFUL_DISCOVER_HERO_ID: process.env.CONTENTFUL_DISCOVER_HERO_ID,

    STOREFRONT_ENDPOINT:
        process.env.STOREFRONT_ENDPOINT ||
        process.env.NEXT_PUBLIC_STOREFRONT_ENDPOINT,
    STOREFRONT_ACCESS_TOKEN:
        process.env.STOREFRONT_ACCESS_TOKEN ||
        process.env.NEXT_PUBLIC_STOREFRONT_ACCESS_TOKEN,

    SENDINBLUE_ENDPOINT: process.env.SENDINBLUE_ENDPOINT,

    SHOPIFY_DOMAIN:
        process.env.SHOPIFY_DOMAIN || process.env.NEXT_PUBLIC_SHOPIFY_DOMAIN,
    ONAIR_ENDPOINT:
        process.env.ONAIR_ENDPOINT || process.env.NEXT_PUBLIC_ONAIR_ENDPOINT,
    ONAIR_URL: process.env.ONAIR_URL,
    IAP_ENDPOINT: process.env.IAP_ENDPOINT,
    RECAPTCHA_SITE_KEY:
        process.env.RECAPTCHA_SITE_KEY ||
        process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY,

    // LAMBDA ONLY SECRETS:
    SHOPIFY_HMAC_SECRET: process.env.SHOPIFY_HMAC_SECRET,
    ENVIRONMENT: process.env.ENVIRONMENT,
    SHOPIFY_ORDER_LINE_ITEM_TABLE: process.env.SHOPIFY_ORDER_LINE_ITEM_TABLE,
    JWT_SECRET: process.env.JWT_SECRET,
    ADMIN_API_ENDPOINT: process.env.ADMIN_API_ENDPOINT,
    ADMIN_API_ACCESS_TOKEN: process.env.ADMIN_API_ACCESS_TOKEN,
    CONTENTFUL_SECRET: process.env.CONTENTFUL_SECRET,
    CONTENTFUL_HOST: process.env.CONTENTFUL_HOST,
    FRESHDESK_API_KEY: process.env.FRESHDESK_API_KEY,
    FRESHDESK_ENDPOINT: process.env.FRESHDESK_ENDPOINT,
    DISABLED: process.env.DISABLED,
    SHOPIFY_AVAILABLE_CURRENCIES: process.env.SHOPIFY_AVAILABLE_CURRENCIES,
    SHOPIFY_AUD_PRICELIST_ID: process.env.SHOPIFY_AUD_PRICELIST_ID,
    SHOPIFY_GBP_PRICELIST_ID: process.env.SHOPIFY_GBP_PRICELIST_ID,
    SHOPIFY_USD_PRICELIST_ID: process.env.SHOPIFY_USD_PRICELIST_ID,
    RECAPTCHA_VALIDATION_ENDPOINT: process.env.RECAPTCHA_VALIDATION_ENDPOINT,
    RECAPTCHA_SECRET: process.env.RECAPTCHA_SECRET,
    LOGIN_HANDOVER_TABLE: process.env.LOGIN_HANDOVER_TABLE,

    // THEOPLAYER
    YOUBORA_ACCOUNT_CODE: process.env.YOUBORA_ACCOUNT_CODE,
    CHROMECAST_APP_ID: process.env.CHROMECAST_APP_ID,
    THEOPLAYER_ENABLE_AC3: process.env.THEOPLAYER_ENABLE_AC3,

    // IPSTACK
    IPSTACK_ENDPOINT:
        process.env.IPSTACK_ENDPOINT ||
        process.env.NEXT_PUBLIC_IPSTACK_ENDPOINT,
    IPSTACK_ACCESS_KEY:
        process.env.IPSTACK_ACCESS_KEY ||
        process.env.NEXT_PUBLIC_IPSTACK_ACCESS_KEY,

    // IAP
    ANDROID_TV_PACKAGE_NAME: process.env.ANDROID_TV_PACKAGE_NAME,
    ANDROID_MOBILE_PACKAGE_NAME: process.env.ANDROID_MOBILE_PACKAGE_NAME,
    APPLE_VERIFY_RECEIPT_SANDBOX: process.env.APPLE_VERIFY_RECEIPT_SANDBOX,
    GOOGLE_SERVICE_ACCOUNT_EMAIL: process.env.GOOGLE_SERVICE_ACCOUNT_EMAIL,
    GOOGLE_SERVICE_ACCOUNT_KEY: process.env.GOOGLE_SERVICE_ACCOUNT_KEY,
};

// It makes a type from the keys of mapKeyToEnvVar
export type ENVVAR = keyof typeof mapKeyToEnvVar;

/**
 * @deprecated please do not call this function anymore with the default export but use getEnv as a named export instead
 */
export default function getEnvironmentClassic(environmentVariable: ENVVAR) {
    return nullthrows(
        mapKeyToEnvVar[environmentVariable],
        `process.env.${environmentVariable} is not defined`,
    );
}

// eslint-disable-next-line unicorn/prevent-abbreviations
export function setEnv(environmentVariable: ENVVAR, value?: string) {
    mapKeyToEnvVar[environmentVariable] = value;
}

/**
 * @param environment variable name
 * this function will grab an environment variable value for you, please use in favor of getEnvClassic which is a default export
 */
// eslint-disable-next-line unicorn/prevent-abbreviations
export const getEnv = getEnvironmentClassic;
