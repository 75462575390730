/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { callContentful } from '../../../next/src/utils/server-functions/contentfulServerSide';

import type { EventsAndMerchandisesResponse } from '../../../next/src/state/queries/eventQueries';
import type { ContentfulGenericResponse } from '../types';

type QueryVariables = Record<string, any>;

export interface FetchContentfulArguments {
    query: string;
    queryVariables?: QueryVariables;
}

/**
 * @deprecated Seems like we are prefering createClient from contentful
 */
async function fetchContentful({
    query,
    queryVariables,
}: FetchContentfulArguments) {
    const {
        data,
        errors,
    }: ContentfulGenericResponse<EventsAndMerchandisesResponse> =
        await callContentful({
            query,
            queryVariables,
        });

    return { data, errors };
}

export { fetchContentful }; // for test purposes
export default fetchContentful;
