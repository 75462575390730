export enum ValidationErrorCode {
    invalidEmail = 'INVALID_EMAIL',
    emptyPassword = 'PASSWORD_EMPTY',
    required = 'REQUIRED',
    invalid = 'INVALID',
    notLongEnough = 'NOT_LONG_ENOUGH',
    tooLong = 'TOO_LONG',
    matchesOldPassword = 'MATCHES_OLD_PASSWORD',
    fileIsTooBig = 'FILE_IS_TOO_BIG',
}
