import type { IGatsbyImageData } from 'gatsby-plugin-image';
import type { RequestPlatform } from '../../api';
import type {
    AuthXML,
    ContentfulContentBlock as ContentfulContentBlockV1,
    ContentfulEvent as ContentfulEventV1,
    ContentfulEventCarousel as ContentfulEventCarouselV1,
    ContentfulEventHero as ContentfulEventHeroV1,
    ContentfulEventVariant as ContentfulEventVariantV1,
    ContentfulGallery as ContentfulGalleryV1,
    ContentfulImage,
    ContentfulSellingPoints as ContentfulSellingPointsV1,
    ContentfulTestimonialBlock,
    ContentfulLink as ContentfulLinkV1,
    ContentfulTestimonial as ContentfulTestimonialV1,
    ContentfulVideo as ContentfulVideoV1,
    ContentfulEventMerchandise as ContentfulEventMerchandiseV1,
    ContentfulButton as ContentfulButtonV1,
    ContentfulIcon,
    ContentfulEventTag,
} from './v1';
import type {
    ContentfulAsset,
    ContentfulDocument,
    ContentfulGraphqlRichDescription,
} from '../api';

export type {
    AuthXML,
    ContentfulIcon,
    ContentfulButtonType,
    ContentfulContentBlockType,
    ContentfulEventBlacklistedCountry,
    ContentfulEventHeroTheme,
    ContentfulEventOption,
    ContentfulEventTag,
    ContentfulEventType,
    ContentfulHero,
    ContentfulImage,
    ContentfulPrice,
    ContentfulRedirect,
    ContentfulReduxAction,
    ContentfulSellingPoint,
    ContentfulSocialLinks,
    ContentfulTestimonialBlock,
} from './v1';

export const CONTENTFUL_VERSION = '2';

export type ContentfulButton = ContentfulButtonV1 & {
    isBuyButton?: boolean;
};

export type ContentfulVideo = ContentfulVideoV1 & {
    selfHostedVideo?: ContentfulStream;
};
export interface ContentfulSpotifyPlaylist {
    id: string;
    __type: 'spotifyPlaylist';
    callToActionButton: ContentfulButton;
    embeddedPlaylist: {
        embeddedPlaylist: string;
    };
    title?: string;
    text?: ContentfulGraphqlRichDescription | ContentfulDocument | string;
}
export interface ContentfulCountryGroup {
    countries: string[];
    title: string;
}

export type ContentfulEventMerchandise = ContentfulEventMerchandiseV1 & {
    whitelistedCountryGroups?: ContentfulCountryGroup[];
};

export interface ContentfulGenre {
    __type?: 'genre';
    title: string;
    displayLabel: string;
}

export interface ImageAsset {
    asset?: {
        description?: string;
        title: string;
        url: string;
        width: number;
        height: number;
    }
}

export interface ContentfulStream {
    __type?: 'stream';
    id?: string;
    title: string;
    hlsUrl?: string;
    dashUrl?: string;
    dashCastUrl?: string;
    dashAppUrl?: string;
    duration?: number;
    thumbnailImage?: ImageAsset
}

export interface ContentfulStreamSecurity {
    id?: string;
    __type?: 'streamSecurity';
    authXML?: AuthXML;
    certUrl?: string;
    poster: ContentfulImage;
    chromecastReceiverAppId?: string;
}

export type ContentfulSellingPoints = ContentfulSellingPointsV1 & {
    showTitle: boolean;
};
export type ContentfulLink = ContentfulLinkV1 & {
    shouldFollow?: boolean;
    shouldOpenInNewTab?: boolean;
};

export type ContentfulAssetWithGatsbyImageData = ContentfulAsset & {
    gatsbyImageData: IGatsbyImageData;
};

export type ContentfulImageWithGatsbyImageData = ContentfulImage & {
    asset?: ContentfulAssetWithGatsbyImageData;
};

export interface ContentfulSEO {
    title: string;
    description: {
        description: string;
    };
    image?: ContentfulImageWithGatsbyImageData;
}

export type ContentfulTestimonial = Omit<ContentfulTestimonialV1, 'rating'> & {
    jobTitle?: string;
    rating?: number;
};

export type ContentfulEventVariant = Omit<
    ContentfulEventVariantV1,
    'dashUrl' | 'hlsUrl' | 'dashCastUrl'
> & {
    stream?: ContentfulStream;
    verticalIcons?: ContentfulIcon[];
    price?: {
        __type?: 'price';
        title: string;
        eur: number;
        usd: number;
        gbp: number;
        aud: number;
    };
};

export type ContentfulContentBlock = Omit<
    ContentfulContentBlockV1,
    'description' | 'isImageOnTheLeft' | 'button'
> & {
    event?: ContentfulEvent;
    subtitle?: string;
    description?:
        | ContentfulGraphqlRichDescription
        | ContentfulDocument
        | string;
    imagePosition?: 'left' | 'center' | 'right';
    socialMediaIconsTitle?: string;
    showAppStoreBanners: boolean;
    button?: ContentfulButton;
};
export interface ContentfulHighlight {
    __type?: 'highlight';
    id?: string;
    title: string;
    text: ContentfulGraphqlRichDescription | ContentfulDocument | string;
    icon?: ContentfulIcon;
}
export interface ContentfulHighlightsBlock {
    __type?: 'highlightsBlock';
    id?: string;
    // title: string; // for internal use only
    highlights: ContentfulHighlight[];
}

export interface ContentfulGallery extends ContentfulGalleryV1 {
    preserveAspectRatio: boolean;
}

export type ContentfulEventBlock =
    | ContentfulContentBlock
    | ContentfulSellingPoints
    | ContentfulGallery
    | ContentfulVideo
    | ContentfulSpotifyPlaylist
    | ContentfulEventCarousel
    | ContentfulHighlightsBlock;

export type ContentfulEvent = Omit<
    ContentfulEventV1,
    'variants' | 'preferredVariant' | 'blocks'
> & {
    id?: string;
    variants: ContentfulEventVariant[];
    genres?: ContentfulGenre[];
    preferredVariant?: ContentfulEventVariant;
    seo?: ContentfulSEO;
    carouselLogo?: ContentfulImage;
    /* Self hosted trailer in our media servers */
    trailer?: ContentfulStream;
    blocks?: ContentfulEventBlock[];
    duration?: number;
    shortTitle?: string;
    relatedShows?: ContentfulEventCarousel;
    eventStart: string;
};

export type ContentfulEventHero = Omit<
    ContentfulEventHeroV1,
    'event' | 'description'
> & {
    event: ContentfulEvent;
    description: {
        raw: string;
        json: any;
    };
};

export interface ContentfulTile {
    __type?: 'Tile';
    id: string;
    title: string;
    shortTitle?: string;
    label: string;
    landscapeImage: ContentfulImage;
    slug: string;
    sublabel?: string;
    logo?: ContentfulImage;
}

export type ContentfulEventCarouselContent = ContentfulTile | ContentfulEvent;

export type ContentfulEventCarousel = Omit<
    ContentfulEventCarouselV1,
    'eventTag'
> & {
    genres?: ContentfulGenre[];
    content?: ContentfulEventCarouselContent[];
    sortingOrder?: 'descending' | 'ascending';
    platforms?: RequestPlatform[];
    eventTag?: ContentfulEventTag;
    showDates: boolean;
    events: ContentfulEvent[];
    size: string;
    slug?: string;
    category?: string;
};

export type ContentfulFlexiblePageBlock =
    | ContentfulContentBlock
    | ContentfulGallery
    | ContentfulEventCarousel
    | ContentfulSellingPoints
    | ContentfulVideo
    | ContentfulTestimonialBlock
    | ContentfulSpotifyPlaylist
    | ContentfulHighlightsBlock;

// The type names must match with the values declared in
// onair_migrations/OAR-2711-platform-message-notification/index.js AVAILABLE_PLATFORMS constant
export type AvailablePlatformsToMessage =
    | 'web'
    | 'ios-mobile'
    | 'android-mobile'
    | 'ios-tv'
    | 'android-tv'
    | 'fire-tv'
    | 'lg-tv';

export interface PlatformMessage {
    description: string;
    updatedAt: string;
}
export interface ContentfulPlatformMessage extends PlatformMessage {
    platforms: AvailablePlatformsToMessage;
    shouldDisplayMessage: boolean;
}
