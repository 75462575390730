import { createClient } from '../helpers/contentful';
import { normalizeContentfulResponse } from '../utils/normalizers';

import type { ContentfulFlexiblePageBlock } from '../types';
import { getEnvironmentVariableServerSide } from '../../../next/src/utils/server-functions/getEnvServerSide';

export async function fetchFlexiblePageBlocks(
    slug: string,
): Promise<ContentfulFlexiblePageBlock[]> {
    const {
        CONTENTFUL_SPACE_ID,
        CONTENTFUL_ENVIRONMENT,
        CONTENTFUL_ACCESS_TOKEN,
    } = await getEnvironmentVariableServerSide([
        'CONTENTFUL_SPACE_ID',
        'CONTENTFUL_ENVIRONMENT',
        'CONTENTFUL_ACCESS_TOKEN',
    ]);

    try {
        const client = createClient({
            space: CONTENTFUL_SPACE_ID!,
            environment: CONTENTFUL_ENVIRONMENT,
            accessToken: CONTENTFUL_ACCESS_TOKEN!,
        });

        const entries = await client.getEntries({
            content_type: 'flexiblePage',
            'fields.slug[in]': slug,
            include: 3,
        });
        const normalizedEntries = normalizeContentfulResponse(entries.items);

        // @ts-expect-error types need fixing
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-member-access
        return normalizedEntries[0].blocks;
    } catch {
        throw new Error(
            `Something went wrong when trying to fetch the ${slug} page`,
        );
    }
}
