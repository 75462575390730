import { createSlice } from '@reduxjs/toolkit';

import type { PayloadAction} from '@reduxjs/toolkit';
import type ROUTES from '@/constants/routes';

export type FocusedRoute = typeof ROUTES.login | typeof ROUTES.register

export interface NavigationState {
    loginReason: ''
    checkoutUrl: '',
    from: '',
    returnTo: '',
    focusedRoute?: FocusedRoute
}

export const navigateSliceInitialState: NavigationState = {
    loginReason: '',
    checkoutUrl: '',
    from: '',
    returnTo: '',
    focusedRoute: undefined
};


export enum NavigationActionTypes {
    createNavigationParams = "navigation/create",
}

//@ts-expect-error
export const createNavigationParams = ({ state }) => ({
	type: NavigationActionTypes.createNavigationParams,
    payload: state
});


export const navigationSlice = createSlice({
    name: 'navigation',
    initialState: navigateSliceInitialState,
    reducers: {
        setFocusedRoute(state, action:PayloadAction<FocusedRoute | undefined>) {
            state.focusedRoute = action.payload
        }
    },
    extraReducers: (builder) => {
        //@ts-expect-error
        builder.addCase(NavigationActionTypes.createNavigationParams, (state, { payload }) => {
			state.loginReason = payload.loginReason;
			state.checkoutUrl = payload.checkoutUrl;
			state.from = payload.from;
			state.returnTo = payload.returnTo;
        });
    },
});

export const navigateActions = navigationSlice.actions;
