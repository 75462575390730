"use client";
export const userAgentDetails = () => {
    if (typeof window !== 'undefined' && window.navigator) {
        const { userAgent } = navigator;
        let browserName;
        let platformName;
        let isMobile = false;

        // Detect browser
        if (userAgent.match(/chrome|chromium|crios/i)) {
            browserName = 'Chrome';
        } else if (userAgent.match(/firefox|fxios/i)) {
            browserName = 'Firefox';
        } else if (userAgent.match(/safari/i)) {
            browserName = 'Safari';
        } else if (userAgent.match(/opr\//i)) {
            browserName = 'Opera';
        } else if (userAgent.match(/edg/i)) {
            browserName = 'Edge';
        } else {
            browserName = 'No browser detection';
        }

        // Detect platform
        if (userAgent.toLowerCase().indexOf('win') !== -1) {
            platformName = 'Windows';
        } else if (userAgent.toLowerCase().indexOf('mac') !== -1) {
            platformName = 'Apple';
        } else if (userAgent.toLowerCase().indexOf('linux') !== -1) {
            platformName = 'Linux';
        } else if (userAgent.toLowerCase().indexOf('x11') !== -1) {
            platformName = 'Unix';
        } else {
            platformName = 'No platform detection';
        }

        // Detect if mobile
        if (
            /Android|webOS|iPhone|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(
                navigator.userAgent,
            )
        ) {
            isMobile = true;
        }

        return {
            browserName,
            platformName,
            isMobile,
        };
    }
    else {
        return {
            browserName: '',
            platformName: '',
            isMobile: false,
        }
    }
};
