import { createSlice } from '@reduxjs/toolkit';

import type { PayloadAction } from '@reduxjs/toolkit';

export const NotificationTypeMap = {
    NO_INTERNET_CONNECTION: {
        id: 'NO_INTERNET_CONNECTION',
        icon: 'information',
    },
};

export interface Notification {
    id: string;
    icon: string;
    title: string;
    description: string;
    timeVisible?: number;
}

export interface NotificationsState {
    notifications: Notification[];
    nextItemToRemove: Notification;
}

export const notificationsInitialState: NotificationsState = {
    notifications: [] as Notification[],
    nextItemToRemove: {} as Notification,
};

export const notificationsSlice = createSlice({
    name: 'notifications',
    initialState: notificationsInitialState,
    reducers: {
        clear() {
            return notificationsInitialState;
        },
        pushNotification: (state, action: PayloadAction<Notification>) => {
            const duplicate = state.notifications.find(
                (item) => item.id === action.payload.id,
            );
            if (!duplicate) {
                state.notifications.push(action.payload);
            }
            return state;
        },
        removeNotification: (state, action: PayloadAction<string>) => {
            const notificationToRemove = state.notifications.find(
                (item) => item.id === action.payload,
            );
            if (notificationToRemove) {
                state.notifications.splice(
                    state.notifications.indexOf(notificationToRemove),
                    1,
                );
            }
            state.nextItemToRemove = {} as Notification;
            return state;
        },
        popNotification: (state, action: PayloadAction<string>) => {
            if (!state.nextItemToRemove.id) {
                // check if we're not still removing, then let the component that requests it wait
                const nextItemToRemove = state.notifications.find(
                    (item) => item.id === action.payload,
                );
                if (nextItemToRemove) {
                    state.nextItemToRemove = nextItemToRemove;
                }
            }
            return state;
        },
    },
});

export const { clear, pushNotification, popNotification, removeNotification } =
    notificationsSlice.actions;

export const selectNotifications = (state: {
    notifications: NotificationsState;
}): Notification[] => state.notifications.notifications;
export const selectNextNotificationRemoved = (state: {
    notifications: NotificationsState;
}): Notification => state.notifications.nextItemToRemove;
