/* eslint-disable @typescript-eslint/no-unnecessary-condition */
/* eslint-disable @typescript-eslint/no-unsafe-return */
'use server';

import { FetchContentfulArguments } from 'onair_frontend-lib';

export async function callContentful({
    query,
    queryVariables,
}: FetchContentfulArguments) {
    const endpoint = `https://graphql.contentful.com/content/v1/spaces/${process
        .env.CONTENTFUL_SPACE_ID!}/environments/${process.env
        .CONTENTFUL_ENVIRONMENT!}?access_token=${process.env
        .CONTENTFUL_ACCESS_TOKEN!}`;

    const response = await fetch(endpoint, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            query,
            variables: queryVariables,
        }),
        cache: 'no-store',
    });
    if (!response.ok) {
        throw new Error(response.statusText ?? `${response.status}`);
    }
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return await response.json();
}
