import { BLOCKS } from '@contentful/rich-text-types';

import type {
    ContentfulGenre,
    ContentfulIcon,
    ContentfulImage,
    EventStream,
} from '../types/contentful';
import type { Event } from '../types/contentful/event';

export const eventInfoLogoUrl =
    '//images.ctfassets.net/s26j9zm7rn0w/7lynBqC4QkRbfG8KAG3T3S/7865a1ff8d25e88d7871d47ad45caaba/onair_ub40-featuring-ali-campbell_in-memory-of-astro_logo_large.png';

export const dashUrlMock =
    'https://test.onair.events/vod/20211227-144705_dolby-demo-nodrm-conf-v2_1_3/dash/index_web.mpd';

export const hlsUrlMock =
    'https://test.onair.events/vod/20211227-144705_dolby-demo-nodrm-conf-v2_1_3/cmaf/index.m3u8';

export const streamMock: EventStream = {
    title: 'Stream example',
    dashUrl: dashUrlMock,
    hlsUrl: hlsUrlMock,
};

export const genreMock: ContentfulGenre = {
    title: 'mock-genre',
    displayLabel: 'Mock genre',
};

export const multipleGenresMock: ContentfulGenre[] = [
    genreMock,
    {
        title: 'mock-genre-2',
        displayLabel: 'Mock genre 2',
    },
];

export const skusMock = {
    sys: {
        type: 'Array',
    },
    total: 2,
    skip: 0,
    limit: 100,
    items: [
        {
            metadata: {
                tags: [],
            },
            sys: {
                space: {
                    sys: {
                        type: 'Link',
                        linkType: 'Space',
                        id: 's26j9zm7rn0w',
                    },
                },
                id: 'I35oqUfuqFMu9yBUXoyq1',
                type: 'Entry',
                createdAt: '2021-11-18T09:25:01.904Z',
                updatedAt: '2021-12-14T16:13:53.137Z',
                environment: {
                    sys: {
                        id: 'development',
                        type: 'Link',
                        linkType: 'Environment',
                    },
                },
                revision: 13,
                contentType: {
                    sys: {
                        type: 'Link',
                        linkType: 'ContentType',
                        id: 'eventVariant',
                    },
                },
                locale: 'en-US',
            },
            fields: {
                title: 'Friday / 20:00 AEST / HD + Stereo',
                sku: 'annemarie-therapy-fri-aest-hd',
                doorsOpen: '2021-12-14T21:00+01:00',
                saleEnd: '2021-12-11T21:00+01:00',
                eventStart: '2021-12-14T14:00+01:00',
                eventEnd: '2021-12-14T18:00+01:00',
                price: {
                    title: 'Default Variant Price',
                    EUR: 14.99,
                    USD: 14.99,
                    GBP: 14.99,
                    AUD: 14.99,
                },
                stream: {
                    title: 'annemarie-therapy-fri-aest-hd',
                    dashUrl: dashUrlMock,
                    hlsUrl: hlsUrlMock,
                },
                compareAtPrice: {
                    title: 'Default Variant Price CompareAt',
                    EUR: 17.99,
                    USD: 17.99,
                    GBP: 17.99,
                    AUD: 17.99,
                },
                options: [
                    {
                        position: 1,
                        value: 'Friday',
                    },
                    {
                        position: 2,
                        value: '20:00 AEST',
                    },
                    {
                        position: 3,
                        value: 'HD + Stereo',
                    },
                ],
            },
        },
        {
            metadata: {
                tags: [],
            },
            sys: {
                space: {
                    sys: {
                        type: 'Link',
                        linkType: 'Space',
                        id: 's26j9zm7rn0w',
                    },
                },
                id: '15zg6uCFPy4b2SO70h5zR8',
                type: 'Entry',
                createdAt: '2021-11-18T09:29:46.820Z',
                updatedAt: '2021-12-14T16:11:05.441Z',
                environment: {
                    sys: {
                        id: 'development',
                        type: 'Link',
                        linkType: 'Environment',
                    },
                },
                revision: 6,
                contentType: {
                    sys: {
                        type: 'Link',
                        linkType: 'ContentType',
                        id: 'eventVariant',
                    },
                },
                locale: 'en-US',
            },
            fields: {
                title: 'Saturday / 21:00 CEST / 4K UHD + Dolby Atmos',
                sku: 'annemarie-therapy-sat-cest-uhd',
                doorsOpen: '2021-12-11T21:00+01:00',
                saleEnd: '2021-12-11T21:00+01:00',
                eventStart: '2021-12-11T21:00+01:00',
                eventEnd: '2021-12-12T00:00+01:00',
                price: {
                    title: 'Default Variant Price',
                    EUR: 18.99,
                    USD: 18.99,
                    GBP: 18.99,
                    AUD: 18.99,
                },
                stream: {
                    title: 'annemarie-therapy-sat-cest-uhd',
                    dashUrl: dashUrlMock,
                    hlsUrl: hlsUrlMock,
                },
                compareAtPrice: {
                    title: 'Default Variant Price CompareAt',
                    EUR: 20.99,
                    USD: 20.99,
                    GBP: 20.99,
                    AUD: 20.99,
                },
                options: [
                    {
                        position: 1,
                        value: 'Saturday',
                    },
                    {
                        position: 2,
                        value: '21:00 CEST',
                    },
                    {
                        position: 3,
                        value: '4K UHD + Dolby Atmos',
                    },
                ],
            },
        },
    ],
};

export const eventsMock = {
    sys: {
        type: 'Array',
    },
    total: 2,
    skip: 0,
    limit: 100,
    items: [
        {
            metadata: {
                tags: [],
            },
            sys: {
                space: {
                    sys: {
                        type: 'Link',
                        linkType: 'Space',
                        id: 's26j9zm7rn0w',
                    },
                },
                id: '77Gmn4M6hYfWqqxSpEzYDR',
                type: 'Entry',
                createdAt: '2021-10-19T13:51:04.350Z',
                updatedAt: '2021-12-14T14:52:11.154Z',
                environment: {
                    sys: {
                        id: 'development',
                        type: 'Link',
                        linkType: 'Environment',
                    },
                },
                revision: 28,
                contentType: {
                    sys: {
                        type: 'Link',
                        linkType: 'ContentType',
                        id: 'event',
                    },
                },
                locale: 'en-US',
            },
            fields: {
                title: 'Anne Marie Therapy Past',
                slug: 'annemarie-therapy-past',
                shopifyProductId: '6720059736256',
                variants: [
                    {
                        title: 'Friday / 20:00 AEST / HD + Stereo',
                        sku: 'annemarie-therapy-fri-aest-hd',
                        doorsOpen: '2021-12-14T21:00+01:00',
                        saleEnd: '2021-12-11T21:00+01:00',
                        eventStart: '2021-12-14T14:00+01:00',
                        eventEnd: '2021-12-14T18:00+01:00',
                        price: {
                            title: 'Default Variant Price',
                            EUR: 14.99,
                            USD: 14.99,
                            GBP: 14.99,
                            AUD: 14.99,
                        },
                        stream: {
                            title: 'annemarie-therapy-fri-aest-hd',
                            dashUrl: dashUrlMock,
                            hlsUrl: hlsUrlMock,
                        },
                        compareAtPrice: {
                            title: 'Default Variant Price CompareAt',
                            EUR: 17.99,
                            USD: 17.99,
                            GBP: 17.99,
                            AUD: 17.99,
                        },
                        options: [
                            {
                                position: 1,
                                value: 'Friday',
                            },
                            {
                                position: 2,
                                value: '20:00 AEST',
                            },
                            {
                                position: 3,
                                value: 'HD + Stereo',
                            },
                        ],
                    },
                    {
                        title: 'Saturday / 20:00 EST / HD + Stereo',
                        sku: 'annemarie-therapy-sat-aest-hd',
                        doorsOpen: '2021-12-11T21:00+01:00',
                        saleEnd: '2021-12-11T21:00+01:00',
                        eventStart: '2021-12-04T20:00+01:00',
                        eventEnd: '2021-12-05T00:00+01:00',
                        price: {
                            title: 'Default Variant Price',
                            EUR: 14.99,
                            USD: 14.99,
                            GBP: 14.99,
                            AUD: 14.99,
                        },
                        stream: {
                            title: 'annemarie-therapy-sat-aest-hd',
                            dashUrl: dashUrlMock,
                            hlsUrl: hlsUrlMock,
                        },
                        options: [
                            {
                                position: 1,
                                value: 'Saturday',
                            },
                            {
                                position: 2,
                                value: '20:00 EST',
                            },
                            {
                                position: 3,
                                value: 'HD + Stereo',
                            },
                        ],
                    },
                    {
                        title: 'Friday / 21:00 CEST / HD + Stereo',
                        sku: 'annemarie-therapy-fri-cest-hd',
                        doorsOpen: '2021-12-11T21:00+01:00',
                        saleEnd: '2021-12-11T21:00+01:00',
                        eventStart: '2021-12-10T21:00+01:00',
                        eventEnd: '2021-12-10T23:00+01:00',
                        price: {
                            title: 'Default Variant Price',
                            EUR: 14.99,
                            USD: 14.99,
                            GBP: 14.99,
                            AUD: 14.99,
                        },
                        stream: {
                            title: 'annemarie-therapy-fri-cest-hd',
                            dashUrl: dashUrlMock,
                            hlsUrl: hlsUrlMock,
                        },
                        options: [
                            {
                                position: 1,
                                value: 'Friday',
                            },
                            {
                                position: 2,
                                value: '21:00 CEST',
                            },
                            {
                                position: 3,
                                value: 'HD + Stereo',
                            },
                        ],
                    },
                    {
                        title: 'Saturday / 21:00 CEST / HD + Stereo',
                        sku: 'annemarie-therapy-sat-cest-hd',
                        doorsOpen: '2021-12-11T21:00+01:00',
                        saleEnd: '2021-12-11T21:00+01:00',
                        eventStart: '2021-12-11T21:00+01:00',
                        eventEnd: '2021-12-12T00:00+01:00',
                        price: {
                            title: 'Default Variant Price',
                            EUR: 14.99,
                            USD: 14.99,
                            GBP: 14.99,
                            AUD: 14.99,
                        },
                        stream: {
                            title: 'annemarie-therapy-sat-cest-hd',
                            dashUrl: dashUrlMock,
                            hlsUrl: hlsUrlMock,
                        },
                        options: [
                            {
                                position: 1,
                                value: 'Saturday',
                            },
                            {
                                position: 2,
                                value: '21:00 CEST',
                            },
                            {
                                position: 3,
                                value: 'HD + Stereo',
                            },
                        ],
                    },
                    {
                        title: 'Friday / 22:00 GMT / HD + Stereo',
                        sku: 'annemarie-therapy-fri-gmt-hd',
                        doorsOpen: '2021-12-11T21:00+01:00',
                        saleEnd: '2021-12-11T21:00+01:00',
                        eventStart: '2021-12-10T22:00+01:00',
                        eventEnd: '2021-12-11T00:00+01:00',
                        price: {
                            title: 'Default Variant Price',
                            EUR: 14.99,
                            USD: 14.99,
                            GBP: 14.99,
                            AUD: 14.99,
                        },
                        stream: {
                            title: 'annemarie-therapy-fri-gmt-hd',
                            dashUrl: dashUrlMock,
                            hlsUrl: hlsUrlMock,
                        },
                        options: [
                            {
                                position: 1,
                                value: 'Friday',
                            },
                            {
                                position: 2,
                                value: '22:00 GMT',
                            },
                            {
                                position: 3,
                                value: 'HD + Stereo',
                            },
                        ],
                    },
                    {
                        title: 'Saturday / 22:00 GMT / HD + Stereo',
                        sku: 'annemarie-therapy-sat-gmt-hd',
                        doorsOpen: '2021-12-11T21:00+01:00',
                        saleEnd: '2021-12-11T21:00+01:00',
                        eventStart: '2021-12-10T22:00+01:00',
                        eventEnd: '2021-12-11T00:00+01:00',
                        price: {
                            title: 'Default Variant Price',
                            EUR: 14.99,
                            USD: 14.99,
                            GBP: 14.99,
                            AUD: 14.99,
                        },
                        stream: {
                            title: 'annemarie-therapy-sat-gmt-hd',
                            dashUrl: dashUrlMock,
                            hlsUrl: hlsUrlMock,
                        },
                        options: [
                            {
                                position: 1,
                                value: 'Saturday',
                            },
                            {
                                position: 2,
                                value: '22:00 GMT',
                            },
                            {
                                position: 3,
                                value: 'HD + Stereo',
                            },
                        ],
                    },
                    {
                        title: 'Friday / 20:00 AEST / 4K UHD + Dolby Atmos',
                        sku: 'annemarie-therapy-fri-aest-uhd',
                        doorsOpen: '2021-12-11T21:00+01:00',
                        saleEnd: '2021-12-11T21:00+01:00',
                        eventStart: '2021-12-10T20:00+01:00',
                        eventEnd: '2021-12-11T00:00+01:00',
                        price: {
                            title: 'Default Variant Price',
                            EUR: 18.99,
                            USD: 18.99,
                            GBP: 18.99,
                            AUD: 18.99,
                        },
                        stream: {
                            title: 'annemarie-therapy-sat-gmt-hd',
                            dashUrl: dashUrlMock,
                            hlsUrl: hlsUrlMock,
                        },
                        compareAtPrice: {
                            title: 'Default Variant Price CompareAt',
                            EUR: 20.99,
                            USD: 20.99,
                            GBP: 20.99,
                            AUD: 20.99,
                        },
                        options: [
                            {
                                position: 1,
                                value: 'Friday',
                            },
                            {
                                position: 2,
                                value: '20:00 AEST',
                            },
                            {
                                position: 3,
                                value: '4K UHD + Dolby Atmos',
                            },
                        ],
                    },
                    {
                        title: 'Saturday / 20:00 AEST / 4K UHD + Dolby Atmos',
                        sku: 'annemarie-therapy-sat-aest-uhd',
                        doorsOpen: '2021-12-11T21:00+01:00',
                        saleEnd: '2021-12-11T21:00+01:00',
                        eventStart: '2021-12-11T20:00+01:00',
                        eventEnd: '2021-12-12T00:00+01:00',
                        price: {
                            title: 'Default Variant Price',
                            EUR: 18.99,
                            USD: 18.99,
                            GBP: 18.99,
                            AUD: 18.99,
                        },
                        stream: {
                            title: 'annemarie-therapy-sat-gmt-hd',
                            dashUrl: dashUrlMock,
                            hlsUrl: hlsUrlMock,
                        },
                        compareAtPrice: {
                            title: 'Default Variant Price CompareAt',
                            EUR: 20.99,
                            USD: 20.99,
                            GBP: 20.99,
                            AUD: 20.99,
                        },
                        options: [
                            {
                                position: 1,
                                value: 'Saturday',
                            },
                            {
                                position: 2,
                                value: '20:00 AEST',
                            },
                            {
                                position: 3,
                                value: '4K UHD + Dolby Atmos',
                            },
                        ],
                    },
                    {
                        title: 'Friday / 21:00 CEST / 4K UHD + Dolby Atmos',
                        sku: 'annemarie-therapy-fri-cest-uhd',
                        doorsOpen: '2021-12-11T21:00+01:00',
                        saleEnd: '2021-12-11T21:00+01:00',
                        eventStart: '2021-12-10T21:00+01:00',
                        eventEnd: '2021-12-11T00:00+01:00',
                        price: {
                            title: 'Default Variant Price',
                            EUR: 18.99,
                            USD: 18.99,
                            GBP: 18.99,
                            AUD: 18.99,
                        },
                        stream: {
                            title: 'annemarie-therapy-sat-gmt-hd',
                            dashUrl: dashUrlMock,
                            hlsUrl: hlsUrlMock,
                        },
                        compareAtPrice: {
                            title: 'Default Variant Price CompareAt',
                            EUR: 20.99,
                            USD: 20.99,
                            GBP: 20.99,
                            AUD: 20.99,
                        },
                        options: [
                            {
                                position: 1,
                                value: 'Friday',
                            },
                            {
                                position: 2,
                                value: '21:00 CEST',
                            },
                            {
                                position: 3,
                                value: '4K UHD + Dolby Atmos',
                            },
                        ],
                    },
                    {
                        title: 'Saturday / 21:00 CEST / 4K UHD + Dolby Atmos',
                        sku: 'annemarie-therapy-sat-cest-uhd',
                        doorsOpen: '2021-12-11T21:00+01:00',
                        saleEnd: '2021-12-11T21:00+01:00',
                        eventStart: '2021-12-11T21:00+01:00',
                        eventEnd: '2021-12-12T00:00+01:00',
                        price: {
                            title: 'Default Variant Price',
                            EUR: 18.99,
                            USD: 18.99,
                            GBP: 18.99,
                            AUD: 18.99,
                        },
                        stream: {
                            title: 'annemarie-therapy-sat-gmt-hd',
                            dashUrl: dashUrlMock,
                            hlsUrl: hlsUrlMock,
                        },
                        compareAtPrice: {
                            title: 'Default Variant Price CompareAt',
                            EUR: 20.99,
                            USD: 20.99,
                            GBP: 20.99,
                            AUD: 20.99,
                        },
                        options: [
                            {
                                position: 1,
                                value: 'Saturday',
                            },
                            {
                                position: 2,
                                value: '21:00 CEST',
                            },
                            {
                                position: 3,
                                value: '4K UHD + Dolby Atmos',
                            },
                        ],
                    },
                    {
                        title: 'Friday / 22:00 GMT / 4K UHD + Dolby Atmos',
                        sku: 'annemarie-therapy-fri-gmt-uhd',
                        doorsOpen: '2021-12-11T21:00+01:00',
                        saleEnd: '2021-12-11T21:00+01:00',
                        eventStart: '2021-12-10T22:00+01:00',
                        eventEnd: '2021-12-11T00:00+01:00',
                        price: {
                            title: 'Default Variant Price',
                            EUR: 18.99,
                            USD: 18.99,
                            GBP: 18.99,
                            AUD: 18.99,
                        },
                        stream: {
                            title: 'annemarie-therapy-sat-gmt-hd',
                            dashUrl: dashUrlMock,
                            hlsUrl: hlsUrlMock,
                        },
                        compareAtPrice: {
                            title: 'Default Variant Price CompareAt',
                            EUR: 20.99,
                            USD: 20.99,
                            GBP: 20.99,
                            AUD: 20.99,
                        },
                        options: [
                            {
                                position: 1,
                                value: 'Saturday',
                            },
                            {
                                position: 2,
                                value: '22:00 GMT',
                            },
                            {
                                position: 3,
                                value: '4K UHD + Dolby Atmos',
                            },
                        ],
                    },
                    {
                        title: 'Saturday / 22:00 GMT / 4K UHD + Dolby Atmos',
                        sku: 'annemarie-therapy-sat-gmt-uhd',
                        doorsOpen: '2021-12-11T21:00+01:00',
                        saleEnd: '2021-12-11T21:00+01:00',
                        eventStart: '2021-12-11T22:00+01:00',
                        eventEnd: '2021-12-12T00:00+01:00',
                        price: {
                            title: 'Default Variant Price',
                            EUR: 18.99,
                            USD: 18.99,
                            GBP: 18.99,
                            AUD: 18.99,
                        },
                        stream: {
                            title: 'annemarie-therapy-sat-gmt-hd',
                            dashUrl: dashUrlMock,
                            hlsUrl: hlsUrlMock,
                        },
                        compareAtPrice: {
                            title: 'Default Variant Price CompareAt',
                            EUR: 20.99,
                            USD: 20.99,
                            GBP: 20.99,
                            AUD: 20.99,
                        },
                        options: [
                            {
                                position: 1,
                                value: 'Saturday',
                            },
                            {
                                position: 2,
                                value: '22:00 GMT',
                            },
                            {
                                position: 3,
                                value: '4K UHD + Dolby Atmos',
                            },
                        ],
                    },
                ],
                poster: {
                    asset: {
                        title: 'Anne-Marie-Therapy',
                        description: 'on air home page banner',
                        file: {
                            url: '//images.ctfassets.net/s26j9zm7rn0w/3AvhknIQvgH6RVOUMTxQoq/51bd9204765c8a4b2798197a5c6395fa/Anne_Marie_1.jpeg',
                            details: {
                                size: 92908,
                                image: {
                                    width: 750,
                                    height: 387,
                                },
                            },
                            fileName: 'Anne Marie 1.jpeg',
                            contentType: 'image/jpeg',
                        },
                    },
                    alt: 'Anne Marie listening to her fans with a mic in her hand',
                },
                eventInfoLogo: {
                    asset: {
                        title: 'Logo Title',
                        description: '',
                        file: {
                            url: eventInfoLogoUrl,
                            details: {
                                size: 25531,
                                image: {
                                    width: 366,
                                    height: 180,
                                },
                            },
                            fileName: 'Logo Title.png',
                            contentType: 'image/png',
                        },
                    },
                    alt: 'Anne Marie Therapy - The Live Experience with a pink and pretty font',
                },
                tags: ['past-events'],
                type: 'spo',
                socialLinks: {
                    instagramLink: 'https://www.instagram.com/annemarie/',
                    twitterLink: 'https://twitter.com/annemarie',
                    facebookLink: 'https://facebook.com/iamannemarie',
                },
                trailer: streamMock,
            },
        },
        {
            metadata: {
                tags: [],
            },
            sys: {
                space: {
                    sys: {
                        type: 'Link',
                        linkType: 'Space',
                        id: 's26j9zm7rn0w',
                    },
                },
                id: '7I1HWn8hYao5C8hruBTrUi',
                type: 'Entry',
                createdAt: '2021-11-24T11:13:38.994Z',
                updatedAt: '2021-11-24T11:17:22.677Z',
                environment: {
                    sys: {
                        id: 'development',
                        type: 'Link',
                        linkType: 'Environment',
                    },
                },
                revision: 2,
                contentType: {
                    sys: {
                        type: 'Link',
                        linkType: 'ContentType',
                        id: 'event',
                    },
                },
                locale: 'en-US',
            },
            fields: {
                title: 'Anne Marie Therapy Future',
                slug: 'annemarie-therapyfuture',
                shopifyProductId: 'fake-id-2',
                variants: [
                    {
                        title: 'Friday / 20:00 AEST / HD + Stereo',
                        sku: 'annemarie-therapy-fri-aest-hd',
                        doorsOpen: '2021-12-14T21:00+01:00',
                        saleEnd: '2021-12-11T21:00+01:00',
                        eventStart: '2021-12-14T14:00+01:00',
                        eventEnd: '2021-12-14T18:00+01:00',
                        price: {
                            title: 'Default Variant Price',
                            EUR: 14.99,
                            USD: 14.99,
                            GBP: 14.99,
                            AUD: 14.99,
                        },
                        stream: {
                            title: 'annemarie-therapy-sat-gmt-hd',
                            dashUrl: dashUrlMock,
                            hlsUrl: hlsUrlMock,
                        },
                        compareAtPrice: {
                            title: 'Default Variant Price CompareAt',
                            EUR: 17.99,
                            USD: 17.99,
                            GBP: 17.99,
                            AUD: 17.99,
                        },
                        options: [
                            {
                                position: 1,
                                value: 'Friday',
                            },
                            {
                                position: 2,
                                value: '20:00 AEST',
                            },
                            {
                                position: 3,
                                value: 'HD + Stereo',
                            },
                        ],
                    },
                    {
                        title: 'Saturday / 20:00 EST / HD + Stereo',
                        sku: 'annemarie-therapy-sat-aest-hd',
                        doorsOpen: '2021-12-11T21:00+01:00',
                        saleEnd: '2021-12-11T21:00+01:00',
                        eventStart: '2021-12-04T20:00+01:00',
                        eventEnd: '2021-12-05T00:00+01:00',
                        price: {
                            title: 'Default Variant Price',
                            EUR: 14.99,
                            USD: 14.99,
                            GBP: 14.99,
                            AUD: 14.99,
                        },
                        stream: {
                            title: 'annemarie-therapy-sat-gmt-hd',
                            dashUrl: dashUrlMock,
                            hlsUrl: hlsUrlMock,
                        },
                        options: [
                            {
                                position: 1,
                                value: 'Saturday',
                            },
                            {
                                position: 2,
                                value: '20:00 EST',
                            },
                            {
                                position: 3,
                                value: 'HD + Stereo',
                            },
                        ],
                    },
                    {
                        title: 'Friday / 21:00 CEST / HD + Stereo',
                        sku: 'annemarie-therapy-fri-cest-hd',
                        doorsOpen: '2021-12-11T21:00+01:00',
                        saleEnd: '2021-12-11T21:00+01:00',
                        eventStart: '2021-12-10T21:00+01:00',
                        eventEnd: '2021-12-10T23:00+01:00',
                        price: {
                            title: 'Default Variant Price',
                            EUR: 14.99,
                            USD: 14.99,
                            GBP: 14.99,
                            AUD: 14.99,
                        },
                        stream: {
                            title: 'annemarie-therapy-sat-gmt-hd',
                            dashUrl: dashUrlMock,
                            hlsUrl: hlsUrlMock,
                        },
                        options: [
                            {
                                position: 1,
                                value: 'Friday',
                            },
                            {
                                position: 2,
                                value: '21:00 CEST',
                            },
                            {
                                position: 3,
                                value: 'HD + Stereo',
                            },
                        ],
                    },
                    {
                        title: 'Saturday / 21:00 CEST / HD + Stereo',
                        sku: 'annemarie-therapy-sat-cest-hd',
                        doorsOpen: '2021-12-11T21:00+01:00',
                        saleEnd: '2021-12-11T21:00+01:00',
                        eventStart: '2021-12-11T21:00+01:00',
                        eventEnd: '2021-12-12T00:00+01:00',
                        price: {
                            title: 'Default Variant Price',
                            EUR: 14.99,
                            USD: 14.99,
                            GBP: 14.99,
                            AUD: 14.99,
                        },
                        stream: {
                            title: 'annemarie-therapy-sat-gmt-hd',
                            dashUrl: dashUrlMock,
                            hlsUrl: hlsUrlMock,
                        },
                        options: [
                            {
                                position: 1,
                                value: 'Saturday',
                            },
                            {
                                position: 2,
                                value: '21:00 CEST',
                            },
                            {
                                position: 3,
                                value: 'HD + Stereo',
                            },
                        ],
                    },
                    {
                        title: 'Friday / 22:00 GMT / HD + Stereo',
                        sku: 'annemarie-therapy-fri-gmt-hd',
                        doorsOpen: '2021-12-11T21:00+01:00',
                        saleEnd: '2021-12-11T21:00+01:00',
                        eventStart: '2021-12-10T22:00+01:00',
                        eventEnd: '2021-12-11T00:00+01:00',
                        price: {
                            title: 'Default Variant Price',
                            EUR: 14.99,
                            USD: 14.99,
                            GBP: 14.99,
                            AUD: 14.99,
                        },
                        stream: {
                            title: 'annemarie-therapy-sat-gmt-hd',
                            dashUrl: dashUrlMock,
                            hlsUrl: hlsUrlMock,
                        },
                        options: [
                            {
                                position: 1,
                                value: 'Friday',
                            },
                            {
                                position: 2,
                                value: '22:00 GMT',
                            },
                            {
                                position: 3,
                                value: 'HD + Stereo',
                            },
                        ],
                    },
                    {
                        title: 'Saturday / 22:00 GMT / HD + Stereo',
                        sku: 'annemarie-therapy-sat-gmt-hd',
                        doorsOpen: '2021-12-11T21:00+01:00',
                        saleEnd: '2021-12-11T21:00+01:00',
                        eventStart: '2021-12-10T22:00+01:00',
                        eventEnd: '2021-12-11T00:00+01:00',
                        price: {
                            title: 'Default Variant Price',
                            EUR: 14.99,
                            USD: 14.99,
                            GBP: 14.99,
                            AUD: 14.99,
                        },
                        stream: {
                            title: 'annemarie-therapy-sat-gmt-hd',
                            dashUrl: dashUrlMock,
                            hlsUrl: hlsUrlMock,
                        },
                        options: [
                            {
                                position: 1,
                                value: 'Saturday',
                            },
                            {
                                position: 2,
                                value: '22:00 GMT',
                            },
                            {
                                position: 3,
                                value: 'HD + Stereo',
                            },
                        ],
                    },
                    {
                        title: 'Friday / 20:00 AEST / 4K UHD + Dolby Atmos',
                        sku: 'annemarie-therapy-fri-aest-uhd',
                        doorsOpen: '2021-12-11T21:00+01:00',
                        saleEnd: '2021-12-11T21:00+01:00',
                        eventStart: '2021-12-10T20:00+01:00',
                        eventEnd: '2021-12-11T00:00+01:00',
                        price: {
                            title: 'Default Variant Price',
                            EUR: 18.99,
                            USD: 18.99,
                            GBP: 18.99,
                            AUD: 18.99,
                        },
                        stream: {
                            title: 'annemarie-therapy-sat-gmt-hd',
                            dashUrl: dashUrlMock,
                            hlsUrl: hlsUrlMock,
                        },
                        compareAtPrice: {
                            title: 'Default Variant Price CompareAt',
                            EUR: 20.99,
                            USD: 20.99,
                            GBP: 20.99,
                            AUD: 20.99,
                        },
                        options: [
                            {
                                position: 1,
                                value: 'Friday',
                            },
                            {
                                position: 2,
                                value: '20:00 AEST',
                            },
                            {
                                position: 3,
                                value: '4K UHD + Dolby Atmos',
                            },
                        ],
                    },
                    {
                        title: 'Saturday / 20:00 AEST / 4K UHD + Dolby Atmos',
                        sku: 'annemarie-therapy-sat-aest-uhd',
                        doorsOpen: '2021-12-11T21:00+01:00',
                        saleEnd: '2021-12-11T21:00+01:00',
                        eventStart: '2021-12-11T20:00+01:00',
                        eventEnd: '2021-12-12T00:00+01:00',
                        price: {
                            title: 'Default Variant Price',
                            EUR: 18.99,
                            USD: 18.99,
                            GBP: 18.99,
                            AUD: 18.99,
                        },
                        stream: {
                            title: 'annemarie-therapy-sat-gmt-hd',
                            dashUrl: dashUrlMock,
                            hlsUrl: hlsUrlMock,
                        },
                        compareAtPrice: {
                            title: 'Default Variant Price CompareAt',
                            EUR: 20.99,
                            USD: 20.99,
                            GBP: 20.99,
                            AUD: 20.99,
                        },
                        options: [
                            {
                                position: 1,
                                value: 'Saturday',
                            },
                            {
                                position: 2,
                                value: '20:00 AEST',
                            },
                            {
                                position: 3,
                                value: '4K UHD + Dolby Atmos',
                            },
                        ],
                    },
                    {
                        title: 'Friday / 21:00 CEST / 4K UHD + Dolby Atmos',
                        sku: 'annemarie-therapy-fri-cest-uhd',
                        doorsOpen: '2021-12-11T21:00+01:00',
                        saleEnd: '2021-12-11T21:00+01:00',
                        eventStart: '2021-12-10T21:00+01:00',
                        eventEnd: '2021-12-11T00:00+01:00',
                        price: {
                            title: 'Default Variant Price',
                            EUR: 18.99,
                            USD: 18.99,
                            GBP: 18.99,
                            AUD: 18.99,
                        },
                        stream: {
                            title: 'annemarie-therapy-sat-gmt-hd',
                            dashUrl: dashUrlMock,
                            hlsUrl: hlsUrlMock,
                        },
                        compareAtPrice: {
                            title: 'Default Variant Price CompareAt',
                            EUR: 20.99,
                            USD: 20.99,
                            GBP: 20.99,
                            AUD: 20.99,
                        },
                        options: [
                            {
                                position: 1,
                                value: 'Friday',
                            },
                            {
                                position: 2,
                                value: '21:00 CEST',
                            },
                            {
                                position: 3,
                                value: '4K UHD + Dolby Atmos',
                            },
                        ],
                    },
                    {
                        title: 'Saturday / 21:00 CEST / 4K UHD + Dolby Atmos',
                        sku: 'annemarie-therapy-sat-cest-uhd',
                        doorsOpen: '2021-12-11T21:00+01:00',
                        saleEnd: '2021-12-11T21:00+01:00',
                        eventStart: '2021-12-11T21:00+01:00',
                        eventEnd: '2021-12-12T00:00+01:00',
                        price: {
                            title: 'Default Variant Price',
                            EUR: 18.99,
                            USD: 18.99,
                            GBP: 18.99,
                            AUD: 18.99,
                        },
                        stream: {
                            title: 'annemarie-therapy-sat-gmt-hd',
                            dashUrl: dashUrlMock,
                            hlsUrl: hlsUrlMock,
                        },
                        compareAtPrice: {
                            title: 'Default Variant Price CompareAt',
                            EUR: 20.99,
                            USD: 20.99,
                            GBP: 20.99,
                            AUD: 20.99,
                        },
                        options: [
                            {
                                position: 1,
                                value: 'Saturday',
                            },
                            {
                                position: 2,
                                value: '21:00 CEST',
                            },
                            {
                                position: 3,
                                value: '4K UHD + Dolby Atmos',
                            },
                        ],
                    },
                    {
                        title: 'Friday / 22:00 GMT / 4K UHD + Dolby Atmos',
                        sku: 'annemarie-therapy-fri-gmt-uhd',
                        doorsOpen: '2021-12-11T21:00+01:00',
                        saleEnd: '2021-12-11T21:00+01:00',
                        eventStart: '2021-12-10T22:00+01:00',
                        eventEnd: '2021-12-11T00:00+01:00',
                        price: {
                            title: 'Default Variant Price',
                            EUR: 18.99,
                            USD: 18.99,
                            GBP: 18.99,
                            AUD: 18.99,
                        },
                        stream: {
                            title: 'annemarie-therapy-sat-gmt-hd',
                            dashUrl: dashUrlMock,
                            hlsUrl: hlsUrlMock,
                        },
                        compareAtPrice: {
                            title: 'Default Variant Price CompareAt',
                            EUR: 20.99,
                            USD: 20.99,
                            GBP: 20.99,
                            AUD: 20.99,
                        },
                        options: [
                            {
                                position: 1,
                                value: 'Friday',
                            },
                            {
                                position: 2,
                                value: '22:00 GMT',
                            },
                            {
                                position: 3,
                                value: '4K UHD + Dolby Atmos',
                            },
                        ],
                    },
                    {
                        title: 'Saturday / 22:00 GMT / 4K UHD + Dolby Atmos',
                        sku: 'annemarie-therapy-sat-gmt-uhd',
                        doorsOpen: '2021-12-11T21:00+01:00',
                        saleEnd: '2021-12-11T21:00+01:00',
                        eventStart: '2021-12-11T22:00+01:00',
                        eventEnd: '2021-12-12T00:00+01:00',
                        price: {
                            title: 'Default Variant Price',
                            EUR: 18.99,
                            USD: 18.99,
                            GBP: 18.99,
                            AUD: 18.99,
                        },
                        stream: {
                            title: 'annemarie-therapy-sat-gmt-hd',
                            dashUrl: dashUrlMock,
                            hlsUrl: hlsUrlMock,
                        },
                        compareAtPrice: {
                            title: 'Default Variant Price CompareAt',
                            EUR: 20.99,
                            USD: 20.99,
                            GBP: 20.99,
                            AUD: 20.99,
                        },
                        options: [
                            {
                                position: 1,
                                value: 'Saturday',
                            },
                            {
                                position: 2,
                                value: '22:00 GMT',
                            },
                            {
                                position: 3,
                                value: '4K UHD + Dolby Atmos',
                            },
                        ],
                    },
                ],
                poster: {
                    asset: {
                        title: 'Anne-Marie-Therapy',
                        description: 'on air home page banner',
                        file: {
                            url: '//images.ctfassets.net/s26j9zm7rn0w/3AvhknIQvgH6RVOUMTxQoq/51bd9204765c8a4b2798197a5c6395fa/Anne_Marie_1.jpeg',
                            details: {
                                size: 92908,
                                image: {
                                    width: 750,
                                    height: 387,
                                },
                            },
                            fileName: 'Anne Marie 1.jpeg',
                            contentType: 'image/jpeg',
                        },
                    },
                    alt: 'Anne Marie listening to her fans with a mic in her hand',
                },
                eventInfoLogo: {
                    asset: {
                        title: 'Logo Title',
                        description: '',
                        file: {
                            url: eventInfoLogoUrl,
                            details: {
                                size: 25531,
                                image: {
                                    width: 366,
                                    height: 180,
                                },
                            },
                            fileName: 'Logo Title.png',
                            contentType: 'image/png',
                        },
                    },
                    alt: 'Anne Marie Therapy - The Live Experience with a pink and pretty font',
                },
                tags: ['future-events'],
                type: 'spo',
            },
        },
    ],
    includes: {
        Entry: [
            {
                metadata: {
                    tags: [],
                },
                sys: {
                    space: {
                        sys: {
                            type: 'Link',
                            linkType: 'Space',
                            id: 's26j9zm7rn0w',
                        },
                    },
                    id: '01eqXqEYQIo0FouXbW26LE',
                    type: 'Entry',
                    createdAt: '2021-11-18T09:26:49.520Z',
                    updatedAt: '2021-12-14T16:09:32.161Z',
                    environment: {
                        sys: {
                            id: 'development',
                            type: 'Link',
                            linkType: 'Environment',
                        },
                    },
                    revision: 6,
                    contentType: {
                        sys: {
                            type: 'Link',
                            linkType: 'ContentType',
                            id: 'eventVariant',
                        },
                    },
                    locale: 'en-US',
                },
                fields: {
                    title: 'Friday / 21:00 CEST / HD + Stereo',
                    sku: 'annemarie-therapy-fri-cest-hd',
                    doorsOpen: '2021-12-11T21:00+01:00',
                    saleEnd: '2021-12-11T21:00+01:00',
                    eventStart: '2021-12-10T21:00+01:00',
                    eventEnd: '2021-12-10T23:00+01:00',
                    price: {
                        title: 'Default Variant Price',
                        EUR: 14.99,
                        USD: 14.99,
                        GBP: 14.99,
                        AUD: 14.99,
                    },
                    stream: {
                        title: 'annemarie-therapy-fri-cest-hd',
                        dashUrl: dashUrlMock,
                        hlsUrl: hlsUrlMock,
                    },
                },
            },
            {
                metadata: {
                    tags: [],
                },
                sys: {
                    space: {
                        sys: {
                            type: 'Link',
                            linkType: 'Space',
                            id: 's26j9zm7rn0w',
                        },
                    },
                    id: '13NljDbIVBu8KV4yzBG7Ou',
                    type: 'Entry',
                    createdAt: '2021-11-29T15:51:13.370Z',
                    updatedAt: '2021-11-30T10:19:59.104Z',
                    environment: {
                        sys: {
                            id: 'development',
                            type: 'Link',
                            linkType: 'Environment',
                        },
                    },
                    revision: 3,
                    contentType: {
                        sys: {
                            type: 'Link',
                            linkType: 'ContentType',
                            id: 'eventFeature',
                        },
                    },
                    locale: 'en-US',
                },
                fields: {
                    title: 'Ultra HD Video',
                    description: {
                        data: {},
                        content: [
                            {
                                data: {},
                                content: [
                                    {
                                        data: {},
                                        marks: [],
                                        value: 'This livestream is recorded in 4K, making it possible to have a crisp and clear video in Ultra High Definition.',
                                        nodeType: 'text',
                                    },
                                ],
                                nodeType: BLOCKS.PARAGRAPH,
                            },
                        ],
                        nodeType: BLOCKS.DOCUMENT,
                    },
                    icon: {
                        sys: {
                            type: 'Link',
                            linkType: 'Entry',
                            id: '168CYkmcl0jMiIqSfRRzY6',
                        },
                    },
                },
            },
            {
                metadata: {
                    tags: [],
                },
                sys: {
                    space: {
                        sys: {
                            type: 'Link',
                            linkType: 'Space',
                            id: 's26j9zm7rn0w',
                        },
                    },
                    id: '15zg6uCFPy4b2SO70h5zR8',
                    type: 'Entry',
                    createdAt: '2021-11-18T09:29:46.820Z',
                    updatedAt: '2021-12-14T16:11:05.441Z',
                    environment: {
                        sys: {
                            id: 'development',
                            type: 'Link',
                            linkType: 'Environment',
                        },
                    },
                    revision: 6,
                    contentType: {
                        sys: {
                            type: 'Link',
                            linkType: 'ContentType',
                            id: 'eventVariant',
                        },
                    },
                    locale: 'en-US',
                },
                fields: {
                    title: 'Saturday / 21:00 CEST / 4K UHD + Dolby Atmos',
                    sku: 'annemarie-therapy-sat-cest-uhd',
                    doorsOpen: '2021-12-11T21:00+01:00',
                    saleEnd: '2021-12-11T21:00+01:00',
                    eventStart: '2021-12-11T21:00+01:00',
                    eventEnd: '2021-12-12T00:00+01:00',
                    price: {
                        title: 'Default Variant Price',
                        EUR: 18.99,
                        USD: 18.99,
                        GBP: 18.99,
                        AUD: 18.99,
                    },
                    stream: {
                        title: 'annemarie-therapy-fri-aest-hd',
                        dashUrl: dashUrlMock,
                        hlsUrl: hlsUrlMock,
                    },
                    compareAtPrice: {
                        title: 'Default Variant Price CompareAt',
                        EUR: 20.99,
                        USD: 20.99,
                        GBP: 20.99,
                        AUD: 20.99,
                    },
                },
            },
            {
                metadata: {
                    tags: [],
                },
                sys: {
                    space: {
                        sys: {
                            type: 'Link',
                            linkType: 'Space',
                            id: 's26j9zm7rn0w',
                        },
                    },
                    id: '1bqLaxZ58hgwOzUFFw1SsG',
                    type: 'Entry',
                    createdAt: '2021-11-26T10:35:25.272Z',
                    updatedAt: '2021-11-29T11:44:22.709Z',
                    environment: {
                        sys: {
                            id: 'development',
                            type: 'Link',
                            linkType: 'Environment',
                        },
                    },
                    revision: 9,
                    contentType: {
                        sys: {
                            type: 'Link',
                            linkType: 'ContentType',
                            id: 'contentBlock',
                        },
                    },
                    locale: 'en-US',
                },
                fields: {
                    type: 'EventDescription',
                    title: 'Therapy',
                    description: {
                        data: {},
                        content: [
                            {
                                data: {},
                                content: [
                                    {
                                        data: {},
                                        marks: [],
                                        value: '‘Therapy’ is a collection of songs that embody Anne-Marie’s characterful artistry, self-effacing attitude, and beautiful honesty, which has seen the Essex-born star reign supreme as Gen Z role model across the globe. The highly-anticipated new album will include Anne-Marie’s UK Top 3 ‘Don’t Play’ with KSI and Digital Farm Animals, feel-good Nathan Dawe and MoStack collaboration ‘Way Too Long’; and her recent Niall Horan duet ‘Our Song’.',
                                        nodeType: 'text',
                                    },
                                ],
                                nodeType: BLOCKS.PARAGRAPH,
                            },
                        ],
                        nodeType: BLOCKS.DOCUMENT,
                    },
                    imagePosition: 'right',
                    image: {
                        sys: {
                            type: 'Link',
                            linkType: 'Entry',
                            id: '1LGjzevT0lU2CYP5XaqJFM',
                        },
                    },
                    shouldShowSocialButtons: true,
                    showAppStoreBanners: false,
                },
            },
            {
                metadata: {
                    tags: [],
                },
                sys: {
                    space: {
                        sys: {
                            type: 'Link',
                            linkType: 'Space',
                            id: 's26j9zm7rn0w',
                        },
                    },
                    id: '1eZcP5TaacE9COuAh2Vaoo',
                    type: 'Entry',
                    createdAt: '2021-11-18T09:27:38.278Z',
                    updatedAt: '2021-12-14T16:10:15.761Z',
                    environment: {
                        sys: {
                            id: 'development',
                            type: 'Link',
                            linkType: 'Environment',
                        },
                    },
                    revision: 5,
                    contentType: {
                        sys: {
                            type: 'Link',
                            linkType: 'ContentType',
                            id: 'eventVariant',
                        },
                    },
                    locale: 'en-US',
                },
                fields: {
                    title: 'Friday / 22:00 GMT / HD + Stereo',
                    sku: 'annemarie-therapy-fri-gmt-hd',
                    doorsOpen: '2021-12-11T21:00+01:00',
                    saleEnd: '2021-12-11T21:00+01:00',
                    eventStart: '2021-12-10T22:00+01:00',
                    eventEnd: '2021-12-11T00:00+01:00',
                    price: {
                        title: 'Default Variant Price',
                        EUR: 14.99,
                        USD: 14.99,
                        GBP: 14.99,
                        AUD: 14.99,
                    },
                    stream: {
                        title: 'annemarie-therapy-fri-gmt-hd',
                        dashUrl: dashUrlMock,
                        hlsUrl: hlsUrlMock,
                    },
                },
            },
            {
                metadata: {
                    tags: [],
                },
                sys: {
                    space: {
                        sys: {
                            type: 'Link',
                            linkType: 'Space',
                            id: 's26j9zm7rn0w',
                        },
                    },
                    id: '1ggUUb5ZfwlRoamngo9H6i',
                    type: 'Entry',
                    createdAt: '2021-11-16T16:45:18.093Z',
                    updatedAt: '2021-11-18T09:26:04.614Z',
                    environment: {
                        sys: {
                            id: 'development',
                            type: 'Link',
                            linkType: 'Environment',
                        },
                    },
                    revision: 3,
                    contentType: {
                        sys: {
                            type: 'Link',
                            linkType: 'ContentType',
                            id: 'eventOptions',
                        },
                    },
                    locale: 'en-US',
                },
            },
            {
                metadata: {
                    tags: [],
                },
                sys: {
                    space: {
                        sys: {
                            type: 'Link',
                            linkType: 'Space',
                            id: 's26j9zm7rn0w',
                        },
                    },
                    id: '1jSiR3rq47Q5xyGX3n8ky4',
                    type: 'Entry',
                    createdAt: '2021-11-30T14:46:19.931Z',
                    updatedAt: '2021-11-30T15:08:12.577Z',
                    environment: {
                        sys: {
                            id: 'development',
                            type: 'Link',
                            linkType: 'Environment',
                        },
                    },
                    revision: 2,
                    contentType: {
                        sys: {
                            type: 'Link',
                            linkType: 'ContentType',
                            id: 'image',
                        },
                    },
                    locale: 'en-US',
                },
                fields: {
                    asset: {
                        title: 'Annemarie in her car',
                        description: '',
                        file: {
                            url: '//images.ctfassets.net/s26j9zm7rn0w/7DhugovEReeLsVdDf9kYYT/7fa6befd99a4a597a14722d39bb6d837/annemarie_car.jpeg',
                            details: {
                                size: 295556,
                                image: {
                                    width: 1638,
                                    height: 2048,
                                },
                            },
                            fileName: 'annemarie car.jpeg',
                            contentType: 'image/jpeg',
                        },
                    },
                    alt: 'Annemarie in a fancy auto',
                },
            },
            {
                metadata: {
                    tags: [],
                },
                sys: {
                    space: {
                        sys: {
                            type: 'Link',
                            linkType: 'Space',
                            id: 's26j9zm7rn0w',
                        },
                    },
                    id: '1qtUJa72F9i7UKvONb6m3X',
                    type: 'Entry',
                    createdAt: '2021-11-30T14:47:51.342Z',
                    updatedAt: '2021-11-30T15:47:03.613Z',
                    environment: {
                        sys: {
                            id: 'development',
                            type: 'Link',
                            linkType: 'Environment',
                        },
                    },
                    revision: 5,
                    contentType: {
                        sys: {
                            type: 'Link',
                            linkType: 'ContentType',
                            id: 'image',
                        },
                    },
                    locale: 'en-US',
                },
                fields: {
                    asset: {
                        title: 'Annemarie close up',
                        description: '',
                        file: {
                            url: '//images.ctfassets.net/s26j9zm7rn0w/6O9wB7UnbXotxpEiXDd6zM/49eeb881a13aa7c2f2bc92b7990ea4a0/anne-marie-2-1610125552-view-1.jpeg',
                            details: {
                                size: 28123,
                                image: {
                                    width: 460,
                                    height: 374,
                                },
                            },
                            fileName: 'anne-marie-2-1610125552-view-1.jpeg',
                            contentType: 'image/jpeg',
                        },
                    },
                    alt: 'Annemarie close up',
                },
            },
            {
                metadata: {
                    tags: [],
                },
                sys: {
                    space: {
                        sys: {
                            type: 'Link',
                            linkType: 'Space',
                            id: 's26j9zm7rn0w',
                        },
                    },
                    id: '1tVUL2Q1E9l8sz0pjLLRJJ',
                    type: 'Entry',
                    createdAt: '2021-11-18T09:29:35.172Z',
                    updatedAt: '2021-12-14T16:10:57.509Z',
                    environment: {
                        sys: {
                            id: 'development',
                            type: 'Link',
                            linkType: 'Environment',
                        },
                    },
                    revision: 6,
                    contentType: {
                        sys: {
                            type: 'Link',
                            linkType: 'ContentType',
                            id: 'eventVariant',
                        },
                    },
                    locale: 'en-US',
                },
                fields: {
                    title: 'Friday / 21:00 CEST / 4K UHD + Dolby Atmos',
                    sku: 'annemarie-therapy-fri-cest-uhd',
                    doorsOpen: '2021-12-11T21:00+01:00',
                    saleEnd: '2021-12-11T21:00+01:00',
                    eventStart: '2021-12-10T21:00+01:00',
                    eventEnd: '2021-12-11T00:00+01:00',
                    price: {
                        title: 'Default Variant Price',
                        EUR: 18.99,
                        USD: 18.99,
                        GBP: 18.99,
                        AUD: 18.99,
                    },
                    stream: {
                        title: 'annemarie-therapy-fri-aest-hd',
                        dashUrl: dashUrlMock,
                        hlsUrl: hlsUrlMock,
                    },
                    compareAtPrice: {
                        title: 'Default Variant Price CompareAt',
                        EUR: 20.99,
                        USD: 20.99,
                        GBP: 20.99,
                        AUD: 20.99,
                    },
                },
            },
            {
                metadata: {
                    tags: [],
                },
                sys: {
                    space: {
                        sys: {
                            type: 'Link',
                            linkType: 'Space',
                            id: 's26j9zm7rn0w',
                        },
                    },
                    id: '1wM3dKv7PIuruP4Tlev5s0',
                    type: 'Entry',
                    createdAt: '2021-11-16T16:46:34.919Z',
                    updatedAt: '2021-11-18T09:31:03.994Z',
                    environment: {
                        sys: {
                            id: 'development',
                            type: 'Link',
                            linkType: 'Environment',
                        },
                    },
                    revision: 3,
                    contentType: {
                        sys: {
                            type: 'Link',
                            linkType: 'ContentType',
                            id: 'eventOptions',
                        },
                    },
                    locale: 'en-US',
                },
            },
            {
                metadata: {
                    tags: [],
                },
                sys: {
                    space: {
                        sys: {
                            type: 'Link',
                            linkType: 'Space',
                            id: 's26j9zm7rn0w',
                        },
                    },
                    id: '28EEnbZauBM0HDAaiIEnL4',
                    type: 'Entry',
                    createdAt: '2021-11-18T09:28:58.848Z',
                    updatedAt: '2021-12-14T16:10:35.293Z',
                    environment: {
                        sys: {
                            id: 'development',
                            type: 'Link',
                            linkType: 'Environment',
                        },
                    },
                    revision: 6,
                    contentType: {
                        sys: {
                            type: 'Link',
                            linkType: 'ContentType',
                            id: 'eventVariant',
                        },
                    },
                    locale: 'en-US',
                },
                fields: {
                    title: 'Friday / 20:00 AEST / 4K UHD + Dolby Atmos',
                    sku: 'annemarie-therapy-fri-aest-uhd',
                    doorsOpen: '2021-12-11T21:00+01:00',
                    saleEnd: '2021-12-11T21:00+01:00',
                    eventStart: '2021-12-10T20:00+01:00',
                    eventEnd: '2021-12-11T00:00+01:00',
                    price: {
                        title: 'Default Variant Price',
                        EUR: 18.99,
                        USD: 18.99,
                        GBP: 18.99,
                        AUD: 18.99,
                    },
                    stream: {
                        title: 'annemarie-therapy-fri-aest-hd',
                        dashUrl: dashUrlMock,
                        hlsUrl: hlsUrlMock,
                    },
                    compareAtPrice: {
                        title: 'Default Variant Price CompareAt',
                        EUR: 20.99,
                        USD: 20.99,
                        GBP: 20.99,
                        AUD: 20.99,
                    },
                },
            },
            {
                metadata: {
                    tags: [],
                },
                sys: {
                    space: {
                        sys: {
                            type: 'Link',
                            linkType: 'Space',
                            id: 's26j9zm7rn0w',
                        },
                    },
                    id: '2dsXxJJTpmwWndjONWzQtm',
                    type: 'Entry',
                    createdAt: '2021-11-18T09:27:12.440Z',
                    updatedAt: '2021-12-14T16:10:02.647Z',
                    environment: {
                        sys: {
                            id: 'development',
                            type: 'Link',
                            linkType: 'Environment',
                        },
                    },
                    revision: 7,
                    contentType: {
                        sys: {
                            type: 'Link',
                            linkType: 'ContentType',
                            id: 'eventVariant',
                        },
                    },
                    locale: 'en-US',
                },
                fields: {
                    title: 'Saturday / 21:00 CEST / HD + Stereo',
                    sku: 'annemarie-therapy-sat-cest-hd',
                    doorsOpen: '2021-12-11T21:00+01:00',
                    saleEnd: '2021-12-11T21:00+01:00',
                    eventStart: '2021-12-11T21:00+01:00',
                    eventEnd: '2021-12-12T00:00+01:00',
                    price: {
                        title: 'Default Variant Price',
                        EUR: 14.99,
                        USD: 14.99,
                        GBP: 14.99,
                        AUD: 14.99,
                    },
                    stream: {
                        title: 'annemarie-therapy-fri-gmt-hd',
                        dashUrl: dashUrlMock,
                        hlsUrl: hlsUrlMock,
                    },
                },
            },
            {
                metadata: {
                    tags: [],
                },
                sys: {
                    space: {
                        sys: {
                            type: 'Link',
                            linkType: 'Space',
                            id: 's26j9zm7rn0w',
                        },
                    },
                    id: '2qmpu7iqK9lBVAmhcsYDwG',
                    type: 'Entry',
                    createdAt: '2021-11-29T15:50:53.516Z',
                    updatedAt: '2021-11-30T10:20:39.000Z',
                    environment: {
                        sys: {
                            id: 'development',
                            type: 'Link',
                            linkType: 'Environment',
                        },
                    },
                    revision: 3,
                    contentType: {
                        sys: {
                            type: 'Link',
                            linkType: 'ContentType',
                            id: 'eventFeature',
                        },
                    },
                    locale: 'en-US',
                },
                fields: {
                    title: 'Dolby Atmos Sound',
                    description: {
                        data: {},
                        content: [
                            {
                                data: {},
                                content: [
                                    {
                                        data: {},
                                        marks: [],
                                        value: 'This concert can be enjoyed with Dolby Atmos sound, the best immersive sound quality available on the market.',
                                        nodeType: 'text',
                                    },
                                ],
                                nodeType: BLOCKS.PARAGRAPH,
                            },
                        ],
                        nodeType: BLOCKS.DOCUMENT,
                    },
                    icon: {
                        sys: {
                            type: 'Link',
                            linkType: 'Entry',
                            id: '3gNKEsW4kPwXxfvFsFsw3k',
                        },
                    },
                },
            },
            {
                metadata: {
                    tags: [],
                },
                sys: {
                    space: {
                        sys: {
                            type: 'Link',
                            linkType: 'Space',
                            id: 's26j9zm7rn0w',
                        },
                    },
                    id: '4IxOI8VIDpJlWJUYiadMuo',
                    type: 'Entry',
                    createdAt: '2021-11-30T14:50:56.970Z',
                    updatedAt: '2021-11-30T16:26:04.318Z',
                    environment: {
                        sys: {
                            id: 'development',
                            type: 'Link',
                            linkType: 'Environment',
                        },
                    },
                    revision: 3,
                    contentType: {
                        sys: {
                            type: 'Link',
                            linkType: 'ContentType',
                            id: 'image',
                        },
                    },
                    locale: 'en-US',
                },
                fields: {
                    asset: {
                        title: 'Annemarie during brit awards',
                        description: '',
                        file: {
                            url: '//images.ctfassets.net/s26j9zm7rn0w/40gW57BZYEWP0TOGaYkL18/bb1273f3e517b9c359f538e2a01b1c17/awards_2.jpeg',
                            details: {
                                size: 117153,
                                image: {
                                    width: 1200,
                                    height: 800,
                                },
                            },
                            fileName: 'awards 2.jpeg',
                            contentType: 'image/jpeg',
                        },
                    },
                    alt: 'Annemarie during the brit awards',
                },
            },
            {
                metadata: {
                    tags: [],
                },
                sys: {
                    space: {
                        sys: {
                            type: 'Link',
                            linkType: 'Space',
                            id: 's26j9zm7rn0w',
                        },
                    },
                    id: '4a4S5JLYLvX6mrLeYKIpOv',
                    type: 'Entry',
                    createdAt: '2021-11-18T09:29:14.888Z',
                    updatedAt: '2021-12-14T16:10:47.562Z',
                    environment: {
                        sys: {
                            id: 'development',
                            type: 'Link',
                            linkType: 'Environment',
                        },
                    },
                    revision: 6,
                    contentType: {
                        sys: {
                            type: 'Link',
                            linkType: 'ContentType',
                            id: 'eventVariant',
                        },
                    },
                    locale: 'en-US',
                },
                fields: {
                    title: 'Saturday / 20:00 AEST / 4K UHD + Dolby Atmos',
                    sku: 'annemarie-therapy-sat-aest-uhd',
                    doorsOpen: '2021-12-11T21:00+01:00',
                    saleEnd: '2021-12-11T21:00+01:00',
                    eventStart: '2021-12-11T20:00+01:00',
                    eventEnd: '2021-12-12T00:00+01:00',
                    price: {
                        title: 'Default Variant Price',
                        EUR: 18.99,
                        USD: 18.99,
                        GBP: 18.99,
                        AUD: 18.99,
                    },
                    stream: {
                        title: 'annemarie-therapy-fri-aest-hd',
                        dashUrl: dashUrlMock,
                        hlsUrl: hlsUrlMock,
                    },
                    compareAtPrice: {
                        title: 'Default Variant Price CompareAt',
                        EUR: 20.99,
                        USD: 20.99,
                        GBP: 20.99,
                        AUD: 20.99,
                    },
                },
            },
            {
                metadata: {
                    tags: [],
                },
                sys: {
                    space: {
                        sys: {
                            type: 'Link',
                            linkType: 'Space',
                            id: 's26j9zm7rn0w',
                        },
                    },
                    id: '53nzRaghorQtIVL02jfK2S',
                    type: 'Entry',
                    createdAt: '2021-11-29T15:51:55.689Z',
                    updatedAt: '2021-11-30T10:21:22.957Z',
                    environment: {
                        sys: {
                            id: 'development',
                            type: 'Link',
                            linkType: 'Environment',
                        },
                    },
                    revision: 3,
                    contentType: {
                        sys: {
                            type: 'Link',
                            linkType: 'ContentType',
                            id: 'eventFeature',
                        },
                    },
                    locale: 'en-US',
                },
                fields: {
                    title: 'Ticket instantly available',
                    description: {
                        data: {},
                        content: [
                            {
                                data: {},
                                content: [
                                    {
                                        data: {},
                                        marks: [],
                                        value: 'Upon completing your order and payment your ticket will be available to you right away. You can start enjoying the show!',
                                        nodeType: 'text',
                                    },
                                ],
                                nodeType: BLOCKS.PARAGRAPH,
                            },
                        ],
                        nodeType: BLOCKS.DOCUMENT,
                    },
                    icon: {
                        sys: {
                            type: 'Link',
                            linkType: 'Entry',
                            id: '5JwegCTkTfWE7l6ya8ZVo9',
                        },
                    },
                },
            },
            {
                metadata: {
                    tags: [],
                },
                sys: {
                    space: {
                        sys: {
                            type: 'Link',
                            linkType: 'Space',
                            id: 's26j9zm7rn0w',
                        },
                    },
                    id: '5hYGxn8rtNNhvp1a30YOvF',
                    type: 'Entry',
                    createdAt: '2021-11-15T17:14:23.833Z',
                    updatedAt: '2021-11-15T17:14:41.669Z',
                    environment: {
                        sys: {
                            id: 'development',
                            type: 'Link',
                            linkType: 'Environment',
                        },
                    },
                    revision: 2,
                    contentType: {
                        sys: {
                            type: 'Link',
                            linkType: 'ContentType',
                            id: 'image',
                        },
                    },
                    locale: 'en-US',
                },
                fields: {
                    asset: {
                        title: 'Logo Title',
                        description: '',
                        file: {
                            url: eventInfoLogoUrl,
                            details: {
                                size: 25531,
                                image: {
                                    width: 366,
                                    height: 180,
                                },
                            },
                            fileName: 'Logo Title.png',
                            contentType: 'image/png',
                        },
                    },
                    alt: 'Anne Marie Therapy - The Live Experience with a pink and pretty font',
                },
            },
            {
                metadata: {
                    tags: [],
                },
                sys: {
                    space: {
                        sys: {
                            type: 'Link',
                            linkType: 'Space',
                            id: 's26j9zm7rn0w',
                        },
                    },
                    id: '6KB8y7QrJw3OEcGblEDVg6',
                    type: 'Entry',
                    createdAt: '2021-11-18T09:25:24.020Z',
                    updatedAt: '2021-12-14T16:08:31.805Z',
                    environment: {
                        sys: {
                            id: 'development',
                            type: 'Link',
                            linkType: 'Environment',
                        },
                    },
                    revision: 7,
                    contentType: {
                        sys: {
                            type: 'Link',
                            linkType: 'ContentType',
                            id: 'eventVariant',
                        },
                    },
                    locale: 'en-US',
                },
                fields: {
                    title: 'Saturday / 20:00 EST / HD + Stereo',
                    sku: 'annemarie-therapy-sat-aest-hd',
                    doorsOpen: '2021-12-11T21:00+01:00',
                    saleEnd: '2021-12-11T21:00+01:00',
                    eventStart: '2021-12-04T20:00+01:00',
                    eventEnd: '2021-12-05T00:00+01:00',
                    price: {
                        title: 'Default Variant Price',
                        EUR: 14.99,
                        USD: 14.99,
                        GBP: 14.99,
                        AUD: 14.99,
                    },
                    stream: {
                        title: 'annemarie-therapy-fri-gmt-hd',
                        dashUrl: dashUrlMock,
                        hlsUrl: hlsUrlMock,
                    },
                },
            },
            {
                metadata: {
                    tags: [],
                },
                sys: {
                    space: {
                        sys: {
                            type: 'Link',
                            linkType: 'Space',
                            id: 's26j9zm7rn0w',
                        },
                    },
                    id: '6LfPgI4juW6Ib20dhpfS12',
                    type: 'Entry',
                    createdAt: '2021-11-18T09:30:07.080Z',
                    updatedAt: '2021-12-14T16:11:14.343Z',
                    environment: {
                        sys: {
                            id: 'development',
                            type: 'Link',
                            linkType: 'Environment',
                        },
                    },
                    revision: 6,
                    contentType: {
                        sys: {
                            type: 'Link',
                            linkType: 'ContentType',
                            id: 'eventVariant',
                        },
                    },
                    locale: 'en-US',
                },
                fields: {
                    title: 'Friday / 22:00 GMT / 4K UHD + Dolby Atmos',
                    sku: 'annemarie-therapy-fri-gmt-uhd',
                    doorsOpen: '2021-12-11T21:00+01:00',
                    saleEnd: '2021-12-11T21:00+01:00',
                    eventStart: '2021-12-10T22:00+01:00',
                    eventEnd: '2021-12-11T00:00+01:00',
                    price: {
                        title: 'Default Variant Price',
                        EUR: 18.99,
                        USD: 18.99,
                        GBP: 18.99,
                        AUD: 18.99,
                    },
                    stream: {
                        title: 'annemarie-therapy-fri-aest-hd',
                        dashUrl: dashUrlMock,
                        hlsUrl: hlsUrlMock,
                    },
                    compareAtPrice: {
                        title: 'Default Variant Price CompareAt',
                        EUR: 20.99,
                        USD: 20.99,
                        GBP: 20.99,
                        AUD: 20.99,
                    },
                },
            },
            {
                metadata: {
                    tags: [],
                },
                sys: {
                    space: {
                        sys: {
                            type: 'Link',
                            linkType: 'Space',
                            id: 's26j9zm7rn0w',
                        },
                    },
                    id: '6R0NST9CTBdsBPnmqGDRKm',
                    type: 'Entry',
                    createdAt: '2021-11-18T09:28:11.516Z',
                    updatedAt: '2021-12-14T16:10:25.438Z',
                    environment: {
                        sys: {
                            id: 'development',
                            type: 'Link',
                            linkType: 'Environment',
                        },
                    },
                    revision: 5,
                    contentType: {
                        sys: {
                            type: 'Link',
                            linkType: 'ContentType',
                            id: 'eventVariant',
                        },
                    },
                    locale: 'en-US',
                },
                fields: {
                    title: 'Saturday / 22:00 GMT / HD + Stereo',
                    sku: 'annemarie-therapy-sat-gmt-hd',
                    doorsOpen: '2021-12-11T21:00+01:00',
                    saleEnd: '2021-12-11T21:00+01:00',
                    eventStart: '2021-12-10T22:00+01:00',
                    eventEnd: '2021-12-11T00:00+01:00',
                    price: {
                        title: 'Default Variant Price',
                        EUR: 14.99,
                        USD: 14.99,
                        GBP: 14.99,
                        AUD: 14.99,
                    },
                    stream: {
                        title: 'annemarie-therapy-fri-gmt-hd',
                        dashUrl: dashUrlMock,
                        hlsUrl: hlsUrlMock,
                    },
                },
            },
            {
                metadata: {
                    tags: [],
                },
                sys: {
                    space: {
                        sys: {
                            type: 'Link',
                            linkType: 'Space',
                            id: 's26j9zm7rn0w',
                        },
                    },
                    id: '7nckKUJ36Gh5UD7lxo0Riy',
                    type: 'Entry',
                    createdAt: '2021-11-18T09:30:18.228Z',
                    updatedAt: '2021-12-14T16:06:30.568Z',
                    environment: {
                        sys: {
                            id: 'development',
                            type: 'Link',
                            linkType: 'Environment',
                        },
                    },
                    revision: 6,
                    contentType: {
                        sys: {
                            type: 'Link',
                            linkType: 'ContentType',
                            id: 'eventVariant',
                        },
                    },
                    locale: 'en-US',
                },
                fields: {
                    title: 'Saturday / 22:00 GMT / 4K UHD + Dolby Atmos',
                    sku: 'annemarie-therapy-sat-gmt-uhd',
                    doorsOpen: '2021-12-11T21:00+01:00',
                    saleEnd: '2021-12-11T21:00+01:00',
                    eventStart: '2021-12-11T22:00+01:00',
                    eventEnd: '2021-12-12T00:00+01:00',
                    price: {
                        title: 'Default Variant Price',
                        EUR: 18.99,
                        USD: 18.99,
                        GBP: 18.99,
                        AUD: 18.99,
                    },
                    stream: {
                        title: 'annemarie-therapy-fri-gmt-hd',
                        dashUrl: dashUrlMock,
                        hlsUrl: hlsUrlMock,
                    },
                    compareAtPrice: {
                        title: 'Default Variant Price CompareAt',
                        EUR: 20.99,
                        USD: 20.99,
                        GBP: 20.99,
                        AUD: 20.99,
                    },
                },
            },
            {
                metadata: {
                    tags: [],
                },
                sys: {
                    space: {
                        sys: {
                            type: 'Link',
                            linkType: 'Space',
                            id: 's26j9zm7rn0w',
                        },
                    },
                    id: 'ATtdjDvJYz4LndZap6HbK',
                    type: 'Entry',
                    createdAt: '2021-11-16T16:45:58.131Z',
                    updatedAt: '2021-11-16T16:45:58.131Z',
                    environment: {
                        sys: {
                            id: 'development',
                            type: 'Link',
                            linkType: 'Environment',
                        },
                    },
                    revision: 1,
                    contentType: {
                        sys: {
                            type: 'Link',
                            linkType: 'ContentType',
                            id: 'eventOptions',
                        },
                    },
                    locale: 'en-US',
                },
            },
            {
                metadata: {
                    tags: [],
                },
                sys: {
                    space: {
                        sys: {
                            type: 'Link',
                            linkType: 'Space',
                            id: 's26j9zm7rn0w',
                        },
                    },
                    id: 'Elh1lpAQW1pMtZ9O6pamj',
                    type: 'Entry',
                    createdAt: '2021-11-29T15:51:37.267Z',
                    updatedAt: '2021-11-30T10:23:55.358Z',
                    environment: {
                        sys: {
                            id: 'development',
                            type: 'Link',
                            linkType: 'Environment',
                        },
                    },
                    revision: 4,
                    contentType: {
                        sys: {
                            type: 'Link',
                            linkType: 'ContentType',
                            id: 'eventFeature',
                        },
                    },
                    locale: 'en-US',
                },
                fields: {
                    title: 'iDeal Payments',
                    description: {
                        data: {},
                        content: [
                            {
                                data: {},
                                content: [
                                    {
                                        data: {},
                                        marks: [],
                                        value: 'We support iDeal payments for a quick and secure payment handling. Your payment will be instantly handled.',
                                        nodeType: 'text',
                                    },
                                ],
                                nodeType: BLOCKS.PARAGRAPH,
                            },
                        ],
                        nodeType: BLOCKS.DOCUMENT,
                    },
                    icon: {
                        sys: {
                            type: 'Link',
                            linkType: 'Entry',
                            id: '4DsfriC5WB3E0MxHopEpIo',
                        },
                    },
                },
            },
            {
                metadata: {
                    tags: [],
                },
                sys: {
                    space: {
                        sys: {
                            type: 'Link',
                            linkType: 'Space',
                            id: 's26j9zm7rn0w',
                        },
                    },
                    id: 'I35oqUfuqFMu9yBUXoyq1',
                    type: 'Entry',
                    createdAt: '2021-11-18T09:25:01.904Z',
                    updatedAt: '2021-12-14T16:13:53.137Z',
                    environment: {
                        sys: {
                            id: 'development',
                            type: 'Link',
                            linkType: 'Environment',
                        },
                    },
                    revision: 13,
                    contentType: {
                        sys: {
                            type: 'Link',
                            linkType: 'ContentType',
                            id: 'eventVariant',
                        },
                    },
                    locale: 'en-US',
                },
                fields: {
                    title: 'Friday / 20:00 AEST / HD + Stereo',
                    sku: 'annemarie-therapy-fri-aest-hd',
                    doorsOpen: '2021-12-14T21:00+01:00',
                    saleEnd: '2021-12-11T21:00+01:00',
                    eventStart: '2021-12-14T14:00+01:00',
                    eventEnd: '2021-12-14T18:00+01:00',
                    price: {
                        title: 'Default Variant Price',
                        EUR: 14.99,
                        USD: 14.99,
                        GBP: 14.99,
                        AUD: 14.99,
                    },
                    stream: {
                        title: 'annemarie-therapy-fri-aest-hd',
                        dashUrl: dashUrlMock,
                        hlsUrl: hlsUrlMock,
                    },
                    compareAtPrice: {
                        title: 'Default Variant Price CompareAt',
                        EUR: 17.99,
                        USD: 17.99,
                        GBP: 17.99,
                        AUD: 17.99,
                    },
                    options: [
                        {
                            position: 1,
                            value: 'Friday',
                        },
                        {
                            position: 2,
                            value: '20:00 AEST',
                        },
                        {
                            position: 3,
                            value: 'HD + Stereo',
                        },
                    ],
                },
            },
            {
                metadata: {
                    tags: [],
                },
                sys: {
                    space: {
                        sys: {
                            type: 'Link',
                            linkType: 'Space',
                            id: 's26j9zm7rn0w',
                        },
                    },
                    id: 'VzSnV6NCM5oN6Ovg3tvKc',
                    type: 'Entry',
                    createdAt: '2021-11-15T17:46:51.943Z',
                    updatedAt: '2021-11-15T17:46:51.943Z',
                    environment: {
                        sys: {
                            id: 'development',
                            type: 'Link',
                            linkType: 'Environment',
                        },
                    },
                    revision: 1,
                    contentType: {
                        sys: {
                            type: 'Link',
                            linkType: 'ContentType',
                            id: 'image',
                        },
                    },
                    locale: 'en-US',
                },
                fields: {
                    asset: {
                        title: 'Anne-Marie-Therapy',
                        description: 'on air home page banner',
                        file: {
                            url: '//images.ctfassets.net/s26j9zm7rn0w/3AvhknIQvgH6RVOUMTxQoq/51bd9204765c8a4b2798197a5c6395fa/Anne_Marie_1.jpeg',
                            details: {
                                size: 92908,
                                image: {
                                    width: 750,
                                    height: 387,
                                },
                            },
                            fileName: 'Anne Marie 1.jpeg',
                            contentType: 'image/jpeg',
                        },
                    },
                    alt: 'Anne Marie listening to her fans with a mic in her hand',
                },
            },
            {
                metadata: {
                    tags: [],
                },
                sys: {
                    space: {
                        sys: {
                            type: 'Link',
                            linkType: 'Space',
                            id: 's26j9zm7rn0w',
                        },
                    },
                    id: 'rnZC90c4jReTzWOCqeOC7',
                    type: 'Entry',
                    createdAt: '2021-11-29T13:29:55.903Z',
                    updatedAt: '2021-11-29T13:58:50.186Z',
                    environment: {
                        sys: {
                            id: 'development',
                            type: 'Link',
                            linkType: 'Environment',
                        },
                    },
                    revision: 2,
                    contentType: {
                        sys: {
                            type: 'Link',
                            linkType: 'ContentType',
                            id: 'contentBlock',
                        },
                    },
                    locale: 'en-US',
                },
                fields: {
                    type: 'Artist',
                    title: 'Anne-Marie',
                    description: {
                        data: {},
                        content: [
                            {
                                data: {},
                                content: [
                                    {
                                        data: {},
                                        marks: [],
                                        value: 'Anne-Marie is an English singer and songwriter. She has attained several charting singles on the UK Singles Chart, including Clean Bandit\'s "Rockabye", featuring Sean Paul, which peaked at number one, as well as "Alarm", "Ciao Adios", "Friends", "2002" and "Don\'t Play". Her debut studio album Speak Your Mind was released on 27 April 2018, and peaked at number three on the UK Albums Chart. She was nominated for four awards at the 2019 Brit Awards, including Best British Female Solo Artist. In 2015, she signed a record deal with Asylum (a sub-label of Atlantic Records) and, till 2018, she began releasing her work through the label.',
                                        nodeType: 'text',
                                    },
                                ],
                                nodeType: BLOCKS.PARAGRAPH,
                            },
                        ],
                        nodeType: BLOCKS.DOCUMENT,
                    },
                    imagePosition: 'left',
                    image: {
                        sys: {
                            type: 'Link',
                            linkType: 'Entry',
                            id: '1uaFMSNpj5eB9IPH2Y5ICX',
                        },
                    },
                    shouldShowSocialButtons: false,
                    showAppStoreBanners: false,
                },
            },
        ],
        Asset: [
            {
                metadata: {
                    tags: [],
                },
                sys: {
                    space: {
                        sys: {
                            type: 'Link',
                            linkType: 'Space',
                            id: 's26j9zm7rn0w',
                        },
                    },
                    id: '3AvhknIQvgH6RVOUMTxQoq',
                    type: 'Asset',
                    createdAt: '2021-09-23T13:24:08.010Z',
                    updatedAt: '2021-09-23T13:24:08.010Z',
                    environment: {
                        sys: {
                            id: 'development',
                            type: 'Link',
                            linkType: 'Environment',
                        },
                    },
                    revision: 1,
                    locale: 'en-US',
                },
                fields: {
                    title: 'Anne-Marie-Therapy',
                    description: 'on air home page banner',
                    file: {
                        url: '//images.ctfassets.net/s26j9zm7rn0w/3AvhknIQvgH6RVOUMTxQoq/51bd9204765c8a4b2798197a5c6395fa/Anne_Marie_1.jpeg',
                        details: {
                            size: 92908,
                            image: {
                                width: 750,
                                height: 387,
                            },
                        },
                        fileName: 'Anne Marie 1.jpeg',
                        contentType: 'image/jpeg',
                    },
                },
            },
            {
                metadata: {
                    tags: [],
                },
                sys: {
                    space: {
                        sys: {
                            type: 'Link',
                            linkType: 'Space',
                            id: 's26j9zm7rn0w',
                        },
                    },
                    id: '40gW57BZYEWP0TOGaYkL18',
                    type: 'Asset',
                    createdAt: '2021-11-30T15:09:08.418Z',
                    updatedAt: '2021-11-30T15:47:40.989Z',
                    environment: {
                        sys: {
                            id: 'development',
                            type: 'Link',
                            linkType: 'Environment',
                        },
                    },
                    revision: 2,
                    locale: 'en-US',
                },
                fields: {
                    title: 'Annemarie during brit awards',
                    description: '',
                    file: {
                        url: '//images.ctfassets.net/s26j9zm7rn0w/40gW57BZYEWP0TOGaYkL18/bb1273f3e517b9c359f538e2a01b1c17/awards_2.jpeg',
                        details: {
                            size: 117153,
                            image: {
                                width: 1200,
                                height: 800,
                            },
                        },
                        fileName: 'awards 2.jpeg',
                        contentType: 'image/jpeg',
                    },
                },
            },
            {
                metadata: {
                    tags: [],
                },
                sys: {
                    space: {
                        sys: {
                            type: 'Link',
                            linkType: 'Space',
                            id: 's26j9zm7rn0w',
                        },
                    },
                    id: '56YykScvgwuSfZyq8b8CB1',
                    type: 'Asset',
                    createdAt: '2021-09-23T13:24:00.112Z',
                    updatedAt: '2021-09-23T13:24:00.112Z',
                    environment: {
                        sys: {
                            id: 'development',
                            type: 'Link',
                            linkType: 'Environment',
                        },
                    },
                    revision: 1,
                    locale: 'en-US',
                },
                fields: {
                    title: 'Logo Title',
                    description: '',
                    file: {
                        url: eventInfoLogoUrl,
                        details: {
                            size: 25531,
                            image: {
                                width: 366,
                                height: 180,
                            },
                        },
                        fileName: 'Logo Title.png',
                        contentType: 'image/png',
                    },
                },
            },
            {
                metadata: {
                    tags: [],
                },
                sys: {
                    space: {
                        sys: {
                            type: 'Link',
                            linkType: 'Space',
                            id: 's26j9zm7rn0w',
                        },
                    },
                    id: '6O9wB7UnbXotxpEiXDd6zM',
                    type: 'Asset',
                    createdAt: '2021-11-30T15:44:24.081Z',
                    updatedAt: '2021-12-03T08:23:05.589Z',
                    environment: {
                        sys: {
                            id: 'development',
                            type: 'Link',
                            linkType: 'Environment',
                        },
                    },
                    revision: 2,
                    locale: 'en-US',
                },
                fields: {
                    title: 'Annemarie close up',
                    description: '',
                    file: {
                        url: '//images.ctfassets.net/s26j9zm7rn0w/6O9wB7UnbXotxpEiXDd6zM/49eeb881a13aa7c2f2bc92b7990ea4a0/anne-marie-2-1610125552-view-1.jpeg',
                        details: {
                            size: 28123,
                            image: {
                                width: 460,
                                height: 374,
                            },
                        },
                        fileName: 'anne-marie-2-1610125552-view-1.jpeg',
                        contentType: 'image/jpeg',
                    },
                },
            },
            {
                metadata: {
                    tags: [],
                },
                sys: {
                    space: {
                        sys: {
                            type: 'Link',
                            linkType: 'Space',
                            id: 's26j9zm7rn0w',
                        },
                    },
                    id: '7DhugovEReeLsVdDf9kYYT',
                    type: 'Asset',
                    createdAt: '2021-11-30T15:08:01.237Z',
                    updatedAt: '2021-11-30T15:08:01.237Z',
                    environment: {
                        sys: {
                            id: 'development',
                            type: 'Link',
                            linkType: 'Environment',
                        },
                    },
                    revision: 1,
                    locale: 'en-US',
                },
                fields: {
                    title: 'Annemarie in her car',
                    description: '',
                    file: {
                        url: '//images.ctfassets.net/s26j9zm7rn0w/7DhugovEReeLsVdDf9kYYT/7fa6befd99a4a597a14722d39bb6d837/annemarie_car.jpeg',
                        details: {
                            size: 295556,
                            image: {
                                width: 1638,
                                height: 2048,
                            },
                        },
                        fileName: 'annemarie car.jpeg',
                        contentType: 'image/jpeg',
                    },
                },
            },
        ],
    },
};

// Combination of skusMock and eventsMock
export const ticketsMock: Event[] = [
    {
        __type: 'event',
        title: 'Anne Marie Therapy Past',
        slug: 'annemarie-therapy-past',
        shopifyProductId: '6720059736256',
        variants: [
            {
                __type: 'eventVariant',
                title: 'Friday / 20:00 AEST / HD + Stereo',
                sku: 'annemarie-therapy-fri-aest-hd',
                doorsOpen: '2021-12-14T21:00+01:00',
                saleEnd: '2021-12-11T21:00+01:00',
                eventStart: '2021-12-14T14:00+01:00',
                eventEnd: '2021-12-14T18:00+01:00',
                price: {
                    title: 'Default Variant Price',
                    EUR: 14.99,
                    USD: 14.99,
                    GBP: 14.99,
                    AUD: 14.99,
                },
                stream: {
                    title: 'annemarie-therapy-fri-aest-hd',
                    dashUrl: dashUrlMock,
                    hlsUrl: hlsUrlMock,
                },
                compareAtPrice: {
                    title: 'Default Variant Price CompareAt',
                    EUR: 17.99,
                    USD: 17.99,
                    GBP: 17.99,
                    AUD: 17.99,
                },
                options: [
                    {
                        position: 1,
                        value: 'Friday',
                    },
                    {
                        position: 2,
                        value: '20:00 AEST',
                    },
                    {
                        position: 3,
                        value: 'HD + Stereo',
                    },
                ],
            },
        ],
        poster: {
            asset: {
                title: 'annemarie-therapy',
                description: 'on air home page banner',
                url: '//images.ctfassets.net/s26j9zm7rn0w/3AvhknIQvgH6RVOUMTxQoq/51bd9204765c8a4b2798197a5c6395fa/Anne_Marie_1.jpeg',
                width: 750,
                height: 387,
                file: {
                    url: '//images.ctfassets.net/s26j9zm7rn0w/3AvhknIQvgH6RVOUMTxQoq/51bd9204765c8a4b2798197a5c6395fa/Anne_Marie_1.jpeg',
                    details: {
                        size: 92908,
                        image: {
                            width: 750,
                            height: 387,
                        },
                    },
                    fileName: 'Anne Marie 1.jpeg',
                    contentType: 'image/jpeg',
                },
            },
            alt: 'Anne Marie listening to her fans with a mic in her hand',
        },
        eventInfoLogo: {
            asset: {
                title: 'Logo Title',
                description: '',
                url: '//images.ctfassets.net/s26j9zm7rn0w/6O9wB7UnbXotxpEiXDd6zM/49eeb881a13aa7c2f2bc92b7990ea4a0/anne-marie-2-1610125552-view-1.jpeg',
                width: 366,
                height: 180,
                file: {
                    url: '//images.ctfassets.net/s26j9zm7rn0w/6O9wB7UnbXotxpEiXDd6zM/49eeb881a13aa7c2f2bc92b7990ea4a0/anne-marie-2-1610125552-view-1.jpeg',
                    details: {
                        size: 25531,
                        image: {
                            width: 366,
                            height: 180,
                        },
                    },
                    fileName: 'Logo Title.png',
                    contentType: 'image/png',
                },
            },
            alt: 'Anne Marie Therapy - The Live Experience with a pink and pretty font',
        },
        tags: ['past-events'],
        type: 'spo',
        blocks: [
            {
                __type: 'contentBlock',
                type: 'EventDescription',
                title: 'Therapy',
                description: {
                    data: {},
                    content: [
                        {
                            data: {},
                            content: [
                                {
                                    data: {},
                                    marks: [],
                                    value: '‘Therapy’ is a collection of songs that embody Anne-Marie’s characterful artistry, self-effacing attitude, and beautiful honesty, which has seen the Essex-born star reign supreme as Gen Z role model across the globe. The highly-anticipated new album will include Anne-Marie’s UK Top 3 ‘Don’t Play’ with KSI and Digital Farm Animals, feel-good Nathan Dawe and MoStack collaboration ‘Way Too Long’; and her recent Niall Horan duet ‘Our Song’.',
                                    nodeType: 'text',
                                },
                            ],
                            nodeType: BLOCKS.PARAGRAPH,
                        },
                    ],
                    nodeType: BLOCKS.DOCUMENT,
                },
                imagePosition: 'right',
                shouldShowSocialButtons: true,
                showAppStoreBanners: false,
                image: {
                    asset: {
                        title: 'Logo Title',
                        description: '',
                        url: eventInfoLogoUrl,
                        width: 366,
                        height: 180,
                        file: {
                            url: eventInfoLogoUrl,
                            details: {
                                size: 25531,
                                image: {
                                    width: 366,
                                    height: 180,
                                },
                            },
                            fileName: 'Logo Title.png',
                            contentType: 'image/png',
                        },
                    },
                    alt: 'Anne Marie Therapy - The Live Experience with a pink and pretty font',
                },
            },
            {
                __type: 'videoBlock',
                youtubeLink:
                    'https://www.youtube.com/watch?v=r3RXHOTMmLw&ab_channel=Anne-Marie',
            },
            {
                __type: 'contentBlock',
                type: 'Artist',
                title: 'Anne-Marie',
                description: {
                    data: {},
                    content: [
                        {
                            data: {},
                            content: [
                                {
                                    data: {},
                                    marks: [],
                                    value: 'Anne-Marie is an English singer and songwriter. She has attained several charting singles on the UK Singles Chart, including Clean Bandit\'s "Rockabye", featuring Sean Paul, which peaked at number one, as well as "Alarm", "Ciao Adios", "Friends", "2002" and "Don\'t Play". Her debut studio album Speak Your Mind was released on 27 April 2018, and peaked at number three on the UK Albums Chart. She was nominated for four awards at the 2019 Brit Awards, including Best British Female Solo Artist. In 2015, she signed a record deal with Asylum (a sub-label of Atlantic Records) and, till 2018, she began releasing her work through the label.',
                                    nodeType: 'text',
                                },
                            ],
                            nodeType: BLOCKS.PARAGRAPH,
                        },
                    ],
                    nodeType: BLOCKS.DOCUMENT,
                },
                imagePosition: 'left',
                shouldShowSocialButtons: false,
                showAppStoreBanners: false,
                image: {
                    asset: {
                        title: 'Logo Title',
                        description: '',
                        url: eventInfoLogoUrl,
                        width: 366,
                        height: 180,
                        file: {
                            url: eventInfoLogoUrl,
                            details: {
                                size: 25531,
                                image: {
                                    width: 366,
                                    height: 180,
                                },
                            },
                            fileName: 'Logo Title.png',
                            contentType: 'image/png',
                        },
                    },
                    alt: 'Anne Marie Therapy - The Live Experience with a pink and pretty font',
                },
            },
            {
                __type: 'galleryBlock',
                preserveAspectRatio: false,
                images: [
                    {
                        asset: {
                            title: 'Annemarie during brit awards',
                            description: '',
                            url: '//images.ctfassets.net/s26j9zm7rn0w/40gW57BZYEWP0TOGaYkL18/bb1273f3e517b9c359f538e2a01b1c17/awards_2.jpeg',
                            width: 1200,
                            height: 800,
                            file: {
                                url: '//images.ctfassets.net/s26j9zm7rn0w/40gW57BZYEWP0TOGaYkL18/bb1273f3e517b9c359f538e2a01b1c17/awards_2.jpeg',
                                details: {
                                    size: 117153,
                                    image: {
                                        width: 1200,
                                        height: 800,
                                    },
                                },
                                fileName: 'awards 2.jpeg',
                                contentType: 'image/jpeg',
                            },
                        },
                        alt: 'Annemarie during the brit awards',
                    },
                    {
                        asset: {
                            title: 'Annemarie in her car',
                            description: '',
                            url: '//images.ctfassets.net/s26j9zm7rn0w/7DhugovEReeLsVdDf9kYYT/7fa6befd99a4a597a14722d39bb6d837/annemarie_car.jpeg',
                            width: 1638,
                            height: 2048,
                            file: {
                                url: '//images.ctfassets.net/s26j9zm7rn0w/7DhugovEReeLsVdDf9kYYT/7fa6befd99a4a597a14722d39bb6d837/annemarie_car.jpeg',
                                details: {
                                    size: 295556,
                                    image: {
                                        width: 1638,
                                        height: 2048,
                                    },
                                },
                                fileName: 'annemarie car.jpeg',
                                contentType: 'image/jpeg',
                            },
                        },
                        alt: 'Annemarie in a fancy auto',
                    },
                    {
                        asset: {
                            title: 'Annemarie close up',
                            description: '',
                            url: '//images.ctfassets.net/s26j9zm7rn0w/6O9wB7UnbXotxpEiXDd6zM/49eeb881a13aa7c2f2bc92b7990ea4a0/anne-marie-2-1610125552-view-1.jpeg',
                            width: 460,
                            height: 374,
                            file: {
                                url: '//images.ctfassets.net/s26j9zm7rn0w/6O9wB7UnbXotxpEiXDd6zM/49eeb881a13aa7c2f2bc92b7990ea4a0/anne-marie-2-1610125552-view-1.jpeg',
                                details: {
                                    size: 28123,
                                    image: {
                                        width: 460,
                                        height: 374,
                                    },
                                },
                                fileName: 'anne-marie-2-1610125552-view-1.jpeg',
                                contentType: 'image/jpeg',
                            },
                        },
                        alt: 'Annemarie close up',
                    },
                ],
            },
        ],
        socialLinks: {
            instagramLink: 'https://www.instagram.com/annemarie/',
            twitterLink: 'https://twitter.com/annemarie',
            facebookLink: 'https://facebook.com/iamannemarie',
        },
        trailer: streamMock,
        genres: multipleGenresMock,
    },
    {
        __type: 'event',
        title: 'Anne Marie Therapy Past',
        slug: 'annemarie-therapy-past',
        shopifyProductId: '6720059736256',
        variants: [
            {
                __type: 'eventVariant',
                title: 'Saturday / 21:00 CEST / 4K UHD + Dolby Atmos',
                sku: 'annemarie-therapy-sat-cest-uhd',
                doorsOpen: '2021-12-11T21:00+01:00',
                saleEnd: '2021-12-11T21:00+01:00',
                eventStart: '2021-12-11T21:00+01:00',
                eventEnd: '2021-12-12T00:00+01:00',
                price: {
                    title: 'Default Variant Price',
                    EUR: 18.99,
                    USD: 18.99,
                    GBP: 18.99,
                    AUD: 18.99,
                },
                stream: {
                    title: 'annemarie-therapy-sat-cest-uhd',
                    dashUrl: dashUrlMock,
                    hlsUrl: hlsUrlMock,
                },
                compareAtPrice: {
                    title: 'Default Variant Price CompareAt',
                    EUR: 20.99,
                    USD: 20.99,
                    GBP: 20.99,
                    AUD: 20.99,
                },
                options: [
                    {
                        position: 1,
                        value: 'Saturday',
                    },
                    {
                        position: 2,
                        value: '21:00 CEST',
                    },
                    {
                        position: 3,
                        value: '4K UHD + Dolby Atmos',
                    },
                ],
            },
        ],
        poster: {
            asset: {
                title: 'annemarie-therapy',
                description: 'on air home page banner',
                url: '//images.ctfassets.net/s26j9zm7rn0w/3AvhknIQvgH6RVOUMTxQoq/51bd9204765c8a4b2798197a5c6395fa/Anne_Marie_1.jpeg',
                width: 750,
                height: 387,
                file: {
                    url: '//images.ctfassets.net/s26j9zm7rn0w/3AvhknIQvgH6RVOUMTxQoq/51bd9204765c8a4b2798197a5c6395fa/Anne_Marie_1.jpeg',
                    details: {
                        size: 92908,
                        image: {
                            width: 750,
                            height: 387,
                        },
                    },
                    fileName: 'Anne Marie 1.jpeg',
                    contentType: 'image/jpeg',
                },
            },
            alt: 'Anne Marie listening to her fans with a mic in her hand',
        },
        eventInfoLogo: {
            asset: {
                title: 'Logo Title',
                description: '',
                url: eventInfoLogoUrl,
                width: 366,
                height: 180,
                file: {
                    url: eventInfoLogoUrl,
                    details: {
                        size: 25531,
                        image: {
                            width: 366,
                            height: 180,
                        },
                    },
                    fileName: 'Logo Title.png',
                    contentType: 'image/png',
                },
            },
            alt: 'Anne Marie Therapy - The Live Experience with a pink and pretty font',
        },
        tags: ['past-events'],
        type: 'spo',
        blocks: [
            {
                __type: 'contentBlock',
                type: 'EventDescription',
                title: 'Therapy',
                description: {
                    data: {},
                    content: [
                        {
                            data: {},
                            content: [
                                {
                                    data: {},
                                    marks: [],
                                    value: '‘Therapy’ is a collection of songs that embody Anne-Marie’s characterful artistry, self-effacing attitude, and beautiful honesty, which has seen the Essex-born star reign supreme as Gen Z role model across the globe. The highly-anticipated new album will include Anne-Marie’s UK Top 3 ‘Don’t Play’ with KSI and Digital Farm Animals, feel-good Nathan Dawe and MoStack collaboration ‘Way Too Long’; and her recent Niall Horan duet ‘Our Song’.',
                                    nodeType: 'text',
                                },
                            ],
                            nodeType: BLOCKS.PARAGRAPH,
                        },
                    ],
                    nodeType: BLOCKS.DOCUMENT,
                },
                imagePosition: 'right',
                shouldShowSocialButtons: true,
                showAppStoreBanners: false,
                image: {
                    asset: {
                        title: 'Logo Title',
                        description: '',
                        url: eventInfoLogoUrl,
                        width: 366,
                        height: 180,
                        file: {
                            url: eventInfoLogoUrl,
                            details: {
                                size: 25531,
                                image: {
                                    width: 366,
                                    height: 180,
                                },
                            },
                            fileName: 'Logo Title.png',
                            contentType: 'image/png',
                        },
                    },
                    alt: 'Anne Marie Therapy - The Live Experience with a pink and pretty font',
                },
            },
            {
                __type: 'videoBlock',
                youtubeLink:
                    'https://www.youtube.com/watch?v=r3RXHOTMmLw&ab_channel=Anne-Marie',
            },
            {
                __type: 'contentBlock',
                type: 'Artist',
                title: 'Anne-Marie',
                description: {
                    data: {},
                    content: [
                        {
                            data: {},
                            content: [
                                {
                                    data: {},
                                    marks: [],
                                    value: 'Anne-Marie is an English singer and songwriter. She has attained several charting singles on the UK Singles Chart, including Clean Bandit\'s "Rockabye", featuring Sean Paul, which peaked at number one, as well as "Alarm", "Ciao Adios", "Friends", "2002" and "Don\'t Play". Her debut studio album Speak Your Mind was released on 27 April 2018, and peaked at number three on the UK Albums Chart. She was nominated for four awards at the 2019 Brit Awards, including Best British Female Solo Artist. In 2015, she signed a record deal with Asylum (a sub-label of Atlantic Records) and, till 2018, she began releasing her work through the label.',
                                    nodeType: 'text',
                                },
                            ],
                            nodeType: BLOCKS.PARAGRAPH,
                        },
                    ],
                    nodeType: BLOCKS.DOCUMENT,
                },
                imagePosition: 'left',
                shouldShowSocialButtons: false,
                showAppStoreBanners: false,
                image: {
                    asset: {
                        title: 'Logo Title',
                        description: '',
                        url: eventInfoLogoUrl,
                        width: 366,
                        height: 180,
                        file: {
                            url: eventInfoLogoUrl,
                            details: {
                                size: 25531,
                                image: {
                                    width: 366,
                                    height: 180,
                                },
                            },
                            fileName: 'Logo Title.png',
                            contentType: 'image/png',
                        },
                    },
                    alt: 'Anne Marie Therapy - The Live Experience with a pink and pretty font',
                },
            },
            {
                __type: 'galleryBlock',
                preserveAspectRatio: false,
                images: [
                    {
                        asset: {
                            title: 'Annemarie during brit awards',
                            description: '',
                            url: '//images.ctfassets.net/s26j9zm7rn0w/40gW57BZYEWP0TOGaYkL18/bb1273f3e517b9c359f538e2a01b1c17/awards_2.jpeg',
                            width: 1200,
                            height: 800,
                            file: {
                                url: '//images.ctfassets.net/s26j9zm7rn0w/40gW57BZYEWP0TOGaYkL18/bb1273f3e517b9c359f538e2a01b1c17/awards_2.jpeg',
                                details: {
                                    size: 117153,
                                    image: {
                                        width: 1200,
                                        height: 800,
                                    },
                                },
                                fileName: 'awards 2.jpeg',
                                contentType: 'image/jpeg',
                            },
                        },
                        alt: 'Annemarie during the brit awards',
                    },
                    {
                        asset: {
                            title: 'Annemarie in her car',
                            description: '',
                            url: '//images.ctfassets.net/s26j9zm7rn0w/7DhugovEReeLsVdDf9kYYT/7fa6befd99a4a597a14722d39bb6d837/annemarie_car.jpeg',
                            width: 1638,
                            height: 2048,
                            file: {
                                url: '//images.ctfassets.net/s26j9zm7rn0w/7DhugovEReeLsVdDf9kYYT/7fa6befd99a4a597a14722d39bb6d837/annemarie_car.jpeg',
                                details: {
                                    size: 295556,
                                    image: {
                                        width: 1638,
                                        height: 2048,
                                    },
                                },
                                fileName: 'annemarie car.jpeg',
                                contentType: 'image/jpeg',
                            },
                        },
                        alt: 'Annemarie in a fancy auto',
                    },
                    {
                        asset: {
                            title: 'Annemarie close up',
                            description: '',
                            url: '//images.ctfassets.net/s26j9zm7rn0w/6O9wB7UnbXotxpEiXDd6zM/49eeb881a13aa7c2f2bc92b7990ea4a0/anne-marie-2-1610125552-view-1.jpeg',
                            width: 460,
                            height: 374,
                            file: {
                                url: '//images.ctfassets.net/s26j9zm7rn0w/6O9wB7UnbXotxpEiXDd6zM/49eeb881a13aa7c2f2bc92b7990ea4a0/anne-marie-2-1610125552-view-1.jpeg',
                                details: {
                                    size: 28123,
                                    image: {
                                        width: 460,
                                        height: 374,
                                    },
                                },
                                fileName: 'anne-marie-2-1610125552-view-1.jpeg',
                                contentType: 'image/jpeg',
                            },
                        },
                        alt: 'Annemarie close up',
                    },
                ],
            },
        ],
        socialLinks: {
            instagramLink: 'https://www.instagram.com/annemarie/',
            twitterLink: 'https://twitter.com/annemarie',
            facebookLink: 'https://facebook.com/iamannemarie',
        },
        trailer: streamMock,
        genres: multipleGenresMock,
    },
];

export const heroMock = {
    fields: {
        nickname: 'Anne Marie Info Hero',
        description: {
            data: {},
            content: [
                {
                    data: {},
                    content: [
                        {
                            data: {},
                            marks: [],
                            value: 'As she prepares to reach a whole new level with her eagerly anticipated album ',
                            nodeType: 'text',
                        },
                        {
                            data: {},
                            marks: [
                                {
                                    type: 'italic',
                                },
                            ],
                            value: 'Therapy',
                            nodeType: 'text',
                        },
                        {
                            data: {},
                            marks: [],
                            value: ' on July 23rd, Anne-Marie will celebrate its release by playing an exclusive global livestream show.',
                            nodeType: 'text',
                        },
                    ],
                    nodeType: BLOCKS.PARAGRAPH,
                },
            ],
            nodeType: BLOCKS.DOCUMENT,
        },
        event: {
            fields: {
                title: 'Anne Marie Therapy 2030',
                slug: 'annemarie-therapy2030',
                shortDescription:
                    'As she prepares to reach a whole new level with the release of her eagerly anticipated second album ‘Therapy’ on July 23rd, Anne-Marie will celebrate its release by playing a global livestream show on August 7th.',
                variants: [
                    {
                        fields: {
                            sku: 'annemarie-therapy-fri-aest-hd',
                            title: 'Friday / 20:00 AEST / HD + Stereo',
                            doorsOpen: '2022-03-17T00:00+01:00',
                            eventStart: '2021-01-17T14:00+01:00',
                            saleEnd: '2022-03-17T00:00+01:00',
                            eventEnd: '2022-03-17T18:00+01:00',
                            price: {
                                title: 'Default Variant Price',
                                EUR: 14.99,
                                USD: 14.99,
                                GBP: 14.99,
                                AUD: 14.99,
                            },
                            compareAtPrice: {
                                title: 'Default Variant Price CompareAt',
                                EUR: 17.99,
                                USD: 17.99,
                                GBP: 17.99,
                                AUD: 17.99,
                            },
                            shopifyProductVariantId: '41338061652160',
                        },
                    },
                ],
                blocks: [
                    {
                        sys: {
                            contentType: {
                                sys: {
                                    id: 'contentBlock',
                                },
                            },
                        },
                        fields: {
                            title: 'Therapy',
                            description: {
                                data: {},
                                content: [
                                    {
                                        data: {},
                                        content: [
                                            {
                                                data: {},
                                                marks: [],
                                                value: '‘Therapy’ is a collection of songs that embody Anne-Marie’s characterful artistry, self-effacing attitude, and beautiful honesty, which has seen the Essex-born star reign supreme as Gen Z role model across the globe. The highly-anticipated new album will include Anne-Marie’s UK Top 3 ‘Don’t Play’ with KSI and Digital Farm Animals, feel-good Nathan Dawe and MoStack collaboration ‘Way Too Long’; and her recent Niall Horan duet ‘Our Song’.',
                                                nodeType: 'text',
                                            },
                                        ],
                                        nodeType: BLOCKS.PARAGRAPH,
                                    },
                                ],
                                nodeType: BLOCKS.DOCUMENT,
                            },
                            imagePosition: 'right',
                            image: {
                                asset: {
                                    title: 'Logo Title',
                                    description: '',
                                    file: {
                                        url: eventInfoLogoUrl,
                                        details: {
                                            size: 25531,
                                            image: {
                                                width: 366,
                                                height: 180,
                                            },
                                        },
                                        fileName: 'Logo Title.png',
                                        contentType: 'image/png',
                                    },
                                },
                                alt: 'Anne Marie Therapy - The Live Experience with a pink and pretty font',
                            },
                            shouldShowSocialButtons: true,
                            showAppStoreBanners: false,
                        },
                    },
                    {
                        sys: {
                            contentType: {
                                sys: {
                                    id: 'videoBlock',
                                },
                            },
                        },
                        fields: {
                            youtubeLink:
                                'https://www.youtube.com/watch?v=r3RXHOTMmLw&ab_channel=Anne-Marie',
                        },
                    },
                ],
                poster: {
                    asset: {
                        title: 'Anne-Marie-Therapy',
                        description: 'on air home page banner',
                        file: {
                            url: '//images.ctfassets.net/s26j9zm7rn0w/3AvhknIQvgH6RVOUMTxQoq/51bd9204765c8a4b2798197a5c6395fa/Anne_Marie_1.jpeg',
                            details: {
                                size: 92908,
                                image: {
                                    width: 750,
                                    height: 387,
                                },
                            },
                            fileName: 'Anne Marie 1.jpeg',
                            contentType: 'image/jpeg',
                        },
                    },
                    alt: 'Anne Marie listening to her fans with a mic in her hand',
                },
                shopifyProductId: '7021736591552',
            },
        },
    },
};

export const cheapestVariantMock = {
    title: 'Saturday / 20:00 EST / HD + Stereo',
    sku: 'annemarie-therapy-sat-aest-hd',
    doorsOpen: '2021-12-11T21:00+01:00',
    saleEnd: '2021-12-11T21:00+01:00',
    eventStart: '2021-12-04T20:00+01:00',
    eventEnd: '2021-12-05T00:00+01:00',
    price: {
        title: 'Default Variant Price CompareAt',
        EUR: 7.99,
        USD: 7.99,
        GBP: 7.99,
        AUD: 7.99,
    },
    stream: {
        title: 'annemarie-therapy-fri-aest-hd',
        dashUrl: dashUrlMock,
        hlsUrl: hlsUrlMock,
    },
    options: [
        {
            id: 'Saturday',
            position: 1,
            value: 'Saturday',
        },
        {
            id: '20:00 EST',
            position: 2,
            value: '20:00 EST',
        },
        {
            id: 'HD + Stereo',
            position: 3,
            value: 'HD + Stereo',
        },
    ],
};

export const cheapestVariantsMock = [
    {
        title: 'Friday / 20:00 AEST / HD + Stereo',
        sku: 'annemarie-therapy-fri-aest-hd',
        doorsOpen: '2021-12-14T21:00+01:00',
        saleEnd: '2021-12-11T21:00+01:00',
        eventStart: '2021-12-14T14:00+01:00',
        eventEnd: '2021-12-14T18:00+01:00',
        price: {
            title: 'Default Variant Price',
            EUR: 14.99,
            USD: 14.99,
            GBP: 14.99,
            AUD: 14.99,
        },
        stream: {
            title: 'annemarie-therapy-fri-aest-hd',
            dashUrl: dashUrlMock,
            hlsUrl: hlsUrlMock,
        },
        compareAtPrice: {
            title: 'Default Variant Price CompareAt',
            EUR: 17.99,
            USD: 17.99,
            GBP: 17.99,
            AUD: 17.99,
        },
        options: [
            {
                id: 'Friday',
                position: 1,
                value: 'Friday',
            },
            {
                id: '20:00 AEST',
                position: 2,
                value: '20:00 AEST',
            },
            {
                id: 'HD + Stereo',
                position: 3,
                value: 'HD + Stereo',
            },
        ],
    },
    {
        ...cheapestVariantMock,
    },
    {
        title: 'Friday / 20:00 AEST / HD + Stereo',
        sku: 'annemarie-therapy-fri-aest-hd',
        doorsOpen: '2021-12-14T21:00+01:00',
        saleEnd: '2021-12-11T21:00+01:00',
        eventStart: '2021-12-14T14:00+01:00',
        eventEnd: '2021-12-14T18:00+01:00',
        price: {
            title: 'Default Variant Price',
            EUR: 12.99,
            USD: 12.99,
            GBP: 12.99,
            AUD: 12.99,
        },
        stream: {
            title: 'annemarie-therapy-fri-aest-hd',
            dashUrl: dashUrlMock,
            hlsUrl: hlsUrlMock,
        },
        compareAtPrice: {
            title: 'Default Variant Price CompareAt',
            EUR: 13.99,
            USD: 13.99,
            GBP: 13.99,
            AUD: 13.99,
        },
        options: [
            {
                id: 'Friday',
                position: 1,
                value: 'Friday',
            },
            {
                id: '20:00 AEST',
                position: 2,
                value: '20:00 AEST',
            },
            {
                id: 'HD + Stereo',
                position: 3,
                value: 'HD + Stereo',
            },
        ],
    },
];

export const contentfulIconsMock: ContentfulIcon[] = [
    {
        type: 'oa-quality-uhd',
    },
    {
        type: 'oa-age18nl',
    },
];
export const termsConditionsDirtyMock = {
    sys: {
        type: 'Array',
    },
    total: 1,
    skip: 0,
    limit: 100,
    items: [
        {
            metadata: {
                tags: [],
            },
            sys: {
                space: {
                    sys: {
                        type: 'Link',
                        linkType: 'Space',
                        id: 's26j9zm7rn0w',
                    },
                },
                id: '2rutkWWC48XCYKVkmF8TtQ',
                type: 'Entry',
                createdAt: '2022-04-12T15:46:52.041Z',
                updatedAt: '2022-04-18T16:51:48.877Z',
                environment: {
                    sys: {
                        id: 'development',
                        type: 'Link',
                        linkType: 'Environment',
                    },
                },
                revision: 8,
                contentType: {
                    sys: {
                        type: 'Link',
                        linkType: 'ContentType',
                        id: 'flexiblePage',
                    },
                },
                locale: 'en-US',
            },
            fields: {
                slug: 'privacy-policy',
                seo: {
                    metadata: {
                        tags: [],
                    },
                    sys: {
                        space: {
                            sys: {
                                type: 'Link',
                                linkType: 'Space',
                                id: 's26j9zm7rn0w',
                            },
                        },
                        id: '4YS6auScLrbibpmj8GdduH',
                        type: 'Entry',
                        createdAt: '2022-03-31T17:28:13.771Z',
                        updatedAt: '2022-03-31T17:28:13.771Z',
                        environment: {
                            sys: {
                                id: 'development',
                                type: 'Link',
                                linkType: 'Environment',
                            },
                        },
                        revision: 1,
                        contentType: {
                            sys: {
                                type: 'Link',
                                linkType: 'ContentType',
                                id: 'seo',
                            },
                        },
                        locale: 'en-US',
                    },
                    fields: {
                        title: 'Privacy Policy',
                        description: 'Privacy Policy of On Air',
                    },
                },
                blocks: [
                    {
                        metadata: {
                            tags: [],
                        },
                        sys: {
                            space: {
                                sys: {
                                    type: 'Link',
                                    linkType: 'Space',
                                    id: 's26j9zm7rn0w',
                                },
                            },
                            id: '5Yklzv0WtKG8HngNApK95B',
                            type: 'Entry',
                            createdAt: '2022-04-12T16:42:28.899Z',
                            updatedAt: '2022-04-12T16:53:27.714Z',
                            environment: {
                                sys: {
                                    id: 'development',
                                    type: 'Link',
                                    linkType: 'Environment',
                                },
                            },
                            revision: 2,
                            contentType: {
                                sys: {
                                    type: 'Link',
                                    linkType: 'ContentType',
                                    id: 'contentBlock',
                                },
                            },
                            locale: 'en-US',
                        },
                        fields: {
                            title: 'Privacy Policy',
                            description: {
                                nodeType: 'document',
                                data: {},
                                content: [
                                    {
                                        nodeType: 'heading-3',
                                        data: {},
                                        content: [
                                            {
                                                nodeType: 'text',
                                                value: 'Who we are',
                                                marks: [],
                                                data: {},
                                            },
                                        ],
                                    },
                                    {
                                        nodeType: 'paragraph',
                                        data: {},
                                        content: [
                                            {
                                                nodeType: 'text',
                                                value: 'We are On Air B.V.',
                                                marks: [
                                                    {
                                                        type: 'bold',
                                                    },
                                                ],
                                                data: {},
                                            },
                                            {
                                                nodeType: 'text',
                                                value: 'Â , located at the at Eemnesserweg 20, 3741 GA, Baarn in The Netherlands, and registered with the Dutch trade register (Kamer van Koophandel) as number 73550019 (',
                                                marks: [],
                                                data: {},
                                            },
                                            {
                                                nodeType: 'text',
                                                value: 'we, our, us',
                                                marks: [
                                                    {
                                                        type: 'bold',
                                                    },
                                                ],
                                                data: {},
                                            },
                                            {
                                                nodeType: 'text',
                                                value: ').',
                                                marks: [],
                                                data: {},
                                            },
                                        ],
                                    },
                                    {
                                        nodeType: 'heading-3',
                                        data: {},
                                        content: [
                                            {
                                                nodeType: 'text',
                                                value: 'These Terms',
                                                marks: [],
                                                data: {},
                                            },
                                        ],
                                    },
                                    {
                                        nodeType: 'paragraph',
                                        data: {},
                                        content: [
                                            {
                                                nodeType: 'text',
                                                value: 'These terms and conditions (together with the documents referred to in them) (the Terms)',
                                                marks: [
                                                    {
                                                        type: 'bold',
                                                    },
                                                ],
                                                data: {},
                                            },
                                            {
                                                nodeType: 'text',
                                                value: 'Â govern your relationship with us when access our event streaming platform located atÂ ',
                                                marks: [],
                                                data: {},
                                            },
                                            {
                                                nodeType: 'hyperlink',
                                                data: {
                                                    uri: 'https://onair.events/',
                                                },
                                                content: [
                                                    {
                                                        nodeType: 'text',
                                                        value: 'https://onair.events',
                                                        marks: [],
                                                        data: {},
                                                    },
                                                ],
                                            },
                                            {
                                                nodeType: 'text',
                                                value: 'Â (theÂ ',
                                                marks: [],
                                                data: {},
                                            },
                                            {
                                                nodeType: 'text',
                                                value: 'Platform',
                                                marks: [
                                                    {
                                                        type: 'bold',
                                                    },
                                                ],
                                                data: {},
                                            },
                                            {
                                                nodeType: 'text',
                                                value: ') and, if applicable, download and use our mobile app (theÂ ',
                                                marks: [],
                                                data: {},
                                            },
                                            {
                                                nodeType: 'text',
                                                value: 'Mobile App',
                                                marks: [
                                                    {
                                                        type: 'bold',
                                                    },
                                                ],
                                                data: {},
                                            },
                                            {
                                                nodeType: 'text',
                                                value: ') from a third party platform and/or use our smart TV app (theÂ ',
                                                marks: [],
                                                data: {},
                                            },
                                            {
                                                nodeType: 'text',
                                                value: 'Smart TV App',
                                                marks: [
                                                    {
                                                        type: 'bold',
                                                    },
                                                ],
                                                data: {},
                                            },
                                            {
                                                nodeType: 'text',
                                                value: ') and when you purchase merchandise products via the Platform (theÂ ',
                                                marks: [],
                                                data: {},
                                            },
                                            {
                                                nodeType: 'text',
                                                value: 'Merchandise Products',
                                                marks: [
                                                    {
                                                        type: 'bold',
                                                    },
                                                ],
                                                data: {},
                                            },
                                            {
                                                nodeType: 'text',
                                                value: ').',
                                                marks: [],
                                                data: {},
                                            },
                                            {
                                                nodeType: 'text',
                                                value: 'Terminology:',
                                                marks: [
                                                    {
                                                        type: 'bold',
                                                    },
                                                ],
                                                data: {},
                                            },
                                            {
                                                nodeType: 'text',
                                                value: 'Â In these Terms:',
                                                marks: [],
                                                data: {},
                                            },
                                        ],
                                    },
                                    {
                                        nodeType: 'unordered-list',
                                        data: {},
                                        content: [
                                            {
                                                nodeType: 'list-item',
                                                data: {},
                                                content: [
                                                    {
                                                        nodeType: 'paragraph',
                                                        data: {},
                                                        content: [
                                                            {
                                                                nodeType:
                                                                    'text',
                                                                value: 'Where we want to refer to the Platform, the Mobile App, and the Smart TV App together, we will say the ',
                                                                marks: [],
                                                                data: {},
                                                            },
                                                            {
                                                                nodeType:
                                                                    'text',
                                                                value: 'Services',
                                                                marks: [
                                                                    {
                                                                        type: 'bold',
                                                                    },
                                                                ],
                                                                data: {},
                                                            },
                                                            {
                                                                nodeType:
                                                                    'text',
                                                                value: '.',
                                                                marks: [],
                                                                data: {},
                                                            },
                                                        ],
                                                    },
                                                ],
                                            },
                                            {
                                                nodeType: 'list-item',
                                                data: {},
                                                content: [
                                                    {
                                                        nodeType: 'paragraph',
                                                        data: {},
                                                        content: [
                                                            {
                                                                nodeType:
                                                                    'text',
                                                                value: 'Where we want to refer to the Mobile App and the Smart TV App together, we will say the ',
                                                                marks: [],
                                                                data: {},
                                                            },
                                                            {
                                                                nodeType:
                                                                    'text',
                                                                value: 'Apps',
                                                                marks: [
                                                                    {
                                                                        type: 'bold',
                                                                    },
                                                                ],
                                                                data: {},
                                                            },
                                                            {
                                                                nodeType:
                                                                    'text',
                                                                value: '. Otherwise, we will refer to things individually using the defined terms set out above.',
                                                                marks: [],
                                                                data: {},
                                                            },
                                                        ],
                                                    },
                                                ],
                                            },
                                        ],
                                    },
                                    {
                                        nodeType: 'paragraph',
                                        data: {},
                                        content: [
                                            {
                                                nodeType: 'text',
                                                value: 'Please refer carefully to these Terms before you start to use any of the Services. We recommend that you print a copy of these Terms for future reference. By using any of the Services, you will be deemed to have understood and accepted these Terms, which form a binding agreement between you and us.',
                                                marks: [],
                                                data: {},
                                            },
                                        ],
                                    },
                                    {
                                        nodeType: 'heading-3',
                                        data: {},
                                        content: [
                                            {
                                                nodeType: 'text',
                                                value: 'Other applicable terms',
                                                marks: [],
                                                data: {},
                                            },
                                        ],
                                    },
                                    {
                                        nodeType: 'paragraph',
                                        data: {},
                                        content: [
                                            {
                                                nodeType: 'text',
                                                value: 'These Terms include our Privacy PolicyÂ ',
                                                marks: [],
                                                data: {},
                                            },
                                            {
                                                nodeType: 'hyperlink',
                                                data: {
                                                    uri: 'https://www.onair.events/pages/privacy-policy',
                                                },
                                                content: [
                                                    {
                                                        nodeType: 'text',
                                                        value: 'https://www.onair.events/pages/privacy-policy',
                                                        marks: [],
                                                        data: {},
                                                    },
                                                ],
                                            },
                                            {
                                                nodeType: 'text',
                                                value: "Â (the Privacy Policy), which sets out the terms on which we process any personal data we collect from you or that you provide to us via the Platform. You may also be subject to third party terms relating to the Apps â€œsee the 'Mobile App' and 'Smart TV App' sections below for more information.",
                                                marks: [],
                                                data: {},
                                            },
                                        ],
                                    },
                                    {
                                        nodeType: 'heading-3',
                                        data: {},
                                        content: [
                                            {
                                                nodeType: 'text',
                                                value: 'Changes to these terms',
                                                marks: [],
                                                data: {},
                                            },
                                        ],
                                    },
                                    {
                                        nodeType: 'paragraph',
                                        data: {},
                                        content: [
                                            {
                                                nodeType: 'text',
                                                value: 'At any time, we may amend these Terms to ensure that we remain compliant with relevant laws and regulations and to ensure that we are constantly improving your experience using the Services. Please check this page from time to time to take notice of any changes we have made. By continuing to use any of the Services after changes are made, you are accepting those changes and will be bound by them.',
                                                marks: [],
                                                data: {},
                                            },
                                        ],
                                    },
                                    {
                                        nodeType: 'heading-3',
                                        data: {},
                                        content: [
                                            {
                                                nodeType: 'text',
                                                value: 'The platform',
                                                marks: [],
                                                data: {},
                                            },
                                        ],
                                    },
                                    {
                                        nodeType: 'paragraph',
                                        data: {},
                                        content: [
                                            {
                                                nodeType: 'text',
                                                value: 'We offer various functions via the Platform (together the Platform Functions), including those which allow you to:',
                                                marks: [],
                                                data: {},
                                            },
                                        ],
                                    },
                                    {
                                        nodeType: 'unordered-list',
                                        data: {},
                                        content: [
                                            {
                                                nodeType: 'list-item',
                                                data: {},
                                                content: [
                                                    {
                                                        nodeType: 'paragraph',
                                                        data: {},
                                                        content: [
                                                            {
                                                                nodeType:
                                                                    'text',
                                                                value: 'Browse the range of live events we offer for streaming via the Platform (the Events) and view information about the Events and the artists/performers;',
                                                                marks: [],
                                                                data: {},
                                                            },
                                                        ],
                                                    },
                                                ],
                                            },
                                            {
                                                nodeType: 'list-item',
                                                data: {},
                                                content: [
                                                    {
                                                        nodeType: 'paragraph',
                                                        data: {},
                                                        content: [
                                                            {
                                                                nodeType:
                                                                    'text',
                                                                value: 'Purchase pay-per-view tickets to Events (each such Event being a Purchased Event); and',
                                                                marks: [],
                                                                data: {},
                                                            },
                                                        ],
                                                    },
                                                ],
                                            },
                                            {
                                                nodeType: 'list-item',
                                                data: {},
                                                content: [
                                                    {
                                                        nodeType: 'paragraph',
                                                        data: {},
                                                        content: [
                                                            {
                                                                nodeType:
                                                                    'text',
                                                                value: 'View your Purchased Events; and',
                                                                marks: [],
                                                                data: {},
                                                            },
                                                        ],
                                                    },
                                                ],
                                            },
                                            {
                                                nodeType: 'list-item',
                                                data: {},
                                                content: [
                                                    {
                                                        nodeType: 'paragraph',
                                                        data: {},
                                                        content: [
                                                            {
                                                                nodeType:
                                                                    'text',
                                                                value: 'purchase Merchandise Products.',
                                                                marks: [],
                                                                data: {},
                                                            },
                                                        ],
                                                    },
                                                ],
                                            },
                                        ],
                                    },
                                    {
                                        nodeType: 'paragraph',
                                        data: {},
                                        content: [
                                            {
                                                nodeType: 'text',
                                                value: 'The list above is not an exhaustive list of the functions of the Platform and we may decide to offer additional Platform Functions, or cease to provide any Platform Functions, at any time.',
                                                marks: [],
                                                data: {},
                                            },
                                        ],
                                    },
                                    {
                                        nodeType: 'heading-3',
                                        data: {},
                                        content: [
                                            {
                                                nodeType: 'text',
                                                value: 'Registration',
                                                marks: [],
                                                data: {},
                                            },
                                        ],
                                    },
                                    {
                                        nodeType: 'paragraph',
                                        data: {},
                                        content: [
                                            {
                                                nodeType: 'text',
                                                value: 'You do not have to register to the Platform, but some of the Platform Functions are available only to users who register and set up an account.Â To register, you will need to complete a simple registration process by providing certain information (including your name, date of birth, email address and if you choose to provide it (though you do not have to) your gender). You will also need to choose a username and password for use in connection with your account.You agree that you will provide truthful and accurate information when registering on the Platform. The decision to register a password is in our discretion and we may revoke your password at any time.You are responsible for maintaining the confidentiality of your password and account information, and you are responsible for all activities that occur under your password or account and for any access to or use of the Services by you or any person or entity using your password, whether or not such access or use has been authorised by you.You must immediately notify us of any unauthorised use of your password or account or any other breach of security relating to the Services. We will not be liable for any loss or damage whatsoever resulting from the disclosure of your username and/or password contrary to these Terms.You are responsible for ensuring that the information we hold about you is up to date. Please amend your details as appropriate from time to time or emailÂ ',
                                                marks: [],
                                                data: {},
                                            },
                                            {
                                                nodeType: 'hyperlink',
                                                data: {
                                                    uri: 'mailto:helpdesk@onair.events',
                                                },
                                                content: [
                                                    {
                                                        nodeType: 'text',
                                                        value: 'helpdesk@onair.events',
                                                        marks: [],
                                                        data: {},
                                                    },
                                                ],
                                            },
                                            {
                                                nodeType: 'text',
                                                value: 'Â to notify us of any changes.',
                                                marks: [],
                                                data: {},
                                            },
                                        ],
                                    },
                                    {
                                        nodeType: 'heading-3',
                                        data: {},
                                        content: [
                                            {
                                                nodeType: 'text',
                                                value: 'Individual accounts',
                                                marks: [],
                                                data: {},
                                            },
                                        ],
                                    },
                                    {
                                        nodeType: 'paragraph',
                                        data: {},
                                        content: [
                                            {
                                                nodeType: 'text',
                                                value: 'We offer one type of account: individual accounts (Private Accounts)As the names suggest:',
                                                marks: [],
                                                data: {},
                                            },
                                        ],
                                    },
                                    {
                                        nodeType: 'unordered-list',
                                        data: {},
                                        content: [
                                            {
                                                nodeType: 'list-item',
                                                data: {},
                                                content: [
                                                    {
                                                        nodeType: 'paragraph',
                                                        data: {},
                                                        content: [
                                                            {
                                                                nodeType:
                                                                    'text',
                                                                value: 'Private Accounts are designed for individual users (Individual Users), who wish to access the Platform Functions for personal, non-commercial purposes.',
                                                                marks: [],
                                                                data: {},
                                                            },
                                                        ],
                                                    },
                                                ],
                                            },
                                        ],
                                    },
                                    {
                                        nodeType: 'paragraph',
                                        data: {},
                                        content: [
                                            {
                                                nodeType: 'text',
                                                value: 'All the Platform Functions are available to Individual Users.',
                                                marks: [],
                                                data: {},
                                            },
                                        ],
                                    },
                                    {
                                        nodeType: 'heading-3',
                                        data: {},
                                        content: [
                                            {
                                                nodeType: 'text',
                                                value: 'Fees and Payment',
                                                marks: [],
                                                data: {},
                                            },
                                        ],
                                    },
                                    {
                                        nodeType: 'paragraph',
                                        data: {},
                                        content: [
                                            {
                                                nodeType: 'text',
                                                value: 'Most of the Platform Functions are provided free of charge. For example, we will not charge you to set up your account.However, access to the Events is provided on a pay-per-view basis, and prices will appear on the relevant page(s) of the Platform and the Apps. Payment must be made in advance, in full.The prices mentioned in the offer of Merchandise Products are stated in Euros and exclude shipping costs, VAT, import duties and clearance charges.Â The amounts due for the Merchandise Products must be paid within 14 days of the start of the consideration period. If you fail to fulfil your payment obligation(s) on time, after we have notified you of the late payment and given you a period of 14 days to fulfil your payment obligations, and if payment has not been made within this 14-day period, you will owe statutory interest on the amount due, and we will be entitled to charge you for the extrajudicial collection costs we have incurred.We accept major cards for payment. By paying using your credit or debit card you confirm that the card is yours and that there are sufficient funds or credit available to cover the relevant fees.',
                                                marks: [],
                                                data: {},
                                            },
                                        ],
                                    },
                                    {
                                        nodeType: 'heading-3',
                                        data: {},
                                        content: [
                                            {
                                                nodeType: 'text',
                                                value: 'Delivery and Execution',
                                                marks: [],
                                                data: {},
                                            },
                                        ],
                                    },
                                    {
                                        nodeType: 'paragraph',
                                        data: {},
                                        content: [
                                            {
                                                nodeType: 'text',
                                                value: 'The place of delivery of the Merchandise Products is the address that you have made known to On Air.The Merchandise Products will be delivered by a third party. Which party delivers the Merchandise Products depends on the Event to which they relate.Â The delivery time of the Merchandise Products depends on the third party delivering the Merchandise Products. An indication of the delivery time is given during the ordering process. If the delivery is delayed, or if an order cannot be carried out, or can only be carried out partially, you will receive notification of this within 30 days of placing the order.',
                                                marks: [],
                                                data: {},
                                            },
                                        ],
                                    },
                                    {
                                        nodeType: 'heading-3',
                                        data: {},
                                        content: [
                                            {
                                                nodeType: 'text',
                                                value: 'Event postponement and cancellation',
                                                marks: [],
                                                data: {},
                                            },
                                        ],
                                    },
                                    {
                                        nodeType: 'paragraph',
                                        data: {},
                                        content: [
                                            {
                                                nodeType: 'text',
                                                value: 'If a Purchased Event is postponed for whatever reason, we will notify you via email and you will be offered the option of either accessing the Purchased Event at the new date and time, or receiving a full refund of the price you have paid for the postponed Purchased Event.Unless and until you let us know which option you prefer, then we will assume you want to access the Purchased Event at the new date and time.If a Purchased Event is cancelled entirely for whatever reason, you will be notified via email and we will automatically refund you the full price you have paid for the cancelled Purchased Event.Except for our obligations set out above, you acknowledge that we will not be liable to you in any way in respect of any postponed or cancelled events.',
                                                marks: [],
                                                data: {},
                                            },
                                        ],
                                    },
                                    {
                                        nodeType: 'heading-3',
                                        data: {},
                                        content: [
                                            {
                                                nodeType: 'text',
                                                value: 'Acceptable Use Restrictions',
                                                marks: [],
                                                data: {},
                                            },
                                        ],
                                    },
                                    {
                                        nodeType: 'paragraph',
                                        data: {},
                                        content: [
                                            {
                                                nodeType: 'text',
                                                value: 'You may use the Services for lawful purposes only. In particular, but without limitation, you agree not to:',
                                                marks: [],
                                                data: {},
                                            },
                                        ],
                                    },
                                    {
                                        nodeType: 'unordered-list',
                                        data: {},
                                        content: [
                                            {
                                                nodeType: 'list-item',
                                                data: {},
                                                content: [
                                                    {
                                                        nodeType: 'paragraph',
                                                        data: {},
                                                        content: [
                                                            {
                                                                nodeType:
                                                                    'text',
                                                                value: 'Use the Services in any way that is unlawful or fraudulent, or has any unlawful or fraudulent purpose or effect;',
                                                                marks: [],
                                                                data: {},
                                                            },
                                                        ],
                                                    },
                                                ],
                                            },
                                            {
                                                nodeType: 'list-item',
                                                data: {},
                                                content: [
                                                    {
                                                        nodeType: 'paragraph',
                                                        data: {},
                                                        content: [
                                                            {
                                                                nodeType:
                                                                    'text',
                                                                value: 'Use the Services, in any way that is defamatory, threatening, abusive, pornographic, or otherwise objectionable; or which advocates bigotry, hatred, or illegal discrimination;',
                                                                marks: [],
                                                                data: {},
                                                            },
                                                        ],
                                                    },
                                                ],
                                            },
                                            {
                                                nodeType: 'list-item',
                                                data: {},
                                                content: [
                                                    {
                                                        nodeType: 'paragraph',
                                                        data: {},
                                                        content: [
                                                            {
                                                                nodeType:
                                                                    'text',
                                                                value: 'Use, share, or otherwise exploit the Services for any commercial, business, or monetised purpose whatsoever unless expressly permitted under these Terms;',
                                                                marks: [],
                                                                data: {},
                                                            },
                                                        ],
                                                    },
                                                ],
                                            },
                                            {
                                                nodeType: 'list-item',
                                                data: {},
                                                content: [
                                                    {
                                                        nodeType: 'paragraph',
                                                        data: {},
                                                        content: [
                                                            {
                                                                nodeType:
                                                                    'text',
                                                                value: 'Reproduce, duplicate, copy, share, or re-sell any part of the Services in contravention of these Terms; use the Services in a way that could damage, disable, overburden, impair or compromise our systems or security or interfere with other users;',
                                                                marks: [],
                                                                data: {},
                                                            },
                                                        ],
                                                    },
                                                ],
                                            },
                                            {
                                                nodeType: 'list-item',
                                                data: {},
                                                content: [
                                                    {
                                                        nodeType: 'paragraph',
                                                        data: {},
                                                        content: [
                                                            {
                                                                nodeType:
                                                                    'text',
                                                                value: 'Transmit any data, send or upload any material that contains viruses, Trojan horses, worms, time- bombs, keystroke loggers, spyware, adware or any other harmful programs or similar computer code designed to adversely affect the operation of Services; or',
                                                                marks: [],
                                                                data: {},
                                                            },
                                                        ],
                                                    },
                                                ],
                                            },
                                            {
                                                nodeType: 'list-item',
                                                data: {},
                                                content: [
                                                    {
                                                        nodeType: 'paragraph',
                                                        data: {},
                                                        content: [
                                                            {
                                                                nodeType:
                                                                    'text',
                                                                value: 'Access without authority, interfere with, damage or disrupt (a) any part of the Services; (b) any equipment or network on which the Services are stored; (c) any software used in the provision of the Services; or (d) any equipment, network or software owned or used by any third party,',
                                                                marks: [],
                                                                data: {},
                                                            },
                                                        ],
                                                    },
                                                ],
                                            },
                                        ],
                                    },
                                    {
                                        nodeType: 'paragraph',
                                        data: {},
                                        content: [
                                            {
                                                nodeType: 'text',
                                                value: '(together the Acceptable Use Restrictions).',
                                                marks: [],
                                                data: {},
                                            },
                                        ],
                                    },
                                    {
                                        nodeType: 'heading-3',
                                        data: {},
                                        content: [
                                            {
                                                nodeType: 'text',
                                                value: 'Ending your rights under these terms',
                                                marks: [],
                                                data: {},
                                            },
                                        ],
                                    },
                                    {
                                        nodeType: 'paragraph',
                                        data: {},
                                        content: [
                                            {
                                                nodeType: 'text',
                                                value: 'We may end your rights under these Terms immediately and without notice if:',
                                                marks: [],
                                                data: {},
                                            },
                                        ],
                                    },
                                    {
                                        nodeType: 'unordered-list',
                                        data: {},
                                        content: [
                                            {
                                                nodeType: 'list-item',
                                                data: {},
                                                content: [
                                                    {
                                                        nodeType: 'paragraph',
                                                        data: {},
                                                        content: [
                                                            {
                                                                nodeType:
                                                                    'text',
                                                                value: 'You have breached any of the App Licence Restrictions or the Acceptable Use Restrictions;',
                                                                marks: [],
                                                                data: {},
                                                            },
                                                        ],
                                                    },
                                                ],
                                            },
                                            {
                                                nodeType: 'list-item',
                                                data: {},
                                                content: [
                                                    {
                                                        nodeType: 'paragraph',
                                                        data: {},
                                                        content: [
                                                            {
                                                                nodeType:
                                                                    'text',
                                                                value: 'We believe that your use of the Services is unsuitable in any way;',
                                                                marks: [],
                                                                data: {},
                                                            },
                                                        ],
                                                    },
                                                ],
                                            },
                                            {
                                                nodeType: 'list-item',
                                                data: {},
                                                content: [
                                                    {
                                                        nodeType: 'paragraph',
                                                        data: {},
                                                        content: [
                                                            {
                                                                nodeType:
                                                                    'text',
                                                                value: 'Or you are otherwise in breach of these Terms. If we end your rights under these Terms:',
                                                                marks: [],
                                                                data: {},
                                                            },
                                                        ],
                                                    },
                                                ],
                                            },
                                            {
                                                nodeType: 'list-item',
                                                data: {},
                                                content: [
                                                    {
                                                        nodeType: 'paragraph',
                                                        data: {},
                                                        content: [
                                                            {
                                                                nodeType:
                                                                    'text',
                                                                value: 'You must immediately stop all activities authorised by these Terms, including your access to the Services; and',
                                                                marks: [],
                                                                data: {},
                                                            },
                                                        ],
                                                    },
                                                ],
                                            },
                                            {
                                                nodeType: 'list-item',
                                                data: {},
                                                content: [
                                                    {
                                                        nodeType: 'paragraph',
                                                        data: {},
                                                        content: [
                                                            {
                                                                nodeType:
                                                                    'text',
                                                                value: 'You must immediately delete or remove the Apps from all devices then in your possession, custody or control and, if required, confirm to us that you have done so.',
                                                                marks: [],
                                                                data: {},
                                                            },
                                                        ],
                                                    },
                                                ],
                                            },
                                        ],
                                    },
                                    {
                                        nodeType: 'heading-3',
                                        data: {},
                                        content: [
                                            {
                                                nodeType: 'text',
                                                value: 'User Content',
                                                marks: [],
                                                data: {},
                                            },
                                        ],
                                    },
                                    {
                                        nodeType: 'paragraph',
                                        data: {},
                                        content: [
                                            {
                                                nodeType: 'text',
                                                value: 'Anything and everything you upload to or share or communicate via the Services is referred to in these Terms as the User Content.You are solely responsible for ensuring that you have all the necessary rights to allÂ ',
                                                marks: [],
                                                data: {},
                                            },
                                            {
                                                nodeType: 'text',
                                                value: 'User Content',
                                                marks: [
                                                    {
                                                        type: 'bold',
                                                    },
                                                ],
                                                data: {},
                                            },
                                            {
                                                nodeType: 'text',
                                                value: ', including without limitation all rights required to enable access to the User Content by other users of the Services.You hereby grant to us a royalty-free, worldwide, irrevocable licence in perpetuity to host and store the User Content via the Services and to make available the User Content to other users of the Services.As between us and you, the User Content is and will remain owned and controlled solely by you and you acknowledge that you have sole responsibility for the User Content.',
                                                marks: [],
                                                data: {},
                                            },
                                        ],
                                    },
                                    {
                                        nodeType: 'heading-3',
                                        data: {},
                                        content: [
                                            {
                                                nodeType: 'text',
                                                value: 'Intellectual Property Rights',
                                                marks: [],
                                                data: {},
                                            },
                                        ],
                                    },
                                    {
                                        nodeType: 'paragraph',
                                        data: {},
                                        content: [
                                            {
                                                nodeType: 'text',
                                                value: 'You acknowledge and agree that the Services, and all material published on, in, or via the Services other than the User Content (including but not limited to all text, graphics, photos, logos, button icons, images, trade marks, audio and audio visual clips, databases, data compilations, and data (together the On Air Content)) is owned and controlled by or licensed to us.You have no rights in, or to, the On Air Content other than the right to use the On Air Content strictly in accordance with these Terms.You may not copy, adapt, display, share, communicate to the public or otherwise use any On Air Content except as enabled and permitted by the Services and these Terms from time to time.',
                                                marks: [],
                                                data: {},
                                            },
                                        ],
                                    },
                                    {
                                        nodeType: 'heading-3',
                                        data: {},
                                        content: [
                                            {
                                                nodeType: 'text',
                                                value: 'Cancellation by you',
                                                marks: [],
                                                data: {},
                                            },
                                        ],
                                    },
                                    {
                                        nodeType: 'paragraph',
                                        data: {},
                                        content: [
                                            {
                                                nodeType: 'text',
                                                value: 'Your statutory right to cancel',
                                                marks: [
                                                    {
                                                        type: 'bold',
                                                    },
                                                ],
                                                data: {},
                                            },
                                            {
                                                nodeType: 'text',
                                                value: ": If you are an individual user (i.e. not a business user) who has paid to access an event via the On Air service and/or ordered a Merchandise Product, you have a legal right - under the conditions and restrictions described below - to change your mind and cancel the contract you've entered into with us. This right lasts for 14 days from the day you make payment or, in the case of the purchase of Merchandise Products, from the day after you receive the Merchandise Products.Â ",
                                                marks: [],
                                                data: {},
                                            },
                                            {
                                                nodeType: 'text',
                                                value: 'When you cannot cancel',
                                                marks: [
                                                    {
                                                        type: 'bold',
                                                    },
                                                ],
                                                data: {},
                                            },
                                            {
                                                nodeType: 'text',
                                                value: ': If you start to stream/access the event within the 14-day cancellation period (even if you do not access the whole thing) and you consented to this and acknowledged when ordering that you would lose your right to cancel if you did this, you will lose your right to cancel the contract.',
                                                marks: [],
                                                data: {},
                                            },
                                            {
                                                nodeType: 'text',
                                                value: 'Your obligations during the cooling-off period:',
                                                marks: [
                                                    {
                                                        type: 'bold',
                                                    },
                                                ],
                                                data: {},
                                            },
                                            {
                                                nodeType: 'text',
                                                value: 'Â During the cooling-off period you will handle the Merchandise Products and the packaging with care. You will only unpack or use the product to the extent necessary to determine the nature, characteristics and operation thereof. The basic principle here is that you may only handle and inspect the Merchandise Products as you would in a shop.',
                                                marks: [],
                                                data: {},
                                            },
                                            {
                                                nodeType: 'text',
                                                value: 'How to tell us you want to cancel',
                                                marks: [
                                                    {
                                                        type: 'bold',
                                                    },
                                                ],
                                                data: {},
                                            },
                                            {
                                                nodeType: 'text',
                                                value: ': You can inform us that you want to cancel by any clear means, including:By email. Email us atÂ ',
                                                marks: [],
                                                data: {},
                                            },
                                            {
                                                nodeType: 'hyperlink',
                                                data: {
                                                    uri: 'mailto:helpdesk@onair.events',
                                                },
                                                content: [
                                                    {
                                                        nodeType: 'text',
                                                        value: 'helpdesk@onair.events',
                                                        marks: [],
                                                        data: {},
                                                    },
                                                ],
                                            },
                                            {
                                                nodeType: 'text',
                                                value: '. Please provide your name, address, details of the event you want to cancel and, where available, your phone number and email address.By post. Print off the model cancellation form below and post it to us at our address. Or simply write to us, including details of details of the event you want to cancel, when you paid for it, and your name and address.',
                                                marks: [],
                                                data: {},
                                            },
                                            {
                                                nodeType: 'text',
                                                value: 'Method of return:',
                                                marks: [
                                                    {
                                                        type: 'bold',
                                                    },
                                                ],
                                                data: {},
                                            },
                                            {
                                                nodeType: 'text',
                                                value: 'Â You will return the Merchandise Products with all delivered accessories, if reasonably possible in the original condition and packaging, and in accordance with the reasonable and clear instructions provided by us or the supplier. You bear the risk and burden of proof of the correct and timely exercise of the right of withdrawal. You will be responsible for the costs of returning Merchandise.',
                                                marks: [],
                                                data: {},
                                            },
                                            {
                                                nodeType: 'text',
                                                value: 'Effect of cancellation',
                                                marks: [
                                                    {
                                                        type: 'bold',
                                                    },
                                                ],
                                                data: {},
                                            },
                                            {
                                                nodeType: 'text',
                                                value: ': If you cancel as described above, we will refund the total price you paid for the cancelled event via the method you used for payment. Your refund will be made to you within 14 days of your telling us you want to cancel. Refunds for returned Merchandise Products will be made including the shipping costs you paid, no later than 14 days after we receive the returned Merchandise Products or after you prove that you returned the Merchandise Products.',
                                                marks: [],
                                                data: {},
                                            },
                                        ],
                                    },
                                    {
                                        nodeType: 'heading-3',
                                        data: {},
                                        content: [
                                            {
                                                nodeType: 'text',
                                                value: 'Availability of the services',
                                                marks: [],
                                                data: {},
                                            },
                                        ],
                                    },
                                    {
                                        nodeType: 'paragraph',
                                        data: {},
                                        content: [
                                            {
                                                nodeType: 'text',
                                                value: 'The Services are provided on an "as is" basis. We make no representations, warranties or guarantees of any kind regarding the availability or operation of the Services, or that the Services will be secure, uninterrupted or free of defects.Your access to the Services may be suspended or restricted occasionally to allow for maintenance, repairs, upgrades, or the introduction of new functions or services. We will not be liable to you if for any reason the Services are unavailable at any time or for any period. In addition, we may use third parties to (help) conduct our Services. We are not liable for any Services operated by third parties.',
                                                marks: [],
                                                data: {},
                                            },
                                        ],
                                    },
                                    {
                                        nodeType: 'heading-3',
                                        data: {},
                                        content: [
                                            {
                                                nodeType: 'text',
                                                value: 'Websites we link to',
                                                marks: [],
                                                data: {},
                                            },
                                        ],
                                    },
                                    {
                                        nodeType: 'paragraph',
                                        data: {},
                                        content: [
                                            {
                                                nodeType: 'text',
                                                value: 'The Services may offer links to other websites from which third party services can be obtained and which we reasonably believe to be reputable sources of such services. However, you acknowledge that these other websites are independent from us and we make no representations or warranties as to the legitimacy, accuracy or quality of such third party services, and we do not accept any responsibility for their content, safety, practices or privacy policies.',
                                                marks: [],
                                                data: {},
                                            },
                                        ],
                                    },
                                    {
                                        nodeType: 'heading-3',
                                        data: {},
                                        content: [
                                            {
                                                nodeType: 'text',
                                                value: 'Computer viruses',
                                                marks: [],
                                                data: {},
                                            },
                                        ],
                                    },
                                    {
                                        nodeType: 'paragraph',
                                        data: {},
                                        content: [
                                            {
                                                nodeType: 'text',
                                                value: 'We will use reasonable endeavours to ensure that no part of the Services will contain or spread any viruses or other malicious code.However, we recommend that you ensure that equipment used to access the Services run up-to-date anti-virus software as a precaution, and you are advised to virus-check any materials downloaded from the Services and regularly check for the presence of viruses and other malicious code.To the full extent permitted by law we exclude liability for damage or loss of any kind caused by viruses or other harmful components originating or contracted from the Services.',
                                                marks: [],
                                                data: {},
                                            },
                                        ],
                                    },
                                    {
                                        nodeType: 'heading-3',
                                        data: {},
                                        content: [
                                            {
                                                nodeType: 'text',
                                                value: 'No reliance on information',
                                                marks: [],
                                                data: {},
                                            },
                                        ],
                                    },
                                    {
                                        nodeType: 'paragraph',
                                        data: {},
                                        content: [
                                            {
                                                nodeType: 'text',
                                                value: 'All information published on or via the Services is provided in good faith and for general information purpose only. We make no warranties about the completeness, reliability, or accuracy of such information. Any action you take based on such information is taken at your own risk.',
                                                marks: [],
                                                data: {},
                                            },
                                        ],
                                    },
                                    {
                                        nodeType: 'heading-3',
                                        data: {},
                                        content: [
                                            {
                                                nodeType: 'text',
                                                value: 'Our responsibility for loss or damage suffered by you',
                                                marks: [],
                                                data: {},
                                            },
                                        ],
                                    },
                                    {
                                        nodeType: 'paragraph',
                                        data: {},
                                        content: [
                                            {
                                                nodeType: 'text',
                                                value: 'We exclude / limit our liability to the extent permitted by law. This includes liability for death or personal injury caused by our negligence or the negligence of our employees, agents or subcontractors and for fraud or fraudulent misrepresentation.If you are an Individual User: we provide the Services for domestic and private use only. You agree not to use Services for any commercial or business purposes.We are not responsible for the acts or omissions of any third party, including any third party app store from whom you download or access the Apps or third parties that are hired by us to manage or store your personal data.If any aspect of the Services damages a device or digital content belonging to you and this is caused by our failure to use reasonable care and skill, we will either repair the damage or pay you compensation.However, we will not be liable for damage that you could have avoided by following our advice to apply an update offered to you free of charge or for damage that was caused by you failing to correctly follow download, installation, or usage instructions or to have in place the minimum system requirements and anti-virus software advised by us.Except as set out in the paragraph above, you accept and agree we will not be liable for any harmful effect that accessing the Services may have on you, and you agree that you access the Services at your own risk.',
                                                marks: [],
                                                data: {},
                                            },
                                        ],
                                    },
                                    {
                                        nodeType: 'heading-3',
                                        data: {},
                                        content: [
                                            {
                                                nodeType: 'text',
                                                value: 'Communications between us',
                                                marks: [],
                                                data: {},
                                            },
                                        ],
                                    },
                                    {
                                        nodeType: 'paragraph',
                                        data: {},
                                        content: [
                                            {
                                                nodeType: 'text',
                                                value: 'If you wish to contact us for any reason, you can do so:',
                                                marks: [],
                                                data: {},
                                            },
                                        ],
                                    },
                                    {
                                        nodeType: 'unordered-list',
                                        data: {},
                                        content: [
                                            {
                                                nodeType: 'list-item',
                                                data: {},
                                                content: [
                                                    {
                                                        nodeType: 'paragraph',
                                                        data: {},
                                                        content: [
                                                            {
                                                                nodeType:
                                                                    'text',
                                                                value: 'By e-mail at ',
                                                                marks: [],
                                                                data: {},
                                                            },
                                                            {
                                                                nodeType:
                                                                    'hyperlink',
                                                                data: {
                                                                    uri: 'mailto:helpdesk@onair.events',
                                                                },
                                                                content: [
                                                                    {
                                                                        nodeType:
                                                                            'text',
                                                                        value: 'helpdesk@onair.events',
                                                                        marks: [],
                                                                        data: {},
                                                                    },
                                                                ],
                                                            },
                                                            {
                                                                nodeType:
                                                                    'text',
                                                                value: '.',
                                                                marks: [],
                                                                data: {},
                                                            },
                                                        ],
                                                    },
                                                ],
                                            },
                                            {
                                                nodeType: 'list-item',
                                                data: {},
                                                content: [
                                                    {
                                                        nodeType: 'paragraph',
                                                        data: {},
                                                        content: [
                                                            {
                                                                nodeType:
                                                                    'text',
                                                                value: 'By writing to On Air B.V., Eemnesserweg 20, 3741 GA, Baarn, The Netherlands.',
                                                                marks: [],
                                                                data: {},
                                                            },
                                                        ],
                                                    },
                                                ],
                                            },
                                        ],
                                    },
                                    {
                                        nodeType: 'heading-3',
                                        data: {},
                                        content: [
                                            {
                                                nodeType: 'text',
                                                value: 'Complaints procedure',
                                                marks: [],
                                                data: {},
                                            },
                                        ],
                                    },
                                    {
                                        nodeType: 'paragraph',
                                        data: {},
                                        content: [
                                            {
                                                nodeType: 'text',
                                                value: 'We have adopted a complaints procedure and will deal with your complaint in accordance with this procedure.Â Complaints about the execution of the agreement must be submitted to us fully and clearly described within a reasonable time after you have discovered the defects. Complaints submitted to On Air shall be answered within a period of 14 days from the date of receipt. If a complaint requires a foreseeably longer processing time, we will send you within the period of 14 days a notice of receipt and an indication of when you can expect a more detailed answer.You must give us at least 4 weeks to resolve the complaint in mutual consultation. After this period, a dispute arises that is subject to the dispute settlement procedure.',
                                                marks: [],
                                                data: {},
                                            },
                                        ],
                                    },
                                    {
                                        nodeType: 'heading-3',
                                        data: {},
                                        content: [
                                            {
                                                nodeType: 'text',
                                                value: 'Other important terms',
                                                marks: [],
                                                data: {},
                                            },
                                        ],
                                    },
                                    {
                                        nodeType: 'paragraph',
                                        data: {},
                                        content: [
                                            {
                                                nodeType: 'text',
                                                value: 'We may transfer our rights and obligations under these Terms to another organisation, whose terms and conditions are presentedÂ ',
                                                marks: [],
                                                data: {},
                                            },
                                            {
                                                nodeType: 'hyperlink',
                                                data: {
                                                    uri: 'https://www.shopify.com/legal/terms',
                                                },
                                                content: [
                                                    {
                                                        nodeType: 'text',
                                                        value: 'here',
                                                        marks: [],
                                                        data: {},
                                                    },
                                                ],
                                            },
                                            {
                                                nodeType: 'text',
                                                value: 'Â but this will not affect your rights or our obligations under these Terms. If we do not enforce our rights against you, or if we delay in doing so, that does not mean that we have waived our rights against you, and it does not mean that you are relieved of your obligations under these Terms. If we do waive a breach by you, we will only do so in writing, and that will not mean that we will automatically waive any later breach by you.Each of the terms and conditions of these Terms operates separately. If any court or competent authority decides that any of them are unlawful or unenforceable, the remaining terms and conditions will remain in full force and effect.These Terms are governed by Dutch law and the courts of Amsterdam, the Netherlands will have exclusive jurisdiction.',
                                                marks: [],
                                                data: {},
                                            },
                                        ],
                                    },
                                    {
                                        nodeType: 'heading-3',
                                        data: {},
                                        content: [
                                            {
                                                nodeType: 'text',
                                                value: 'Annex I: Model withdrawal form',
                                                marks: [],
                                                data: {},
                                            },
                                        ],
                                    },
                                    {
                                        nodeType: 'paragraph',
                                        data: {},
                                        content: [
                                            {
                                                nodeType: 'text',
                                                value: "Model withdrawal formÂ  Â Â (complete and return this form only if you wish to withdraw from the contract)- To:Â Â  Â Â [ trader's name][ geographical address of the trader][ fax number of trader, if available][ email address or electronic address of trader]Â  Â Â - I/We* hereby give notice that I/We* have decided to terminate our contract concerningthe sale of the following products: [product designation]*the supply of the following digital content: [digital content designation]*the provision of the following service: [service designation]*,revoked/revoked*Â  Â Â Â - Ordered on*/received on* [date of order for services or date of receipt for products].Â - Name of consumer(s)Â - Address of consumer(s)Â - Signature of consumer(s)] (only if this form is notified on paper)Â  Â Â * Delete where not applicable or fill in where appropriate.",
                                                marks: [],
                                                data: {},
                                            },
                                        ],
                                    },
                                    {
                                        nodeType: 'paragraph',
                                        data: {},
                                        content: [
                                            {
                                                nodeType: 'text',
                                                value: '10/02/2022, 13:51:06',
                                                marks: [],
                                                data: {},
                                            },
                                        ],
                                    },
                                ],
                            },
                            imagePosition: 'right',
                            shouldShowSocialButtons: false,
                            showAppStoreBanners: false,
                            type: 'Generic',
                        },
                    },
                ],
            },
        },
    ],
    includes: {
        Entry: [
            {
                metadata: {
                    tags: [],
                },
                sys: {
                    space: {
                        sys: {
                            type: 'Link',
                            linkType: 'Space',
                            id: 's26j9zm7rn0w',
                        },
                    },
                    id: '4YS6auScLrbibpmj8GdduH',
                    type: 'Entry',
                    createdAt: '2022-03-31T17:28:13.771Z',
                    updatedAt: '2022-03-31T17:28:13.771Z',
                    environment: {
                        sys: {
                            id: 'development',
                            type: 'Link',
                            linkType: 'Environment',
                        },
                    },
                    revision: 1,
                    contentType: {
                        sys: {
                            type: 'Link',
                            linkType: 'ContentType',
                            id: 'seo',
                        },
                    },
                    locale: 'en-US',
                },
                fields: {
                    title: 'Privacy Policy',
                    description: 'Privacy Policy of On Air',
                },
            },
            {
                metadata: {
                    tags: [],
                },
                sys: {
                    space: {
                        sys: {
                            type: 'Link',
                            linkType: 'Space',
                            id: 's26j9zm7rn0w',
                        },
                    },
                    id: '5Yklzv0WtKG8HngNApK95B',
                    type: 'Entry',
                    createdAt: '2022-04-12T16:42:28.899Z',
                    updatedAt: '2022-04-12T16:53:27.714Z',
                    environment: {
                        sys: {
                            id: 'development',
                            type: 'Link',
                            linkType: 'Environment',
                        },
                    },
                    revision: 2,
                    contentType: {
                        sys: {
                            type: 'Link',
                            linkType: 'ContentType',
                            id: 'contentBlock',
                        },
                    },
                    locale: 'en-US',
                },
                fields: {
                    title: 'Privacy Policy',
                    description: {
                        nodeType: 'document',
                        data: {},
                        content: [
                            {
                                nodeType: 'heading-3',
                                data: {},
                                content: [
                                    {
                                        nodeType: 'text',
                                        value: 'Who we are',
                                        marks: [],
                                        data: {},
                                    },
                                ],
                            },
                            {
                                nodeType: 'paragraph',
                                data: {},
                                content: [
                                    {
                                        nodeType: 'text',
                                        value: 'We are On Air B.V.',
                                        marks: [
                                            {
                                                type: 'bold',
                                            },
                                        ],
                                        data: {},
                                    },
                                    {
                                        nodeType: 'text',
                                        value: 'Â , located at the at Eemnesserweg 20, 3741 GA, Baarn in The Netherlands, and registered with the Dutch trade register (Kamer van Koophandel) as number 73550019 (',
                                        marks: [],
                                        data: {},
                                    },
                                    {
                                        nodeType: 'text',
                                        value: 'we, our, us',
                                        marks: [
                                            {
                                                type: 'bold',
                                            },
                                        ],
                                        data: {},
                                    },
                                    {
                                        nodeType: 'text',
                                        value: ').',
                                        marks: [],
                                        data: {},
                                    },
                                ],
                            },
                            {
                                nodeType: 'heading-3',
                                data: {},
                                content: [
                                    {
                                        nodeType: 'text',
                                        value: 'These Terms',
                                        marks: [],
                                        data: {},
                                    },
                                ],
                            },
                            {
                                nodeType: 'paragraph',
                                data: {},
                                content: [
                                    {
                                        nodeType: 'text',
                                        value: 'These terms and conditions (together with the documents referred to in them) (the Terms)',
                                        marks: [
                                            {
                                                type: 'bold',
                                            },
                                        ],
                                        data: {},
                                    },
                                    {
                                        nodeType: 'text',
                                        value: 'Â govern your relationship with us when access our event streaming platform located atÂ ',
                                        marks: [],
                                        data: {},
                                    },
                                    {
                                        nodeType: 'hyperlink',
                                        data: {
                                            uri: 'https://onair.events/',
                                        },
                                        content: [
                                            {
                                                nodeType: 'text',
                                                value: 'https://onair.events',
                                                marks: [],
                                                data: {},
                                            },
                                        ],
                                    },
                                    {
                                        nodeType: 'text',
                                        value: 'Â (theÂ ',
                                        marks: [],
                                        data: {},
                                    },
                                    {
                                        nodeType: 'text',
                                        value: 'Platform',
                                        marks: [
                                            {
                                                type: 'bold',
                                            },
                                        ],
                                        data: {},
                                    },
                                    {
                                        nodeType: 'text',
                                        value: ') and, if applicable, download and use our mobile app (theÂ ',
                                        marks: [],
                                        data: {},
                                    },
                                    {
                                        nodeType: 'text',
                                        value: 'Mobile App',
                                        marks: [
                                            {
                                                type: 'bold',
                                            },
                                        ],
                                        data: {},
                                    },
                                    {
                                        nodeType: 'text',
                                        value: ') from a third party platform and/or use our smart TV app (theÂ ',
                                        marks: [],
                                        data: {},
                                    },
                                    {
                                        nodeType: 'text',
                                        value: 'Smart TV App',
                                        marks: [
                                            {
                                                type: 'bold',
                                            },
                                        ],
                                        data: {},
                                    },
                                    {
                                        nodeType: 'text',
                                        value: ') and when you purchase merchandise products via the Platform (theÂ ',
                                        marks: [],
                                        data: {},
                                    },
                                    {
                                        nodeType: 'text',
                                        value: 'Merchandise Products',
                                        marks: [
                                            {
                                                type: 'bold',
                                            },
                                        ],
                                        data: {},
                                    },
                                    {
                                        nodeType: 'text',
                                        value: ').',
                                        marks: [],
                                        data: {},
                                    },
                                    {
                                        nodeType: 'text',
                                        value: 'Terminology:',
                                        marks: [
                                            {
                                                type: 'bold',
                                            },
                                        ],
                                        data: {},
                                    },
                                    {
                                        nodeType: 'text',
                                        value: 'Â In these Terms:',
                                        marks: [],
                                        data: {},
                                    },
                                ],
                            },
                            {
                                nodeType: 'unordered-list',
                                data: {},
                                content: [
                                    {
                                        nodeType: 'list-item',
                                        data: {},
                                        content: [
                                            {
                                                nodeType: 'paragraph',
                                                data: {},
                                                content: [
                                                    {
                                                        nodeType: 'text',
                                                        value: 'Where we want to refer to the Platform, the Mobile App, and the Smart TV App together, we will say the ',
                                                        marks: [],
                                                        data: {},
                                                    },
                                                    {
                                                        nodeType: 'text',
                                                        value: 'Services',
                                                        marks: [
                                                            {
                                                                type: 'bold',
                                                            },
                                                        ],
                                                        data: {},
                                                    },
                                                    {
                                                        nodeType: 'text',
                                                        value: '.',
                                                        marks: [],
                                                        data: {},
                                                    },
                                                ],
                                            },
                                        ],
                                    },
                                    {
                                        nodeType: 'list-item',
                                        data: {},
                                        content: [
                                            {
                                                nodeType: 'paragraph',
                                                data: {},
                                                content: [
                                                    {
                                                        nodeType: 'text',
                                                        value: 'Where we want to refer to the Mobile App and the Smart TV App together, we will say the ',
                                                        marks: [],
                                                        data: {},
                                                    },
                                                    {
                                                        nodeType: 'text',
                                                        value: 'Apps',
                                                        marks: [
                                                            {
                                                                type: 'bold',
                                                            },
                                                        ],
                                                        data: {},
                                                    },
                                                    {
                                                        nodeType: 'text',
                                                        value: '. Otherwise, we will refer to things individually using the defined terms set out above.',
                                                        marks: [],
                                                        data: {},
                                                    },
                                                ],
                                            },
                                        ],
                                    },
                                ],
                            },
                            {
                                nodeType: 'paragraph',
                                data: {},
                                content: [
                                    {
                                        nodeType: 'text',
                                        value: 'Please refer carefully to these Terms before you start to use any of the Services. We recommend that you print a copy of these Terms for future reference. By using any of the Services, you will be deemed to have understood and accepted these Terms, which form a binding agreement between you and us.',
                                        marks: [],
                                        data: {},
                                    },
                                ],
                            },
                            {
                                nodeType: 'heading-3',
                                data: {},
                                content: [
                                    {
                                        nodeType: 'text',
                                        value: 'Other applicable terms',
                                        marks: [],
                                        data: {},
                                    },
                                ],
                            },
                            {
                                nodeType: 'paragraph',
                                data: {},
                                content: [
                                    {
                                        nodeType: 'text',
                                        value: 'These Terms include our Privacy PolicyÂ ',
                                        marks: [],
                                        data: {},
                                    },
                                    {
                                        nodeType: 'hyperlink',
                                        data: {
                                            uri: 'https://www.onair.events/pages/privacy-policy',
                                        },
                                        content: [
                                            {
                                                nodeType: 'text',
                                                value: 'https://www.onair.events/pages/privacy-policy',
                                                marks: [],
                                                data: {},
                                            },
                                        ],
                                    },
                                    {
                                        nodeType: 'text',
                                        value: "Â (the Privacy Policy), which sets out the terms on which we process any personal data we collect from you or that you provide to us via the Platform. You may also be subject to third party terms relating to the Apps â€œsee the 'Mobile App' and 'Smart TV App' sections below for more information.",
                                        marks: [],
                                        data: {},
                                    },
                                ],
                            },
                            {
                                nodeType: 'heading-3',
                                data: {},
                                content: [
                                    {
                                        nodeType: 'text',
                                        value: 'Changes to these terms',
                                        marks: [],
                                        data: {},
                                    },
                                ],
                            },
                            {
                                nodeType: 'paragraph',
                                data: {},
                                content: [
                                    {
                                        nodeType: 'text',
                                        value: 'At any time, we may amend these Terms to ensure that we remain compliant with relevant laws and regulations and to ensure that we are constantly improving your experience using the Services. Please check this page from time to time to take notice of any changes we have made. By continuing to use any of the Services after changes are made, you are accepting those changes and will be bound by them.',
                                        marks: [],
                                        data: {},
                                    },
                                ],
                            },
                            {
                                nodeType: 'heading-3',
                                data: {},
                                content: [
                                    {
                                        nodeType: 'text',
                                        value: 'The platform',
                                        marks: [],
                                        data: {},
                                    },
                                ],
                            },
                            {
                                nodeType: 'paragraph',
                                data: {},
                                content: [
                                    {
                                        nodeType: 'text',
                                        value: 'We offer various functions via the Platform (together the Platform Functions), including those which allow you to:',
                                        marks: [],
                                        data: {},
                                    },
                                ],
                            },
                            {
                                nodeType: 'unordered-list',
                                data: {},
                                content: [
                                    {
                                        nodeType: 'list-item',
                                        data: {},
                                        content: [
                                            {
                                                nodeType: 'paragraph',
                                                data: {},
                                                content: [
                                                    {
                                                        nodeType: 'text',
                                                        value: 'Browse the range of live events we offer for streaming via the Platform (the Events) and view information about the Events and the artists/performers;',
                                                        marks: [],
                                                        data: {},
                                                    },
                                                ],
                                            },
                                        ],
                                    },
                                    {
                                        nodeType: 'list-item',
                                        data: {},
                                        content: [
                                            {
                                                nodeType: 'paragraph',
                                                data: {},
                                                content: [
                                                    {
                                                        nodeType: 'text',
                                                        value: 'Purchase pay-per-view tickets to Events (each such Event being a Purchased Event); and',
                                                        marks: [],
                                                        data: {},
                                                    },
                                                ],
                                            },
                                        ],
                                    },
                                    {
                                        nodeType: 'list-item',
                                        data: {},
                                        content: [
                                            {
                                                nodeType: 'paragraph',
                                                data: {},
                                                content: [
                                                    {
                                                        nodeType: 'text',
                                                        value: 'View your Purchased Events; and',
                                                        marks: [],
                                                        data: {},
                                                    },
                                                ],
                                            },
                                        ],
                                    },
                                    {
                                        nodeType: 'list-item',
                                        data: {},
                                        content: [
                                            {
                                                nodeType: 'paragraph',
                                                data: {},
                                                content: [
                                                    {
                                                        nodeType: 'text',
                                                        value: 'purchase Merchandise Products.',
                                                        marks: [],
                                                        data: {},
                                                    },
                                                ],
                                            },
                                        ],
                                    },
                                ],
                            },
                            {
                                nodeType: 'paragraph',
                                data: {},
                                content: [
                                    {
                                        nodeType: 'text',
                                        value: 'The list above is not an exhaustive list of the functions of the Platform and we may decide to offer additional Platform Functions, or cease to provide any Platform Functions, at any time.',
                                        marks: [],
                                        data: {},
                                    },
                                ],
                            },
                            {
                                nodeType: 'heading-3',
                                data: {},
                                content: [
                                    {
                                        nodeType: 'text',
                                        value: 'Registration',
                                        marks: [],
                                        data: {},
                                    },
                                ],
                            },
                            {
                                nodeType: 'paragraph',
                                data: {},
                                content: [
                                    {
                                        nodeType: 'text',
                                        value: 'You do not have to register to the Platform, but some of the Platform Functions are available only to users who register and set up an account.Â To register, you will need to complete a simple registration process by providing certain information (including your name, date of birth, email address and if you choose to provide it (though you do not have to) your gender). You will also need to choose a username and password for use in connection with your account.You agree that you will provide truthful and accurate information when registering on the Platform. The decision to register a password is in our discretion and we may revoke your password at any time.You are responsible for maintaining the confidentiality of your password and account information, and you are responsible for all activities that occur under your password or account and for any access to or use of the Services by you or any person or entity using your password, whether or not such access or use has been authorised by you.You must immediately notify us of any unauthorised use of your password or account or any other breach of security relating to the Services. We will not be liable for any loss or damage whatsoever resulting from the disclosure of your username and/or password contrary to these Terms.You are responsible for ensuring that the information we hold about you is up to date. Please amend your details as appropriate from time to time or emailÂ ',
                                        marks: [],
                                        data: {},
                                    },
                                    {
                                        nodeType: 'hyperlink',
                                        data: {
                                            uri: 'mailto:helpdesk@onair.events',
                                        },
                                        content: [
                                            {
                                                nodeType: 'text',
                                                value: 'helpdesk@onair.events',
                                                marks: [],
                                                data: {},
                                            },
                                        ],
                                    },
                                    {
                                        nodeType: 'text',
                                        value: 'Â to notify us of any changes.',
                                        marks: [],
                                        data: {},
                                    },
                                ],
                            },
                            {
                                nodeType: 'heading-3',
                                data: {},
                                content: [
                                    {
                                        nodeType: 'text',
                                        value: 'Individual accounts',
                                        marks: [],
                                        data: {},
                                    },
                                ],
                            },
                            {
                                nodeType: 'paragraph',
                                data: {},
                                content: [
                                    {
                                        nodeType: 'text',
                                        value: 'We offer one type of account: individual accounts (Private Accounts)As the names suggest:',
                                        marks: [],
                                        data: {},
                                    },
                                ],
                            },
                            {
                                nodeType: 'unordered-list',
                                data: {},
                                content: [
                                    {
                                        nodeType: 'list-item',
                                        data: {},
                                        content: [
                                            {
                                                nodeType: 'paragraph',
                                                data: {},
                                                content: [
                                                    {
                                                        nodeType: 'text',
                                                        value: 'Private Accounts are designed for individual users (Individual Users), who wish to access the Platform Functions for personal, non-commercial purposes.',
                                                        marks: [],
                                                        data: {},
                                                    },
                                                ],
                                            },
                                        ],
                                    },
                                ],
                            },
                            {
                                nodeType: 'paragraph',
                                data: {},
                                content: [
                                    {
                                        nodeType: 'text',
                                        value: 'All the Platform Functions are available to Individual Users.',
                                        marks: [],
                                        data: {},
                                    },
                                ],
                            },
                            {
                                nodeType: 'heading-3',
                                data: {},
                                content: [
                                    {
                                        nodeType: 'text',
                                        value: 'Fees and Payment',
                                        marks: [],
                                        data: {},
                                    },
                                ],
                            },
                            {
                                nodeType: 'paragraph',
                                data: {},
                                content: [
                                    {
                                        nodeType: 'text',
                                        value: 'Most of the Platform Functions are provided free of charge. For example, we will not charge you to set up your account.However, access to the Events is provided on a pay-per-view basis, and prices will appear on the relevant page(s) of the Platform and the Apps. Payment must be made in advance, in full.The prices mentioned in the offer of Merchandise Products are stated in Euros and exclude shipping costs, VAT, import duties and clearance charges.Â The amounts due for the Merchandise Products must be paid within 14 days of the start of the consideration period. If you fail to fulfil your payment obligation(s) on time, after we have notified you of the late payment and given you a period of 14 days to fulfil your payment obligations, and if payment has not been made within this 14-day period, you will owe statutory interest on the amount due, and we will be entitled to charge you for the extrajudicial collection costs we have incurred.We accept major cards for payment. By paying using your credit or debit card you confirm that the card is yours and that there are sufficient funds or credit available to cover the relevant fees.',
                                        marks: [],
                                        data: {},
                                    },
                                ],
                            },
                            {
                                nodeType: 'heading-3',
                                data: {},
                                content: [
                                    {
                                        nodeType: 'text',
                                        value: 'Delivery and Execution',
                                        marks: [],
                                        data: {},
                                    },
                                ],
                            },
                            {
                                nodeType: 'paragraph',
                                data: {},
                                content: [
                                    {
                                        nodeType: 'text',
                                        value: 'The place of delivery of the Merchandise Products is the address that you have made known to On Air.The Merchandise Products will be delivered by a third party. Which party delivers the Merchandise Products depends on the Event to which they relate.Â The delivery time of the Merchandise Products depends on the third party delivering the Merchandise Products. An indication of the delivery time is given during the ordering process. If the delivery is delayed, or if an order cannot be carried out, or can only be carried out partially, you will receive notification of this within 30 days of placing the order.',
                                        marks: [],
                                        data: {},
                                    },
                                ],
                            },
                            {
                                nodeType: 'heading-3',
                                data: {},
                                content: [
                                    {
                                        nodeType: 'text',
                                        value: 'Event postponement and cancellation',
                                        marks: [],
                                        data: {},
                                    },
                                ],
                            },
                            {
                                nodeType: 'paragraph',
                                data: {},
                                content: [
                                    {
                                        nodeType: 'text',
                                        value: 'If a Purchased Event is postponed for whatever reason, we will notify you via email and you will be offered the option of either accessing the Purchased Event at the new date and time, or receiving a full refund of the price you have paid for the postponed Purchased Event.Unless and until you let us know which option you prefer, then we will assume you want to access the Purchased Event at the new date and time.If a Purchased Event is cancelled entirely for whatever reason, you will be notified via email and we will automatically refund you the full price you have paid for the cancelled Purchased Event.Except for our obligations set out above, you acknowledge that we will not be liable to you in any way in respect of any postponed or cancelled events.',
                                        marks: [],
                                        data: {},
                                    },
                                ],
                            },
                            {
                                nodeType: 'heading-3',
                                data: {},
                                content: [
                                    {
                                        nodeType: 'text',
                                        value: 'Acceptable Use Restrictions',
                                        marks: [],
                                        data: {},
                                    },
                                ],
                            },
                            {
                                nodeType: 'paragraph',
                                data: {},
                                content: [
                                    {
                                        nodeType: 'text',
                                        value: 'You may use the Services for lawful purposes only. In particular, but without limitation, you agree not to:',
                                        marks: [],
                                        data: {},
                                    },
                                ],
                            },
                            {
                                nodeType: 'unordered-list',
                                data: {},
                                content: [
                                    {
                                        nodeType: 'list-item',
                                        data: {},
                                        content: [
                                            {
                                                nodeType: 'paragraph',
                                                data: {},
                                                content: [
                                                    {
                                                        nodeType: 'text',
                                                        value: 'Use the Services in any way that is unlawful or fraudulent, or has any unlawful or fraudulent purpose or effect;',
                                                        marks: [],
                                                        data: {},
                                                    },
                                                ],
                                            },
                                        ],
                                    },
                                    {
                                        nodeType: 'list-item',
                                        data: {},
                                        content: [
                                            {
                                                nodeType: 'paragraph',
                                                data: {},
                                                content: [
                                                    {
                                                        nodeType: 'text',
                                                        value: 'Use the Services, in any way that is defamatory, threatening, abusive, pornographic, or otherwise objectionable; or which advocates bigotry, hatred, or illegal discrimination;',
                                                        marks: [],
                                                        data: {},
                                                    },
                                                ],
                                            },
                                        ],
                                    },
                                    {
                                        nodeType: 'list-item',
                                        data: {},
                                        content: [
                                            {
                                                nodeType: 'paragraph',
                                                data: {},
                                                content: [
                                                    {
                                                        nodeType: 'text',
                                                        value: 'Use, share, or otherwise exploit the Services for any commercial, business, or monetised purpose whatsoever unless expressly permitted under these Terms;',
                                                        marks: [],
                                                        data: {},
                                                    },
                                                ],
                                            },
                                        ],
                                    },
                                    {
                                        nodeType: 'list-item',
                                        data: {},
                                        content: [
                                            {
                                                nodeType: 'paragraph',
                                                data: {},
                                                content: [
                                                    {
                                                        nodeType: 'text',
                                                        value: 'Reproduce, duplicate, copy, share, or re-sell any part of the Services in contravention of these Terms; use the Services in a way that could damage, disable, overburden, impair or compromise our systems or security or interfere with other users;',
                                                        marks: [],
                                                        data: {},
                                                    },
                                                ],
                                            },
                                        ],
                                    },
                                    {
                                        nodeType: 'list-item',
                                        data: {},
                                        content: [
                                            {
                                                nodeType: 'paragraph',
                                                data: {},
                                                content: [
                                                    {
                                                        nodeType: 'text',
                                                        value: 'Transmit any data, send or upload any material that contains viruses, Trojan horses, worms, time- bombs, keystroke loggers, spyware, adware or any other harmful programs or similar computer code designed to adversely affect the operation of Services; or',
                                                        marks: [],
                                                        data: {},
                                                    },
                                                ],
                                            },
                                        ],
                                    },
                                    {
                                        nodeType: 'list-item',
                                        data: {},
                                        content: [
                                            {
                                                nodeType: 'paragraph',
                                                data: {},
                                                content: [
                                                    {
                                                        nodeType: 'text',
                                                        value: 'Access without authority, interfere with, damage or disrupt (a) any part of the Services; (b) any equipment or network on which the Services are stored; (c) any software used in the provision of the Services; or (d) any equipment, network or software owned or used by any third party,',
                                                        marks: [],
                                                        data: {},
                                                    },
                                                ],
                                            },
                                        ],
                                    },
                                ],
                            },
                            {
                                nodeType: 'paragraph',
                                data: {},
                                content: [
                                    {
                                        nodeType: 'text',
                                        value: '(together the Acceptable Use Restrictions).',
                                        marks: [],
                                        data: {},
                                    },
                                ],
                            },
                            {
                                nodeType: 'heading-3',
                                data: {},
                                content: [
                                    {
                                        nodeType: 'text',
                                        value: 'Ending your rights under these terms',
                                        marks: [],
                                        data: {},
                                    },
                                ],
                            },
                            {
                                nodeType: 'paragraph',
                                data: {},
                                content: [
                                    {
                                        nodeType: 'text',
                                        value: 'We may end your rights under these Terms immediately and without notice if:',
                                        marks: [],
                                        data: {},
                                    },
                                ],
                            },
                            {
                                nodeType: 'unordered-list',
                                data: {},
                                content: [
                                    {
                                        nodeType: 'list-item',
                                        data: {},
                                        content: [
                                            {
                                                nodeType: 'paragraph',
                                                data: {},
                                                content: [
                                                    {
                                                        nodeType: 'text',
                                                        value: 'You have breached any of the App Licence Restrictions or the Acceptable Use Restrictions;',
                                                        marks: [],
                                                        data: {},
                                                    },
                                                ],
                                            },
                                        ],
                                    },
                                    {
                                        nodeType: 'list-item',
                                        data: {},
                                        content: [
                                            {
                                                nodeType: 'paragraph',
                                                data: {},
                                                content: [
                                                    {
                                                        nodeType: 'text',
                                                        value: 'We believe that your use of the Services is unsuitable in any way;',
                                                        marks: [],
                                                        data: {},
                                                    },
                                                ],
                                            },
                                        ],
                                    },
                                    {
                                        nodeType: 'list-item',
                                        data: {},
                                        content: [
                                            {
                                                nodeType: 'paragraph',
                                                data: {},
                                                content: [
                                                    {
                                                        nodeType: 'text',
                                                        value: 'Or you are otherwise in breach of these Terms. If we end your rights under these Terms:',
                                                        marks: [],
                                                        data: {},
                                                    },
                                                ],
                                            },
                                        ],
                                    },
                                    {
                                        nodeType: 'list-item',
                                        data: {},
                                        content: [
                                            {
                                                nodeType: 'paragraph',
                                                data: {},
                                                content: [
                                                    {
                                                        nodeType: 'text',
                                                        value: 'You must immediately stop all activities authorised by these Terms, including your access to the Services; and',
                                                        marks: [],
                                                        data: {},
                                                    },
                                                ],
                                            },
                                        ],
                                    },
                                    {
                                        nodeType: 'list-item',
                                        data: {},
                                        content: [
                                            {
                                                nodeType: 'paragraph',
                                                data: {},
                                                content: [
                                                    {
                                                        nodeType: 'text',
                                                        value: 'You must immediately delete or remove the Apps from all devices then in your possession, custody or control and, if required, confirm to us that you have done so.',
                                                        marks: [],
                                                        data: {},
                                                    },
                                                ],
                                            },
                                        ],
                                    },
                                ],
                            },
                            {
                                nodeType: 'heading-3',
                                data: {},
                                content: [
                                    {
                                        nodeType: 'text',
                                        value: 'User Content',
                                        marks: [],
                                        data: {},
                                    },
                                ],
                            },
                            {
                                nodeType: 'paragraph',
                                data: {},
                                content: [
                                    {
                                        nodeType: 'text',
                                        value: 'Anything and everything you upload to or share or communicate via the Services is referred to in these Terms as the User Content.You are solely responsible for ensuring that you have all the necessary rights to allÂ ',
                                        marks: [],
                                        data: {},
                                    },
                                    {
                                        nodeType: 'text',
                                        value: 'User Content',
                                        marks: [
                                            {
                                                type: 'bold',
                                            },
                                        ],
                                        data: {},
                                    },
                                    {
                                        nodeType: 'text',
                                        value: ', including without limitation all rights required to enable access to the User Content by other users of the Services.You hereby grant to us a royalty-free, worldwide, irrevocable licence in perpetuity to host and store the User Content via the Services and to make available the User Content to other users of the Services.As between us and you, the User Content is and will remain owned and controlled solely by you and you acknowledge that you have sole responsibility for the User Content.',
                                        marks: [],
                                        data: {},
                                    },
                                ],
                            },
                            {
                                nodeType: 'heading-3',
                                data: {},
                                content: [
                                    {
                                        nodeType: 'text',
                                        value: 'Intellectual Property Rights',
                                        marks: [],
                                        data: {},
                                    },
                                ],
                            },
                            {
                                nodeType: 'paragraph',
                                data: {},
                                content: [
                                    {
                                        nodeType: 'text',
                                        value: 'You acknowledge and agree that the Services, and all material published on, in, or via the Services other than the User Content (including but not limited to all text, graphics, photos, logos, button icons, images, trade marks, audio and audio visual clips, databases, data compilations, and data (together the On Air Content)) is owned and controlled by or licensed to us.You have no rights in, or to, the On Air Content other than the right to use the On Air Content strictly in accordance with these Terms.You may not copy, adapt, display, share, communicate to the public or otherwise use any On Air Content except as enabled and permitted by the Services and these Terms from time to time.',
                                        marks: [],
                                        data: {},
                                    },
                                ],
                            },
                            {
                                nodeType: 'heading-3',
                                data: {},
                                content: [
                                    {
                                        nodeType: 'text',
                                        value: 'Cancellation by you',
                                        marks: [],
                                        data: {},
                                    },
                                ],
                            },
                            {
                                nodeType: 'paragraph',
                                data: {},
                                content: [
                                    {
                                        nodeType: 'text',
                                        value: 'Your statutory right to cancel',
                                        marks: [
                                            {
                                                type: 'bold',
                                            },
                                        ],
                                        data: {},
                                    },
                                    {
                                        nodeType: 'text',
                                        value: ": If you are an individual user (i.e. not a business user) who has paid to access an event via the On Air service and/or ordered a Merchandise Product, you have a legal right - under the conditions and restrictions described below - to change your mind and cancel the contract you've entered into with us. This right lasts for 14 days from the day you make payment or, in the case of the purchase of Merchandise Products, from the day after you receive the Merchandise Products.Â ",
                                        marks: [],
                                        data: {},
                                    },
                                    {
                                        nodeType: 'text',
                                        value: 'When you cannot cancel',
                                        marks: [
                                            {
                                                type: 'bold',
                                            },
                                        ],
                                        data: {},
                                    },
                                    {
                                        nodeType: 'text',
                                        value: ': If you start to stream/access the event within the 14-day cancellation period (even if you do not access the whole thing) and you consented to this and acknowledged when ordering that you would lose your right to cancel if you did this, you will lose your right to cancel the contract.',
                                        marks: [],
                                        data: {},
                                    },
                                    {
                                        nodeType: 'text',
                                        value: 'Your obligations during the cooling-off period:',
                                        marks: [
                                            {
                                                type: 'bold',
                                            },
                                        ],
                                        data: {},
                                    },
                                    {
                                        nodeType: 'text',
                                        value: 'Â During the cooling-off period you will handle the Merchandise Products and the packaging with care. You will only unpack or use the product to the extent necessary to determine the nature, characteristics and operation thereof. The basic principle here is that you may only handle and inspect the Merchandise Products as you would in a shop.',
                                        marks: [],
                                        data: {},
                                    },
                                    {
                                        nodeType: 'text',
                                        value: 'How to tell us you want to cancel',
                                        marks: [
                                            {
                                                type: 'bold',
                                            },
                                        ],
                                        data: {},
                                    },
                                    {
                                        nodeType: 'text',
                                        value: ': You can inform us that you want to cancel by any clear means, including:By email. Email us atÂ ',
                                        marks: [],
                                        data: {},
                                    },
                                    {
                                        nodeType: 'hyperlink',
                                        data: {
                                            uri: 'mailto:helpdesk@onair.events',
                                        },
                                        content: [
                                            {
                                                nodeType: 'text',
                                                value: 'helpdesk@onair.events',
                                                marks: [],
                                                data: {},
                                            },
                                        ],
                                    },
                                    {
                                        nodeType: 'text',
                                        value: '. Please provide your name, address, details of the event you want to cancel and, where available, your phone number and email address.By post. Print off the model cancellation form below and post it to us at our address. Or simply write to us, including details of details of the event you want to cancel, when you paid for it, and your name and address.',
                                        marks: [],
                                        data: {},
                                    },
                                    {
                                        nodeType: 'text',
                                        value: 'Method of return:',
                                        marks: [
                                            {
                                                type: 'bold',
                                            },
                                        ],
                                        data: {},
                                    },
                                    {
                                        nodeType: 'text',
                                        value: 'Â You will return the Merchandise Products with all delivered accessories, if reasonably possible in the original condition and packaging, and in accordance with the reasonable and clear instructions provided by us or the supplier. You bear the risk and burden of proof of the correct and timely exercise of the right of withdrawal. You will be responsible for the costs of returning Merchandise.',
                                        marks: [],
                                        data: {},
                                    },
                                    {
                                        nodeType: 'text',
                                        value: 'Effect of cancellation',
                                        marks: [
                                            {
                                                type: 'bold',
                                            },
                                        ],
                                        data: {},
                                    },
                                    {
                                        nodeType: 'text',
                                        value: ': If you cancel as described above, we will refund the total price you paid for the cancelled event via the method you used for payment. Your refund will be made to you within 14 days of your telling us you want to cancel. Refunds for returned Merchandise Products will be made including the shipping costs you paid, no later than 14 days after we receive the returned Merchandise Products or after you prove that you returned the Merchandise Products.',
                                        marks: [],
                                        data: {},
                                    },
                                ],
                            },
                            {
                                nodeType: 'heading-3',
                                data: {},
                                content: [
                                    {
                                        nodeType: 'text',
                                        value: 'Availability of the services',
                                        marks: [],
                                        data: {},
                                    },
                                ],
                            },
                            {
                                nodeType: 'paragraph',
                                data: {},
                                content: [
                                    {
                                        nodeType: 'text',
                                        value: 'The Services are provided on an "as is" basis. We make no representations, warranties or guarantees of any kind regarding the availability or operation of the Services, or that the Services will be secure, uninterrupted or free of defects.Your access to the Services may be suspended or restricted occasionally to allow for maintenance, repairs, upgrades, or the introduction of new functions or services. We will not be liable to you if for any reason the Services are unavailable at any time or for any period. In addition, we may use third parties to (help) conduct our Services. We are not liable for any Services operated by third parties.',
                                        marks: [],
                                        data: {},
                                    },
                                ],
                            },
                            {
                                nodeType: 'heading-3',
                                data: {},
                                content: [
                                    {
                                        nodeType: 'text',
                                        value: 'Websites we link to',
                                        marks: [],
                                        data: {},
                                    },
                                ],
                            },
                            {
                                nodeType: 'paragraph',
                                data: {},
                                content: [
                                    {
                                        nodeType: 'text',
                                        value: 'The Services may offer links to other websites from which third party services can be obtained and which we reasonably believe to be reputable sources of such services. However, you acknowledge that these other websites are independent from us and we make no representations or warranties as to the legitimacy, accuracy or quality of such third party services, and we do not accept any responsibility for their content, safety, practices or privacy policies.',
                                        marks: [],
                                        data: {},
                                    },
                                ],
                            },
                            {
                                nodeType: 'heading-3',
                                data: {},
                                content: [
                                    {
                                        nodeType: 'text',
                                        value: 'Computer viruses',
                                        marks: [],
                                        data: {},
                                    },
                                ],
                            },
                            {
                                nodeType: 'paragraph',
                                data: {},
                                content: [
                                    {
                                        nodeType: 'text',
                                        value: 'We will use reasonable endeavours to ensure that no part of the Services will contain or spread any viruses or other malicious code.However, we recommend that you ensure that equipment used to access the Services run up-to-date anti-virus software as a precaution, and you are advised to virus-check any materials downloaded from the Services and regularly check for the presence of viruses and other malicious code.To the full extent permitted by law we exclude liability for damage or loss of any kind caused by viruses or other harmful components originating or contracted from the Services.',
                                        marks: [],
                                        data: {},
                                    },
                                ],
                            },
                            {
                                nodeType: 'heading-3',
                                data: {},
                                content: [
                                    {
                                        nodeType: 'text',
                                        value: 'No reliance on information',
                                        marks: [],
                                        data: {},
                                    },
                                ],
                            },
                            {
                                nodeType: 'paragraph',
                                data: {},
                                content: [
                                    {
                                        nodeType: 'text',
                                        value: 'All information published on or via the Services is provided in good faith and for general information purpose only. We make no warranties about the completeness, reliability, or accuracy of such information. Any action you take based on such information is taken at your own risk.',
                                        marks: [],
                                        data: {},
                                    },
                                ],
                            },
                            {
                                nodeType: 'heading-3',
                                data: {},
                                content: [
                                    {
                                        nodeType: 'text',
                                        value: 'Our responsibility for loss or damage suffered by you',
                                        marks: [],
                                        data: {},
                                    },
                                ],
                            },
                            {
                                nodeType: 'paragraph',
                                data: {},
                                content: [
                                    {
                                        nodeType: 'text',
                                        value: 'We exclude / limit our liability to the extent permitted by law. This includes liability for death or personal injury caused by our negligence or the negligence of our employees, agents or subcontractors and for fraud or fraudulent misrepresentation.If you are an Individual User: we provide the Services for domestic and private use only. You agree not to use Services for any commercial or business purposes.We are not responsible for the acts or omissions of any third party, including any third party app store from whom you download or access the Apps or third parties that are hired by us to manage or store your personal data.If any aspect of the Services damages a device or digital content belonging to you and this is caused by our failure to use reasonable care and skill, we will either repair the damage or pay you compensation.However, we will not be liable for damage that you could have avoided by following our advice to apply an update offered to you free of charge or for damage that was caused by you failing to correctly follow download, installation, or usage instructions or to have in place the minimum system requirements and anti-virus software advised by us.Except as set out in the paragraph above, you accept and agree we will not be liable for any harmful effect that accessing the Services may have on you, and you agree that you access the Services at your own risk.',
                                        marks: [],
                                        data: {},
                                    },
                                ],
                            },
                            {
                                nodeType: 'heading-3',
                                data: {},
                                content: [
                                    {
                                        nodeType: 'text',
                                        value: 'Communications between us',
                                        marks: [],
                                        data: {},
                                    },
                                ],
                            },
                            {
                                nodeType: 'paragraph',
                                data: {},
                                content: [
                                    {
                                        nodeType: 'text',
                                        value: 'If you wish to contact us for any reason, you can do so:',
                                        marks: [],
                                        data: {},
                                    },
                                ],
                            },
                            {
                                nodeType: 'unordered-list',
                                data: {},
                                content: [
                                    {
                                        nodeType: 'list-item',
                                        data: {},
                                        content: [
                                            {
                                                nodeType: 'paragraph',
                                                data: {},
                                                content: [
                                                    {
                                                        nodeType: 'text',
                                                        value: 'By e-mail at ',
                                                        marks: [],
                                                        data: {},
                                                    },
                                                    {
                                                        nodeType: 'hyperlink',
                                                        data: {
                                                            uri: 'mailto:helpdesk@onair.events',
                                                        },
                                                        content: [
                                                            {
                                                                nodeType:
                                                                    'text',
                                                                value: 'helpdesk@onair.events',
                                                                marks: [],
                                                                data: {},
                                                            },
                                                        ],
                                                    },
                                                    {
                                                        nodeType: 'text',
                                                        value: '.',
                                                        marks: [],
                                                        data: {},
                                                    },
                                                ],
                                            },
                                        ],
                                    },
                                    {
                                        nodeType: 'list-item',
                                        data: {},
                                        content: [
                                            {
                                                nodeType: 'paragraph',
                                                data: {},
                                                content: [
                                                    {
                                                        nodeType: 'text',
                                                        value: 'By writing to On Air B.V., Eemnesserweg 20, 3741 GA, Baarn, The Netherlands.',
                                                        marks: [],
                                                        data: {},
                                                    },
                                                ],
                                            },
                                        ],
                                    },
                                ],
                            },
                            {
                                nodeType: 'heading-3',
                                data: {},
                                content: [
                                    {
                                        nodeType: 'text',
                                        value: 'Complaints procedure',
                                        marks: [],
                                        data: {},
                                    },
                                ],
                            },
                            {
                                nodeType: 'paragraph',
                                data: {},
                                content: [
                                    {
                                        nodeType: 'text',
                                        value: 'We have adopted a complaints procedure and will deal with your complaint in accordance with this procedure.Â Complaints about the execution of the agreement must be submitted to us fully and clearly described within a reasonable time after you have discovered the defects. Complaints submitted to On Air shall be answered within a period of 14 days from the date of receipt. If a complaint requires a foreseeably longer processing time, we will send you within the period of 14 days a notice of receipt and an indication of when you can expect a more detailed answer.You must give us at least 4 weeks to resolve the complaint in mutual consultation. After this period, a dispute arises that is subject to the dispute settlement procedure.',
                                        marks: [],
                                        data: {},
                                    },
                                ],
                            },
                            {
                                nodeType: 'heading-3',
                                data: {},
                                content: [
                                    {
                                        nodeType: 'text',
                                        value: 'Other important terms',
                                        marks: [],
                                        data: {},
                                    },
                                ],
                            },
                            {
                                nodeType: 'paragraph',
                                data: {},
                                content: [
                                    {
                                        nodeType: 'text',
                                        value: 'We may transfer our rights and obligations under these Terms to another organisation, whose terms and conditions are presentedÂ ',
                                        marks: [],
                                        data: {},
                                    },
                                    {
                                        nodeType: 'hyperlink',
                                        data: {
                                            uri: 'https://www.shopify.com/legal/terms',
                                        },
                                        content: [
                                            {
                                                nodeType: 'text',
                                                value: 'here',
                                                marks: [],
                                                data: {},
                                            },
                                        ],
                                    },
                                    {
                                        nodeType: 'text',
                                        value: 'Â but this will not affect your rights or our obligations under these Terms. If we do not enforce our rights against you, or if we delay in doing so, that does not mean that we have waived our rights against you, and it does not mean that you are relieved of your obligations under these Terms. If we do waive a breach by you, we will only do so in writing, and that will not mean that we will automatically waive any later breach by you.Each of the terms and conditions of these Terms operates separately. If any court or competent authority decides that any of them are unlawful or unenforceable, the remaining terms and conditions will remain in full force and effect.These Terms are governed by Dutch law and the courts of Amsterdam, the Netherlands will have exclusive jurisdiction.',
                                        marks: [],
                                        data: {},
                                    },
                                ],
                            },
                            {
                                nodeType: 'heading-3',
                                data: {},
                                content: [
                                    {
                                        nodeType: 'text',
                                        value: 'Annex I: Model withdrawal form',
                                        marks: [],
                                        data: {},
                                    },
                                ],
                            },
                            {
                                nodeType: 'paragraph',
                                data: {},
                                content: [
                                    {
                                        nodeType: 'text',
                                        value: "Model withdrawal formÂ  Â Â (complete and return this form only if you wish to withdraw from the contract)- To:Â Â  Â Â [ trader's name][ geographical address of the trader][ fax number of trader, if available][ email address or electronic address of trader]Â  Â Â - I/We* hereby give notice that I/We* have decided to terminate our contract concerningthe sale of the following products: [product designation]*the supply of the following digital content: [digital content designation]*the provision of the following service: [service designation]*,revoked/revoked*Â  Â Â Â - Ordered on*/received on* [date of order for services or date of receipt for products].Â - Name of consumer(s)Â - Address of consumer(s)Â - Signature of consumer(s)] (only if this form is notified on paper)Â  Â Â * Delete where not applicable or fill in where appropriate.",
                                        marks: [],
                                        data: {},
                                    },
                                ],
                            },
                            {
                                nodeType: 'paragraph',
                                data: {},
                                content: [
                                    {
                                        nodeType: 'text',
                                        value: '10/02/2022, 13:51:06',
                                        marks: [],
                                        data: {},
                                    },
                                ],
                            },
                        ],
                    },
                    imagePosition: 'right',
                    shouldShowSocialButtons: false,
                    showAppStoreBanners: false,
                    type: 'Generic',
                },
            },
        ],
    },
};

export const termsConditionsNormalizedMock = [
    {
        __type: 'flexiblePage',
        slug: 'privacy-policy',
        seo: {
            __type: 'seo',
            title: 'Privacy Policy',
            description: 'Privacy Policy of On Air',
        },
        blocks: [
            {
                __type: 'contentBlock',
                title: 'Privacy Policy',
                description: {
                    nodeType: 'document',
                    data: {},
                    content: [
                        {
                            nodeType: 'heading-3',
                            data: {},
                            content: [
                                {
                                    nodeType: 'text',
                                    value: 'Who we are',
                                    marks: [],
                                    data: {},
                                },
                            ],
                        },
                        {
                            nodeType: 'paragraph',
                            data: {},
                            content: [
                                {
                                    nodeType: 'text',
                                    value: 'We are On Air B.V.',
                                    marks: [
                                        {
                                            type: 'bold',
                                        },
                                    ],
                                    data: {},
                                },
                                {
                                    nodeType: 'text',
                                    value: 'Â , located at the at Eemnesserweg 20, 3741 GA, Baarn in The Netherlands, and registered with the Dutch trade register (Kamer van Koophandel) as number 73550019 (',
                                    marks: [],
                                    data: {},
                                },
                                {
                                    nodeType: 'text',
                                    value: 'we, our, us',
                                    marks: [
                                        {
                                            type: 'bold',
                                        },
                                    ],
                                    data: {},
                                },
                                {
                                    nodeType: 'text',
                                    value: ').',
                                    marks: [],
                                    data: {},
                                },
                            ],
                        },
                        {
                            nodeType: 'heading-3',
                            data: {},
                            content: [
                                {
                                    nodeType: 'text',
                                    value: 'These Terms',
                                    marks: [],
                                    data: {},
                                },
                            ],
                        },
                        {
                            nodeType: 'paragraph',
                            data: {},
                            content: [
                                {
                                    nodeType: 'text',
                                    value: 'These terms and conditions (together with the documents referred to in them) (the Terms)',
                                    marks: [
                                        {
                                            type: 'bold',
                                        },
                                    ],
                                    data: {},
                                },
                                {
                                    nodeType: 'text',
                                    value: 'Â govern your relationship with us when access our event streaming platform located atÂ ',
                                    marks: [],
                                    data: {},
                                },
                                {
                                    nodeType: 'hyperlink',
                                    data: {
                                        uri: 'https://onair.events/',
                                    },
                                    content: [
                                        {
                                            nodeType: 'text',
                                            value: 'https://onair.events',
                                            marks: [],
                                            data: {},
                                        },
                                    ],
                                },
                                {
                                    nodeType: 'text',
                                    value: 'Â (theÂ ',
                                    marks: [],
                                    data: {},
                                },
                                {
                                    nodeType: 'text',
                                    value: 'Platform',
                                    marks: [
                                        {
                                            type: 'bold',
                                        },
                                    ],
                                    data: {},
                                },
                                {
                                    nodeType: 'text',
                                    value: ') and, if applicable, download and use our mobile app (theÂ ',
                                    marks: [],
                                    data: {},
                                },
                                {
                                    nodeType: 'text',
                                    value: 'Mobile App',
                                    marks: [
                                        {
                                            type: 'bold',
                                        },
                                    ],
                                    data: {},
                                },
                                {
                                    nodeType: 'text',
                                    value: ') from a third party platform and/or use our smart TV app (theÂ ',
                                    marks: [],
                                    data: {},
                                },
                                {
                                    nodeType: 'text',
                                    value: 'Smart TV App',
                                    marks: [
                                        {
                                            type: 'bold',
                                        },
                                    ],
                                    data: {},
                                },
                                {
                                    nodeType: 'text',
                                    value: ') and when you purchase merchandise products via the Platform (theÂ ',
                                    marks: [],
                                    data: {},
                                },
                                {
                                    nodeType: 'text',
                                    value: 'Merchandise Products',
                                    marks: [
                                        {
                                            type: 'bold',
                                        },
                                    ],
                                    data: {},
                                },
                                {
                                    nodeType: 'text',
                                    value: ').',
                                    marks: [],
                                    data: {},
                                },
                                {
                                    nodeType: 'text',
                                    value: 'Terminology:',
                                    marks: [
                                        {
                                            type: 'bold',
                                        },
                                    ],
                                    data: {},
                                },
                                {
                                    nodeType: 'text',
                                    value: 'Â In these Terms:',
                                    marks: [],
                                    data: {},
                                },
                            ],
                        },
                        {
                            nodeType: 'unordered-list',
                            data: {},
                            content: [
                                {
                                    nodeType: 'list-item',
                                    data: {},
                                    content: [
                                        {
                                            nodeType: 'paragraph',
                                            data: {},
                                            content: [
                                                {
                                                    nodeType: 'text',
                                                    value: 'Where we want to refer to the Platform, the Mobile App, and the Smart TV App together, we will say the ',
                                                    marks: [],
                                                    data: {},
                                                },
                                                {
                                                    nodeType: 'text',
                                                    value: 'Services',
                                                    marks: [
                                                        {
                                                            type: 'bold',
                                                        },
                                                    ],
                                                    data: {},
                                                },
                                                {
                                                    nodeType: 'text',
                                                    value: '.',
                                                    marks: [],
                                                    data: {},
                                                },
                                            ],
                                        },
                                    ],
                                },
                                {
                                    nodeType: 'list-item',
                                    data: {},
                                    content: [
                                        {
                                            nodeType: 'paragraph',
                                            data: {},
                                            content: [
                                                {
                                                    nodeType: 'text',
                                                    value: 'Where we want to refer to the Mobile App and the Smart TV App together, we will say the ',
                                                    marks: [],
                                                    data: {},
                                                },
                                                {
                                                    nodeType: 'text',
                                                    value: 'Apps',
                                                    marks: [
                                                        {
                                                            type: 'bold',
                                                        },
                                                    ],
                                                    data: {},
                                                },
                                                {
                                                    nodeType: 'text',
                                                    value: '. Otherwise, we will refer to things individually using the defined terms set out above.',
                                                    marks: [],
                                                    data: {},
                                                },
                                            ],
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            nodeType: 'paragraph',
                            data: {},
                            content: [
                                {
                                    nodeType: 'text',
                                    value: 'Please refer carefully to these Terms before you start to use any of the Services. We recommend that you print a copy of these Terms for future reference. By using any of the Services, you will be deemed to have understood and accepted these Terms, which form a binding agreement between you and us.',
                                    marks: [],
                                    data: {},
                                },
                            ],
                        },
                        {
                            nodeType: 'heading-3',
                            data: {},
                            content: [
                                {
                                    nodeType: 'text',
                                    value: 'Other applicable terms',
                                    marks: [],
                                    data: {},
                                },
                            ],
                        },
                        {
                            nodeType: 'paragraph',
                            data: {},
                            content: [
                                {
                                    nodeType: 'text',
                                    value: 'These Terms include our Privacy PolicyÂ ',
                                    marks: [],
                                    data: {},
                                },
                                {
                                    nodeType: 'hyperlink',
                                    data: {
                                        uri: 'https://www.onair.events/pages/privacy-policy',
                                    },
                                    content: [
                                        {
                                            nodeType: 'text',
                                            value: 'https://www.onair.events/pages/privacy-policy',
                                            marks: [],
                                            data: {},
                                        },
                                    ],
                                },
                                {
                                    nodeType: 'text',
                                    value: "Â (the Privacy Policy), which sets out the terms on which we process any personal data we collect from you or that you provide to us via the Platform. You may also be subject to third party terms relating to the Apps â€œsee the 'Mobile App' and 'Smart TV App' sections below for more information.",
                                    marks: [],
                                    data: {},
                                },
                            ],
                        },
                        {
                            nodeType: 'heading-3',
                            data: {},
                            content: [
                                {
                                    nodeType: 'text',
                                    value: 'Changes to these terms',
                                    marks: [],
                                    data: {},
                                },
                            ],
                        },
                        {
                            nodeType: 'paragraph',
                            data: {},
                            content: [
                                {
                                    nodeType: 'text',
                                    value: 'At any time, we may amend these Terms to ensure that we remain compliant with relevant laws and regulations and to ensure that we are constantly improving your experience using the Services. Please check this page from time to time to take notice of any changes we have made. By continuing to use any of the Services after changes are made, you are accepting those changes and will be bound by them.',
                                    marks: [],
                                    data: {},
                                },
                            ],
                        },
                        {
                            nodeType: 'heading-3',
                            data: {},
                            content: [
                                {
                                    nodeType: 'text',
                                    value: 'The platform',
                                    marks: [],
                                    data: {},
                                },
                            ],
                        },
                        {
                            nodeType: 'paragraph',
                            data: {},
                            content: [
                                {
                                    nodeType: 'text',
                                    value: 'We offer various functions via the Platform (together the Platform Functions), including those which allow you to:',
                                    marks: [],
                                    data: {},
                                },
                            ],
                        },
                        {
                            nodeType: 'unordered-list',
                            data: {},
                            content: [
                                {
                                    nodeType: 'list-item',
                                    data: {},
                                    content: [
                                        {
                                            nodeType: 'paragraph',
                                            data: {},
                                            content: [
                                                {
                                                    nodeType: 'text',
                                                    value: 'Browse the range of live events we offer for streaming via the Platform (the Events) and view information about the Events and the artists/performers;',
                                                    marks: [],
                                                    data: {},
                                                },
                                            ],
                                        },
                                    ],
                                },
                                {
                                    nodeType: 'list-item',
                                    data: {},
                                    content: [
                                        {
                                            nodeType: 'paragraph',
                                            data: {},
                                            content: [
                                                {
                                                    nodeType: 'text',
                                                    value: 'Purchase pay-per-view tickets to Events (each such Event being a Purchased Event); and',
                                                    marks: [],
                                                    data: {},
                                                },
                                            ],
                                        },
                                    ],
                                },
                                {
                                    nodeType: 'list-item',
                                    data: {},
                                    content: [
                                        {
                                            nodeType: 'paragraph',
                                            data: {},
                                            content: [
                                                {
                                                    nodeType: 'text',
                                                    value: 'View your Purchased Events; and',
                                                    marks: [],
                                                    data: {},
                                                },
                                            ],
                                        },
                                    ],
                                },
                                {
                                    nodeType: 'list-item',
                                    data: {},
                                    content: [
                                        {
                                            nodeType: 'paragraph',
                                            data: {},
                                            content: [
                                                {
                                                    nodeType: 'text',
                                                    value: 'purchase Merchandise Products.',
                                                    marks: [],
                                                    data: {},
                                                },
                                            ],
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            nodeType: 'paragraph',
                            data: {},
                            content: [
                                {
                                    nodeType: 'text',
                                    value: 'The list above is not an exhaustive list of the functions of the Platform and we may decide to offer additional Platform Functions, or cease to provide any Platform Functions, at any time.',
                                    marks: [],
                                    data: {},
                                },
                            ],
                        },
                        {
                            nodeType: 'heading-3',
                            data: {},
                            content: [
                                {
                                    nodeType: 'text',
                                    value: 'Registration',
                                    marks: [],
                                    data: {},
                                },
                            ],
                        },
                        {
                            nodeType: 'paragraph',
                            data: {},
                            content: [
                                {
                                    nodeType: 'text',
                                    value: 'You do not have to register to the Platform, but some of the Platform Functions are available only to users who register and set up an account.Â To register, you will need to complete a simple registration process by providing certain information (including your name, date of birth, email address and if you choose to provide it (though you do not have to) your gender). You will also need to choose a username and password for use in connection with your account.You agree that you will provide truthful and accurate information when registering on the Platform. The decision to register a password is in our discretion and we may revoke your password at any time.You are responsible for maintaining the confidentiality of your password and account information, and you are responsible for all activities that occur under your password or account and for any access to or use of the Services by you or any person or entity using your password, whether or not such access or use has been authorised by you.You must immediately notify us of any unauthorised use of your password or account or any other breach of security relating to the Services. We will not be liable for any loss or damage whatsoever resulting from the disclosure of your username and/or password contrary to these Terms.You are responsible for ensuring that the information we hold about you is up to date. Please amend your details as appropriate from time to time or emailÂ ',
                                    marks: [],
                                    data: {},
                                },
                                {
                                    nodeType: 'hyperlink',
                                    data: {
                                        uri: 'mailto:helpdesk@onair.events',
                                    },
                                    content: [
                                        {
                                            nodeType: 'text',
                                            value: 'helpdesk@onair.events',
                                            marks: [],
                                            data: {},
                                        },
                                    ],
                                },
                                {
                                    nodeType: 'text',
                                    value: 'Â to notify us of any changes.',
                                    marks: [],
                                    data: {},
                                },
                            ],
                        },
                        {
                            nodeType: 'heading-3',
                            data: {},
                            content: [
                                {
                                    nodeType: 'text',
                                    value: 'Individual accounts',
                                    marks: [],
                                    data: {},
                                },
                            ],
                        },
                        {
                            nodeType: 'paragraph',
                            data: {},
                            content: [
                                {
                                    nodeType: 'text',
                                    value: 'We offer one type of account: individual accounts (Private Accounts)As the names suggest:',
                                    marks: [],
                                    data: {},
                                },
                            ],
                        },
                        {
                            nodeType: 'unordered-list',
                            data: {},
                            content: [
                                {
                                    nodeType: 'list-item',
                                    data: {},
                                    content: [
                                        {
                                            nodeType: 'paragraph',
                                            data: {},
                                            content: [
                                                {
                                                    nodeType: 'text',
                                                    value: 'Private Accounts are designed for individual users (Individual Users), who wish to access the Platform Functions for personal, non-commercial purposes.',
                                                    marks: [],
                                                    data: {},
                                                },
                                            ],
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            nodeType: 'paragraph',
                            data: {},
                            content: [
                                {
                                    nodeType: 'text',
                                    value: 'All the Platform Functions are available to Individual Users.',
                                    marks: [],
                                    data: {},
                                },
                            ],
                        },
                        {
                            nodeType: 'heading-3',
                            data: {},
                            content: [
                                {
                                    nodeType: 'text',
                                    value: 'Fees and Payment',
                                    marks: [],
                                    data: {},
                                },
                            ],
                        },
                        {
                            nodeType: 'paragraph',
                            data: {},
                            content: [
                                {
                                    nodeType: 'text',
                                    value: 'Most of the Platform Functions are provided free of charge. For example, we will not charge you to set up your account.However, access to the Events is provided on a pay-per-view basis, and prices will appear on the relevant page(s) of the Platform and the Apps. Payment must be made in advance, in full.The prices mentioned in the offer of Merchandise Products are stated in Euros and exclude shipping costs, VAT, import duties and clearance charges.Â The amounts due for the Merchandise Products must be paid within 14 days of the start of the consideration period. If you fail to fulfil your payment obligation(s) on time, after we have notified you of the late payment and given you a period of 14 days to fulfil your payment obligations, and if payment has not been made within this 14-day period, you will owe statutory interest on the amount due, and we will be entitled to charge you for the extrajudicial collection costs we have incurred.We accept major cards for payment. By paying using your credit or debit card you confirm that the card is yours and that there are sufficient funds or credit available to cover the relevant fees.',
                                    marks: [],
                                    data: {},
                                },
                            ],
                        },
                        {
                            nodeType: 'heading-3',
                            data: {},
                            content: [
                                {
                                    nodeType: 'text',
                                    value: 'Delivery and Execution',
                                    marks: [],
                                    data: {},
                                },
                            ],
                        },
                        {
                            nodeType: 'paragraph',
                            data: {},
                            content: [
                                {
                                    nodeType: 'text',
                                    value: 'The place of delivery of the Merchandise Products is the address that you have made known to On Air.The Merchandise Products will be delivered by a third party. Which party delivers the Merchandise Products depends on the Event to which they relate.Â The delivery time of the Merchandise Products depends on the third party delivering the Merchandise Products. An indication of the delivery time is given during the ordering process. If the delivery is delayed, or if an order cannot be carried out, or can only be carried out partially, you will receive notification of this within 30 days of placing the order.',
                                    marks: [],
                                    data: {},
                                },
                            ],
                        },
                        {
                            nodeType: 'heading-3',
                            data: {},
                            content: [
                                {
                                    nodeType: 'text',
                                    value: 'Event postponement and cancellation',
                                    marks: [],
                                    data: {},
                                },
                            ],
                        },
                        {
                            nodeType: 'paragraph',
                            data: {},
                            content: [
                                {
                                    nodeType: 'text',
                                    value: 'If a Purchased Event is postponed for whatever reason, we will notify you via email and you will be offered the option of either accessing the Purchased Event at the new date and time, or receiving a full refund of the price you have paid for the postponed Purchased Event.Unless and until you let us know which option you prefer, then we will assume you want to access the Purchased Event at the new date and time.If a Purchased Event is cancelled entirely for whatever reason, you will be notified via email and we will automatically refund you the full price you have paid for the cancelled Purchased Event.Except for our obligations set out above, you acknowledge that we will not be liable to you in any way in respect of any postponed or cancelled events.',
                                    marks: [],
                                    data: {},
                                },
                            ],
                        },
                        {
                            nodeType: 'heading-3',
                            data: {},
                            content: [
                                {
                                    nodeType: 'text',
                                    value: 'Acceptable Use Restrictions',
                                    marks: [],
                                    data: {},
                                },
                            ],
                        },
                        {
                            nodeType: 'paragraph',
                            data: {},
                            content: [
                                {
                                    nodeType: 'text',
                                    value: 'You may use the Services for lawful purposes only. In particular, but without limitation, you agree not to:',
                                    marks: [],
                                    data: {},
                                },
                            ],
                        },
                        {
                            nodeType: 'unordered-list',
                            data: {},
                            content: [
                                {
                                    nodeType: 'list-item',
                                    data: {},
                                    content: [
                                        {
                                            nodeType: 'paragraph',
                                            data: {},
                                            content: [
                                                {
                                                    nodeType: 'text',
                                                    value: 'Use the Services in any way that is unlawful or fraudulent, or has any unlawful or fraudulent purpose or effect;',
                                                    marks: [],
                                                    data: {},
                                                },
                                            ],
                                        },
                                    ],
                                },
                                {
                                    nodeType: 'list-item',
                                    data: {},
                                    content: [
                                        {
                                            nodeType: 'paragraph',
                                            data: {},
                                            content: [
                                                {
                                                    nodeType: 'text',
                                                    value: 'Use the Services, in any way that is defamatory, threatening, abusive, pornographic, or otherwise objectionable; or which advocates bigotry, hatred, or illegal discrimination;',
                                                    marks: [],
                                                    data: {},
                                                },
                                            ],
                                        },
                                    ],
                                },
                                {
                                    nodeType: 'list-item',
                                    data: {},
                                    content: [
                                        {
                                            nodeType: 'paragraph',
                                            data: {},
                                            content: [
                                                {
                                                    nodeType: 'text',
                                                    value: 'Use, share, or otherwise exploit the Services for any commercial, business, or monetised purpose whatsoever unless expressly permitted under these Terms;',
                                                    marks: [],
                                                    data: {},
                                                },
                                            ],
                                        },
                                    ],
                                },
                                {
                                    nodeType: 'list-item',
                                    data: {},
                                    content: [
                                        {
                                            nodeType: 'paragraph',
                                            data: {},
                                            content: [
                                                {
                                                    nodeType: 'text',
                                                    value: 'Reproduce, duplicate, copy, share, or re-sell any part of the Services in contravention of these Terms; use the Services in a way that could damage, disable, overburden, impair or compromise our systems or security or interfere with other users;',
                                                    marks: [],
                                                    data: {},
                                                },
                                            ],
                                        },
                                    ],
                                },
                                {
                                    nodeType: 'list-item',
                                    data: {},
                                    content: [
                                        {
                                            nodeType: 'paragraph',
                                            data: {},
                                            content: [
                                                {
                                                    nodeType: 'text',
                                                    value: 'Transmit any data, send or upload any material that contains viruses, Trojan horses, worms, time- bombs, keystroke loggers, spyware, adware or any other harmful programs or similar computer code designed to adversely affect the operation of Services; or',
                                                    marks: [],
                                                    data: {},
                                                },
                                            ],
                                        },
                                    ],
                                },
                                {
                                    nodeType: 'list-item',
                                    data: {},
                                    content: [
                                        {
                                            nodeType: 'paragraph',
                                            data: {},
                                            content: [
                                                {
                                                    nodeType: 'text',
                                                    value: 'Access without authority, interfere with, damage or disrupt (a) any part of the Services; (b) any equipment or network on which the Services are stored; (c) any software used in the provision of the Services; or (d) any equipment, network or software owned or used by any third party,',
                                                    marks: [],
                                                    data: {},
                                                },
                                            ],
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            nodeType: 'paragraph',
                            data: {},
                            content: [
                                {
                                    nodeType: 'text',
                                    value: '(together the Acceptable Use Restrictions).',
                                    marks: [],
                                    data: {},
                                },
                            ],
                        },
                        {
                            nodeType: 'heading-3',
                            data: {},
                            content: [
                                {
                                    nodeType: 'text',
                                    value: 'Ending your rights under these terms',
                                    marks: [],
                                    data: {},
                                },
                            ],
                        },
                        {
                            nodeType: 'paragraph',
                            data: {},
                            content: [
                                {
                                    nodeType: 'text',
                                    value: 'We may end your rights under these Terms immediately and without notice if:',
                                    marks: [],
                                    data: {},
                                },
                            ],
                        },
                        {
                            nodeType: 'unordered-list',
                            data: {},
                            content: [
                                {
                                    nodeType: 'list-item',
                                    data: {},
                                    content: [
                                        {
                                            nodeType: 'paragraph',
                                            data: {},
                                            content: [
                                                {
                                                    nodeType: 'text',
                                                    value: 'You have breached any of the App Licence Restrictions or the Acceptable Use Restrictions;',
                                                    marks: [],
                                                    data: {},
                                                },
                                            ],
                                        },
                                    ],
                                },
                                {
                                    nodeType: 'list-item',
                                    data: {},
                                    content: [
                                        {
                                            nodeType: 'paragraph',
                                            data: {},
                                            content: [
                                                {
                                                    nodeType: 'text',
                                                    value: 'We believe that your use of the Services is unsuitable in any way;',
                                                    marks: [],
                                                    data: {},
                                                },
                                            ],
                                        },
                                    ],
                                },
                                {
                                    nodeType: 'list-item',
                                    data: {},
                                    content: [
                                        {
                                            nodeType: 'paragraph',
                                            data: {},
                                            content: [
                                                {
                                                    nodeType: 'text',
                                                    value: 'Or you are otherwise in breach of these Terms. If we end your rights under these Terms:',
                                                    marks: [],
                                                    data: {},
                                                },
                                            ],
                                        },
                                    ],
                                },
                                {
                                    nodeType: 'list-item',
                                    data: {},
                                    content: [
                                        {
                                            nodeType: 'paragraph',
                                            data: {},
                                            content: [
                                                {
                                                    nodeType: 'text',
                                                    value: 'You must immediately stop all activities authorised by these Terms, including your access to the Services; and',
                                                    marks: [],
                                                    data: {},
                                                },
                                            ],
                                        },
                                    ],
                                },
                                {
                                    nodeType: 'list-item',
                                    data: {},
                                    content: [
                                        {
                                            nodeType: 'paragraph',
                                            data: {},
                                            content: [
                                                {
                                                    nodeType: 'text',
                                                    value: 'You must immediately delete or remove the Apps from all devices then in your possession, custody or control and, if required, confirm to us that you have done so.',
                                                    marks: [],
                                                    data: {},
                                                },
                                            ],
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            nodeType: 'heading-3',
                            data: {},
                            content: [
                                {
                                    nodeType: 'text',
                                    value: 'User Content',
                                    marks: [],
                                    data: {},
                                },
                            ],
                        },
                        {
                            nodeType: 'paragraph',
                            data: {},
                            content: [
                                {
                                    nodeType: 'text',
                                    value: 'Anything and everything you upload to or share or communicate via the Services is referred to in these Terms as the User Content.You are solely responsible for ensuring that you have all the necessary rights to allÂ ',
                                    marks: [],
                                    data: {},
                                },
                                {
                                    nodeType: 'text',
                                    value: 'User Content',
                                    marks: [
                                        {
                                            type: 'bold',
                                        },
                                    ],
                                    data: {},
                                },
                                {
                                    nodeType: 'text',
                                    value: ', including without limitation all rights required to enable access to the User Content by other users of the Services.You hereby grant to us a royalty-free, worldwide, irrevocable licence in perpetuity to host and store the User Content via the Services and to make available the User Content to other users of the Services.As between us and you, the User Content is and will remain owned and controlled solely by you and you acknowledge that you have sole responsibility for the User Content.',
                                    marks: [],
                                    data: {},
                                },
                            ],
                        },
                        {
                            nodeType: 'heading-3',
                            data: {},
                            content: [
                                {
                                    nodeType: 'text',
                                    value: 'Intellectual Property Rights',
                                    marks: [],
                                    data: {},
                                },
                            ],
                        },
                        {
                            nodeType: 'paragraph',
                            data: {},
                            content: [
                                {
                                    nodeType: 'text',
                                    value: 'You acknowledge and agree that the Services, and all material published on, in, or via the Services other than the User Content (including but not limited to all text, graphics, photos, logos, button icons, images, trade marks, audio and audio visual clips, databases, data compilations, and data (together the On Air Content)) is owned and controlled by or licensed to us.You have no rights in, or to, the On Air Content other than the right to use the On Air Content strictly in accordance with these Terms.You may not copy, adapt, display, share, communicate to the public or otherwise use any On Air Content except as enabled and permitted by the Services and these Terms from time to time.',
                                    marks: [],
                                    data: {},
                                },
                            ],
                        },
                        {
                            nodeType: 'heading-3',
                            data: {},
                            content: [
                                {
                                    nodeType: 'text',
                                    value: 'Cancellation by you',
                                    marks: [],
                                    data: {},
                                },
                            ],
                        },
                        {
                            nodeType: 'paragraph',
                            data: {},
                            content: [
                                {
                                    nodeType: 'text',
                                    value: 'Your statutory right to cancel',
                                    marks: [
                                        {
                                            type: 'bold',
                                        },
                                    ],
                                    data: {},
                                },
                                {
                                    nodeType: 'text',
                                    value: ": If you are an individual user (i.e. not a business user) who has paid to access an event via the On Air service and/or ordered a Merchandise Product, you have a legal right - under the conditions and restrictions described below - to change your mind and cancel the contract you've entered into with us. This right lasts for 14 days from the day you make payment or, in the case of the purchase of Merchandise Products, from the day after you receive the Merchandise Products.Â ",
                                    marks: [],
                                    data: {},
                                },
                                {
                                    nodeType: 'text',
                                    value: 'When you cannot cancel',
                                    marks: [
                                        {
                                            type: 'bold',
                                        },
                                    ],
                                    data: {},
                                },
                                {
                                    nodeType: 'text',
                                    value: ': If you start to stream/access the event within the 14-day cancellation period (even if you do not access the whole thing) and you consented to this and acknowledged when ordering that you would lose your right to cancel if you did this, you will lose your right to cancel the contract.',
                                    marks: [],
                                    data: {},
                                },
                                {
                                    nodeType: 'text',
                                    value: 'Your obligations during the cooling-off period:',
                                    marks: [
                                        {
                                            type: 'bold',
                                        },
                                    ],
                                    data: {},
                                },
                                {
                                    nodeType: 'text',
                                    value: 'Â During the cooling-off period you will handle the Merchandise Products and the packaging with care. You will only unpack or use the product to the extent necessary to determine the nature, characteristics and operation thereof. The basic principle here is that you may only handle and inspect the Merchandise Products as you would in a shop.',
                                    marks: [],
                                    data: {},
                                },
                                {
                                    nodeType: 'text',
                                    value: 'How to tell us you want to cancel',
                                    marks: [
                                        {
                                            type: 'bold',
                                        },
                                    ],
                                    data: {},
                                },
                                {
                                    nodeType: 'text',
                                    value: ': You can inform us that you want to cancel by any clear means, including:By email. Email us atÂ ',
                                    marks: [],
                                    data: {},
                                },
                                {
                                    nodeType: 'hyperlink',
                                    data: {
                                        uri: 'mailto:helpdesk@onair.events',
                                    },
                                    content: [
                                        {
                                            nodeType: 'text',
                                            value: 'helpdesk@onair.events',
                                            marks: [],
                                            data: {},
                                        },
                                    ],
                                },
                                {
                                    nodeType: 'text',
                                    value: '. Please provide your name, address, details of the event you want to cancel and, where available, your phone number and email address.By post. Print off the model cancellation form below and post it to us at our address. Or simply write to us, including details of details of the event you want to cancel, when you paid for it, and your name and address.',
                                    marks: [],
                                    data: {},
                                },
                                {
                                    nodeType: 'text',
                                    value: 'Method of return:',
                                    marks: [
                                        {
                                            type: 'bold',
                                        },
                                    ],
                                    data: {},
                                },
                                {
                                    nodeType: 'text',
                                    value: 'Â You will return the Merchandise Products with all delivered accessories, if reasonably possible in the original condition and packaging, and in accordance with the reasonable and clear instructions provided by us or the supplier. You bear the risk and burden of proof of the correct and timely exercise of the right of withdrawal. You will be responsible for the costs of returning Merchandise.',
                                    marks: [],
                                    data: {},
                                },
                                {
                                    nodeType: 'text',
                                    value: 'Effect of cancellation',
                                    marks: [
                                        {
                                            type: 'bold',
                                        },
                                    ],
                                    data: {},
                                },
                                {
                                    nodeType: 'text',
                                    value: ': If you cancel as described above, we will refund the total price you paid for the cancelled event via the method you used for payment. Your refund will be made to you within 14 days of your telling us you want to cancel. Refunds for returned Merchandise Products will be made including the shipping costs you paid, no later than 14 days after we receive the returned Merchandise Products or after you prove that you returned the Merchandise Products.',
                                    marks: [],
                                    data: {},
                                },
                            ],
                        },
                        {
                            nodeType: 'heading-3',
                            data: {},
                            content: [
                                {
                                    nodeType: 'text',
                                    value: 'Availability of the services',
                                    marks: [],
                                    data: {},
                                },
                            ],
                        },
                        {
                            nodeType: 'paragraph',
                            data: {},
                            content: [
                                {
                                    nodeType: 'text',
                                    value: 'The Services are provided on an "as is" basis. We make no representations, warranties or guarantees of any kind regarding the availability or operation of the Services, or that the Services will be secure, uninterrupted or free of defects.Your access to the Services may be suspended or restricted occasionally to allow for maintenance, repairs, upgrades, or the introduction of new functions or services. We will not be liable to you if for any reason the Services are unavailable at any time or for any period. In addition, we may use third parties to (help) conduct our Services. We are not liable for any Services operated by third parties.',
                                    marks: [],
                                    data: {},
                                },
                            ],
                        },
                        {
                            nodeType: 'heading-3',
                            data: {},
                            content: [
                                {
                                    nodeType: 'text',
                                    value: 'Websites we link to',
                                    marks: [],
                                    data: {},
                                },
                            ],
                        },
                        {
                            nodeType: 'paragraph',
                            data: {},
                            content: [
                                {
                                    nodeType: 'text',
                                    value: 'The Services may offer links to other websites from which third party services can be obtained and which we reasonably believe to be reputable sources of such services. However, you acknowledge that these other websites are independent from us and we make no representations or warranties as to the legitimacy, accuracy or quality of such third party services, and we do not accept any responsibility for their content, safety, practices or privacy policies.',
                                    marks: [],
                                    data: {},
                                },
                            ],
                        },
                        {
                            nodeType: 'heading-3',
                            data: {},
                            content: [
                                {
                                    nodeType: 'text',
                                    value: 'Computer viruses',
                                    marks: [],
                                    data: {},
                                },
                            ],
                        },
                        {
                            nodeType: 'paragraph',
                            data: {},
                            content: [
                                {
                                    nodeType: 'text',
                                    value: 'We will use reasonable endeavours to ensure that no part of the Services will contain or spread any viruses or other malicious code.However, we recommend that you ensure that equipment used to access the Services run up-to-date anti-virus software as a precaution, and you are advised to virus-check any materials downloaded from the Services and regularly check for the presence of viruses and other malicious code.To the full extent permitted by law we exclude liability for damage or loss of any kind caused by viruses or other harmful components originating or contracted from the Services.',
                                    marks: [],
                                    data: {},
                                },
                            ],
                        },
                        {
                            nodeType: 'heading-3',
                            data: {},
                            content: [
                                {
                                    nodeType: 'text',
                                    value: 'No reliance on information',
                                    marks: [],
                                    data: {},
                                },
                            ],
                        },
                        {
                            nodeType: 'paragraph',
                            data: {},
                            content: [
                                {
                                    nodeType: 'text',
                                    value: 'All information published on or via the Services is provided in good faith and for general information purpose only. We make no warranties about the completeness, reliability, or accuracy of such information. Any action you take based on such information is taken at your own risk.',
                                    marks: [],
                                    data: {},
                                },
                            ],
                        },
                        {
                            nodeType: 'heading-3',
                            data: {},
                            content: [
                                {
                                    nodeType: 'text',
                                    value: 'Our responsibility for loss or damage suffered by you',
                                    marks: [],
                                    data: {},
                                },
                            ],
                        },
                        {
                            nodeType: 'paragraph',
                            data: {},
                            content: [
                                {
                                    nodeType: 'text',
                                    value: 'We exclude / limit our liability to the extent permitted by law. This includes liability for death or personal injury caused by our negligence or the negligence of our employees, agents or subcontractors and for fraud or fraudulent misrepresentation.If you are an Individual User: we provide the Services for domestic and private use only. You agree not to use Services for any commercial or business purposes.We are not responsible for the acts or omissions of any third party, including any third party app store from whom you download or access the Apps or third parties that are hired by us to manage or store your personal data.If any aspect of the Services damages a device or digital content belonging to you and this is caused by our failure to use reasonable care and skill, we will either repair the damage or pay you compensation.However, we will not be liable for damage that you could have avoided by following our advice to apply an update offered to you free of charge or for damage that was caused by you failing to correctly follow download, installation, or usage instructions or to have in place the minimum system requirements and anti-virus software advised by us.Except as set out in the paragraph above, you accept and agree we will not be liable for any harmful effect that accessing the Services may have on you, and you agree that you access the Services at your own risk.',
                                    marks: [],
                                    data: {},
                                },
                            ],
                        },
                        {
                            nodeType: 'heading-3',
                            data: {},
                            content: [
                                {
                                    nodeType: 'text',
                                    value: 'Communications between us',
                                    marks: [],
                                    data: {},
                                },
                            ],
                        },
                        {
                            nodeType: 'paragraph',
                            data: {},
                            content: [
                                {
                                    nodeType: 'text',
                                    value: 'If you wish to contact us for any reason, you can do so:',
                                    marks: [],
                                    data: {},
                                },
                            ],
                        },
                        {
                            nodeType: 'unordered-list',
                            data: {},
                            content: [
                                {
                                    nodeType: 'list-item',
                                    data: {},
                                    content: [
                                        {
                                            nodeType: 'paragraph',
                                            data: {},
                                            content: [
                                                {
                                                    nodeType: 'text',
                                                    value: 'By e-mail at ',
                                                    marks: [],
                                                    data: {},
                                                },
                                                {
                                                    nodeType: 'hyperlink',
                                                    data: {
                                                        uri: 'mailto:helpdesk@onair.events',
                                                    },
                                                    content: [
                                                        {
                                                            nodeType: 'text',
                                                            value: 'helpdesk@onair.events',
                                                            marks: [],
                                                            data: {},
                                                        },
                                                    ],
                                                },
                                                {
                                                    nodeType: 'text',
                                                    value: '.',
                                                    marks: [],
                                                    data: {},
                                                },
                                            ],
                                        },
                                    ],
                                },
                                {
                                    nodeType: 'list-item',
                                    data: {},
                                    content: [
                                        {
                                            nodeType: 'paragraph',
                                            data: {},
                                            content: [
                                                {
                                                    nodeType: 'text',
                                                    value: 'By writing to On Air B.V., Eemnesserweg 20, 3741 GA, Baarn, The Netherlands.',
                                                    marks: [],
                                                    data: {},
                                                },
                                            ],
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            nodeType: 'heading-3',
                            data: {},
                            content: [
                                {
                                    nodeType: 'text',
                                    value: 'Complaints procedure',
                                    marks: [],
                                    data: {},
                                },
                            ],
                        },
                        {
                            nodeType: 'paragraph',
                            data: {},
                            content: [
                                {
                                    nodeType: 'text',
                                    value: 'We have adopted a complaints procedure and will deal with your complaint in accordance with this procedure.Â Complaints about the execution of the agreement must be submitted to us fully and clearly described within a reasonable time after you have discovered the defects. Complaints submitted to On Air shall be answered within a period of 14 days from the date of receipt. If a complaint requires a foreseeably longer processing time, we will send you within the period of 14 days a notice of receipt and an indication of when you can expect a more detailed answer.You must give us at least 4 weeks to resolve the complaint in mutual consultation. After this period, a dispute arises that is subject to the dispute settlement procedure.',
                                    marks: [],
                                    data: {},
                                },
                            ],
                        },
                        {
                            nodeType: 'heading-3',
                            data: {},
                            content: [
                                {
                                    nodeType: 'text',
                                    value: 'Other important terms',
                                    marks: [],
                                    data: {},
                                },
                            ],
                        },
                        {
                            nodeType: 'paragraph',
                            data: {},
                            content: [
                                {
                                    nodeType: 'text',
                                    value: 'We may transfer our rights and obligations under these Terms to another organisation, whose terms and conditions are presentedÂ ',
                                    marks: [],
                                    data: {},
                                },
                                {
                                    nodeType: 'hyperlink',
                                    data: {
                                        uri: 'https://www.shopify.com/legal/terms',
                                    },
                                    content: [
                                        {
                                            nodeType: 'text',
                                            value: 'here',
                                            marks: [],
                                            data: {},
                                        },
                                    ],
                                },
                                {
                                    nodeType: 'text',
                                    value: 'Â but this will not affect your rights or our obligations under these Terms. If we do not enforce our rights against you, or if we delay in doing so, that does not mean that we have waived our rights against you, and it does not mean that you are relieved of your obligations under these Terms. If we do waive a breach by you, we will only do so in writing, and that will not mean that we will automatically waive any later breach by you.Each of the terms and conditions of these Terms operates separately. If any court or competent authority decides that any of them are unlawful or unenforceable, the remaining terms and conditions will remain in full force and effect.These Terms are governed by Dutch law and the courts of Amsterdam, the Netherlands will have exclusive jurisdiction.',
                                    marks: [],
                                    data: {},
                                },
                            ],
                        },
                        {
                            nodeType: 'heading-3',
                            data: {},
                            content: [
                                {
                                    nodeType: 'text',
                                    value: 'Annex I: Model withdrawal form',
                                    marks: [],
                                    data: {},
                                },
                            ],
                        },
                        {
                            nodeType: 'paragraph',
                            data: {},
                            content: [
                                {
                                    nodeType: 'text',
                                    value: "Model withdrawal formÂ  Â Â (complete and return this form only if you wish to withdraw from the contract)- To:Â Â  Â Â [ trader's name][ geographical address of the trader][ fax number of trader, if available][ email address or electronic address of trader]Â  Â Â - I/We* hereby give notice that I/We* have decided to terminate our contract concerningthe sale of the following products: [product designation]*the supply of the following digital content: [digital content designation]*the provision of the following service: [service designation]*,revoked/revoked*Â  Â Â Â - Ordered on*/received on* [date of order for services or date of receipt for products].Â - Name of consumer(s)Â - Address of consumer(s)Â - Signature of consumer(s)] (only if this form is notified on paper)Â  Â Â * Delete where not applicable or fill in where appropriate.",
                                    marks: [],
                                    data: {},
                                },
                            ],
                        },
                        {
                            nodeType: 'paragraph',
                            data: {},
                            content: [
                                {
                                    nodeType: 'text',
                                    value: '10/02/2022, 13:51:06',
                                    marks: [],
                                    data: {},
                                },
                            ],
                        },
                    ],
                },
                imagePosition: 'right',
                shouldShowSocialButtons: false,
                showAppStoreBanners: false,
                type: 'Generic',
            },
        ],
    },
];

export const contentfulImageMock: ContentfulImage = {
    __type: 'image',
    alt: 'mock placeholder',
    asset: {
        title: 'Mock Title',
        description: '',
        url: eventInfoLogoUrl,
        width: 366,
        height: 180,
        file: {
            url: eventInfoLogoUrl,
            details: {
                size: 25531,
                image: {
                    width: 366,
                    height: 180,
                },
            },
            fileName: 'Mock Title.png',
            contentType: 'image/png',
        },
    },
};

export const discoverCarouselMock = {
    sys: {
        type: 'Array',
    },
    total: 1,
    skip: 0,
    limit: 100,
    items: [
        {
            fields: {
                blocks: [
                    {
                        metadata: {
                            tags: [],
                        },
                        sys: {
                            space: {
                                sys: {
                                    type: 'Link',
                                    linkType: 'Space',
                                    id: 's26j9zm7rn0w',
                                },
                            },
                            id: '3zLNNj6dOHooGfuWydx34Q',
                            type: 'Entry',
                            createdAt: '2023-06-01T14:04:54.135Z',
                            updatedAt: '2023-06-01T14:04:54.135Z',
                            environment: {
                                sys: {
                                    id: 'development-editorial-carousel-1',
                                    type: 'Link',
                                    linkType: 'Environment',
                                },
                            },
                            revision: 1,
                            contentType: {
                                sys: {
                                    type: 'Link',
                                    linkType: 'ContentType',
                                    id: 'eventCarouselBlock',
                                },
                            },
                            locale: 'en-US',
                        },
                        fields: {
                            title: 'Past Shows',
                            landscape: true,
                            content: [
                                {
                                    metadata: {
                                        tags: [],
                                    },
                                    sys: {
                                        space: {
                                            sys: {
                                                type: 'Link',
                                                linkType: 'Space',
                                                id: 's26j9zm7rn0w',
                                            },
                                        },
                                        id: '77Gmn4M6hYfWqqxSpEzYDR',
                                        type: 'Entry',
                                        createdAt: '2021-10-19T13:51:04.350Z',
                                        updatedAt: '2021-12-14T14:52:11.154Z',
                                        environment: {
                                            sys: {
                                                id: 'development',
                                                type: 'Link',
                                                linkType: 'Environment',
                                            },
                                        },
                                        revision: 28,
                                        contentType: {
                                            sys: {
                                                type: 'Link',
                                                linkType: 'ContentType',
                                                id: 'event',
                                            },
                                        },
                                        locale: 'en-US',
                                    },
                                    fields: {
                                        title: 'Anne Marie Therapy Past',
                                        slug: 'annemarie-therapy-past',
                                        shopifyProductId: '6720059736256',
                                        variants: [
                                            {
                                                title: 'Friday / 20:00 AEST / HD + Stereo',
                                                sku: 'annemarie-therapy-fri-aest-hd',
                                                doorsOpen:
                                                    '2021-12-14T21:00+01:00',
                                                saleEnd:
                                                    '2021-12-11T21:00+01:00',
                                                eventStart:
                                                    '2021-12-14T14:00+01:00',
                                                eventEnd:
                                                    '2021-12-14T18:00+01:00',
                                                price: {
                                                    title: 'Default Variant Price',
                                                    EUR: 14.99,
                                                    USD: 14.99,
                                                    GBP: 14.99,
                                                    AUD: 14.99,
                                                },
                                                stream: {
                                                    title: 'annemarie-therapy-fri-aest-hd',
                                                    dashUrl: dashUrlMock,
                                                    hlsUrl: hlsUrlMock,
                                                },
                                                compareAtPrice: {
                                                    title: 'Default Variant Price CompareAt',
                                                    EUR: 17.99,
                                                    USD: 17.99,
                                                    GBP: 17.99,
                                                    AUD: 17.99,
                                                },
                                                options: [
                                                    {
                                                        position: 1,
                                                        value: 'Friday',
                                                    },
                                                    {
                                                        position: 2,
                                                        value: '20:00 AEST',
                                                    },
                                                    {
                                                        position: 3,
                                                        value: 'HD + Stereo',
                                                    },
                                                ],
                                            },
                                            {
                                                title: 'Saturday / 20:00 EST / HD + Stereo',
                                                sku: 'annemarie-therapy-sat-aest-hd',
                                                doorsOpen:
                                                    '2021-12-11T21:00+01:00',
                                                saleEnd:
                                                    '2021-12-11T21:00+01:00',
                                                eventStart:
                                                    '2021-12-04T20:00+01:00',
                                                eventEnd:
                                                    '2021-12-05T00:00+01:00',
                                                price: {
                                                    title: 'Default Variant Price',
                                                    EUR: 14.99,
                                                    USD: 14.99,
                                                    GBP: 14.99,
                                                    AUD: 14.99,
                                                },
                                                stream: {
                                                    title: 'annemarie-therapy-sat-aest-hd',
                                                    dashUrl: dashUrlMock,
                                                    hlsUrl: hlsUrlMock,
                                                },
                                                options: [
                                                    {
                                                        position: 1,
                                                        value: 'Saturday',
                                                    },
                                                    {
                                                        position: 2,
                                                        value: '20:00 EST',
                                                    },
                                                    {
                                                        position: 3,
                                                        value: 'HD + Stereo',
                                                    },
                                                ],
                                            },
                                            {
                                                title: 'Friday / 21:00 CEST / HD + Stereo',
                                                sku: 'annemarie-therapy-fri-cest-hd',
                                                doorsOpen:
                                                    '2021-12-11T21:00+01:00',
                                                saleEnd:
                                                    '2021-12-11T21:00+01:00',
                                                eventStart:
                                                    '2021-12-10T21:00+01:00',
                                                eventEnd:
                                                    '2021-12-10T23:00+01:00',
                                                price: {
                                                    title: 'Default Variant Price',
                                                    EUR: 14.99,
                                                    USD: 14.99,
                                                    GBP: 14.99,
                                                    AUD: 14.99,
                                                },
                                                stream: {
                                                    title: 'annemarie-therapy-fri-cest-hd',
                                                    dashUrl: dashUrlMock,
                                                    hlsUrl: hlsUrlMock,
                                                },
                                                options: [
                                                    {
                                                        position: 1,
                                                        value: 'Friday',
                                                    },
                                                    {
                                                        position: 2,
                                                        value: '21:00 CEST',
                                                    },
                                                    {
                                                        position: 3,
                                                        value: 'HD + Stereo',
                                                    },
                                                ],
                                            },
                                            {
                                                title: 'Saturday / 21:00 CEST / HD + Stereo',
                                                sku: 'annemarie-therapy-sat-cest-hd',
                                                doorsOpen:
                                                    '2021-12-11T21:00+01:00',
                                                saleEnd:
                                                    '2021-12-11T21:00+01:00',
                                                eventStart:
                                                    '2021-12-11T21:00+01:00',
                                                eventEnd:
                                                    '2021-12-12T00:00+01:00',
                                                price: {
                                                    title: 'Default Variant Price',
                                                    EUR: 14.99,
                                                    USD: 14.99,
                                                    GBP: 14.99,
                                                    AUD: 14.99,
                                                },
                                                stream: {
                                                    title: 'annemarie-therapy-sat-cest-hd',
                                                    dashUrl: dashUrlMock,
                                                    hlsUrl: hlsUrlMock,
                                                },
                                                options: [
                                                    {
                                                        position: 1,
                                                        value: 'Saturday',
                                                    },
                                                    {
                                                        position: 2,
                                                        value: '21:00 CEST',
                                                    },
                                                    {
                                                        position: 3,
                                                        value: 'HD + Stereo',
                                                    },
                                                ],
                                            },
                                            {
                                                title: 'Friday / 22:00 GMT / HD + Stereo',
                                                sku: 'annemarie-therapy-fri-gmt-hd',
                                                doorsOpen:
                                                    '2021-12-11T21:00+01:00',
                                                saleEnd:
                                                    '2021-12-11T21:00+01:00',
                                                eventStart:
                                                    '2021-12-10T22:00+01:00',
                                                eventEnd:
                                                    '2021-12-11T00:00+01:00',
                                                price: {
                                                    title: 'Default Variant Price',
                                                    EUR: 14.99,
                                                    USD: 14.99,
                                                    GBP: 14.99,
                                                    AUD: 14.99,
                                                },
                                                stream: {
                                                    title: 'annemarie-therapy-fri-gmt-hd',
                                                    dashUrl: dashUrlMock,
                                                    hlsUrl: hlsUrlMock,
                                                },
                                                options: [
                                                    {
                                                        position: 1,
                                                        value: 'Friday',
                                                    },
                                                    {
                                                        position: 2,
                                                        value: '22:00 GMT',
                                                    },
                                                    {
                                                        position: 3,
                                                        value: 'HD + Stereo',
                                                    },
                                                ],
                                            },
                                            {
                                                title: 'Saturday / 22:00 GMT / HD + Stereo',
                                                sku: 'annemarie-therapy-sat-gmt-hd',
                                                doorsOpen:
                                                    '2021-12-11T21:00+01:00',
                                                saleEnd:
                                                    '2021-12-11T21:00+01:00',
                                                eventStart:
                                                    '2021-12-10T22:00+01:00',
                                                eventEnd:
                                                    '2021-12-11T00:00+01:00',
                                                price: {
                                                    title: 'Default Variant Price',
                                                    EUR: 14.99,
                                                    USD: 14.99,
                                                    GBP: 14.99,
                                                    AUD: 14.99,
                                                },
                                                stream: {
                                                    title: 'annemarie-therapy-sat-gmt-hd',
                                                    dashUrl: dashUrlMock,
                                                    hlsUrl: hlsUrlMock,
                                                },
                                                options: [
                                                    {
                                                        position: 1,
                                                        value: 'Saturday',
                                                    },
                                                    {
                                                        position: 2,
                                                        value: '22:00 GMT',
                                                    },
                                                    {
                                                        position: 3,
                                                        value: 'HD + Stereo',
                                                    },
                                                ],
                                            },
                                            {
                                                title: 'Friday / 20:00 AEST / 4K UHD + Dolby Atmos',
                                                sku: 'annemarie-therapy-fri-aest-uhd',
                                                doorsOpen:
                                                    '2021-12-11T21:00+01:00',
                                                saleEnd:
                                                    '2021-12-11T21:00+01:00',
                                                eventStart:
                                                    '2021-12-10T20:00+01:00',
                                                eventEnd:
                                                    '2021-12-11T00:00+01:00',
                                                price: {
                                                    title: 'Default Variant Price',
                                                    EUR: 18.99,
                                                    USD: 18.99,
                                                    GBP: 18.99,
                                                    AUD: 18.99,
                                                },
                                                stream: {
                                                    title: 'annemarie-therapy-sat-gmt-hd',
                                                    dashUrl: dashUrlMock,
                                                    hlsUrl: hlsUrlMock,
                                                },
                                                compareAtPrice: {
                                                    title: 'Default Variant Price CompareAt',
                                                    EUR: 20.99,
                                                    USD: 20.99,
                                                    GBP: 20.99,
                                                    AUD: 20.99,
                                                },
                                                options: [
                                                    {
                                                        position: 1,
                                                        value: 'Friday',
                                                    },
                                                    {
                                                        position: 2,
                                                        value: '20:00 AEST',
                                                    },
                                                    {
                                                        position: 3,
                                                        value: '4K UHD + Dolby Atmos',
                                                    },
                                                ],
                                            },
                                            {
                                                title: 'Saturday / 20:00 AEST / 4K UHD + Dolby Atmos',
                                                sku: 'annemarie-therapy-sat-aest-uhd',
                                                doorsOpen:
                                                    '2021-12-11T21:00+01:00',
                                                saleEnd:
                                                    '2021-12-11T21:00+01:00',
                                                eventStart:
                                                    '2021-12-11T20:00+01:00',
                                                eventEnd:
                                                    '2021-12-12T00:00+01:00',
                                                price: {
                                                    title: 'Default Variant Price',
                                                    EUR: 18.99,
                                                    USD: 18.99,
                                                    GBP: 18.99,
                                                    AUD: 18.99,
                                                },
                                                stream: {
                                                    title: 'annemarie-therapy-sat-gmt-hd',
                                                    dashUrl: dashUrlMock,
                                                    hlsUrl: hlsUrlMock,
                                                },
                                                compareAtPrice: {
                                                    title: 'Default Variant Price CompareAt',
                                                    EUR: 20.99,
                                                    USD: 20.99,
                                                    GBP: 20.99,
                                                    AUD: 20.99,
                                                },
                                                options: [
                                                    {
                                                        position: 1,
                                                        value: 'Saturday',
                                                    },
                                                    {
                                                        position: 2,
                                                        value: '20:00 AEST',
                                                    },
                                                    {
                                                        position: 3,
                                                        value: '4K UHD + Dolby Atmos',
                                                    },
                                                ],
                                            },
                                            {
                                                title: 'Friday / 21:00 CEST / 4K UHD + Dolby Atmos',
                                                sku: 'annemarie-therapy-fri-cest-uhd',
                                                doorsOpen:
                                                    '2021-12-11T21:00+01:00',
                                                saleEnd:
                                                    '2021-12-11T21:00+01:00',
                                                eventStart:
                                                    '2021-12-10T21:00+01:00',
                                                eventEnd:
                                                    '2021-12-11T00:00+01:00',
                                                price: {
                                                    title: 'Default Variant Price',
                                                    EUR: 18.99,
                                                    USD: 18.99,
                                                    GBP: 18.99,
                                                    AUD: 18.99,
                                                },
                                                stream: {
                                                    title: 'annemarie-therapy-sat-gmt-hd',
                                                    dashUrl: dashUrlMock,
                                                    hlsUrl: hlsUrlMock,
                                                },
                                                compareAtPrice: {
                                                    title: 'Default Variant Price CompareAt',
                                                    EUR: 20.99,
                                                    USD: 20.99,
                                                    GBP: 20.99,
                                                    AUD: 20.99,
                                                },
                                                options: [
                                                    {
                                                        position: 1,
                                                        value: 'Friday',
                                                    },
                                                    {
                                                        position: 2,
                                                        value: '21:00 CEST',
                                                    },
                                                    {
                                                        position: 3,
                                                        value: '4K UHD + Dolby Atmos',
                                                    },
                                                ],
                                            },
                                            {
                                                title: 'Saturday / 21:00 CEST / 4K UHD + Dolby Atmos',
                                                sku: 'annemarie-therapy-sat-cest-uhd',
                                                doorsOpen:
                                                    '2021-12-11T21:00+01:00',
                                                saleEnd:
                                                    '2021-12-11T21:00+01:00',
                                                eventStart:
                                                    '2021-12-11T21:00+01:00',
                                                eventEnd:
                                                    '2021-12-12T00:00+01:00',
                                                price: {
                                                    title: 'Default Variant Price',
                                                    EUR: 18.99,
                                                    USD: 18.99,
                                                    GBP: 18.99,
                                                    AUD: 18.99,
                                                },
                                                stream: {
                                                    title: 'annemarie-therapy-sat-gmt-hd',
                                                    dashUrl: dashUrlMock,
                                                    hlsUrl: hlsUrlMock,
                                                },
                                                compareAtPrice: {
                                                    title: 'Default Variant Price CompareAt',
                                                    EUR: 20.99,
                                                    USD: 20.99,
                                                    GBP: 20.99,
                                                    AUD: 20.99,
                                                },
                                                options: [
                                                    {
                                                        position: 1,
                                                        value: 'Saturday',
                                                    },
                                                    {
                                                        position: 2,
                                                        value: '21:00 CEST',
                                                    },
                                                    {
                                                        position: 3,
                                                        value: '4K UHD + Dolby Atmos',
                                                    },
                                                ],
                                            },
                                            {
                                                title: 'Friday / 22:00 GMT / 4K UHD + Dolby Atmos',
                                                sku: 'annemarie-therapy-fri-gmt-uhd',
                                                doorsOpen:
                                                    '2021-12-11T21:00+01:00',
                                                saleEnd:
                                                    '2021-12-11T21:00+01:00',
                                                eventStart:
                                                    '2021-12-10T22:00+01:00',
                                                eventEnd:
                                                    '2021-12-11T00:00+01:00',
                                                price: {
                                                    title: 'Default Variant Price',
                                                    EUR: 18.99,
                                                    USD: 18.99,
                                                    GBP: 18.99,
                                                    AUD: 18.99,
                                                },
                                                stream: {
                                                    title: 'annemarie-therapy-sat-gmt-hd',
                                                    dashUrl: dashUrlMock,
                                                    hlsUrl: hlsUrlMock,
                                                },
                                                compareAtPrice: {
                                                    title: 'Default Variant Price CompareAt',
                                                    EUR: 20.99,
                                                    USD: 20.99,
                                                    GBP: 20.99,
                                                    AUD: 20.99,
                                                },
                                                options: [
                                                    {
                                                        position: 1,
                                                        value: 'Saturday',
                                                    },
                                                    {
                                                        position: 2,
                                                        value: '22:00 GMT',
                                                    },
                                                    {
                                                        position: 3,
                                                        value: '4K UHD + Dolby Atmos',
                                                    },
                                                ],
                                            },
                                            {
                                                title: 'Saturday / 22:00 GMT / 4K UHD + Dolby Atmos',
                                                sku: 'annemarie-therapy-sat-gmt-uhd',
                                                doorsOpen:
                                                    '2021-12-11T21:00+01:00',
                                                saleEnd:
                                                    '2021-12-11T21:00+01:00',
                                                eventStart:
                                                    '2021-12-11T22:00+01:00',
                                                eventEnd:
                                                    '2021-12-12T00:00+01:00',
                                                price: {
                                                    title: 'Default Variant Price',
                                                    EUR: 18.99,
                                                    USD: 18.99,
                                                    GBP: 18.99,
                                                    AUD: 18.99,
                                                },
                                                stream: {
                                                    title: 'annemarie-therapy-sat-gmt-hd',
                                                    dashUrl: dashUrlMock,
                                                    hlsUrl: hlsUrlMock,
                                                },
                                                compareAtPrice: {
                                                    title: 'Default Variant Price CompareAt',
                                                    EUR: 20.99,
                                                    USD: 20.99,
                                                    GBP: 20.99,
                                                    AUD: 20.99,
                                                },
                                                options: [
                                                    {
                                                        position: 1,
                                                        value: 'Saturday',
                                                    },
                                                    {
                                                        position: 2,
                                                        value: '22:00 GMT',
                                                    },
                                                    {
                                                        position: 3,
                                                        value: '4K UHD + Dolby Atmos',
                                                    },
                                                ],
                                            },
                                        ],
                                        poster: {
                                            asset: {
                                                title: 'Anne-Marie-Therapy',
                                                description:
                                                    'on air home page banner',
                                                file: {
                                                    url: '//images.ctfassets.net/s26j9zm7rn0w/3AvhknIQvgH6RVOUMTxQoq/51bd9204765c8a4b2798197a5c6395fa/Anne_Marie_1.jpeg',
                                                    details: {
                                                        size: 92908,
                                                        image: {
                                                            width: 750,
                                                            height: 387,
                                                        },
                                                    },
                                                    fileName:
                                                        'Anne Marie 1.jpeg',
                                                    contentType: 'image/jpeg',
                                                },
                                            },
                                            alt: 'Anne Marie listening to her fans with a mic in her hand',
                                        },
                                        eventInfoLogo: {
                                            asset: {
                                                title: 'Logo Title',
                                                description: '',
                                                file: {
                                                    url: eventInfoLogoUrl,
                                                    details: {
                                                        size: 25531,
                                                        image: {
                                                            width: 366,
                                                            height: 180,
                                                        },
                                                    },
                                                    fileName: 'Logo Title.png',
                                                    contentType: 'image/png',
                                                },
                                            },
                                            alt: 'Anne Marie Therapy - The Live Experience with a pink and pretty font',
                                        },
                                        tags: ['past-events'],
                                        type: 'spo',
                                        socialLinks: {
                                            instagramLink:
                                                'https://www.instagram.com/annemarie/',
                                            twitterLink:
                                                'https://twitter.com/annemarie',
                                            facebookLink:
                                                'https://facebook.com/iamannemarie',
                                        },
                                        trailer: streamMock,
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
};
