/* eslint-disable unicorn/consistent-function-scoping */
/* eslint-disable unicorn/prevent-abbreviations */
/* eslint-disable unicorn/no-array-for-each */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-secrets/no-secrets */
/* eslint-disable no-extra-boolean-cast */
/* eslint-disable unicorn/no-negated-condition */
/* eslint-disable unicorn/explicit-length-check */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unnecessary-condition */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable prefer-const */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { Carousel, ContentCollection, SimpleVariant } from '@/types/api';
import { normalizeContentfulDeliveryResponse } from '@/utils/contentful/normalizers';
import { getEnvironmentVariableServerSide } from '@/utils/server-functions/getEnvServerSide';

export const transformToOldEvent = (eventLight: any) => {
    const eventLightFormatted = normalizeContentfulDeliveryResponse(eventLight);
    const addTagsToEvent = ({
        startTime,
        showEnd,
    }: {
        startTime: string;
        showEnd: string;
    }) => {
        const currentDate = new Date();

        if (new Date(startTime) > currentDate) {
            return 'future-events';
        }
        if (new Date(showEnd) < currentDate) {
            return 'past-event';
        }

        return 'current-vod';
    };

    //TODO: zak implement - collections
    const fixedBlocks = [
        { __type: 'contentBlock', ...eventLightFormatted.eventDescription },
        { __type: 'contentBlock', ...eventLightFormatted.artistBioBlock },
        { __type: 'videoBlock', selfHostedVideo: eventLightFormatted.trailer },
        {
            __type: 'sellingPointsSection',
            sellingPoints:
                eventLightFormatted.sellingPoints?.sellingPointsCollection,
        },
        {
            __type: 'galleryBlock',
            images: eventLightFormatted.imageGallery?.imagesCollection,
        },
        { __type: 'spotifyPlaylist', ...eventLightFormatted.spotifyPlaylist },
        {
            __type: 'highlightsBlock',
            ...eventLightFormatted.highlights,
            highlights: eventLightFormatted.highlights?.highlightsCollection,
        },
        { __type: 'contentBlock', ...eventLightFormatted.treePlantedBlock },
        { __type: 'contentBlock', ...eventLightFormatted.buyShowNowBlock },
    ];

    const formatBlacklist = (
        blacklists: { '0': string; '1': string }[],
    ): { countryCode: string }[] =>
        blacklists?.map((country) => ({
            countryCode: `${country['0']}${country['1']}`,
        }));

    const formatVariant = (variants: SimpleVariant[]) => {
        const variantsOld = variants?.map((variant: SimpleVariant) => {
            return {
                id: variant.id,
                sku: variant.sku,
                options: [
                    {
                        position: 1,
                        value: variant.quality.displayLabel,
                    },
                ],
                icons:
                    variant.quality.horizontalQualityIconsCollection?.items ||
                    variant.quality.horizontalQualityIconsCollection,
                verticalIcons:
                    variant.quality.verticalQualityIconsCollection?.items ||
                    variant.quality.verticalQualityIconsCollection,
                title: variant.title,
                doorsOpen: eventLightFormatted.doorsOpenTime,
                eventStart: eventLightFormatted.showStartTime,
                saleEnd: eventLightFormatted.saleEndTime,
                eventEnd: eventLightFormatted.showEndTime,
                price: variant.price,
                compareAtPrice: variant.compareAtPrice,
                shopifyProductVariantId: variant.shopifyProductVariantId,
                stream: variant.stream,
            };
        });

        return variantsOld;
    };

    const eventOldFormat = {
        id: eventLightFormatted.id,
        title: eventLightFormatted.title,
        shortTitle: eventLightFormatted.shortTitle,
        slug: eventLightFormatted.slug,
        seo: eventLightFormatted.seo,
        shortDescription: eventLightFormatted.shortDescription,
        poster: {
            asset: eventLightFormatted.landscapeImage,
        },
        eventInfoLogo: {
            asset: eventLightFormatted.showLogo,
        },
        portraitPoster: {
            asset: eventLightFormatted.portraitImage,
        },
        carouselLogo: {
            asset: eventLightFormatted.artistLogo,
        },
        trailer: eventLightFormatted.trailer,
        tags: addTagsToEvent({
            startTime: eventLightFormatted.startTime,
            showEnd: eventLightFormatted.showEnd,
        }),
        vendors: eventLightFormatted.producer,
        venue: eventLightFormatted.venue,
        genres: eventLightFormatted.genres,
        variants: eventLightFormatted.variantComplex?.length
            ? eventLightFormatted.variantComplex
            : formatVariant(eventLightFormatted.variants),
        preferredVariant: formatVariant(eventLightFormatted.variants)?.[0],
        socialLinks: eventLightFormatted.socialLinks,
        merchandises: eventLightFormatted.merchandises,
        blocks: fixedBlocks,
        blacklistedCountries: formatBlacklist(eventLight.blacklist?.countries),
        type: eventLight.type,
        shopifyProductId: eventLight.shopifyProductId,
        relatedShows: eventLight.relatedShows,
    };

    return eventOldFormat;
};

export const fetchCarouselContent = async (
    data: Carousel[],
    page = 1,
    take = 10,
    category?: string,
    slug?: string,
) => {
    const { ONAIR_ENDPOINT } = await getEnvironmentVariableServerSide([
        'ONAIR_ENDPOINT',
    ]);
    const buildUrl = (baseEndpoint: string, params: any) => {
        const query = new URLSearchParams(params);
        return `${baseEndpoint}?${query.toString()}`;
    };

    const fetchEvents = async ({
        data,
        page,
        take,
        category,
    }: {
        data: any;
        page: number;
        take: number;
        category?: string;
    }) => {
        const promises: any = [];
        const commonParams = {
            platform: 'web',
            skip: (page - 1) * take,
            take: take,
        };

        data.forEach((d: any) => {
            const params = {
                ...commonParams,
                carouselId: d.id,
                pageId: '',
                filterId: '',
            };
            let endpoint;

            if (category && slug) {
                endpoint = `${ONAIR_ENDPOINT}/dynamic-page/events`;
                params.pageId = category;
                params.filterId = slug;
            } else {
                endpoint = `${ONAIR_ENDPOINT}/discover/events`;
            }

            const url = buildUrl(endpoint, params);

            promises.push(
                fetch(url, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    cache: 'no-store',
                }),
            );
        });

        return await Promise.all(promises);
    };

    let carousels: {
        id: string;
        title: string;
        size: string;
        __type: string;
        landscape: boolean;
        showDates: boolean;
        //TODO: remove this
        events: any;
        category?: string;
        slug?: string;
    }[] = [];

    await fetchEvents({ data, page, take, category })
        .then(async (responses) => {
            const promiseResponses = await Promise.all(
                responses.map((response) => response.json()),
            );
            carousels = promiseResponses.map((collection, index) => {
                return {
                    id: data[index].id,
                    title: data[index].title,
                    size: data[index].size,
                    __type: 'eventCarouselBlock',
                    landscape: data[index].size === 'small',
                    showDates: data[index].showDates,
                    category,
                    slug,
                    //TODO: remove this
                    events: collection?.data?.contentCollection?.length
                        ? collection?.data?.contentCollection?.map(
                              (content: ContentCollection) => ({
                                  ...content,
                                  poster: {
                                      asset: content.landscapeImage,
                                  },
                                  portraitPoster: {
                                      asset: content.portraitImage,
                                  },
                                  eventInfoLogo: {
                                      asset: content.showLogo,
                                  },
                                  carouselLogo: {
                                      asset: content.artistLogo,
                                  },
                              }),
                          )
                        : [],
                };
            });
        })
        .catch((error) => {
            console.log(error);
        });

    return carousels;
};

export const fetchDynamicPage = async ({
    preview,
    category,
    item,
}: {
    preview: boolean;
    item: {
        title: string;
        displayTitle: string;
    };
    category: string;
}) => {
    let pageCarousels;

    const carouselsResponseGenres = await fetch(
        `${
            process.env.ONAIR_ENDPOINT
        }/dynamic-page/carousels?pageId=${category}&platform=web&skip=0&take=100&preview=${
            preview ? 'true' : 'false'
        }
            &filterId=${item.title}`,
        {
            method: 'GET',
            headers: {
                'content-type': 'application/json',
            },
            cache: 'no-store',
        },
    );

    const { data: carouselsDataGenres, error: carouselsError } =
        await carouselsResponseGenres.json();

    if (carouselsError) return { error: 404 };

    const order = carouselsDataGenres?.discoverCarousels?.map(
        (carousel: { id: string }) => carousel?.id,
    );

    const heroResponse = await fetch(
        `${process.env.ONAIR_ENDPOINT}/event?eventId=${
            carouselsDataGenres.hero.event.id
            // '1hG9pMZunRp8iZahrpvDxv' // Test until API is implemented
        }&preview=${preview ? 'true' : 'false'}`,
        {
            method: 'GET',
            headers: {
                'content-type': 'application/json',
            },
            cache: 'no-store',
        },
    );

    const { data: heroData } = await heroResponse.json();

    const heroEvent = transformToOldEvent(heroData.event);
    const hero = {
        background: heroEvent.poster,
        __typename: 'Hero',
        ...heroEvent,
        event: heroEvent,
    };

    pageCarousels = await fetchCarouselContent(
        carouselsDataGenres.discoverCarousels,
        1,
        10,
        category,
        item.title,
    );

    //@ts-ignore
    const metaTitle = item.displayTitle || item.displayLabel;

    return {
        hero,
        blocks: [...pageCarousels].sort(
            (a, b) => order.indexOf(a.id) - order.indexOf(b.id),
        ),
        seo: {
            title:
                category === 'venue'
                    ? `${metaTitle} – Stream Live Concerts, Shows & Events | On Air`
                    : `Stream Live ${metaTitle} Music Concerts & Films | On Air`,
            description:
                category === 'venue'
                    ? `Streaming on demand: live concerts, events and shows from ${metaTitle}. Available now in cinematic quality.`
                    : ` Streaming on demand: live ${metaTitle} music concerts, events and shows from your favourite artists and bands in cinematic quality.`,
        },
    };
};

export const fetchFlexiblePageData = async ({
    data,
    preview,
    slug,
}: {
    data: any;
    preview: boolean;
    slug: string;
}) => {
    let heroEventFormatted = {};
    let discoverCarousels: any = [];
    let pageCarousels: any = [];
    const carouselsResponse = await fetch(
        `${
            process.env.ONAIR_ENDPOINT
        }/discover/carousels?platform=web&skip=0&take=100&preview=${
            preview ? 'true' : 'false'
        }`,
        {
            method: 'GET',
            headers: {
                'content-type': 'application/json',
            },
            cache: 'no-store',
        },
    );

    const { data: carouselsData } = await carouselsResponse.json();

    const heroResponse = await fetch(
        `${process.env.ONAIR_ENDPOINT}/event?eventId=${
            carouselsData.hero.event.id
        }&preview=${preview ? 'true' : 'false'}`,
        {
            method: 'GET',
            headers: {
                'content-type': 'application/json',
            },
            cache: 'no-store',
        },
    );

    const { data: heroData } = await heroResponse.json();
    const heroEvent = transformToOldEvent(heroData.event);
    heroEventFormatted = {
        background: heroEvent.poster,
        __typename: 'Hero',
        ...heroEvent,
        event: heroEvent,
    };
    const normalizedResponse =
        normalizeContentfulDeliveryResponse(data).flexiblePage?.[0];
    const pageContent = {
        ...normalizedResponse,
        seo: {
            slug,
            ...normalizedResponse.seo,
        },
    };

    pageCarousels = await fetchCarouselContent(
        pageContent.blocks
            .filter(
                (b: any) =>
                    b.__typename === 'EditorialCarousel' ||
                    b.__typename === 'EventCarousel',
            )
            .map((block: any) => {
                return {
                    title: block.title,
                    id: block.id,
                    size:
                        block.imageOrientation === 'Portrait'
                            ? 'medium'
                            : 'small',
                    showDates: block.showDates,
                };
            }),
    );
    const order = pageContent?.blocks?.map(
        (block: { sys: { id: string } }) => block?.sys?.id,
    );

    //To be removed
    const eventNormalize = normalizeContentfulDeliveryResponse(
        pageContent?.hero?.event,
    );

    const preferredVariantNormalize = normalizeContentfulDeliveryResponse(
        pageContent?.hero?.event?.preferredVariant,
    );

    const hero = Object.keys(normalizedResponse.hero || {}).length
        ? { ...normalizedResponse.hero }
        : heroEventFormatted;

    return {
        ...pageContent,
        hero: !!pageContent?.hero.__typename
            ? {
                  ...pageContent?.hero,
                  event: {
                      ...eventNormalize,
                      preferredVariant: preferredVariantNormalize,
                  },
              }
            : { ...hero },
        blocks: [...(pageContent?.blocks || []), ...pageCarousels].sort(
            (a, b) => order.indexOf(a.id) - order.indexOf(b.id),
        ),
        seo: pageContent.seo,
        carousel: discoverCarousels,
    };
};
