export const customerMessages = {
    error_already_enabled:
        'The user can not be enabled because it has been enabled already',
    error_email_bad_domain: 'The email contains an invalid domain name',
    error_blank:
        'The information provided seems to be empty, try to fill up the form and try again',
    error_contains_html_tag:
        'It seems that one of the fields contain an invalid character, avoid using special characters like <, > or /',
    error_contains_url:
        'It seems that one of the fields contain an url and it should not. Review the form and try to submit it again.',
    error_sending_recovery_email:
        'There was an error trying to recover your password, try again later',
    error_customer_disabled:
        'The change can not be done because the user is disabled',
    error_invalid_session:
        'The session seems to be invalid, try to logout and log in',
    error_too_long:
        'It seems that one of the fields exceeded the maximum number of characters. Review the form and try again',
    error_not_found: 'The resource you are looking for can not be found',
    error_password_starts_or_ends_with_whitespace:
        'The password starts or ends with a whitespace, remove it and try again',
    error_taken: 'This ${input} is already used. Please, enter another one',
    error_resetting_password:
        'There was an error trying to reset your password, try again later',
    error_unidentified_customer:
        'We could not find an account associated to this email',
    error_password_too_short:
        'The password needs to contain minimum 5 characters',
    error_invalid_reset_password_url:
        'We cannot reset your password because the link for resetting it is invalid',
};
